import { FETCH_HOME_FAVORITES, ADD_HOME_FAVORITE, REMOVE_HOME_FAVORITE } from '../actions/homeFavorites';

export default function favorites(state = [], { type, homeFavorites }) {
    switch (type) {
        case FETCH_HOME_FAVORITES:
            return Object.assign([], state, homeFavorites);
        case ADD_HOME_FAVORITE: {
            const addFavorites = [...state, homeFavorites];
            return _.uniq(addFavorites);
        }
        case REMOVE_HOME_FAVORITE:
            return state.filter((homeId) => homeId !== homeFavorites);
        default:
            return state;
    }
}
