import React from 'react';
import i18n from '../../i18n';
import Icons from '../../utils/Icons';
import Utils from '../../utils/utils';
import User from '../../models/User';

export default function useCollectionHomeStatusBlocker(user, home) {
    const isCollection = user.get('is_he_collection');
    const userCompletion = user.get('profil').filling_write;
    const homeCompletion = home.get('completion_rate');
    const isUserVerified = user.get('verified_status') === User.STATUS_VERIFIED;

    const showBlocker = (title, image, text, button, info, onConfirm) => {
        sweetAlert({
            content: (
                <div className="home-status-blocker collection-popup">
                    <div className="collection-popup-header">
                        {/* eslint-disable-next-line react/jsx-no-bind */}
                        <div
                            className="icon-collection-close"
                            onClick={() => {
                                sweetAlert.close();
                            }}
                        >
                            {Icons.transform(2)}
                        </div>
                    </div>
                    <div className="collection-popup-content">
                        <h4 className="collection-popup-title">{title}</h4>
                        <img
                            className="collection-popup-img"
                            src={`${Utils.getCloudfrontDomain()}/images/website/collection/${image}`}
                        />
                        <p className="collection-popup-text">{text}</p>
                        <button className="btn btn-primary collection-popup-button" onClick={onConfirm}>
                            {button}
                        </button>
                        {info && <p className="collection-popup-info">{info}</p>}
                    </div>
                </div>
            ),
            closeOnClickOutside: true,
            button: false,
            className: 'collection-popup-modal'
        });
    };

    if (!isCollection) {
        return false;
    }

    if (homeCompletion < 80) {
        showBlocker(
            i18n.t('collection:popup-blocker.first-missing-informations.title'),
            'listing-missing-information.svg',
            i18n.t('collection:popup-blocker.first-missing-informations.text'),
            i18n.t('collection:popup-blocker.first-missing-informations.button'),
            i18n.t('collection:popup-blocker.info'),
            () => {
                window.location.href = `/homes/edit/${home.get('id')}`;
            }
        );
    } else if (userCompletion < 80 && homeCompletion >= 80) {
        showBlocker(
            i18n.t('collection:popup-blocker.second-missing-informations.title'),
            'picture-missing-information.svg',
            i18n.t('collection:popup-blocker.second-missing-informations.text'),
            i18n.t('collection:popup-blocker.second-missing-informations.button'),
            i18n.t('collection:popup-blocker.info'),
            () => {
                window.location.href = `/user/edit/${user.get('id')}`;
            }
        );
    } else if (userCompletion >= 80 && homeCompletion >= 80 && !isUserVerified) {
        showBlocker(
            i18n.t('collection:popup-blocker.verified-member.title'),
            'id-verification.svg',
            i18n.t('collection:popup-blocker.verified-member.text'),
            i18n.t('collection:popup-blocker.verified-member.button'),
            undefined,
            () => {
                window.location.href = `/user/verification/${user.get('id')}`;
            }
        );
    } else {
        return false;
    }
}
