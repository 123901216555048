import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../i18n';

const EditSaveBar = ({ editing, sending, handleCancel, handleSave, isEnoughCompletionRate }) => {
    const popUpMessage = isEnoughCompletionRate
        ? i18n.t('common:popup-warning.home-enough-completion.text')
        : i18n.t('common:popup-warning.home-not-enough-completion.text');

    const icon = isEnoughCompletionRate ? 'icon-clock' : 'icon-error';

    return (
        <footer className={classNames('edit-savebar', { editing })}>
            <div className="container">
                <div className="row">
                    <div className="edit-savebar-actions">
                        <span className="updated-at">
                            <i className={icon}></i>
                            <strong>{popUpMessage}</strong>
                        </span>
                        <div className="edit-savebar-actions-buttons">
                            <button className="btn btn-white-outline btn-cancel" onClick={handleCancel}>
                                {i18n.t('common:cancel')}
                            </button>
                            <button
                                className={classNames(
                                    'btn btn-white-filled primary-color btn-save btn-ajax',
                                    { sending }
                                )}
                                onClick={handleSave}
                            >
                                {i18n.t('common:save_changes')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

EditSaveBar.propTypes = {
    editing: PropTypes.bool.isRequired,
    sending: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    isEnoughCompletionRate: PropTypes.bool.isRequired
};

export default EditSaveBar;
