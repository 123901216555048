import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import { CurrencyFormatter } from '../../utils/Intl';

import PaymentMainProduct from './PaymentMainProduct';
import PaymentSubTotal from './PaymentSubTotal';
import PaymentTotal from './PaymentTotal';
import Conversation from '../../models/Conversation';

export default class PaymentDescription extends React.PureComponent {
    renewalDate = moment().add(1, 'year').format('LL');

    static propTypes = {
        currency: PropTypes.string.isRequired,
        products: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                price: PropTypes.number,
                order: PropTypes.number
            })
        ).isRequired,
        conversations: PropTypes.arrayOf(PropTypes.instanceOf(Conversation)),
        totalToPay: PropTypes.number.isRequired,
        isSubscriber: PropTypes.bool,
        is3xPayment: PropTypes.bool,
        is3xPaymentFromFinalization: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.mapProductToMainProduct = this.mapProductToMainProduct.bind(this);
        this.mapProductToSubTotal = this.mapProductToSubTotal.bind(this);
        this.hasFinalizableExchange = this.hasFinalizableExchange.bind(this);
    }

    /**
     * Used to display the right text at the top of the panel
     */
    mapProductToMainProduct(products, currency) {
        // Main product is the first one (the array is sorted)
        const mainProduct = products[0];

        const path = window.location.pathname;
        const pathCollection = path === '/collection/propose-my-home/step7';

        switch (mainProduct.type) {
            case 'subscription':
            case 'renew-subscription':
                // new user not yet subscribed
                if (!this.props.isSubscriber && this.hasFinalizableExchange()) {
                    return {
                        title: i18n.t('subscription:payment.title1.exchange'),
                        features: mainProduct.isCollection
                            ? i18n.t('collection:billing.membership-features')
                            : i18n.t('subscription:payment.membership.features', {
                                  deposit: CurrencyFormatter.format(mainProduct.deposit, currency),
                                  date: this.renewalDate
                              })
                    };
                }
                return {
                    title: mainProduct.isCollection
                        ? pathCollection
                            ? i18n.t('collection:annual_membership')
                            : i18n.t('collection:billing.annual-membership-title')
                        : i18n.t('subscription:payment.title2'),
                    features: mainProduct.isCollection
                        ? pathCollection
                            ? i18n.t('collection:funnel.membership-features')
                            : i18n.t('collection:billing.membership-features')
                        : i18n.t('subscription:payment.membership.features', {
                              deposit: CurrencyFormatter.format(mainProduct.deposit, currency),
                              date: this.renewalDate
                          })
                };
            case 'gp':
                return {
                    title: i18n.t('subscription:purchase_guest_points'),
                    features: i18n.t('subscription:purchase_guest_points.features', {
                        amount: mainProduct.missingGP
                    })
                };
            default:
                return {};
        }
    }

    /**
     * Used to display the right lines in the subtotal
     */
    mapProductToSubTotal(product) {
        const path = window.location.pathname;
        const pathCollection = path === '/collection/propose-my-home/step7';
        switch (product.type) {
            case 'subscription':
            case 'renew-subscription':
                return {
                    mainText: product.isCollection
                        ? i18n.t('collection:billing.annual_membership')
                        : i18n.t('subscription:annual_membership'),
                    subText:
                        product.isCollection && product.type === 'subscription'
                            ? pathCollection
                                ? i18n.t('collection:funnel.membership-price')
                                : i18n.t('collection:billing.membership-price')
                            : i18n.t('subscription:renewed_on', {
                                  date: product.renewOn ? product.renewOn.format('LL') : this.renewalDate
                              }),
                    type: product.type,
                    price: product.price,
                    deposit: product.deposit,
                    isSubIcon: pathCollection
                };
            case 'gp':
                return {
                    mainText: i18n.t('subscription:purchase_guest_points'),
                    subText: `${product.missingGP} ${i18n.t('exchange:guestpoints')}`,
                    type: product.type,
                    price: product.price
                };
            default:
                return {};
        }
    }

    hasFinalizableExchange() {
        const { conversations } = this.props;
        if (!conversations || _.isEmpty(conversations)) {
            return false;
        }
        return conversations.some((conv) => {
            const exchangeAsGuest = conv.getExchangeAsGuest();
            if (!exchangeAsGuest) {
                return false;
            }
            return conv.isExchangeFinalizable(exchangeAsGuest);
        });
    }

    render() {
        const { currency, products, totalToPay } = this.props;

        if (!products || products.length <= 0) {
            return false;
        }

        const sortedProducts = products.sort((a, b) => a.order - b.order);
        const mainProduct = this.mapProductToMainProduct(sortedProducts, currency);

        return (
            <React.Fragment>
                <PaymentMainProduct {...mainProduct} />
                {products.map((product) => {
                    const productDetail = this.mapProductToSubTotal(product);

                    return (
                        <PaymentSubTotal
                            key={productDetail.type}
                            currency={currency}
                            {...productDetail}
                            is3xPayment={this.props.is3xPayment}
                            is3xPaymentFromFinalization={this.props.is3xPaymentFromFinalization}
                        />
                    );
                })}
                <PaymentTotal
                    currency={currency}
                    totalToPay={totalToPay}
                    is3xPayment={this.props.is3xPayment}
                    is3xPaymentFromFinalization={this.props.is3xPaymentFromFinalization}
                    productPrice={products[0].price}
                />
            </React.Fragment>
        );
    }
}
