import PropTypes from 'prop-types';
import React from 'react';

import Utils from '../../utils/utils';

import PaymentMethod from '../../models/PaymentMethod';

export default class PaymentMethodCard extends React.PureComponent {
    static propTypes = {
        paymentMethod: PropTypes.instanceOf(PaymentMethod)
    };

    getLogo() {
        switch (this.props.paymentMethod.get('brand').toLowerCase()) {
            case 'visa':
                return (
                    <img
                        src={`${Utils.getCloudfrontDomain()}/images/website/subscription/visa.svg`}
                        alt="Visa"
                    />
                );
            case 'mastercard':
                return (
                    <img
                        src={`${Utils.getCloudfrontDomain()}/images/website/subscription/mastercard.svg`}
                        alt="Mastercard"
                    />
                );
            case 'discover':
                return (
                    <img
                        src={`${Utils.getCloudfrontDomain()}/images/website/subscription/discover.svg`}
                        alt="Discover"
                    />
                );
            case 'american express':
                return (
                    <img
                        src={`${Utils.getCloudfrontDomain()}/images/website/subscription/american.svg`}
                        alt="American Express"
                    />
                );
            default:
                return null;
        }
    }

    render() {
        const { paymentMethod } = this.props;
        const expirationMonth = paymentMethod.get('exp_month').toString().padStart(2, '0');
        const expirationDate = `${expirationMonth}/${paymentMethod.get('exp_year')}`;

        return (
            <React.Fragment>
                <div className="payment-method-logo">{this.getLogo()}</div>
                <div className="payment-method-info">
                    <span>&#x25CF;&#x25CF;&#x25CF;&#x25CF; {paymentMethod.get('last4')}</span>
                    <span className="payment-method-info-expiration">{expirationDate}</span>
                </div>
            </React.Fragment>
        );
    }
}
