import { SET_HOMES, ADD_HOME, SAVE_HOME } from '../actions/homes';

import Analytics from '../utils/analytics';

const hasInitialNeighborhoodDescriptions = {};

const hasNeighborhoodDescription = (home) => {
    if (home && home.has('descriptions')) {
        return home.get('descriptions').some((homeDescription) => {
            if (homeDescription.good_place) {
                return true;
            }
            return false;
        });
    }
};

const trackHomeNeighborhood = (store, action) => {
    if (action.type === ADD_HOME) {
        // Store whether the home had an initial neighborhood description
        hasInitialNeighborhoodDescriptions[action.payload.id] = hasNeighborhoodDescription(action.payload);
    } else if (action.type === SET_HOMES) {
        if (action.payload) {
            // Store whether the home had an initial neighborhood description
            action.payload.forEach((home) => {
                hasInitialNeighborhoodDescriptions[home.id] = hasNeighborhoodDescription(home);
            });
        }
    } else if (action.type === SAVE_HOME) {
        // On save, check if that the neighborhood description is not empty anymore and fire the event
        if (action.payload && action.payload.id) {
            const nextHome = action.payload;
            const neighborhoodIsFilled = hasNeighborhoodDescription(nextHome);

            if (
                hasInitialNeighborhoodDescriptions.hasOwnProperty(nextHome.id) &&
                !hasInitialNeighborhoodDescriptions[nextHome.id] &&
                neighborhoodIsFilled
            ) {
                // GTM track only
                Analytics.trackGTM('Home neighborhood');
                // Prevent the event from being sent twice
                hasInitialNeighborhoodDescriptions[nextHome.id] = true;
            }
        }
    }
};

export default trackHomeNeighborhood;
export { hasNeighborhoodDescription };
