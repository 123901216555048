import { useEffect, useRef } from 'react';
import Screen from '../utils/Screen';

export const useSmoothScrollToActive = (
    sliderRef,
    elementSelector,
    activeSmoothScroll = false,
    delay = 100
) => {
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (sliderRef) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                const parentContainer = activeSmoothScroll
                    ? sliderRef.current
                    : sliderRef.current.innerSlider.list;
                const firstActiveElement = parentContainer.querySelector(elementSelector);

                if (firstActiveElement) {
                    if (Screen.isMobile() || activeSmoothScroll) {
                        const parentRight = parentContainer.getBoundingClientRect().right;
                        const firstActiveElementRight = firstActiveElement.getBoundingClientRect().right;
                        if (
                            (!(firstActiveElementRight <= parentRight) && activeSmoothScroll) ||
                            !activeSmoothScroll
                        ) {
                            parentContainer.scrollTo({
                                left: firstActiveElement.offsetLeft,
                                behavior: 'smooth'
                            });
                        }
                    } else {
                        const dataIndex = firstActiveElement
                            .closest('[data-index]')
                            .getAttribute('data-index');
                        const elementWithIndex = document
                            .querySelector(`[data-index="${dataIndex}"]`)
                            .getBoundingClientRect();
                        const slickSlider = document.querySelector('.slick-slider').getBoundingClientRect();

                        if (
                            (!(elementWithIndex.right <= slickSlider.right) && activeSmoothScroll) ||
                            !activeSmoothScroll
                        ) {
                            const index = parseInt(dataIndex, 10);
                            sliderRef.current.slickGoTo(index, false);
                        }
                    }
                }
            }, delay);
        }

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [sliderRef, elementSelector, delay]);
};
