import React from 'react';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';

import User from '../../models/User';

import i18n from '../../i18n';
import { stringToJSX } from '../../utils/react';

import withPaymentFormTools from './withPaymentFormTools';
import PaymentDetails from './PaymentDetails';
import AddPaymentMethodFormActions from './AddPaymentMethodFormActions';
import PaypalButton from './PaypalButton';
import Loading from '../common/Loading';
import CollectionFunnelEvents from '../../utils/collectionFunnelEvents';

export class AddPaymentMethodForm extends React.PureComponent {
    static propTypes = {
        stripe: PropTypes.object,
        context: PropTypes.oneOf(['messaging', 'subscription-payment', 'my-plan']),
        user: PropTypes.instanceOf(User).isRequired,
        addCreditCardAction: PropTypes.func.isRequired,
        addPaypalAction: PropTypes.func.isRequired,
        getBraintreeToken: PropTypes.func.isRequired,
        collapseParentPanel: PropTypes.func.isRequired,
        fields: PropTypes.element.isRequired,
        getTokenPromise: PropTypes.func.isRequired,
        validateCreditCardForm: PropTypes.func.isRequired,
        clearCreditCardForm: PropTypes.func.isRequired,
        errorHandling: PropTypes.shape({
            errorMessage: PropTypes.string,
            formErrors: PropTypes.object,
            listFormErrors: PropTypes.func,
            setError: PropTypes.func,
            setFormError: PropTypes.func,
            clearFormErrors: PropTypes.func,
            handleError: PropTypes.func,
            handleApiError: PropTypes.func,
            handlePaypalError: PropTypes.func
        }),
        isFunnelCollection: PropTypes.bool,
        logoutPopup: PropTypes.func,
        getSending: PropTypes.func,
        track: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            requestInProgress: false,
            selectedPaymentMethod: 'credit-card',
            braintreeToken: null,
            couponName: ''
        };

        this.form = React.createRef();

        this.disableButtons = this.disableButtons.bind(this);
        this.enableButtons = this.enableButtons.bind(this);
        this.getCouponName = this.getCouponName.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleApiError = this.handleApiError.bind(this);
        this.addPaymentMethodPromise = this.addPaymentMethodPromise.bind(this);
        this.addCreditCardPromise = this.addCreditCardPromise.bind(this);
        this.addPaypalPromise = this.addPaypalPromise.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onPaymentMethodChange = this.onPaymentMethodChange.bind(this);
    }

    componentDidMount() {
        this.loadBraintreeToken();
    }

    disableButtons() {
        this.setState({
            requestInProgress: true
        });
    }

    enableButtons() {
        this.setState({
            requestInProgress: false
        });
    }

    loadBraintreeToken() {
        const { getBraintreeToken, errorHandling } = this.props;
        const { selectedPaymentMethod } = this.state;

        getBraintreeToken().then(
            (token) => {
                this.setState({
                    braintreeToken: token
                });
            },
            () => {
                if (selectedPaymentMethod === 'paypal') {
                    errorHandling.handleError({
                        type: 'error',
                        code: 'braintree-token',
                        message: 'Unable to get necessary information to load Paypal button'
                    });
                    this.setState({
                        selectedPaymentMethod: null
                    });
                }
            }
        );
    }

    showApiError() {
        const content = stringToJSX(
            i18n.t('billing:error.generic-message', {
                contact_start: `<a href="mailto:${i18n.t('common:contact-email')}"'>`,
                contact_end: '</a>'
            })
        );
        sweetAlert({
            title: i18n.t('common:an_error_occured'),
            content,
            icon: 'error'
        });
    }

    handleError(e) {
        this.enableButtons();
        this.props.errorHandling.handleError(e);
    }

    handleApiError({ payload }) {
        const { errorHandling, logoutPopup, isFunnelCollection } = this.props;

        if (logoutPopup && isFunnelCollection) {
            this.props.getSending(false);
        }

        if (!payload.code || payload.code !== 402) {
            if (isFunnelCollection) {
                errorHandling.setError(i18n.t('billing:error.card-declined'));
            }

            if (payload.code && payload.code == 500) {
                this.showApiError();
                this.enableButtons();
                return;
            }
        }

        if (!isFunnelCollection) {
            errorHandling.handleApiError(payload);
        }
        this.enableButtons();
    }

    addPaymentMethodPromise(type, ...data) {
        const {
            addCreditCardAction,
            addPaypalAction,
            collapseParentPanel,
            clearCreditCardForm,
            logoutPopup,
            isFunnelCollection
        } = this.props;
        const { couponName } = this.state;
        let action;
        if (type === 'paypal') {
            action = addPaypalAction;
        } else {
            action = addCreditCardAction;
        }

        if (isFunnelCollection) {
            this.props.getSending(true);
        }

        return action(...data, isFunnelCollection && isFunnelCollection, type, couponName && couponName).then(
            () => {
                if (logoutPopup && isFunnelCollection) {
                    this.props.getSending(false);
                    logoutPopup(this.props.user.get('email'));
                    this.props.track('HEC Home created', { category: 'HEC application' });
                    CollectionFunnelEvents.funnelCompleted();
                    this.setState({ couponName: '' });
                }
                clearCreditCardForm(this.form);
                this.enableButtons();
                collapseParentPanel();
            },
            this.handleApiError
        );
    }

    addCreditCardPromise() {
        const { getTokenPromise, validateCreditCardForm, stripe } = this.props;
        return validateCreditCardForm(stripe, this.form).then(
            () =>
                getTokenPromise(stripe, this.form).then((response) => {
                    this.addPaymentMethodPromise('credit-card', response.token);
                }, this.handleError),
            () => {
                this.enableButtons();
            }
        );
    }

    addPaypalPromise(token, data) {
        this.addPaymentMethodPromise('paypal', token, data);
    }

    onSubmit(e) {
        e.preventDefault();

        const { errorHandling } = this.props;
        const { selectedPaymentMethod } = this.state;

        // Reset global error
        errorHandling.setError(null);

        if (selectedPaymentMethod !== 'credit-card') {
            // Do nothing if current view is not credit-card
            return;
        }

        // A request is already in progress
        if (this.state.requestInProgress) {
            return;
        }

        this.disableButtons();

        return this.addCreditCardPromise();
    }

    onCancel() {
        const { errorHandling, clearCreditCardForm } = this.props;
        this.props.collapseParentPanel();
        clearCreditCardForm(this.form);
        errorHandling.clearFormErrors();
    }

    onPaymentMethodChange(e) {
        const { braintreeToken } = this.state;
        const { errorHandling, clearCreditCardForm } = this.props;
        const paymentMethod = e.target.value;

        if (paymentMethod !== 'credit-card') {
            clearCreditCardForm(this.form);
        }
        errorHandling.clearFormErrors();

        this.setState(
            {
                selectedPaymentMethod: paymentMethod
            },
            () => {
                if (paymentMethod === 'paypal' && !braintreeToken) {
                    this.loadBraintreeToken();
                }
            }
        );
    }

    getCouponName(couponName) {
        this.setState({ couponName });
    }

    render() {
        const { context, fields, errorHandling, isFunnelCollection, user } = this.props;
        const { braintreeToken, requestInProgress, selectedPaymentMethod } = this.state;
        const formHasErrors = Object.keys(errorHandling.formErrors).length > 0;
        const hasHome = user?.get('homes')?.length > 0;

        return (
            <form
                ref={this.form}
                noValidate
                className="gtg-form"
                {...(isFunnelCollection && { id: 'form-step-7' })}
                onSubmit={this.onSubmit}
            >
                <PaymentDetails
                    context={context}
                    fields={fields}
                    termsOfUseCheckbox={null}
                    isFunnelCollection={isFunnelCollection}
                    errorMessage={errorHandling.errorMessage}
                    selectedPaymentMethod={selectedPaymentMethod}
                    onPaymentMethodChange={this.onPaymentMethodChange}
                    getCouponName={this.getCouponName}
                    hasHome={hasHome}
                />
                {(() => {
                    if (selectedPaymentMethod === 'paypal') {
                        if (braintreeToken) {
                            return (
                                <PaypalButton
                                    token={braintreeToken}
                                    handlePaypalError={errorHandling.handlePaypalError}
                                    onPaypalTokenGenerated={this.addPaypalPromise}
                                    context="my-plan"
                                />
                            );
                        } else {
                            return <Loading />;
                        }
                    } else if (selectedPaymentMethod === 'credit-card') {
                        return (
                            !isFunnelCollection && (
                                <AddPaymentMethodFormActions
                                    requestInProgress={requestInProgress}
                                    formHasErrors={formHasErrors}
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    onCancel={this.onCancel}
                                />
                            )
                        );
                    }
                })()}
            </form>
        );
    }
}

export default withPaymentFormTools(injectStripe(AddPaymentMethodForm));
