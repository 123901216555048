import React from 'react';
import i18n from '../../../i18n';
import styles from './availabilities.module.scss';
import AvailabilityCaptionCalendar from './availability-caption-calendar';
var AVAILABLE_TYPES = [
    {
        type: 'reciprocal',
        title: i18n.t('exchange:reciprocal'),
        description: i18n.t('home:calendar_home_view_reciprocal_description')
    },
    {
        type: 'guest-points',
        title: i18n.t('home:guestpoints'),
        description: i18n.t('home:calendar_home_view_non_reciprocal.description')
    },
    {
        type: 'available',
        title: i18n.t('home:availability_popup.any_type'),
        description: i18n.t('home:calendar_home_view_available.description')
    },
    {
        type: 'booked-view',
        title: i18n.t('home:calendar.exchange_type_reserved.title'),
        description: i18n.t('home:calendar_home_view_reserved.description')
    }
];
export var CalendarCaptionHomeView = function () {
    return (React.createElement("div", { className: styles['caption-container'] },
        React.createElement("div", { className: styles['home-view-container'] }, AVAILABLE_TYPES.map(function (availability) { return (React.createElement(AvailabilityCaptionCalendar, { key: availability.type, title: availability.title, description: availability.description, type: availability.type })); }))));
};
export default CalendarCaptionHomeView;
