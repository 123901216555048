import React from 'react';

/*
 * @abstract
 */
export default class EditPanel extends React.PureComponent {
    static title = '';

    static priority = 0;

    static isRequired = true;

    /*
     * Check whether the fields in panel are valid.
     * @abstract
     * @return {object}
     */
    static checkValidity() {
        throw new Error('This method should be overridden by inheriting classes.');
    }

    static isValid(value, options) {
        const input = document.createElement('input');
        input.value = value;
        for (const name in options) {
            input[name] = options[name];
        }
        return Boolean(input.validity && input.validity.valid);
    }

    static hasErrors(props, ignore) {
        const validity = this.checkValidity(props);
        if (Array.isArray(ignore)) {
            ignore.forEach((field) => {
                validity[field] = true;
            });
        }
        return _.values(validity).some((valid) => valid === false);
    }

    static isNew() {
        return false;
    }

    hasError(field) {
        const validity = this.constructor.checkValidity(this.props);
        return validity[field] === false;
    }

    render() {
        return false;
    }
}
