import i18n from 'i18next';

export const locales = {
    fr: 'fr-FR',
    en: 'en-US',
    es: 'es-ES',
    it: 'it-IT',
    pt: 'pt-PT',
    nl: 'nl-NL',
    de: 'de-DE',
    da: 'da-DK',
    sv: 'sv-SE',
    nb: 'nb-NO',
    hr: 'hr-HR'
    // hu: 'hu-HU',
    // el: 'el-GR',
    // tr: 'tr-TR'
};

export const languages = Object.keys(locales);

export const namespaces = [
    'billing',
    'collection',
    'common',
    'countries',
    'dashboard',
    'destination',
    'error',
    'exchange',
    'fillingpanel',
    'home',
    'messaging',
    'rating',
    'search',
    'sponsorship',
    'subscription',
    'tour',
    'upload',
    'url',
    'user'
];

i18n.init({
    debug: false,
    getAsync: true,

    // lng: document.documentElement.lang,
    fallbackLng: 'en',
    whitelist: languages,
    // load: 'current',

    // have a common namespace used around the full app
    ns: namespaces,
    defaultNS: 'common',
    // preload: false,

    returnNull: false,
    returnEmptyString: false,

    // backend: {
    //     loadPath: '/locale/%{lng}/%{ns}.json',
    //     allowMultiLoading: false,
    //     crossDomain: false
    // },

    // cache: {
    //     enabled: true,
    //     prefix: 'gtg_trad_',
    //     expirationTime: 24 * 60 * 60 * 1000
    // },

    interpolation: {
        prefix: '%{',
        suffix: '}',
        escapeValue: false // not needed for react
    },
    keySeparator: '#',
    react: {
        defaultTransParent: 'span'
    }
});

// if (process.env.NODE_ENV === 'test') {
namespaces.forEach((namespace) => {
    languages.forEach((lang) => {
        const ressource = require(`../public/locale/${lang}/${namespace}.json`);
        i18n.addResources(lang, namespace, ressource);
    });
});
// }

// Hack to load i18n asynchronously  (https://github.com/i18next/i18next/issues/636)
const htmlTag = typeof document !== 'undefined' ? document.documentElement : null;
if (htmlTag && htmlTag.getAttribute('lang')) {
    i18n.changeLanguage(htmlTag.getAttribute('lang'));
}

export default i18n;
