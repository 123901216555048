import AlertApi from './Alert';
import Ambassador from './Ambassador';
import ConversationApi from './Conversation';
import ExchangeApi from './Exchange';
import FavoritesApi from './Favorites';
import HomeApi from './Home';
import HomeRecommendationApi from './HomeRecommendation';
import ImageApi from './Image';
import ProductApi from './Product';
import RatingApi from './Rating';
import SearchApi from './Search';
import RouletteApi from './Roulette';
import SettingsApi from './Settings';
import SubscriptionApi from './Subscription';
import UserApi from './User';
import UserTourApi from './UserTour';
import UtilsApi from './Utils';
import TravelerApi from './Traveler';
import SponsorApi from './Sponsor';
import ResponseTimeApi from './ResponseTime';
import LeadsApi from './Leads';
import LoyaltyApi from './Loyalty';

export default class Api {
    static Alert = AlertApi;
    static Ambassador = Ambassador;
    static Conversation = ConversationApi;
    static Exchange = ExchangeApi;
    static Favorites = FavoritesApi;
    static Home = HomeApi;
    static HomeRecommendation = HomeRecommendationApi;
    static Image = ImageApi;
    static Loyalty = LoyaltyApi;
    static Search = SearchApi;
    static User = UserApi;
    static Product = ProductApi;
    static Rating = RatingApi;
    static Roulette = RouletteApi;
    static Settings = SettingsApi;
    static Subscription = SubscriptionApi;
    static Utils = UtilsApi;
    static UserTour = UserTourApi;
    static Traveler = TravelerApi;
    static Sponsor = SponsorApi;
    static ResponseTime = ResponseTimeApi;
    static LeadsApi = LeadsApi;
}
