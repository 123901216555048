import { REQUEST_PENDINGS, SET_PENDING_FRIENDS, REMOVE_PENDING_FRIEND } from '../actions/friends';

export default function pendingFriends(
    state = {
        isFetching: false,
        users: []
    },
    { type, payload }
) {
    switch (type) {
        case REQUEST_PENDINGS:
            return Object.assign({}, state, {
                isFetching: true
            });
        case SET_PENDING_FRIENDS:
            return Object.assign({}, state, {
                isFetching: false,
                users: payload
            });
        case REMOVE_PENDING_FRIEND:
            return Object.assign({}, state, {
                isFetching: false,
                users: state.users.filter((friend) => friend.id != payload)
            });
        default:
            return state;
    }
}
