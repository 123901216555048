import { REQUEST_FRIENDS, SET_FRIENDS, ADD_FRIEND, REMOVE_FRIEND } from '../actions/friends';

export default function friends(
    state = {
        isFetching: false,
        users: []
    },
    { type, payload }
) {
    switch (type) {
        case REQUEST_FRIENDS:
            return Object.assign({}, state, {
                isFetching: true
            });
        case SET_FRIENDS:
            return Object.assign({}, state, {
                isFetching: false,
                users: payload
            });
        case ADD_FRIEND:
            return Object.assign({}, state, {
                isFetching: false,
                users: state.users.concat(payload)
            });
        case REMOVE_FRIEND:
            return Object.assign({}, state, {
                isFetching: false,
                users: state.users.filter((friend) => friend.id != payload)
            });
        default:
            return state;
    }
}
