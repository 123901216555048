import React, { useState, useEffect } from 'react';
import { Callout, CheckboxContained, CheckboxContainedGroup, Text } from '@homeexchange/design';
import i18n from '../../../i18n';
import Availability from '../../../models/Availability';
import CalendarUtils from '../../../utils/CalendarUtils';
import Home from '../../../models/Home';
import styles from './availabilities.module.scss';
var AVAILABILITIES_TYPES = [
    {
        type: 'AVAILABLE',
        value: 0,
        id: 0,
        icon: 'available-exchange-type',
        title: i18n.t('home:calendar.exchange_type_available.title'),
        description: i18n.t('home:calendar.exchange_type_available.description')
    },
    {
        type: 'RECIPROCAL',
        value: 2,
        id: 2,
        icon: 'reciprocal-exchange-type',
        title: i18n.t('home:calendar.exchange_type_reciprocal.title'),
        description: i18n.t('home:calendar.exchange_type_reciprocal.description')
    },
    {
        type: 'NON_RECIPROCAL',
        value: 1,
        id: 1,
        icon: 'guestpoint-exchange-type',
        title: i18n.t('home:calendar.exchange_type_non_reciprocal.title'),
        description: i18n.t('home:calendar.exchange_type_non_reciprocal.description')
    },
    {
        type: 'RESERVED',
        value: 5,
        id: 5,
        icon: 'unavailable',
        title: i18n.t('home:calendar.exchange_type_reserved.title'),
        description: i18n.t('home:calendar.exchange_type_reserved.description')
    }
];
export var Availabilities = function (_a) {
    var displayMinNbOfNights = _a.displayMinNbOfNights, onUpdate = _a.onUpdate, selectedType = _a.selectedType, dateRange = _a.dateRange, error = _a.error, contactAllowed = _a.contactAllowed, minimumNights = _a.minimumNights;
    var _b = useState(displayMinNbOfNights), showMinNbOfNights = _b[0], setShowMinNbOfNights = _b[1];
    var _c = useState(false), showContactPreferencesInfo = _c[0], setShowContactPreferencesInfo = _c[1];
    useEffect(function () {
        setShowMinNbOfNights(displayMinNbOfNights);
    }, [displayMinNbOfNights]);
    var handleChange = function (event) {
        if (event.target.value === Availability.RESERVED.type) {
            setShowMinNbOfNights(false);
            if (contactAllowed === Home.CONTACT_NOT_ALLOWED_ON_UNAVAILABLE) {
                setShowContactPreferencesInfo(true);
            }
        }
        else {
            setShowMinNbOfNights(CalendarUtils.calculateNumberOfNightsOfSelectedDates(dateRange) < minimumNights);
            setShowContactPreferencesInfo(false);
        }
        onUpdate(event);
    };
    return (React.createElement("div", { className: styles['availabilities-container'] },
        React.createElement(CheckboxContainedGroup, { collapsible: false, labelContent: React.createElement(React.Fragment, null, i18n.t('home:calendar_add_period_helper')), buttonWithDescription: true, showLines: 2, error: error }, AVAILABILITIES_TYPES.map(function (availability) { return (React.createElement(CheckboxContained, { name: 'availability', type: "radio", key: availability.icon, label: availability.title, value: availability.type, icon: availability.icon, checked: availability.type == selectedType, description: availability.description, id: availability.title, onChange: handleChange })); })),
        showMinNbOfNights && (React.createElement(Callout, { key: "never-availabilities", hasIcon: true },
            React.createElement(Text, { className: "callout-title", isSpan: true }, i18n.t('home:min_number_nights_callout_home_calendar')))),
        showContactPreferencesInfo && (React.createElement(Callout, { key: "warning", hasIcon: true, status: "warning" },
            React.createElement(Text, { className: "callout-title", isSpan: true }, i18n.t('home:unavailable_callout_home_calendar'))))));
};
export default Availabilities;
