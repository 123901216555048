import React from 'react';
import Loading from '../common/Loading';

export default class HomeFake extends React.Component {
    render() {
        return (
            <article className="homebox fake">
                <div className="homebox-container">
                    <div className="homebox-picture">
                        <Loading />
                    </div>
                    <div className="homebox-content">
                        <header>
                            <div className="title">
                                <h1 className="fake-text">&nbsp;</h1>
                            </div>
                        </header>
                        <div className="homebox-review">
                            <div className="fake-text">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}
