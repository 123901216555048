import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Screen from '../../utils/Screen';
import i18n from '../../i18n';

export default function PaymentMainProduct(props) {
    const { title } = props;
    const [features, setFeatures] = useState('');
    const [dropdownFeatures, setDropdownFeatures] = useState('');
    const [dropdownIsVisible, setDropdownVisibility] = useState(false);

    useEffect(() => {
        if (Screen.isMobile()) {
            const currentFeatures = props.features;

            if (currentFeatures.indexOf('<li>') === -1) {
                setFeatures(currentFeatures);
                return;
            }

            const splittedFeatures = currentFeatures.split('<li>');

            const elements = splittedFeatures
                .filter((item) => item !== '')
                .map((elmt) => {
                    return `<li>${elmt}`;
                });

            let formattedFeatures = '';
            let formattedDropdownFeatures = '';

            elements.forEach((element, index) => {
                if (index < 2) {
                    formattedFeatures = `${formattedFeatures}${element}`;
                } else {
                    formattedDropdownFeatures = `${formattedDropdownFeatures}${element}`;
                }
            });

            setFeatures(formattedFeatures);
            setDropdownFeatures(formattedDropdownFeatures);
        } else {
            setFeatures(props.features);
        }
    }, [props.features]);

    function handleOnDropdown() {
        setDropdownVisibility(!dropdownIsVisible);
    }

    // eslint-disable-next-line react/no-multi-comp
    function displayFooter() {
        const text = dropdownIsVisible
            ? i18n.t('billing:pitch-membership.hide-benefits')
            : i18n.t('billing:pitch-membership.show-benefits');

        return (
            <div className="pitch-footer">
                <a className="pitch-footer-dropdown-cta" onClick={handleOnDropdown}>
                    {text}
                    <i
                        className={classNames('fa', {
                            'fa-angle-down': !dropdownIsVisible,
                            'fa-angle-up': dropdownIsVisible
                        })}
                    />
                </a>
            </div>
        );
    }

    return (
        <div className="pitch">
            <h3>{title}</h3>
            <ul dangerouslySetInnerHTML={{ __html: features }}></ul>
            {dropdownIsVisible && <ul dangerouslySetInnerHTML={{ __html: dropdownFeatures }}></ul>}
            {Screen.isMobile() && displayFooter()}
        </div>
    );
}

PaymentMainProduct.propTypes = {
    title: PropTypes.string.isRequired,
    features: PropTypes.string.isRequired
};
