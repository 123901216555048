import { useContext } from 'react';
import AnalyticsContext from './AnalyticsContext';
import Analytics from '../../utils/analytics';

export default function useAnalytics() {
    const analytics = useContext(AnalyticsContext);
    if (!analytics) {
        throw new Error('Missing <AnalyticsProvider>');
    }
    return {
        track: analytics.track.bind(analytics),
        trackSpinalCord: analytics.trackSpinalCord.bind(analytics),
        trackGTM: Analytics.trackGTM
    };
}
