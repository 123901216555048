import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import Home from '../../../models/Home';

import InputNumber from '../../form/InputNumber';

const TRACKING_BEDS_NUMDER_CHNAGED = 'beds_number_changed';

const InputBedList = ({ home, onChange }) => (
    <div className="form-input-number">
        <div className="form-group row">
            <label htmlFor="single_bed" className="col-xs-6 control-label form-label">
                <i className="icon icon-simple-bed icon-large"></i>
                <span>{i18n.t('home:single_bed')}</span>
            </label>
            <div className="input-number-container col-xs-6">
                <InputNumber
                    id="single_bed"
                    trackingData={TRACKING_BEDS_NUMDER_CHNAGED}
                    name="detail.single_bed"
                    value={home.get('detail').single_bed}
                    onChange={onChange}
                    min={0}
                />
            </div>
        </div>
        <div className="form-group row">
            <label htmlFor="double_bed" className="col-xs-6 control-label form-label">
                <i className="icon icon-double-bed icon-large"></i>
                <span>{i18n.t('home:double_bed')}</span>
            </label>
            <div className="input-number-container col-xs-6">
                <InputNumber
                    id="double_bed"
                    trackingData={TRACKING_BEDS_NUMDER_CHNAGED}
                    name="detail.double_bed"
                    value={home.get('detail').double_bed}
                    onChange={onChange}
                    min={0}
                />
            </div>
        </div>
        <div className="form-group row">
            <label htmlFor="big_double_bed" className="col-xs-6 control-label form-label">
                <i className="icon icon-double-bed icon-large"></i>
                <span>{i18n.t('home:big_double_bed')}</span>
            </label>
            <div className="input-number-container col-xs-6">
                <InputNumber
                    id="big_double_bed"
                    trackingData={TRACKING_BEDS_NUMDER_CHNAGED}
                    name="detail.big_double_bed"
                    value={home.get('detail').big_double_bed}
                    onChange={onChange}
                    min={0}
                />
            </div>
        </div>
        <div className="form-group row">
            <label htmlFor="children_bed" className="col-xs-6 control-label form-label">
                <i className="icon icon-simple-bed icon-large"></i>
                <span>{i18n.t('home:children_bed')}</span>
            </label>
            <div className="input-number-container col-xs-6">
                <InputNumber
                    id="children_bed"
                    trackingData={TRACKING_BEDS_NUMDER_CHNAGED}
                    name="detail.children_bed"
                    value={home.get('detail').children_bed}
                    onChange={onChange}
                    min={0}
                />
            </div>
        </div>
        <div className="form-group row">
            <label htmlFor="baby_bed" className="col-xs-6 control-label form-label">
                <i className="icon icon-baby-bed icon-large"></i>
                <span>{i18n.t('home:baby_bed')}</span>
            </label>
            <div className="input-number-container col-xs-6">
                <InputNumber
                    id="baby_bed"
                    trackingData={TRACKING_BEDS_NUMDER_CHNAGED}
                    name="detail.baby_bed"
                    value={home.get('detail').baby_bed}
                    onChange={onChange}
                    min={0}
                />
            </div>
        </div>
        <div className="form-group row">
            <label htmlFor="single_bed_up" className="col-xs-6 control-label form-label">
                <i className="icon icon-simple-bed-up icon-large"></i>
                <span>{i18n.t('home:single_bed_up')}</span>
            </label>
            <div className="input-number-container col-xs-6">
                <InputNumber
                    id="single_bed_up"
                    trackingData={TRACKING_BEDS_NUMDER_CHNAGED}
                    name="detail.single_bed_up"
                    value={home.get('detail').single_bed_up}
                    onChange={onChange}
                    min={0}
                />
            </div>
        </div>
        <div className="form-group row">
            <label htmlFor="double_bed_up" className="col-xs-6 control-label form-label">
                <i className="icon icon-double-bed-up icon-large"></i>
                <span>{i18n.t('home:double_bed_up')}</span>
            </label>
            <div className="input-number-container col-xs-6">
                <InputNumber
                    id="double_bed_up"
                    trackingData={TRACKING_BEDS_NUMDER_CHNAGED}
                    name="detail.double_bed_up"
                    value={home.get('detail').double_bed_up}
                    onChange={onChange}
                    min={0}
                />
            </div>
        </div>
        <div className="form-group row">
            <label htmlFor="children_bed_up" className="col-xs-6 control-label form-label">
                <i className="icon icon-simple-bed-up icon-large"></i>
                <span>{i18n.t('home:children_bed_up')}</span>
            </label>
            <div className="input-number-container col-xs-6">
                <InputNumber
                    id="children_bed_up"
                    trackingData={TRACKING_BEDS_NUMDER_CHNAGED}
                    name="detail.children_bed_up"
                    value={home.get('detail').children_bed_up}
                    onChange={onChange}
                    min={0}
                />
            </div>
        </div>
        <div className="form-group row">
            <label htmlFor="baby_bed_up" className="col-xs-6 control-label form-label">
                <i className="icon icon-baby-bed icon-large"></i>
                <span>{i18n.t('home:baby_bed_up')}</span>
            </label>
            <div className="input-number-container col-xs-6">
                <InputNumber
                    id="baby_bed_up"
                    trackingData={TRACKING_BEDS_NUMDER_CHNAGED}
                    name="detail.baby_bed_up"
                    value={home.get('detail').baby_bed_up}
                    onChange={onChange}
                    min={0}
                />
            </div>
        </div>
    </div>
);

InputBedList.propTypes = {
    home: PropTypes.instanceOf(Home).isRequired,
    onChange: PropTypes.func.isRequired
};

export default InputBedList;
