import { SET_NOTIFICATIONS } from '../actions/notifications';

export default function notifications(state = [], { type, payload }) {
    switch (type) {
        case SET_NOTIFICATIONS:
            return payload;
        default:
            return state;
    }
}
