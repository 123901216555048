import Common from './Common';

export default class Ambassador {
    static get(userId) {
        if (userId) {
            const params = Common.params({
                url: `/v1/users/${userId}/achievements`,
                method: 'get'
            });
            return Common.request(params);
        }
    }
}
