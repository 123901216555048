import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import HomeCarousel from '../home/HomeCarousel';
import i18n from '../../i18n';
import Api from '../../api/Api';

export default class HomepageNewest extends React.Component {
    static propTypes = {
        homes: PropTypes.array,
        isLogged: PropTypes.bool,
        isSimilarHome: PropTypes.bool,
        homeId: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.state = {
            homes: props.homes || []
        };
        this.moduleRef = React.createRef();
        this.fetchHomes = this.fetchHomes.bind(this);
        this.fetchSimilarHomes = this.fetchSimilarHomes.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (!this.props.homes) {
            if (this.props.isSimilarHome) {
                this.fetchSimilarHomes();
            } else {
                this.fetchHomes();
            }
        }
    }

    fetchHomes() {
        const locale = document.documentElement.lang || 'en';
        Api.Home.getLastCompletedHomesWithCompletedUserProfile(20, locale)
            .done((response) => {
                this.setState({
                    homes: response,
                    error: response.length > 0 ? null : i18n.t('dashboard:noresults')
                });
            })
            .fail(() => {
                this.setState({
                    error: i18n.t('dashboard:noresults')
                });
            });
    }

    fetchSimilarHomes() {
        Api.Home.getSimilarHomes(this.props.homeId)
            .done((response) => {
                this.setState({
                    homes: response,
                    error: response.length > 0 ? null : i18n.t('dashboard:noresults')
                });
            })
            .fail(() => {
                this.setState({
                    error: i18n.t('dashboard:noresults')
                });
            });
    }

    render() {
        const moduleClasses = classNames({
            'home-newest-houses': true,
            'home-newest-houses-loading': this.state.homes.length === 0 && !this.state.error
        });
        const { isSimilarHome } = this.props;

        if (this.state.error || this.state.homes.length === 0) {
            return false;
        } else {
            return (
                <div ref={this.moduleRef} className={moduleClasses}>
                    <div className="container">
                        <div className="row">
                            <div className={`col-md-${isSimilarHome ? '12' : '10 col-md-offset-1'}`}>
                                <h2>
                                    {i18n.t(`home:${isSimilarHome ? 'similar' : 'homepage'}.homes.title`)}
                                </h2>
                                <p>
                                    {i18n.t(`home:${isSimilarHome ? 'similar' : 'homepage'}.homes.subtitle`)}
                                </p>
                                <HomeCarousel
                                    homes={this.state.homes.slice(0, 20)}
                                    fakes={20}
                                    displayFavoritePlaceholder={false}
                                    isLogged={this.props.isLogged}
                                    noSearch={true}
                                />
                                {!isSimilarHome && (
                                    <div className="homepage-homes-cta">
                                        <a
                                            href={i18n.t('search:search_url', {
                                                slug: 'everywhere'
                                            })}
                                            className="btn btn-primary "
                                        >
                                            {i18n.t('home:homepage.homes.cta')}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
