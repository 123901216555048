import Common from './Common';

export default class Image {
    static getImageUrl(id, width, height, smartResize, borders) {
        return `${process.env.URL_CDN}/${id}?${jQuery.param(
            Image.getImageParams(width, height, smartResize, borders)
        )}`;
    }

    static getImageParams(width, height, smartResize, borders, rotate) {
        let params = {
            quality: 80
        };
        if (width || height) {
            params = Object.assign(params, {
                width,
                height
            });
        }
        if (smartResize) {
            params = Object.assign(params, { 'smart-resize': true });
        }
        if (borders) {
            params = Object.assign(params, { borders: true });
        }
        if (rotate) {
            params = Object.assign(params, { rotate: parseInt(rotate, 10) });
        }
        return params;
    }

    static sort(sortIds, callback, fail) {
        const params = {
            url: '/v1/images/order',
            method: 'patch',
            data: sortIds
        };
        return Common.fetch(params, callback, fail);
    }

    static post(objectType, objectId, file) {
        const formData = new FormData();
        formData.append('file', file);
        const params = {
            url: objectType === 'user' ? '/v1/images/user' : `/v1/images/${objectType}/${objectId}`,
            method: 'post',
            data: formData,
            contentType: false,
            processData: false
        };
        return Common.request(params);
    }

    static update(imageId, data, callback, fail) {
        const params = {
            url: `/v1/images/${imageId}`,
            method: 'patch',
            data
        };
        return Common.fetch(params, callback, fail);
    }

    static delete(imageId, callback, fail) {
        const params = {
            url: `/v1/images/${imageId}`,
            method: 'delete'
        };
        return Common.fetch(params, callback, fail);
    }
}
