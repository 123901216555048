import React from 'react';
import i18n from '../../i18n';
import Utils from '../../utils/utils';
import HomeEditPanelHouseAndBeds from '../home/edit/HomeEditPanelHouseAndBeds';
import HomeEditPanelPictures from '../home/edit/HomeEditPanelPictures';
import HomeEditPanelDescription from '../home/edit/HomeEditPanelDescription';
import HomeEditPanelFeatures from '../home/edit/HomeEditPanelFeatures';
import Home from '../../models/Home';

const RegularHomeStatusBlocker = (
    newCompletionRate,
    wasOffline,
    user,
    home,
    turnOnline,
    handleSave,
    homeSaved = false,
    redirectEditHome = false
) => {
    const isCollection = user.get('is_he_collection');
    const homeDescription = home.get('descriptions')[0];
    const homeImages = home.get('images');
    const homeCapacity = home.get('capacity');
    const homeFeatures = home?.get('features');

    const closeAndSave = async (e) => {
        await turnOnline(e, {}, { name: 'status', value: true });
        handleSave();
        sweetAlert.close();
    };

    const showBlocker = (title, image, text, button, info, onConfirm, className, putOnline = false) => {
        sweetAlert({
            content: (
                <div className="info-card-modal home-status-blocker regular-popup">
                    <div className="info-card-modal-header">
                        <h3 className="regular-popup-title" dangerouslySetInnerHTML={{ __html: title }} />
                        <i
                            className="icon-circle-close"
                            onClick={() => (putOnline ? closeAndSave() : sweetAlert.close())}
                        />
                    </div>
                    <div className="regular-popup-content">
                        <img
                            className="regular-popup-img"
                            src={`${Utils.getCloudfrontDomain()}/images/website/common/${image}`}
                        />
                        <p className="regular-popup-text">{text}</p>
                        {redirectEditHome ? (
                            <a
                                className="btn btn-primary regular-popup-button"
                                href={i18n.t('home:home.edit.url', { id: home.id })}
                            >
                                {button}
                            </a>
                        ) : (
                            <button className="btn btn-primary regular-popup-button" onClick={onConfirm}>
                                {button}
                            </button>
                        )}
                        {info && (
                            <p className="regular-popup-info" onClick={() => sweetAlert.close()}>
                                {info}
                            </p>
                        )}
                    </div>
                </div>
            ),
            closeOnClickOutside: !putOnline,
            button: false,
            className
        });
    };

    const hasFilledDescription =
        homeDescription.title !== '' &&
        homeDescription.good_feature !== '' &&
        homeDescription.good_place !== '';

    const hasHomeFeatures =
        homeFeatures &&
        typeof homeFeatures === 'object' &&
        Object.values(homeFeatures).some((feature) => feature.length > 0);

    const missingInformation = () => {
        let result = '';

        switch (true) {
            case !hasFilledDescription:
                result = Utils.slugify(HomeEditPanelDescription.title);
                break;

            case !hasHomeFeatures:
                result = Utils.slugify(HomeEditPanelFeatures.title);
                break;

            case homeCapacity === 0:
                result = Utils.slugify(HomeEditPanelHouseAndBeds.title);
                break;

            case homeImages.length < 5:
                result = Utils.slugify(HomeEditPanelPictures.title);
                break;

            default:
                break;
        }

        return result;
    };

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        document.body.parentNode.scrollTo({
            top: element.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
    };

    const showMissingInformationBlocker = () => {
        showBlocker(
            i18n.t('common:popup-blocker.first-missing-informations.title'),
            'listing-missing-information.svg',
            i18n.t('common:popup-blocker.first-missing-informations.text'),
            i18n.t('common:popup-blocker.first-missing-informations.button'),
            undefined,
            () => {
                sweetAlert.close();
                scrollToElement(missingInformation());
            },
            'info-card-modal-regular-home-status-blocker'
        );
    };

    const showCompletionBlocker = () => {
        showBlocker(
            i18n.t('common:popup-success.home-enough-completion.title'),
            'home-enough-completed.svg',
            i18n.t('common:popup-success.home-enough-completion.text'),
            i18n.t('common:ok'),
            undefined,
            closeAndSave,
            'info-card-home-enough-completion',
            true
        );
    };

    if (isCollection) {
        return false;
    }

    if (newCompletionRate < Home.MINIMUM_FILLING_TO_CONTACT) {
        showMissingInformationBlocker();
    } else if (
        wasOffline === Home.STATUS_OFFLINE &&
        homeSaved &&
        newCompletionRate >= Home.MINIMUM_FILLING_TO_CONTACT
    ) {
        showCompletionBlocker();
    } else {
        return false;
    }
};

export default RegularHomeStatusBlocker;
