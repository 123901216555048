import Common from './Common';

export default class HomeRecommendation {
    static NEWEST = 'newest';
    static ACTIVE = 'active';
    static GUESTWANTED = 'guestwanted';
    static THEYLOVE = 'theylove';
    static LASTMINUTE = 'lastminute';
    static COLLECTION = 'collection';

    static get(type, limit, additionalURLParams) {
        const validTypes = [
            HomeRecommendation.NEWEST,
            HomeRecommendation.ACTIVE,
            HomeRecommendation.GUESTWANTED,
            HomeRecommendation.THEYLOVE,
            HomeRecommendation.LASTMINUTE,
            HomeRecommendation.COLLECTION
        ];
        if (validTypes.indexOf(type) === -1) {
            throw new Error('Invalid home recommendation type');
        }

        const data = Object.assign(
            {
                url: `/v1/recommendations/home/${type}`,
                method: 'get'
            },
            {
                data: additionalURLParams
            }
        );

        const params = Common.params(data, null, null, limit);
        return Common.request(params);
    }
}
