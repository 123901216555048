// Externals
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Helpers
import i18n from '../../i18n';

// Components
import CardInfo from '../card-info/CardInfo';

// Utils
import Utils from '../../utils/utils';
import usePrice from '../../utils/usePrice';

const InfoCards = {
    GUESTPOINTS: 'GUESTPOINTS',
    TYPE_OF_EXCHANGE: 'TYPE_OF_EXCHANGE',
    MEMBERSHIP: 'MEMBERSHIP',
    GUARANTEES: 'GUARANTEES'
};

const DashboardInfoCards = ({ isUserSubscribed, showTitle }) => {
    const product = usePrice();

    const getInfoCardContent = (source) => {
        switch (source) {
            case InfoCards.GUESTPOINTS:
                return {
                    className: 'info-card-modal-guestpoints',
                    title: i18n.t('dashboard:dashboard-info-cards.guestpoints-modal.title'),
                    text: (
                        <div>
                            <img
                                className="info-card-modal-img"
                                src={`${Utils.getCloudfrontDomain()}/images/website/home/info-cards-guestpoints.svg`}
                            />
                            <p
                                className="info-card-modal-text"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('dashboard:dashboard-info-cards.guestpoints-modal.text')
                                }}
                            />
                        </div>
                    )
                };
            case InfoCards.TYPE_OF_EXCHANGE:
                return {
                    className: 'info-card-modal-type-of-exchange',
                    title: i18n.t('dashboard:dashboard-info-cards.type-of-exchange-modal.title'),
                    text: (
                        <div className="info-card-modal-type-of-exchange-container">
                            <div className="info-card-modal-type-of-exchange-section info-card-modal-reciprocal-exchange">
                                <img
                                    className="info-card-modal-img"
                                    src={`${Utils.getCloudfrontDomain()}/images/website/home/info-cards-reciprocal-exchange.svg`}
                                />
                                <p
                                    className="info-card-modal-text"
                                    dangerouslySetInnerHTML={{
                                        __html: i18n.t(
                                            'dashboard:dashboard-info-cards.type-of-exchange-modal.reciprocal-text'
                                        )
                                    }}
                                />
                            </div>
                            <div className="info-card-modal-type-of-exchange-section info-card-modal-guestpoints-exchange">
                                <img
                                    className="info-card-modal-img"
                                    src={`${Utils.getCloudfrontDomain()}/images/website/home/info-cards-guestpoints.svg`}
                                />
                                <p
                                    className="info-card-modal-text"
                                    dangerouslySetInnerHTML={{
                                        __html: i18n.t(
                                            'dashboard:dashboard-info-cards.type-of-exchange-modal.guestpoints-text'
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    )
                };
            case InfoCards.MEMBERSHIP:
                return {
                    className: 'info-card-modal-membership',
                    title: i18n.t('dashboard:dashboard-info-cards.membership-modal.title'),
                    text: (
                        <div className="container-membership">
                            <img
                                className="info-card-modal-img"
                                src={`${Utils.getCloudfrontDomain()}/images/website/home/info-cards-membership.svg`}
                            />
                            <p
                                className="info-card-modal-text"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('dashboard:dashboard-info-cards.membership-modal.text', {
                                        price: product.price,
                                        symbol: product.symbol
                                    })
                                }}
                            />
                            {!isUserSubscribed && (
                                <button
                                    onClick={() =>
                                        (window.location.href = i18n.t('url:subscription_payment'))
                                    }
                                    className="btn btn-primary"
                                >
                                    {i18n.t('dashboard:dashboard-info-cards.btn')}
                                </button>
                            )}
                            <div>
                                <a href={i18n.t('url:my-plan.url')} className="link-my-plan">
                                    {i18n.t('common:learn_more')}
                                </a>
                            </div>
                        </div>
                    )
                };
            case InfoCards.GUARANTEES:
                return {
                    className: 'info-card-modal-guarantees',
                    title: i18n.t('dashboard:dashboard-info-cards.guarantees-modal.title'),
                    text: (
                        <div>
                            <img
                                className="info-card-modal-img"
                                src={`${Utils.getCloudfrontDomain()}/images/website/home/info-cards-guarantees.svg`}
                            />
                            <p
                                className="info-card-modal-text"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('dashboard:dashboard-info-cards.guarantees-modal.text')
                                }}
                            />
                        </div>
                    )
                };
            default:
                return {
                    title: '',
                    text: ''
                };
        }
    };

    const handleOnClickCard = (source) => {
        const content = getInfoCardContent(source);

        return sweetAlert({
            className: content.className ? content.className : '',
            content: (
                <div className="info-card-modal">
                    <div className="info-card-modal-header">
                        <h3
                            className="info-card-modal-header-title"
                            dangerouslySetInnerHTML={{ __html: content.title }}
                        />
                        <i
                            className="icon-circle-close"
                            onClick={() => {
                                sweetAlert.close();
                            }}
                        />
                    </div>
                    {content.text}
                </div>
            ),
            closeOnClickOutside: true,
            buttons: false
        });
    };

    const locales = ['fr', 'de', 'nl', 'en', 'it', 'es'];
    const videoSource = `video-info-cards-dashboard_${Utils.getWebsiteLanguage()}`;

    const imageSource = `poster-video-info-cards-dashboard`;
    const videoRef = useRef();
    const [showVideoTextOverlay, setVideoTextOverlay] = useState(true);

    const handleOnClickVideo = () => {
        if (showVideoTextOverlay) {
            videoRef.current.play();
            setVideoTextOverlay(false);
            videoRef.current.controls = true;
        }
    };

    return (
        <div className="container dashboard-module dashboard-info-cards">
            {showTitle && (
                <header>
                    <h1>{i18n.t('dashboard:dashboard-info-cards.title')}</h1>
                </header>
            )}
            <div className={`row col-sm-12 ${!locales.includes(Utils.getWebsiteLanguage()) && 'no-video'}`}>
                <div className="info-cards-container">
                    <CardInfo
                        text={i18n.t('dashboard:dashboard-info-cards.guestpoints-card.title')}
                        icon={'icon-blue-gp'}
                        onClick={() => handleOnClickCard(InfoCards.GUESTPOINTS)}
                    />
                    <CardInfo
                        text={i18n.t('dashboard:dashboard-info-cards.type-of-exchange-card.title')}
                        icon={'icon-blue-exchange-types'}
                        onClick={() => handleOnClickCard(InfoCards.TYPE_OF_EXCHANGE)}
                    />
                    <CardInfo
                        text={i18n.t('dashboard:dashboard-info-cards.membership-card.title')}
                        icon={'icon-blue-membership'}
                        onClick={() => handleOnClickCard(InfoCards.MEMBERSHIP)}
                    />
                    <CardInfo
                        text={i18n.t('dashboard:dashboard-info-cards.guarantees-card.title')}
                        icon={'icon-blue-guarantees'}
                        onClick={() => handleOnClickCard(InfoCards.GUARANTEES)}
                    />
                </div>
                {locales.includes(Utils.getWebsiteLanguage()) && (
                    <div className="info-cards-video-container">
                        <video
                            ref={videoRef}
                            className="info-cards-video"
                            poster={`${Utils.getCloudfrontDomain()}/images/website/home_page/${imageSource}.png`}
                            onClick={handleOnClickVideo}
                        >
                            <source
                                src={`${Utils.getCloudfrontDomain()}/images/website/home_page/${videoSource}.mp4`}
                                type="video/mp4"
                            />
                        </video>

                        {showVideoTextOverlay && (
                            <div className="info-cards-video-overlay" onClick={handleOnClickVideo}>
                                <img
                                    className="info-cards-video-overlay-img"
                                    src={`${Utils.getCloudfrontDomain()}/images/website/home_page/play-circle-video.svg`}
                                />
                                <p className="info-cards-video-overlay-title">
                                    {i18n.t('dashboard:dashboard-info-cards.video.title')}
                                </p>
                                <p className="info-cards-video-overlay-text">
                                    {i18n.t('dashboard:dashboard-info-cards.video.text')}
                                </p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

DashboardInfoCards.propTypes = {
    isUserSubscribed: PropTypes.bool,
    showTitle: PropTypes.bool
};

DashboardInfoCards.defaultProps = {
    showTitle: true
};

export default DashboardInfoCards;
