import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';

export default class UploadForm extends React.Component {
    static propTypes = {
        image: PropTypes.shape({
            id: PropTypes.number,
            url: PropTypes.string
        }),
        welldone: PropTypes.bool,
        addImage: PropTypes.func,
        error: PropTypes.bool,
        load: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.fileRef = React.createRef();
        this.uploadProfileFormRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        e.preventDefault();
        const image = e.target.files[0];
        image.id = Date.now();
        this.props.addImage(image);
        this.fileRef.current.value = '';
    }

    clickInputFile() {
        $('#inputUploadProfile').trigger('click');
    }

    render() {
        return (
            <div className="upload-form">
                <div className="row">
                    <div className="col-md-6">
                        {this.props.load ? (
                            <div className="upload-progress">
                                <div className="upload-progress-bar"></div>
                            </div>
                        ) : (
                            <p className="blocBtn">
                                <button className="btn btn-primary" onClick={this.clickInputFile}>
                                    <i className="icon-home-camera camera-icon" aria-hidden="true"></i>
                                    {!this.props.image
                                        ? i18n.t('upload:uploadImageWait_addImageProfile')
                                        : i18n.t('upload:uploadImageWait_updateImageProfile')}
                                </button>
                            </p>
                        )}
                    </div>
                    <div className="col-md-6">
                        {this.props.welldone ? (
                            <p className="well-done">{i18n.t('upload:uploadForm_bravo')}</p>
                        ) : (
                            ''
                        )}
                        {this.props.error ? (
                            <p className="error-upload">
                                {i18n.t('upload:uploadForm_error')} <br />
                                {i18n.t('upload:uploadForm_error_details')}
                            </p>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <form
                    ref={this.uploadProfileFormRef}
                    encType="multipart/form-data"
                    onChange={this.handleChange}
                >
                    <input
                        id="inputUploadProfile"
                        ref={this.fileRef}
                        type="file"
                        name="file"
                        accept="capture=camera, image/jpeg, image/png"
                    />
                </form>
            </div>
        );
    }
}
