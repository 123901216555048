import { LOAD_USER } from '../actions/loadedUsers';

export default function loadedUsers(state = {}, { type, payload }) {
    switch (type) {
        case LOAD_USER:
            return Object.assign({}, state, payload);
        default:
            return state;
    }
}
