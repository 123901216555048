import PropTypes from 'prop-types';
import React from 'react';

import Utils from '../../utils/utils';

export default class Loading extends React.Component {
    static propTypes = {
        width: PropTypes.string
    };

    static defaultProps = {
        width: '20px'
    };

    render() {
        const styles = {};
        if (this.props.width) {
            styles.width = this.props.width;
        }
        return (
            <div className="loading" style={styles}>
                <img
                    className="loader"
                    src={`${Utils.getCloudfrontDomain()}/images/website/loader-${
                        window.gtg.heCollection ? 'prestige' : 'yellow'
                    }.svg`}
                    alt="Loading"
                />
            </div>
        );
    }
}
