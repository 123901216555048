import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import i18n, { languages } from '../../../i18n';
import Utils from '../../../utils/utils';

import Home from '../../../models/Home';

import CompletionIndicator from '../../common/CompletionIndicator';
import EditPanel from '../../common/EditPanel';

export class HomeEditPanelDescription extends EditPanel {
    static propTypes = {
        home: PropTypes.instanceOf(Home).isRequired,
        onChange: PropTypes.func.isRequired,
        updateHomeLocaleDescription: PropTypes.func
    };

    static priority = 4;

    static isRequired = false;

    static checkValidity(props) {
        const descriptionLocaleOrNonEmpty = props.home
            .get('descriptions')
            .find((d) => d.locale === props.localeDescription);

        return {
            title: Boolean(descriptionLocaleOrNonEmpty && descriptionLocaleOrNonEmpty.title),
            good_place: Boolean(descriptionLocaleOrNonEmpty && descriptionLocaleOrNonEmpty.good_place),
            good_feature: Boolean(descriptionLocaleOrNonEmpty && descriptionLocaleOrNonEmpty.good_feature),
            other: true
        };
    }

    static title = i18n.t('home:descriptions');

    constructor(props) {
        super(props);
        this.state = {
            locale: this.initLocale()
        };
        this.handleLocale = this.handleLocale.bind(this);
    }

    handleLocale(e) {
        this.setState({ locale: e.target.value }, () =>
            this.props.updateHomeLocaleDescription(this.state.locale)
        );
    }

    initLocale() {
        const { home, updateHomeLocaleDescription } = this.props;
        if (home.get('descriptions')) {
            let nonEmptyDescription = home
                .get('descriptions')
                .find((d) => !_.isEmpty(d.title) && d.locale == i18n.language);
            nonEmptyDescription = nonEmptyDescription
                ? nonEmptyDescription
                : home.get('descriptions').find((d) => !_.isEmpty(d.title));
            if (nonEmptyDescription) {
                updateHomeLocaleDescription(nonEmptyDescription.locale);
            }
            return nonEmptyDescription ? nonEmptyDescription.locale : i18n.language;
        } else {
            return i18n.language;
        }
    }

    getDescriptionForLocale() {
        // retrieve description for selected locale
        let descriptionForLocale = this.props.home
            .get('descriptions')
            .find((d) => d.locale === this.state.locale);
        if (!descriptionForLocale) {
            // create description row for selected locale if not exists
            descriptionForLocale = {
                locale: this.state.locale,
                title: '',
                good_feature: '',
                good_place: '',
                other: ''
            };
            this.props.home.get('descriptions').push(descriptionForLocale);
        }
        return descriptionForLocale;
    }

    render() {
        const { home, onChange } = this.props;
        const description = this.getDescriptionForLocale();
        const descriptionIndexForLocale = home
            .get('descriptions')
            .findIndex((d) => d.locale === this.state.locale);
        return (
            <fieldset className="panel panel-default" id={Utils.slugify(this.constructor.title)}>
                <div className="panel-heading">{i18n.t('home:descriptions')}</div>
                <div className="panel-body">
                    <div className="descriptions-locale form-inline">
                        <label htmlFor="descriptions-locale">{i18n.t('home:descriptions_locale')}</label>
                        <select
                            className="form-control"
                            name="locale"
                            id="descriptions-locale"
                            value={this.state.locale}
                            onChange={this.handleLocale}
                        >
                            {languages.map((locale) => (
                                <option value={locale} key={locale}>
                                    {i18n.t(`common:${locale}`)}
                                </option>
                            ))}
                        </select>
                    </div>
                    <p className="descriptions-locale-explanation">
                        {i18n.t('home:descriptions_locale_explanation')}
                    </p>
                    <div
                        className={classNames('form-group', {
                            'has-error': this.hasError('title')
                        })}
                    >
                        <div className="label-row">
                            <label htmlFor="title">{i18n.t('home:title')}</label>
                            <CompletionIndicator object={home} fieldNames={['title']} page="home" />
                        </div>
                        <input
                            className="form-control"
                            type="text"
                            id="title"
                            data-track-edition="home_name_changed"
                            name={`descriptions.${descriptionIndexForLocale}.title`}
                            value={description.title || ''}
                            onChange={onChange}
                        />
                    </div>
                    <div
                        className={classNames('form-group', {
                            'has-error': this.hasError('good_feature')
                        })}
                    >
                        <div className="label-row">
                            <label htmlFor="good_feature">{i18n.t('fillingpanel:good_feature')}</label>
                            <CompletionIndicator object={home} fieldNames={['good_feature']} page="home" />
                        </div>
                        <textarea
                            rows="8"
                            className="form-control"
                            id="good_feature"
                            data-track-edition="home_description_changed"
                            name={`descriptions.${descriptionIndexForLocale}.good_feature`}
                            value={description.good_feature || ''}
                            onChange={onChange}
                        />
                    </div>
                    <div
                        className={classNames('form-group', {
                            'has-error': this.hasError('good_place')
                        })}
                    >
                        <div className="label-row">
                            <label htmlFor="good_place">{i18n.t('fillingpanel:good_place')}</label>
                            <CompletionIndicator object={home} fieldNames={['good_place']} page="home" />
                        </div>
                        <textarea
                            rows="8"
                            className="form-control"
                            id="good_place"
                            data-track-edition="neighborhood_description_changed"
                            name={`descriptions.${descriptionIndexForLocale}.good_place`}
                            value={description.good_place || ''}
                            onChange={onChange}
                        />
                    </div>
                    <div
                        className={classNames('form-group', {
                            'has-error': this.hasError('other')
                        })}
                    >
                        <label htmlFor="other">{i18n.t('home:other')}</label>
                        <textarea
                            rows="8"
                            className="form-control"
                            id="other"
                            data-track-edition="additional_information_changed"
                            name={`descriptions.${descriptionIndexForLocale}.other`}
                            value={description.other || ''}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </fieldset>
        );
    }
}

const mapStateToProps = (state) => ({
    localeDescription: state.localeDescription
});

export default connect(mapStateToProps)(HomeEditPanelDescription);
