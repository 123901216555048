import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import Product from '../../models/Product';
import User from '../../../src/models/User';

import i18n from '../../i18n';
import { getCoupon } from '../../actions/subscription';

export const PromoCodeForm = (props) => {
    const [promoCode, setPromoCode] = useState('');
    const [isPromoCodeValid, setIsPromoCodeValid] = useState(false);
    const { register, handleSubmit, errors, setError } = useForm();

    const handleChange = useCallback((event) => {
        setIsPromoCodeValid(false);
        setPromoCode(event.target.value.toUpperCase());
    }, []);

    const handleClick = () => {
        const hasCollectionProduct =
            props.products.filter((product) => product.isCollectionProduct()).length !== 0;
        const isCollection = props.user.get('is_he_collection');
        const productType = hasCollectionProduct || isCollection ? 1 : 0;
        props.getCoupon(promoCode, productType).then(
            () => {
                setIsPromoCodeValid(true);
                props.getCouponName(promoCode);
            },
            () => setError('promoCode', 'notMatch', i18n.t('billing:promo_code.error'))
        );
    };

    return (
        <React.Fragment>
            <div className="row form-inline promo-code-form">
                <div className="col-md-6 col-sm-4 promo-code-label">
                    <label>{i18n.t('billing:promo_code.label')}</label>
                </div>
                <div
                    className={`col-md-6 ${
                        errors.promoCode ? 'has-error' : isPromoCodeValid ? 'has-success' : ''
                    } promo-code-field`}
                >
                    <input
                        name="promoCode"
                        value={promoCode}
                        onChange={handleChange}
                        type="text"
                        className="form-control"
                        placeholder="Ex : HE_3245"
                        maxLength="20"
                        ref={register}
                    />
                    <button
                        disabled={!promoCode}
                        className="btn btn-primary"
                        onClick={handleSubmit(handleClick)}
                    >
                        {i18n.t('common:apply')}
                    </button>
                </div>
            </div>
            {(errors.promoCode || isPromoCodeValid) && (
                <div
                    className={classNames('row', {
                        'promo-code-error': errors.promoCode,
                        'promo-code-success': isPromoCodeValid
                    })}
                >
                    <div className="col-md-6 col-md-offset-6 col-sm-offset-4">
                        <i
                            className={classNames({
                                'icon-triangle-warning': errors.promoCode,
                                'icon-ok': isPromoCodeValid
                            })}
                        />
                        <span>
                            {errors.promoCode ? errors.promoCode.message : i18n.t('common:congratulations')}
                        </span>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

PromoCodeForm.propTypes = {
    getCoupon: PropTypes.func,
    products: PropTypes.arrayOf(PropTypes.instanceOf(Product)),
    user: PropTypes.instanceOf(User),
    getCouponName: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    getCoupon: bindActionCreators(getCoupon, dispatch)
});

const mapStateToProps = (state) => ({
    coupon: state.subscription.coupon,
    products: state.products,
    user: state.user
});

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodeForm);
