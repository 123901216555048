import React, { Suspense } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withCookies, Cookies } from 'react-cookie';
import SHA256 from 'crypto-js/sha256';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import i18n from '../../i18n';
import UserApi from '../../api/User';
import { withCurrency } from '../currency';
import FacebookLoginWrapper from '../social/FacebookLoginWrapper';
import FacebookButton from '../social/FacebookButton';
import AppleButton from '../social/AppleButton';
import Common from '../../api/Common';
import withAnalytics from '../analytics/withAnalytics';
import Analytics from '../../utils/analytics';
import Utils from '../../utils/utils';
import User from '../../models/User';
import SponsorFriend from './SponsorFriend';
import Sponsor from '../../api/Sponsor';
import EmailVerificationSignup from './EmailVerificationSignup';
import useGTM from '../analytics/useGTM';
import { NAVIGATION_AREAS, NAVIGATION_ACTION, NAVIGATION_TEXT } from '../analytics/analytics-constants';

const EmailSignup = React.lazy(() => import('./EmailSignup'));

export class Signup extends React.Component {
    static propTypes = {
        cookies: PropTypes.instanceOf(Cookies).isRequired,
        currency: PropTypes.string,
        accessToken: PropTypes.string,
        track: PropTypes.func.isRequired,
        user: PropTypes.instanceOf(User)
    };

    static getDerivedStateFromProps(props, state) {
        if (props.accessToken && !state.prevAccessToken) {
            return {
                prevAccessToken: props.accessToken,
                canSendTracking: true
            };
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            showModal: window.popup === 'signup',
            userCreated: null,
            version: null,
            externalSignIn: { isFacebook: false, isApple: false },
            godfatherWhoInvitedMe: null
        };
        this.onOpenSignupEvent = this.onOpenSignupEvent.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.onEmailLogin = this.onEmailLogin.bind(this);
        this.onFacebookLogin = this.onFacebookLogin.bind(this);
        this.onAppleLogin = this.onAppleLogin.bind(this);
        this.handleAuthSignin = this.handleAuthSignin.bind(this);
        this.signin = this.signin.bind(this);
        this.sendAccountTrackingEvents = this.sendAccountTrackingEvents.bind(this);
        this.redirectToSingupFunnel = this.redirectToSingupFunnel.bind(this);
        this.handleFbOrAppleSignin = this.handleFbOrAppleSignin.bind(this);
        this.handleUserCreated = this.handleUserCreated.bind(this);
        this.afterUserCreated = this.afterUserCreated.bind(this);
        this.addGodfather = this.addGodfather.bind(this);
        this.onTrackNavigation = this.onTrackNavigation.bind(this);
    }

    componentDidMount() {
        document.addEventListener('open_signup_popup', this.onOpenSignupEvent);
    }

    componentWillUnmount() {
        document.removeEventListener('open_signup_popup', this.onOpenSignupEvent);
    }

    componentDidUpdate() {
        if (this.props.user) {
            // user just created a account
            if (this.state.canSendTracking && this.state.userCreated) {
                this.handleUserCreated();
            } else if (this.state.externalSignIn.isFacebook || this.state.externalSignIn.isApple) {
                // login without account creation
                this.handleFbOrAppleSignin();
            }
        }
    }

    onOpenSignupEvent(e) {
        const version = e.detail && e.detail.version ? e.detail.version : null;
        this.open(version);
        this.onTrackNavigation('modal', NAVIGATION_ACTION.VIEW, null, NAVIGATION_TEXT.SIGN_UP);
    }

    open(version) {
        this.setState({
            showModal: true,
            version
        });
    }

    close() {
        this.setState({ showModal: false });
    }

    handleUserCreated() {
        const { userCreated } = this.state;
        this.setState({ canSendTracking: false });

        return Sponsor.getGodfatherWhoInvitedMeByEmail(userCreated.email)
            .then((godfather) => {
                const userHaveBeenInvited = godfather.id !== undefined;
                this.setState({
                    godfatherWhoInvitedMe: userHaveBeenInvited ? godfather : null
                });

                if (!userHaveBeenInvited) {
                    return this.afterUserCreated();
                }
            })
            .catch(() => this.afterUserCreated());
    }

    afterUserCreated() {
        return Promise.all([
            this.sendAccountTrackingEvents(this.state.source),
            Analytics.trackIterable('Login', {
                email: this.props.user.get('email'),
                device: 'web'
            })
        ])
            .catch((error) => {
                Sentry.captureException(error);
            })
            .finally(() => {
                this.redirectToSingupFunnel();
            });
    }

    addGodfather(godfather) {
        Sponsor.addGodfather(godfather.sponsorship_key)
            .then(() => {
                this.afterUserCreated();
            })
            .catch(() => {
                this.afterUserCreated();
            });
    }

    /**
     * send login event to ierable if fb or apple login
     */
    handleFbOrAppleSignin() {
        const { externalSignIn } = this.state;
        const { fetchUserCreated } = useGTM(this.props.user);
        let method = null;

        if (externalSignIn.isFacebook) {
            method = 'facebook';
        } else if (externalSignIn.isApple) {
            method = 'apple';
        }

        this.setState({
            externalSignIn: { isFacebook: false, isApple: false }
        });

        Analytics.trackGTM('UserCreated', fetchUserCreated(null, method));

        return Analytics.trackIterable('Login', {
            email: this.props.user.get('email'),
            device: 'web'
        })
            .then(this.redirectFromSignin)
            .catch(this.redirectFromSignin);
    }

    redirectFromSignin() {
        // regular signin => refresh url or redirect if needed
        const redirectTo = Utils.getParameter('redirectTo');
        if (redirectTo) {
            document.location.assign(redirectTo);
        } else {
            document.location.reload();
        }
    }

    // à refacto en plus propre
    isOnUrlHome(urls) {
        let hasUrl = false;

        urls.forEach((url) => {
            if (Utils.hasUrlPath(url)) {
                hasUrl = true;
            }
        });

        return hasUrl;
    }

    redirectToSingupFunnel() {
        if (!Utils.getParameter('subscribe')) {
            let uri = `${i18n.t('url:auth_dream_destination')}?source=${this.state.source}`;
            // à refacto en plus propre
            const homeUrls = [
                'holiday-home',
                'odmor-kuca',
                'feriebolig',
                'vakantie-woning',
                'maison-vacances',
                'ferien-wohnung',
                'case-vacanze',
                'ferie-hjem',
                'casas-de-ferias',
                'casas-de-vacaciones',
                'semester-hem'
            ];

            if (this.isOnUrlHome(homeUrls)) {
                uri = Utils.addParameter(uri, 'redirectToHome', Utils.getLastUrlPath());
                document.location.assign(uri);
            }

            document.location.assign(uri);
        } else {
            document.location.assign(
                Utils.addParameter(
                    i18n.t('url:subscription_payment'),
                    'redirect',
                    i18n.t('url:auth_dream_destination')
                )
            );
        }
    }

    sendAccountTrackingEvents(source) {
        return this.props.track('Account created', {
            category: 'Signup',
            source
        });
    }

    signin(e) {
        e.preventDefault();
        this.close();
        this.onTrackNavigation(
            'button',
            NAVIGATION_ACTION.CLICK,
            NAVIGATION_AREAS.SIGN_UP_MODAL,
            NAVIGATION_TEXT.SIGN_IN
        );
        document.dispatchEvent(new CustomEvent('open_signin_popup'));
    }

    onEmailLogin(data) {
        const { cookies, currency, track } = this.props;

        this.setState({ source: 'email' });

        return UserApi.create(
            Object.assign({}, data, {
                locale: i18n.language,
                currency,
                sponsor: data.sponsor ? data.sponsor : Utils.getParameter('sponsorkey', null),
                club: cookies.get('group-key')
            })
        )
            .then((userCreated) => {
                Common.login(data.email, data.password, i18n.language)
                    .then(() => {
                        this.setState({ userCreated: data });
                    })
                    .catch((e) => {
                        const { fetchUserCreated } = useGTM(new User(userCreated));

                        if (e.responseJSON.includes('email.error.validation')) {
                            sweetAlert({
                                content: <EmailVerificationSignup email={data.email} />,
                                closeOnEsc: false,
                                closeOnClickOutside: false,
                                showCloseButton: false,
                                button: false
                            });

                            this.close();

                            track('Account created', {
                                category: 'Signup',
                                source: 'email',
                                userEmail: SHA256(data.email).toString()
                            });

                            Analytics.trackGTM('UserCreated', fetchUserCreated(null, 'email'));
                        }
                    });
            })
            .catch((e) => e);
    }

    onFacebookLogin(response) {
        const { userID, accessToken } = response.authResponse;
        const { cookies, currency } = this.props;

        return new Promise((resolve, reject) => {
            this.setState({ source: 'facebook' }, () => {
                const data = {
                    currency,
                    locale: i18n.language,
                    groupKey: cookies.get('group-key'),
                    sponsorshipKey: Utils.getParameter('sponsorkey', null)
                };

                return Common.loginFacebook(userID, accessToken, data)
                    .then((token, textStatus, jqXHR) => {
                        this.handleAuthSignin(token, textStatus, jqXHR, 'facebook');
                        resolve();
                    })
                    .catch((error) => {
                        const message = Utils.getErrorMessage(error);
                        if (error.status == 403) {
                            sweetAlert({
                                title: i18n.t('common:an_error_occured'),
                                icon: 'error',
                                text: i18n.t(`user:${JSON.parse(error.responseText).errors}`),
                                closeOnEsc: true,
                                closeOnClickOutside: true
                            });
                        } else if (error.status == 401) {
                            sweetAlert(i18n.t('common:an_error_occured'), message, 'error');
                        } else {
                            sweetAlert(i18n.t('common:an_error_occured'), message, 'error');
                        }

                        // Remove loader
                        this.setState({
                            sendingRequest: false
                        });

                        reject();
                    });
            });
        });
    }

    onTrackNavigation(event, action, area, text) {
        const { fetchNavigation } = useGTM(this.props.user);
        Analytics.trackGTM(event, fetchNavigation(action, area, text));
    }

    onAppleLogin(response) {
        const { cookies, currency } = this.props;

        return new Promise((resolve, reject) => {
            this.setState({ source: 'apple' }, () => {
                const data = {
                    currency,
                    locale: i18n.language,
                    groupKey: cookies.get('group-key'),
                    sponsorshipKey: Utils.getParameter('sponsorkey', null)
                };

                return Common.loginApple(response.payload, data)
                    .then((token, textStatus, jqXHR) => {
                        this.handleAuthSignin(token, textStatus, jqXHR, 'apple');
                        resolve();
                    })
                    .catch((error) => {
                        const message = Utils.getErrorMessage(error);
                        if (error.status == 403) {
                            sweetAlert({
                                title: i18n.t('common:an_error_occured'),
                                icon: 'error',
                                text: i18n.t(`user:${JSON.parse(error.responseText).errors}`),
                                closeOnEsc: true,
                                closeOnClickOutside: true
                            });
                        } else if (error.status == 401) {
                            sweetAlert(i18n.t('common:an_error_occured'), message, 'error');
                        } else {
                            sweetAlert(i18n.t('common:an_error_occured'), message, 'error');
                        }

                        // Remove loader
                        this.setState({
                            sendingRequest: false
                        });

                        reject();
                    });
            });
        });
    }

    handleAuthSignin(token, textStatus, jqXHR, origin) {
        const { status } = jqXHR;
        // Status = 200 means that it's an existing user
        if (status == 201) {
            this.setState({ userCreated: true });
        }
        this.setState({
            externalSignIn: { isFacebook: origin === 'facebook', isApple: origin === 'apple' }
        });
    }

    render() {
        let title = i18n.t('common:create_account');
        const { currency } = this.props;
        let description = i18n.t('common:signup.default.description');
        const { godfatherWhoInvitedMe } = this.state;

        if (this.state.version === 'contact') {
            title = i18n.t('common:signup.contact.title');
            description = i18n.t('common:signup.contact.description');
        }

        return (
            <Modal dialogClassName="modal-login modal-signup" show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <div className="modal-illustration">
                        <img
                            src={`${Utils.getCloudfrontDomain()}/images/website/search/offline_signup_illustration.svg`}
                        />
                    </div>
                    <Modal.Title>{title}</Modal.Title>
                    <span className="separator"></span>
                </Modal.Header>
                <Modal.Body>
                    {godfatherWhoInvitedMe && (
                        <SponsorFriend
                            godfather={godfatherWhoInvitedMe}
                            onCancel={this.afterUserCreated}
                            onConfirm={this.addGodfather}
                        />
                    )}
                    {description && <div className="modal-signup-description">{description}</div>}
                    <Suspense fallback={<div>{`${i18n.t('common:loading')}...`}</div>}>
                        <EmailSignup
                            currency={currency}
                            onLogin={this.onEmailLogin}
                            onClick={() =>
                                this.onTrackNavigation(
                                    'button',
                                    NAVIGATION_ACTION.CLICK,
                                    NAVIGATION_AREAS.SIGN_UP_MODAL,
                                    NAVIGATION_TEXT.SIGN_UP_EMAIL
                                )
                            }
                        />
                    </Suspense>
                    <FacebookLoginWrapper
                        onLogin={this.onFacebookLogin}
                        onClick={() =>
                            this.onTrackNavigation(
                                'button',
                                NAVIGATION_ACTION.CLICK,
                                NAVIGATION_AREAS.SIGN_UP_MODAL,
                                NAVIGATION_TEXT.SIGN_UP_FACEBOOK
                            )
                        }
                    >
                        <FacebookButton text={i18n.t('common:signup_facebook')} block={true} size="large" />
                    </FacebookLoginWrapper>
                    <AppleButton
                        onLogin={this.onAppleLogin}
                        text="signup"
                        onClick={() =>
                            this.onTrackNavigation(
                                'button',
                                NAVIGATION_ACTION.CLICK,
                                NAVIGATION_AREAS.SIGN_UP_MODAL,
                                NAVIGATION_TEXT.SIGN_UP_APPLE
                            )
                        }
                    />
                    <div className="text-block">
                        {i18n.t('already_registrer')}
                        <a className="m-l-5" href="#" onClick={this.signin}>
                            {i18n.t('common:signin')}
                        </a>
                    </div>
                    <span className="separator"></span>
                    <div className="signup-footer">
                        {i18n.t('common:signup_cgu')}{' '}
                        <a href={i18n.t('url:cgu.url')} target="_blank" rel="noopener noreferrer">
                            {i18n.t('common:cgu')}
                        </a>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken,
    user: state.user
});

export default compose(connect(mapStateToProps), withCurrency, withCookies, withAnalytics)(Signup);
