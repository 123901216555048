import { SET_REWARDS } from '../actions/rewards';

export default function rewards(state = [], { type, payload }) {
    switch (type) {
        case SET_REWARDS:
            return payload;
        default:
            return state;
    }
}
