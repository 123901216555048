import React, { useContext } from 'react';
import Screen from '../../utils/Screen';
import FixedNumberDay from './FixedNumberDay';
import NewInputRangeCalendar from '../calendar/NewInputRangeCalendar';
import { ParametersContext } from './InputSearch';

function InputSearchTabFixedDates() {
    const { startOn, setStartOn, endOn, setEndOn } = useContext(ParametersContext);
    const selectedHandler = (dateRange) => {
        setStartOn(dateRange[0]);
        setEndOn(dateRange[1]);
    };
    return (
        <div className="content-fixed-dates">
            <FixedNumberDay />
            <NewInputRangeCalendar
                mode="range"
                startOn={startOn}
                endOn={endOn}
                nbMonths={Screen.isMobile() ? 14 : 2}
                selectedHandler={selectedHandler}
                maintainSelectionOnClickOutside={true}
            />
        </div>
    );
}

export default InputSearchTabFixedDates;
