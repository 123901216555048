import Common from './Common';

export default class Rating {
    static getRatings(userId) {
        const params = {
            url: `/v1/ratings/${userId}`,
            method: 'get',
            auth: false
        };
        return Common.request(params);
    }
}
