import React, { useEffect, useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import i18n from '../../i18n';
import InputCalendar from '../calendar/InputCalendar';
import InputSearchModal from './InputSearchModal';
import { durationKindLabels } from './InputSearchTabFlexibleDates';
import Filters from '../../models/Filters';

const [TYPE_FIXED_DATES, TYPE_FLEXIBLE_DATES, TYPE_LAST_MINUTE] = Filters.SEARCH_TYPES;

export const searchTypeLabels = {
    [TYPE_FIXED_DATES]: i18n.t('search:fixed-dates-title'),
    [TYPE_FLEXIBLE_DATES]: i18n.t('search:flexible-dates-title'),
    [TYPE_LAST_MINUTE]: i18n.t('search:filter.last-minute-title')
};

export const ParametersContext = React.createContext();

function InputSearch({ classname, calendar, setCalendar, context, isFilter, showModal, setShowModal }) {
    const [textToDisplay, setTextToDisplay] = useState('');
    const [searchType, setSearchType] = useState();
    const [dateRanges, setDateRanges] = useState([]);
    const [startOn, setStartOn] = useState();
    const [endOn, setEndOn] = useState();
    const [flexibility, setFlexibility] = useState();
    const [kind, setKind] = useState('one-week');
    const [numberOfNights, setNumberOfNights] = useState();
    const [isValid, setIsValid] = useState();
    const [displayResetIcon, setDisplayResetIcon] = useState(false);

    const inputRef = useRef();

    const initCalendar = (calendarToInit) => {
        // fixed dates
        if (
            calendarToInit &&
            calendarToInit.date_ranges &&
            calendarToInit.date_ranges.length === 1 &&
            !(calendarToInit.duration && calendarToInit.duration.kind)
        ) {
            setStartOn(moment(calendarToInit.date_ranges[0].from));
            setEndOn(moment(calendarToInit.date_ranges[0].to));
            setSearchType(TYPE_FIXED_DATES);
            setFlexibility(calendarToInit.flexibility);
        }
        // flexible dates
        else if (
            calendarToInit &&
            calendarToInit.duration &&
            (calendarToInit.duration.kind || calendarToInit.duration.number_of_nights)
        ) {
            setSearchType(TYPE_FLEXIBLE_DATES);
            setKind(calendarToInit.duration.kind);
            setNumberOfNights(calendarToInit.duration.number_of_nights);
            setDateRanges(calendarToInit.date_ranges);
        }
        // last minute
        else if (calendarToInit && calendarToInit.last_minute) {
            setSearchType(TYPE_LAST_MINUTE);
        }
        // default
        else {
            setSearchType(TYPE_FIXED_DATES);
            setFlexibility(null);
        }
    };

    // split variables from props
    useEffect(() => {
        if (calendar) {
            initCalendar(calendar);
        }
    }, [calendar]);

    const checkIsValid = () => {
        switch (searchType) {
            case TYPE_FIXED_DATES:
                return startOn && endOn;
            case TYPE_FLEXIBLE_DATES:
                return dateRanges.length > 0 && (kind || numberOfNights);
            case TYPE_LAST_MINUTE:
                return true;
            default:
                return false;
        }
    };

    // handle title
    useEffect(() => {
        setIsValid(checkIsValid());
        setDisplayResetIcon(
            startOn ||
                endOn ||
                (searchType === TYPE_FLEXIBLE_DATES && kind) ||
                searchType === TYPE_LAST_MINUTE
        );
        // eslint-disable-next-line default-case
        switch (searchType) {
            case TYPE_FIXED_DATES:
                setTextToDisplay(
                    <span className="input-search-text">
                        <span className="start_on" title={i18n.t('search:filters.arrival')}>
                            {startOn ? startOn.format('DD/MM/YYYY') : i18n.t('search:filters.arrival')}
                        </span>
                        <i className="icon-arrow-right" />
                        <span className="end_on" title={i18n.t('search:filters.departure')}>
                            {endOn ? endOn.format('DD/MM/YYYY') : i18n.t('search:filters.departure')}
                        </span>
                    </span>
                );
                break;
            case TYPE_FLEXIBLE_DATES:
                setTextToDisplay(
                    <span className="input-search-text">
                        {kind ? durationKindLabels[kind] : searchTypeLabels[searchType]}
                        {dateRanges.length === 1 &&
                            i18n.t('search:date_ranges_in', {
                                in: moment(dateRanges[0].from).format('MMMM')
                            })}
                        {dateRanges.length > 1 &&
                            dateRanges.length < 4 &&
                            i18n.t('search:date_ranges_in', {
                                in: dateRanges
                                    .map((dateRange) => moment(dateRange.from).format('MMM'))
                                    .join(', ')
                                    .replace(/, ([^,]*)$/, i18n.t('search:date_ranges_replace'))
                            })}
                        {dateRanges.length >= 4 &&
                            i18n.t('search:date_ranges_from_to', {
                                from: moment(dateRanges[0].from).format('MMM'),
                                to: moment(dateRanges[dateRanges.length - 1].to).format('MMM')
                            })}
                    </span>
                );
                break;
            case TYPE_LAST_MINUTE:
                setTextToDisplay(<span className="input-search-text">{searchTypeLabels[searchType]}</span>);
                break;
            default:
                setTextToDisplay('');
                setDisplayResetIcon(false);
        }
    }, [searchType, startOn, endOn, flexibility, dateRanges, kind, numberOfNights]);

    const getUnchangedDataFromCalendar = () => {
        return {
            ...(calendar.exchange_types && { exchange_types: calendar.exchange_types })
        };
    };

    const updateCalendar = () => {
        let newCalendar = getUnchangedDataFromCalendar();
        switch (searchType) {
            case TYPE_FIXED_DATES:
                if (startOn && endOn) {
                    newCalendar = {
                        ...newCalendar,
                        date_ranges: [
                            {
                                from: startOn.format('YYYY-MM-DD'),
                                to: endOn.format('YYYY-MM-DD')
                            }
                        ]
                    };
                    if (flexibility) {
                        newCalendar.flexibility = flexibility;
                    }
                }
                break;
            case TYPE_FLEXIBLE_DATES:
                newCalendar = {
                    ...newCalendar,
                    date_ranges: dateRanges.map((dateRange) => ({
                        from: dateRange.from,
                        to: dateRange.to
                    })),
                    duration: {
                        kind,
                        number_of_nights: numberOfNights
                    }
                };
                break;
            case TYPE_LAST_MINUTE:
                newCalendar = {
                    ...newCalendar,
                    last_minute: true
                };
                break;
            default:
                setCalendar(getUnchangedDataFromCalendar());
        }

        if (checkIsValid()) {
            setCalendar(newCalendar);
        }
    };

    const onValidate = useCallback(() => {
        updateCalendar();
        setShowModal(false);
    }, [updateCalendar, setShowModal]);

    const resetFilterDate = () => {
        setTextToDisplay('');
        setSearchType(TYPE_FIXED_DATES);
        setDateRanges([]);
        setStartOn();
        setEndOn();
        setFlexibility();
        setKind('one-week');
        setNumberOfNights();
        setIsValid();
        setCalendar(getUnchangedDataFromCalendar());
    };

    return (
        <ParametersContext.Provider
            value={{
                showModal,
                setShowModal,
                searchType,
                setSearchType,
                isValid,
                flexibility,
                setFlexibility,
                startOn,
                setStartOn,
                endOn,
                setEndOn,
                dateRanges,
                setDateRanges,
                kind,
                setKind,
                numberOfNights,
                setNumberOfNights
            }}
        >
            <div
                ref={inputRef}
                className={classNames(
                    `${isFilter ? classname : 'date_range calendar-v2 btn-flexible-search'}`,
                    { 'date-range-flexible': searchType === TYPE_FIXED_DATES },
                    'calendar-txt-container'
                )}
            >
                <div className="d-flex container-input-search-text" onClick={() => setShowModal(true)}>
                    {textToDisplay}
                </div>
                {displayResetIcon && (
                    <span onClick={() => resetFilterDate()}>
                        <i className="icon-close" />
                    </span>
                )}
            </div>
            {showModal && <InputSearchModal onValidate={onValidate} context={context} />}
        </ParametersContext.Provider>
    );
}

InputSearch.propTypes = {
    ...InputCalendar.propTypes
};

export default InputSearch;
