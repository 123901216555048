import {
    SET_SUBSCRIPTION,
    REQUEST_SUBSCRIPTION,
    ERROR_FETCHING_SUBSCRIPTION,
    SET_COUPON
} from '../actions/subscription';

import Product from '../models/Product';
import Subscription from '../models/Subscription';

export default function subscription(
    state = {
        isFetching: false,
        subscription: null,
        coupon: {},
        isToggleAutoRenewDisabled: false
    },
    { type, payload }
) {
    switch (type) {
        case SET_SUBSCRIPTION: {
            const newPayload = JSON.parse(JSON.stringify(payload));
            if (payload.nextProduct) {
                newPayload.nextProduct = new Product(payload.nextProduct);
            }
            if (payload.subscription) {
                newPayload.subscription = new Subscription(
                    Object.assign(
                        {},
                        state.subscription ? state.subscription.attributes : {},
                        payload.subscription
                    )
                );
            }
            newPayload.isFetching = false;
            return Object.assign({}, state, newPayload);
        }
        case REQUEST_SUBSCRIPTION:
            return Object.assign({}, state, {
                isFetching: true
            });
        case ERROR_FETCHING_SUBSCRIPTION: {
            const newPayload = JSON.parse(JSON.stringify(payload));
            if (payload.error && payload.error.error) {
                newPayload.error = newPayload.error.error;
            }
            if (newPayload.error && newPayload.error.details) {
                newPayload.error = newPayload.error.details;
            }
            if (!newPayload.code) {
                // Code for generic errors
                newPayload.code = 'err';
            }
            return Object.assign({}, state, {
                isFetching: false,
                error: newPayload
            });
        }
        case SET_COUPON: {
            return {
                ...state,
                coupon: payload
            };
        }
        default:
            return state;
    }
}
