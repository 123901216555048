import Common from './Common';

export default class UserTour {
    static setTourAsViewed(tourId) {
        const params = Common.params({
            url: '/v1/usertours',
            method: 'post',
            data: {
                tour: tourId
            }
        });
        return Common.request(params);
    }
}
