import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import i18n from '../../i18n';

export default class FacebookButton extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        onTrack: PropTypes.func,
        sendingRequest: PropTypes.bool,
        text: PropTypes.string,
        size: PropTypes.string,
        block: PropTypes.bool
    };

    static defaultProps = {
        text: i18n.t('common:connect_with_facebook'),
        size: null,
        block: false
    };

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { onClick, onTrack } = this.props;

        e.preventDefault();

        onClick();
        if (onTrack) onTrack('facebook-sign-up');
    }

    render() {
        const buttonProps = {};

        if (this.props.block) {
            buttonProps.block = true;
        }
        if (this.props.size) {
            buttonProps.bsSize = this.props.size;
        }

        return (
            <Button
                {...buttonProps}
                bsStyle="default"
                className={classNames('btn-ajax btn-social btn-fb', {
                    sending: this.props.sendingRequest
                })}
                onClick={this.handleClick}
            >
                <i className="fa fa-facebook" />
                {this.props.text}
            </Button>
        );
    }
}
