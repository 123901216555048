import Api from '../api/Api';

export const SEE_MORE_LP = 'SEE_MORE_LP';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const SET_EXTERNAL_LINK = 'SET_EXTERNAL_LINK';
export const SET_REMARKS = 'SET_REMARKS';
export const SET_OPTIONAL = 'SET_OPTIONAL';

export function redirectToSearch() {
    return {
        type: SEE_MORE_LP
    };
}

export function setFormData(payload) {
    return {
        type: SET_FORM_DATA,
        payload
    };
}

export function externalLink(payload) {
    return {
        type: SET_EXTERNAL_LINK,
        payload
    };
}

export function remarks(payload) {
    return {
        type: SET_REMARKS,
        payload
    };
}

export function setOptional(payload) {
    return {
        type: SET_OPTIONAL,
        payload
    };
}

export function setExternalLink(data) {
    return (dispatch) => Api.Home.setExternalLink(data).then(() => dispatch(externalLink(data)));
}

export function getExternalLink() {
    return (dispatch) => Api.Home.getExternalLink().then((result) => dispatch(externalLink(result)));
}

export function setRemarks(data) {
    return (dispatch) => Api.Home.setRemarks(data).then(() => dispatch(remarks(data)));
}

export function getRemarks() {
    return (dispatch) => Api.Home.getRemarks().then((result) => dispatch(remarks(result)));
}
