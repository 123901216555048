import React from 'react';

import i18n from '../../i18n';
import Utils from '../../utils/utils';

const PaypalForm = () => (
    <div className="paypal-form">
        {i18n.t('billing:paypal-form.content')}
        <img src={`${Utils.getCloudfrontDomain()}/images/website/subscription/paypal.svg`} alt="Paypal" />
    </div>
);

export default PaypalForm;
