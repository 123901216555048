import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Loading from '../../common/Loading';
import Utils from '../../../utils/utils';

export default class FilePlaceholder extends PureComponent {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
        file: PropTypes.instanceOf(window.File)
    };

    static defaultData =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

    state = {
        data: null
    };

    static getDerivedStateFromProps(props) {
        if (!props.file) {
            return { data: null };
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        const { file } = this.props;
        if (file && file != prevProps.file) {
            this.readAsDataURL(file);
        }
    }

    readAsDataURL(file) {
        this.setState({ data: null });
        if (file.type.includes('image/')) {
            const reader = new FileReader();
            reader.addEventListener(
                'load',
                () => {
                    this.setState({ data: reader.result });
                },
                false
            );
            reader.readAsDataURL(file);
        } else {
            // 1x1 px image transparent
            this.setState({ data: FilePlaceholder.defaultData });
        }
    }

    render() {
        const { children, file } = this.props;
        const { data } = this.state;
        if (data) {
            return (
                <div
                    className="file-placeholder"
                    data-type={file.type}
                    style={{ backgroundImage: `url(${data})` }}
                >
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">{Utils.humanFileSize(file.size)}</span>
                </div>
            );
        }
        if (file) {
            return (
                <div className="file-placeholder loading">
                    <Loading />
                </div>
            );
        }
        return <div className="file-placeholder empty">{children}</div>;
    }
}
