import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import StarRatingCompact from './StarRatingCompact';

export default class Average extends React.Component {
    static propTypes = {
        globalAvg: PropTypes.number,
        hostAvg: PropTypes.number,
        guestAvg: PropTypes.number,
        nbExchangesGuest: PropTypes.number,
        nbExchangesHost: PropTypes.number
    };

    render() {
        return (
            <div
                className="col-sm-12 ratings"
                itemProp="aggregateRating"
                itemScope
                itemType="http://schema.org/AggregateRating"
            >
                <meta itemProp="ratingValue" value={this.props.globalAvg} />
                <meta itemProp="reviewCount" value={this.props.nbExchangesHost} />
                <div className="row">
                    <div className="col-sm-4">
                        <span className="rate-title">{i18n.t('rating:overall_ratings')}</span>
                        <StarRatingCompact nbStars={this.props.globalAvg} />
                        <br />
                        <span className="rate-exchanges">
                            {i18n.t('common:exchange_count', {
                                count: this.props.nbExchangesGuest + this.props.nbExchangesHost
                            })}
                        </span>
                    </div>
                    <div className="col-sm-4">
                        <span
                            className={this.props.nbExchangesHost == 0 ? 'rate-title disable' : 'rate-title'}
                        >
                            {i18n.t('rating:from_guests')}
                        </span>
                        <StarRatingCompact nbStars={this.props.hostAvg} />
                        <br />
                        <span
                            className={
                                this.props.nbExchangesHost == 0 ? 'rate-exchanges disable' : 'rate-exchanges'
                            }
                        >
                            {i18n.t('common:exchange_count', {
                                count: this.props.nbExchangesHost
                            })}
                        </span>
                    </div>
                    <div className="col-sm-4">
                        <span
                            className={this.props.nbExchangesGuest == 0 ? 'rate-title disable' : 'rate-title'}
                        >
                            {i18n.t('rating:from_hosts')}
                        </span>
                        <StarRatingCompact nbStars={this.props.guestAvg} />
                        <br />
                        <span
                            className={
                                this.props.nbExchangesGuest == 0 ? 'rate-exchanges disable' : 'rate-exchanges'
                            }
                        >
                            {i18n.t('common:exchange_count', {
                                count: this.props.nbExchangesGuest
                            })}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
