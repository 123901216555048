import React from 'react';
import PropTypes from 'prop-types';

import Home from '../../../models/Home';

import { ProgressBar } from '@homeexchange/design';

const HomeStatus = ({ home }) => {
    return (
        <div className="section-status">
            <span>{home.get('completion_rate')}%</span>

            <ProgressBar
                ariaLabel="Progress bar"
                color="secondary"
                size="small"
                value={home.get('completion_rate')}
            />
        </div>
    );
};

HomeStatus.propTypes = {
    home: PropTypes.instanceOf(Home).isRequired
};

export default HomeStatus;
