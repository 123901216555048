import Notification from '../models/Notification';

import { SET_TOP_MENU_NOTIFICATIONS } from '../actions/notifications';

export default function topMenuNotifications(state = new Notification(), { type, payload }) {
    switch (type) {
        case SET_TOP_MENU_NOTIFICATIONS:
            return payload;
        default:
            return state;
    }
}
