import React, { useEffect, useState, useContext, useRef } from 'react';
import classNames from 'classnames';
import i18n from '../../i18n';
import CarouselComponent from '../common/carousel/carousel';
import { ParametersContext } from './InputSearch';
import Filters from '../../models/Filters';
import { useSmoothScrollToActive, useGrabScroll } from '../../hooks';

export const durationKindLabels = {
    'week-end': i18n.t('common:duration.weekend'),
    'one-week': i18n.t('common:duration.week'),
    'two-weeks': i18n.t('common:duration.week_plural', { count: 2 }),
    'one-month': i18n.t('common:duration.month')
};

function InputSearchTabFlexibleDates() {
    const [incomingMonths, setIncomingMonth] = useState([]);
    const [selectedMonths, setSelectedMonths] = useState([]);

    const { dateRanges, setDateRanges, kind, setKind } = useContext(ParametersContext);
    const sliderTravelRef = useRef(null);
    const sliderMonthsRef = useRef(null);

    // init months
    useEffect(() => {
        const months = [];
        for (let i = 0; i < 13; i++) {
            const date = new Date();
            date.setDate(1);
            date.setMonth(date.getMonth() + i);
            months.push(date);
        }
        setIncomingMonth(months);
        const _rangesRef = dateRanges
            .filter((dateRange) => dateRange && dateRange.from && dateRange.to)
            .map((dateRange) => moment(dateRange.from).format('YYYY-MM'))
            .sort();
        if (selectedMonths.join('_') !== _rangesRef.join('_')) {
            setSelectedMonths(() => _rangesRef);
        }
    }, []);

    const syncDateRanges = () => {
        const newRanges = selectedMonths.sort().map((monthRef) => {
            const date = moment(monthRef);
            return {
                from: date.startOf('month').format('YYYY-MM-DD'),
                to: date.endOf('month').format('YYYY-MM-DD')
            };
        });
        setDateRanges(newRanges);
    };

    // selected Month to dateRanges
    useEffect(() => {
        syncDateRanges();
    }, [selectedMonths]);

    // handle month selection
    const handleSelectMonth = (monthRef) => {
        setSelectedMonths((prev) => {
            if (prev.includes(monthRef)) {
                return prev.filter((ref) => ref !== monthRef);
            }
            return [...prev, monthRef].sort();
        });
    };

    useGrabScroll('.flexible-time');
    useSmoothScrollToActive(sliderTravelRef, '.flexible-search-duration.active', true);
    useSmoothScrollToActive(sliderMonthsRef, '.flexible-search-date.active');

    // build slides
    const slidesTravel = Filters.DURATION_KINDS.map((durationKind) => (
        <li
            key={durationKind}
            className={classNames('flexible-search-duration', { active: kind === durationKind })}
            onClick={() => setKind(durationKind)}
        >
            {durationKindLabels[durationKind]}
        </li>
    ));

    const slidesMonths = incomingMonths.map((date) => {
        const month = moment(date).format('MMMM');
        const year = moment(date).format('YYYY');
        const monthRef = moment(date).format('YYYY-MM');
        return (
            <li
                key={`month-${monthRef}`}
                className={classNames('flexible-search-date', { active: selectedMonths.includes(monthRef) })}
                onClick={() => handleSelectMonth(monthRef)}
            >
                <div className={'month'}>{month}</div>
                <div className={'year'}>{year}</div>
            </li>
        );
    });

    return (
        <div className="content-flexible" id="flexible-dates">
            <div className="title">{i18n.t('search:flexible-dates-title.travel')}</div>
            <ul className="flexible-time" ref={sliderTravelRef}>
                {slidesTravel}
            </ul>
            <div className="title">{i18n.t('search:flexible-dates-title.months')}</div>
            <ul className="flexible-date">
                <CarouselComponent
                    swipe={false}
                    variableWidth={true}
                    slides={slidesMonths}
                    sliderRef={sliderMonthsRef}
                    swipeToSlide={true}
                    slidesToScroll={4}
                />
            </ul>
        </div>
    );
}

export default InputSearchTabFlexibleDates;
