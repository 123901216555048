import Api from '../api/Api';
import User from '../models/User';

export const REQUEST_FRIENDS = 'REQUEST_FRIENDS';
export const REQUEST_PENDINGS = 'REQUEST_PENDINGS';
export const REQUEST_SENT_REQUESTS = 'REQUEST_SENT_REQUESTS';

export const SET_FRIENDS = 'SET_FRIENDS';
export const ADD_FRIEND = 'ADD_FRIEND';
export const REMOVE_FRIEND = 'REMOVE_FRIEND';

export const SET_PENDING_FRIENDS = 'SET_PENDING_FRIENDS';
export const REMOVE_PENDING_FRIEND = 'REMOVE_PENDING_FRIEND';

export const SET_SENT_FRIEND_REQUESTS = 'SET_SENT_FRIEND_REQUESTS';
export const REMOVE_SENT_FRIEND_REQUESTS = 'REMOVE_SENT_FRIEND_REQUESTS';

export function requestFriends() {
    return {
        type: REQUEST_FRIENDS
    };
}

export function requestPendings() {
    return {
        type: REQUEST_PENDINGS
    };
}

export function requestSentRequests() {
    return {
        type: REQUEST_SENT_REQUESTS
    };
}

export function setFriends(friends) {
    return {
        type: SET_FRIENDS,
        payload: friends
    };
}

export function addFriend(friend) {
    return {
        type: ADD_FRIEND,
        payload: friend
    };
}

export function removeFriend(friendId) {
    return {
        type: REMOVE_FRIEND,
        payload: friendId
    };
}

export function setPendingFriends(friends) {
    return {
        type: SET_PENDING_FRIENDS,
        payload: friends
    };
}

export function removePendingFriend(friendId) {
    return {
        type: REMOVE_PENDING_FRIEND,
        payload: friendId
    };
}

export function setSentFriendRequests(friends) {
    return {
        type: SET_SENT_FRIEND_REQUESTS,
        payload: friends
    };
}

export function removeSentFriendRequests(friendId) {
    return {
        type: REMOVE_SENT_FRIEND_REQUESTS,
        payload: friendId
    };
}

export function fetchFriends(userId, offset, limit = 1000) {
    return (dispatch) => {
        dispatch(requestFriends());
        return Api.User.fetchFriends(userId, offset, limit).then((response) => {
            if (!response || !response.friends) {
                dispatch(setFriends([]));
                return response;
            }
            let { friends } = response;
            friends = friends.map((friend) => new User(friend));
            dispatch(setFriends(friends));
            return response;
        });
    };
}

export function fetchPendingFriends(offset = 0, limit = 100) {
    return (dispatch) => {
        dispatch(requestPendings());
        return Api.User.fetchPendingFriends(offset, limit).then((response) => {
            if (!response || !response.friends) {
                dispatch(setPendingFriends([]));
                return response;
            }
            let { friends } = response;
            friends = friends.map((friend) => new User(friend));
            dispatch(setPendingFriends(friends));
            return response;
        });
    };
}

export function fetchSentFriendRequests(offset = 0, limit = 100) {
    return (dispatch) => {
        dispatch(requestSentRequests());
        return Api.User.fetchSentFriendRequests(offset, limit).then((response) => {
            if (!response || !response.friends) {
                dispatch(setSentFriendRequests([]));
                return response;
            }
            let { friends } = response;
            friends = friends.map((friend) => new User(friend));
            dispatch(setSentFriendRequests(friends));
            return response;
        });
    };
}

export function inviteFriend(friend) {
    return () => Api.User.addFriend(friend.id);
}

export function deleteFriend(friend) {
    return (dispatch) => {
        dispatch(removeFriend(friend.id));
        return Api.User.deleteFriend(friend.id).then(
            (res) => res,
            () => {
                // restore friend if an error occured
                dispatch(addFriend(friend));
            }
        );
    };
}

export function acceptFriend(friend) {
    return (dispatch) => {
        dispatch(removePendingFriend(friend.id));
        dispatch(addFriend(friend));
        return Api.User.acceptFriend(friend.id);
    };
}

export function refuseFriend(friend) {
    return (dispatch) => {
        dispatch(removePendingFriend(friend.id));
        return Api.User.refuseFriend(friend.id);
    };
}
