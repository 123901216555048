import React, { Suspense } from 'react';

import i18n from '../../i18n';

const LanguageSelector = React.lazy(() => import('../widget/LanguageSelector'));
const changeLanguage = (locale) => {
    const path = document.location.pathname.replace(/^\/[a-z]{2}\//, '/');
    document.location.assign(`/${locale}${path}`);
};

export const LanguageSwitcher = () => (
    <Suspense fallback={<div>{`${i18n.t('common:loading')}...`}</div>}>
        <LanguageSelector language={i18n.language} onChange={changeLanguage} placement="top-end" />
    </Suspense>
);

export default LanguageSwitcher;
