import {
    REQUEST_EXCHANGES_FINALIZED,
    RECEIVE_EXCHANGES_FINALIZED,
    REQUEST_MORE_EXCHANGES_UPCOMING_FINALIZED,
    RECEIVE_MORE_EXCHANGES_UPCOMING_FINALIZED,
    REQUEST_MORE_EXCHANGES_ONGOING_FINALIZED,
    RECEIVE_MORE_EXCHANGES_ONGOING_FINALIZED,
    REQUEST_MORE_EXCHANGES_PAST_FINALIZED,
    RECEIVE_MORE_EXCHANGES_PAST_FINALIZED
} from '../actions/exchangesFinalized';

export default (
    state = {
        isFetching: false
    },
    action
) => {
    switch (action.type) {
        case REQUEST_EXCHANGES_FINALIZED:
        case REQUEST_MORE_EXCHANGES_UPCOMING_FINALIZED:
        case REQUEST_MORE_EXCHANGES_ONGOING_FINALIZED:
        case REQUEST_MORE_EXCHANGES_PAST_FINALIZED:
            return {
                ...state,
                isFetching: true
            };
        case RECEIVE_EXCHANGES_FINALIZED:
            return {
                isFetching: false,
                ...action.exchanges
            };
        case RECEIVE_MORE_EXCHANGES_UPCOMING_FINALIZED:
            return {
                ...state,
                isFetching: false,
                upcoming: {
                    ...action.exchanges.upcoming,
                    exchanges: [...state.upcoming.exchanges, ...action.exchanges.upcoming.exchanges]
                }
            };
        case RECEIVE_MORE_EXCHANGES_ONGOING_FINALIZED:
            return {
                ...state,
                isFetching: false,
                ongoing: {
                    ...action.exchanges.ongoing,
                    exchanges: [...state.ongoing.exchanges, ...action.exchanges.ongoing.exchanges]
                }
            };
        case RECEIVE_MORE_EXCHANGES_PAST_FINALIZED:
            return {
                ...state,
                isFetching: false,
                past: {
                    ...action.exchanges.past,
                    exchanges: [...state.past.exchanges, ...action.exchanges.past.exchanges]
                }
            };
        default:
            return state;
    }
};
