import Common from './Common';
import AlertModel from '../models/Alert';

export default class Alert {
    static me(filters, orderBy, limit, offset, version = 2) {
        const params = Common.params(
            {
                url: `/v1/users/me/alerts${version ? `?version=${version}` : ''}`,
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static get(filters, orderBy, limit, offset, version = 2) {
        const userId = filters.user || 'me';
        delete filters.user;
        const params = Common.params(
            {
                url: `/v1/users/${userId}/alerts${version ? `?version=${version}` : ''}`,
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static find(id) {
        const params = Common.params({
            url: `/v1/users/me/alerts/${id}`,
            method: 'get'
        });
        return Common.request(params);
    }

    static save(alert, version = false) {
        const params = Common.params({
            method: 'post',
            url: `/v1/users/me/alerts${version ? `?version=${version}` : ''}`,
            data: alert.toJSON()
        });
        return Common.request(params);
    }

    static remove(alert) {
        const params = Common.params({
            method: 'delete',
            url: `/v1/users/me/alerts/${alert.id}`
        });
        return Common.request(params);
    }

    static patch(alert, changes) {
        const params = Common.params({
            method: 'patch',
            url: `/v1/users/me/alerts/${alert.id}`,
            data: changes
        });
        return Common.request(params);
    }

    static getAlertForSearch(userId, search) {
        const alert = AlertModel.fromSearch(search.query, search.filters);
        const searchParams = alert.get('params');
        const deferred = $.Deferred();
        const params = Common.params({
            method: 'get',
            url: '/v1/users/me/alerts',
            data: {
                filters: {
                    user: userId,
                    status: [AlertModel.STATUS_ENABLED, AlertModel.STATUS_DISABLED]
                }
            }
        });
        Common.request(params)
            .done((alerts) => {
                const match = alerts.find((alertItem) => _.isEqual(alertItem.params, searchParams));
                if (match) {
                    deferred.resolve(match);
                } else {
                    deferred.reject();
                }
            })
            .fail(() => {
                deferred.reject();
            });
        return deferred.promise();
    }

    static getOnBoardingAlert() {
        const params = Common.params({
            url: '/v1/users/me/alerts/onboarding',
            method: 'get'
        });
        return Common.request(params);
    }
}
