import Model from './Model';
import i18n from '../i18n';

export default class Product extends Model {
    static DISABLE = 0;
    static ENABLE = 1;

    static CAUTION_TYPE = 'CAUTION';
    static INSURANCE_TYPE = 'INSURANCE';
    static PACK_TYPE = 'PACK';
    static SUBSCRIPTION_TYPE = 'SUBSCRIPTION';
    static SUBSCRIPTION_HE_COLLECTION = 'SUBSCRIPTION_HE_COLLECTION';
    static FIXED_RATE_HR = 7.54;
    static PRICE_THIRDX_PAYMENT = 156;

    productMapping = {
        subscription: {
            title: 'exchange:product.subscription.title',
            description: 'exchange:product.subscription.description',
            order: 1
        },
        pack: {
            title: 'exchange:product.pay_as_you_go.title',
            description: 'exchange:product.pay_as_you_go.description',
            order: 2
        },
        free: {
            title: 'exchange:product.free.title',
            description: null,
            order: 3
        }
    };

    parse(attributes, options) {
        attributes = super.parse(attributes, options);

        if (attributes.hasOwnProperty('type')) {
            switch (attributes.type.label) {
                case Product.INSURANCE_TYPE:
                    // For insurance products
                    if (attributes.hasOwnProperty('title')) {
                        attributes.name = i18n.t(`exchange:${attributes.title}`);
                    }

                    if (attributes.hasOwnProperty('description')) {
                        attributes.description = i18n.t(`exchange:${attributes.description}`);
                    }

                    if (attributes.hasOwnProperty('attributes')) {
                        if (attributes.attributes.details) {
                            attributes.details = i18n.t(
                                `exchange:${attributes.attributes.details}`,
                                attributes.attributes
                            );
                        }
                    }
                    break;
                default:
                    break;
            }
        }

        return attributes;
    }

    isFree() {
        return (
            this.has('type') &&
            this.get('type').label.toUpperCase() === Product.PACK_TYPE &&
            this.get('price') == 0
        );
    }

    isPack() {
        return (
            this.has('type') &&
            this.get('type').label.toUpperCase() === Product.PACK_TYPE &&
            this.get('price') > 0
        );
    }

    isSubscription() {
        return (
            this.isCollectionProduct() ||
            (this.has('type') && this.get('type').label.toUpperCase() === Product.SUBSCRIPTION_TYPE)
        );
    }

    isCaution() {
        return this.has('type') && this.get('type').label.toUpperCase() === Product.CAUTION_TYPE;
    }

    isCollectionProduct() {
        return (
            (this.has('type') &&
                this.get('type').label.toUpperCase() === Product.SUBSCRIPTION_HE_COLLECTION) ||
            (this.has('isCollection') && this.get('isCollection'))
        );
    }

    getMappedProduct() {
        if (this.isPack()) {
            return this.productMapping.pack;
        } else if (this.isFree()) {
            return this.productMapping.free;
        } else if (this.isSubscription()) {
            return this.productMapping.subscription;
        }
    }

    getDescription() {
        return this.getMappedProduct().description;
    }

    getTitle() {
        return this.getMappedProduct().title;
    }

    getOrder() {
        return this.getMappedProduct().order;
    }

    isDiscounted() {
        return this.has('original_price') && this.get('original_price') > this.get('price');
    }

    getSpecialOfferDate() {
        return moment('2018-11-30', 'YYYY-MM-DD');
    }
}
