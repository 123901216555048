import { LOAD_HOME } from '../actions/loadedHomes';

export default function loadedHomes(state = {}, { type, payload }) {
    switch (type) {
        case LOAD_HOME:
            return Object.assign({}, state, payload);
        default:
            return state;
    }
}
