// Externals
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classNames from 'classnames';

// Components
import CarouselButton from './carouselButton';
import Screen from '../../../utils/Screen';

const CarouselComponent = ({
    className,
    classNameHeader,
    displayHeader = 'out',
    title,
    subtitle,
    slides,
    additionalComponent,
    additionalCTA,
    sliderRef,
    nbSlides = 4,
    variableWidth = false,
    responsive,
    infinite = false,
    swipe = true,
    swipeToSlide = false,
    slidesToScroll = 1
}) => {
    useEffect(() => {
        const handleWheel = (e) => {
            e.preventDefault();
            const { deltaX, deltaY } = e;

            if (Math.abs(deltaY) > Math.abs(deltaX)) {
                // Check if vertical scrolling (up or down)
                return;
            }

            if (deltaX > 10) {
                sliderRef.current.slickNext();
            } else if (deltaX < -10) {
                sliderRef.current.slickPrev();
            }
        };

        if (sliderRef?.current && !Screen.isMobile()) {
            const sliderNode = sliderRef.current.innerSlider.list;

            sliderNode.addEventListener('wheel', handleWheel);

            return () => {
                sliderNode.removeEventListener('wheel', handleWheel);
            };
        }
    }, [sliderRef]);

    const header = () => (
        <header className={classNames(classNameHeader)}>
            <h2 className="carousel-title-container-title" dangerouslySetInnerHTML={{ __html: title }} />
            <p dangerouslySetInnerHTML={{ __html: subtitle }} />
        </header>
    );

    return (
        <div className={`${className ? className : ''} carousel-tile-container`}>
            {displayHeader === 'out' && header()}
            {additionalComponent && <div>{additionalComponent}</div>}
            <div className="carousel-container">
                <div className="container-fluid slider-container">
                    {displayHeader === 'in' && header()}
                    <div className="container slider-container">
                        <Slider
                            ref={sliderRef}
                            speed={500}
                            variableWidth={variableWidth}
                            slidesToShow={nbSlides}
                            centerPadding={'100px'}
                            slidesToScroll={slidesToScroll}
                            responsive={responsive}
                            swipe={swipe}
                            swipeToSlide={swipeToSlide}
                            infinite={infinite}
                            accessibility={false}
                            prevArrow={<CarouselButton iconName={'icon-left_arrow'} />}
                            nextArrow={<CarouselButton iconName={'icon-right_arrow'} />}
                        >
                            {slides}
                        </Slider>
                    </div>
                </div>
            </div>
            {additionalCTA && <div className="centered-button">{additionalCTA}</div>}
        </div>
    );
};

CarouselComponent.propTypes = {
    displayHeader: PropTypes.string,
    className: PropTypes.string,
    classNameHeader: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    slides: PropTypes.array,
    additionalComponent: PropTypes.object,
    additionalCTA: PropTypes.object,
    nbSlides: PropTypes.number,
    variableWidth: PropTypes.bool,
    responsive: PropTypes.array,
    infinite: PropTypes.bool,
    swipe: PropTypes.bool,
    sliderRef: PropTypes.object,
    swipeToSlide: PropTypes.bool,
    slidesToScroll: PropTypes.number
};

export default CarouselComponent;
