import dot from 'dot-object';
import Feature from '../models/Feature';
import Home from '../models/Home';

export default class Amenities {
    static BABY_GEAR = 4; // kids
    static OLD_BABY_GEAR = 2; // practical
    static PLAYGROUND = 2; // kids
    static OLD_PLAYGROUND = 8; // outdoor
    static MAX_SELECTED_COUNT = 2;

    static updateAmenities(name, home, value, saveValue) {
        dot.override = true;

        if (name === 'feature.kids' && (saveValue === this.BABY_GEAR || saveValue === this.PLAYGROUND)) {
            let subFeatureName, oldFeatureValue, newFeatureValue;

            if (saveValue === this.BABY_GEAR) {
                subFeatureName = 'feature.practical';
                oldFeatureValue = this.OLD_BABY_GEAR;
                newFeatureValue = this.BABY_GEAR;
            } else {
                subFeatureName = 'feature.outdoor';
                oldFeatureValue = this.OLD_PLAYGROUND;
                newFeatureValue = this.PLAYGROUND;
            }

            const previousValue = dot.pick(subFeatureName, home);
            const amenitySubFeature = Feature.bitmaskValues(previousValue);
            const foundOldFeature = amenitySubFeature.includes(oldFeatureValue);
            const foundNewFeature = amenitySubFeature.includes(newFeatureValue);
            let newValue = 0;

            if (foundNewFeature) {
                newValue = previousValue ^ newFeatureValue;
            }

            if (foundOldFeature || foundNewFeature) {
                dot.str(subFeatureName, newValue, home);
            }
        }

        dot.str(name, value, home);
        home = new Home(home);
    }

    static updateSelectedCheckboxes(
        prevSelectedCheckboxes,
        value,
        maxSelectedCount = this.MAX_SELECTED_COUNT
    ) {
        const numericValue = parseInt(value, 10);

        const isAlreadySelected = prevSelectedCheckboxes.includes(numericValue);
        let updatedSelectedCheckboxes;

        if (isAlreadySelected) {
            // If the checkbox is already selected, unselect it
            updatedSelectedCheckboxes = prevSelectedCheckboxes.filter(
                (checkbox) => checkbox !== numericValue
            );
        } else {
            // If the checkbox is not selected, add it
            if (prevSelectedCheckboxes.length >= maxSelectedCount) {
                // If the maximum number of checkboxes is already selected, replace the last one
                updatedSelectedCheckboxes = prevSelectedCheckboxes.slice(0, -1);
            } else {
                updatedSelectedCheckboxes = [...prevSelectedCheckboxes];
            }
            updatedSelectedCheckboxes.push(numericValue);
        }

        // Bitwise operation to update the value
        let newValue = 0;
        updatedSelectedCheckboxes.forEach((checkboxValue) => {
            newValue |= checkboxValue;
        });

        return { updatedSelectedCheckboxes, newValue };
    }
}
