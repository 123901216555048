import Common from './Common';

export default class Payment {
    static getBraintreeToken() {
        const params = {
            url: `/v1/braintree-client-token`,
            method: 'get'
        };
        return Common.request(params);
    }
}
