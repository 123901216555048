import PropTypes from 'prop-types';
import React from 'react';

import { Consumer } from './AnalyticsContext';
import Analytics from '../../utils/analytics';

export default function withAnalytics(WrappedComponent) {
    const name = WrappedComponent.displayName || WrappedComponent.name;

    class AnalyticsWrapper extends React.Component {
        static displayName = `withAnalytics(${name})`;
        static WrappedComponent = WrappedComponent;

        static propTypes = {
            analytics: PropTypes.instanceOf(Analytics),
            forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })])
        };

        render() {
            const { forwardedRef, analytics, ...restProps } = this.props;
            return (
                <WrappedComponent
                    {...restProps}
                    ref={forwardedRef}
                    track={analytics.track}
                    trackSpinalCord={analytics.trackSpinalCord}
                    eventsClient={analytics.eventsClient}
                />
            );
        }
    }

    // eslint-disable-next-line react/no-multi-comp
    const ForwardedComponent = React.forwardRef((props, ref) => (
        <Consumer>
            {(analytics) => <AnalyticsWrapper analytics={analytics} {...props} forwardedRef={ref} />}
        </Consumer>
    ));

    ForwardedComponent.displayName = AnalyticsWrapper.displayName;
    ForwardedComponent.WrappedComponent = AnalyticsWrapper.WrappedComponent;

    return ForwardedComponent;
}
