import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import i18n from '../../../i18n';
import Utils from '../../../utils/utils';

import Home from '../../../models/Home';

import CompletionIndicator from '../../common/CompletionIndicator';
import EditPanel from '../../common/EditPanel';
import UploadList from '../../upload/UploadList';

export default class HomeEditPanelPictures extends EditPanel {
    static propTypes = {
        home: PropTypes.instanceOf(Home).isRequired,
        onChange: PropTypes.func.isRequired,
        handleSave: PropTypes.func.isRequired,
        fieldsets: PropTypes.array.isRequired
    };
    static priority = 6;
    static isRequired = false;
    static checkValidity(props) {
        return {
            pictures: Boolean(Array.isArray(props.home.get('images')) && props.home.get('images').length > 0)
        };
    }
    static title = i18n.t('home:pictures');

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(images) {
        this.props.onChange({
            target: {
                name: 'images',
                value: images
            }
        });
    }

    render() {
        const { home, handleSave, onChange } = this.props;

        return (
            <fieldset className="panel panel-default" id={Utils.slugify(this.constructor.title)}>
                <div className="panel-heading">{i18n.t('home:pictures')}</div>
                <div className="panel-body">
                    <div
                        className={classNames('form-group', {
                            'has-error': this.constructor.hasErrors(this.props)
                        })}
                    >
                        <div className="label-row">
                            <label htmlFor="pictures">{i18n.t('home:your_home_pictures')}</label>
                            <CompletionIndicator
                                object={home}
                                fieldNames={['photo1', 'photo2', 'photo3', 'photo4', 'photo5']}
                                page="home"
                            />
                        </div>
                        <p className="legend">
                            {i18n.t('upload:uploadForm_addNbPhotos_plural', {
                                count: HomeEditPanelPictures.MIN_IMAGES
                            })}
                        </p>
                        <UploadList
                            home={home}
                            images={home.get('images')}
                            onChange={this.onChange}
                            minImages={HomeEditPanelPictures.MIN_IMAGES}
                            handleSave={handleSave}
                            handleTurnOnline={onChange}
                            enableTurnOnlinePopup={true}
                            source="home-edit"
                        />
                    </div>
                </div>
            </fieldset>
        );
    }
}
