export var getCheckboxStatus = function (filterValues, current) {
    if (!filterValues) {
        return false;
    }
    switch (filterValues.length) {
        case 0:
            return false;
        case 1:
            return filterValues[0] === current ? true : false;
        case 2:
            return true;
        default:
            return false;
    }
};
export var generateReverseRange = function (start, end) {
    return Array.from({ length: start - end + 1 }, function (_, i) { return start - i; });
};
export var COORDINATE_PATTERN = new RegExp([
    // Decimal Degrees (DD)
    '([-+]?\\d{1,3}\\.\\d+)[ ,]+([-+]?\\d{1,3}\\.\\d+)',
    // Degrees, Minutes, Seconds (DMS)
    '|(\\d{1,3})°\\s*(\\d{1,2})\'\\s*(\\d{1,2}(?:\\.\\d+)?)"?\\s*([NS])',
    '\\s*(\\d{1,3})°\\s*(\\d{1,2})\'\\s*(\\d{1,2}(?:\\.\\d+)?)"?\\s*([EW])',
    // Degrees and Decimal Minutes (DMM)
    "|(\\d{1,3})°?\\s*(\\d{1,2}(?:\\.\\d+)?)'?\\s*([NS]?)",
    "\\s*(\\d{1,3})°?\\s*(\\d{1,2}(?:\\.\\d+)?)'?\\s*([EW]?)"
].join(''));
export var parseCoordinates = function (coordinateString) {
    var match = COORDINATE_PATTERN.exec(coordinateString);
    if (!match) {
        return null;
    }
    var latitude, longitude;
    // For Decimal Degrees (DD)
    if (match[1]) {
        latitude = parseFloat(match[1]);
        longitude = parseFloat(match[2]);
    }
    // For Degrees, Minutes, Seconds (DMS)
    else if (match[3]) {
        var latDegrees = parseFloat(match[3]);
        var latMinutes = parseFloat(match[4]) / 60;
        var latSeconds = match[5] ? parseFloat(match[5]) / 3600 : 0;
        latitude = latDegrees + latMinutes + latSeconds;
        if (match[6] === 'S')
            latitude = -latitude;
        var lonDegrees = parseFloat(match[7]);
        var lonMinutes = parseFloat(match[8]) / 60;
        var lonSeconds = match[9] ? parseFloat(match[9]) / 3600 : 0;
        longitude = lonDegrees + lonMinutes + lonSeconds;
        if (match[10] === 'W')
            longitude = -longitude;
    }
    // For Degrees and Decimal Minutes (DMM)
    else if (match[11]) {
        latitude = parseFloat(match[11]) + parseFloat(match[12]) / 60;
        if (match[13] === 'S')
            latitude = -latitude;
        longitude = parseFloat(match[14]) + parseFloat(match[15]) / 60;
        if (match[16] === 'W')
            longitude = -longitude;
    }
    return { latitude: latitude, longitude: longitude };
};
