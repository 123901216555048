import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import i18n from '../../i18n';

import { fetchHomes } from '../../actions/homes';

import Home from '../../models/Home';
import Transfert from '../../models/Transfert';

import UploadList from './UploadList';

export class UploadListWithCaption extends React.Component {
    static propTypes = {
        home: PropTypes.instanceOf(Home),
        images: PropTypes.arrayOf(PropTypes.object),
        minImages: PropTypes.number,
        explanations: PropTypes.bool,
        onChange: PropTypes.func,
        dispatch: PropTypes.func.isRequired
    };

    static defaultProps = {
        images: [],
        minImages: 5
    };

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(images) {
        if (this.props.onChange) {
            this.props.onChange(images);
        }
        this.props.dispatch(fetchHomes());
    }

    render() {
        let remaining = 0;
        if (this.props.images.length) {
            remaining = this.props.minImages - this.props.images.length;
        }

        return (
            <section className="upload upload-list">
                <p className="text-center">
                    {remaining > 0 ? (
                        <strong>
                            {i18n.t('upload:uploadForm_addNbPhotos', {
                                count: remaining
                            })}
                        </strong>
                    ) : (
                        <strong>{i18n.t('upload:uploadForm_addPhotos')}</strong>
                    )}
                </p>

                {this.props.explanations && (
                    <p className="text-center">
                        {i18n.t('upload:uploadForm_GP2', {
                            gp: Transfert.MOTIVES_VALUE[Transfert.MOTIVE_FILLING_WRITE_HOME]
                        })}
                    </p>
                )}
                <UploadList {...this.props} onChange={this.handleChange} />
            </section>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let { home } = ownProps;
    const { homeId } = ownProps;
    if (!home && homeId) {
        home = state.homes.find((h) => h.id == homeId);
    }
    return {
        home,
        images: home ? home.get('images') : []
    };
};

export default connect(mapStateToProps)(UploadListWithCaption);
