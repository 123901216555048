import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, HelpBlock } from 'react-bootstrap';

import i18n from '../../i18n';

export default class TextField extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        helpBlock: PropTypes.string,
        cols: PropTypes.number,
        rows: PropTypes.number,
        required: PropTypes.bool,
        min: PropTypes.number,
        max: PropTypes.number,
        withCounter: PropTypes.bool,
        onChange: PropTypes.func.isRequired
    };

    hasError() {
        const { value } = this.props;
        const length = value ? String(value).length : 0;
        const notEmpty = value && length > 0;
        const required = this.props.required ? notEmpty : true;
        const min = this.props.min ? length >= this.props.min : true;
        const max = this.props.max ? length <= this.props.max : true;
        if (!required) {
            return true;
        }
        if (notEmpty && (!min || !max)) {
            return true;
        }
        return false;
    }

    getCounterText() {
        const { value, min, max } = this.props;
        let counter = i18n.t('common:characters_count', {
            count: String(value).length
        });
        if (min >= 0) {
            const left = Math.max(0, min - String(value).length);
            if (left > 0) {
                counter = i18n.t('common:characters_missing_count', {
                    count: left
                });
            }
        } else if (max > 0) {
            const left = max - String(value).length;
            if (left >= 0) {
                counter = i18n.t('common:characters_left_count', {
                    count: left
                });
            } else {
                counter = i18n.t('common:characters_exceed_count', {
                    count: Math.abs(left)
                });
            }
        }
        return counter;
    }

    render() {
        const { value, withCounter, helpBlock } = this.props;
        const hasError = this.hasError();
        return (
            // TODO: replace with React.Fragment when React 16
            <div className={hasError ? 'has-error' : null} style={{ width: '100%' }}>
                <FormControl
                    componentClass="textarea"
                    cols={this.props.cols}
                    rows={this.props.rows}
                    id={this.props.id}
                    name={this.props.name}
                    value={value}
                    required={this.props.required}
                    min={this.props.min}
                    max={this.props.max}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                />
                {withCounter && value && (
                    <span className="help-block counter" style={{ float: 'right' }}>
                        {this.getCounterText()}
                    </span>
                )}
                {helpBlock && hasError && <HelpBlock bsClass="help-block invalid">{helpBlock}</HelpBlock>}
            </div>
        );
    }
}
