import configureStore from './store/configureStore';

// get token from Legacy
let accessToken;
let refreshToken;
let expiresAt;
if (window.gtg) {
    ({ accessToken, refreshToken, expiresAt } = window.gtg);
}

const store = configureStore({
    auth: {
        accessToken,
        refreshToken,
        expiresAt
    }
});

export default store;
