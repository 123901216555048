import Common from './Common';

export default class Utils {
    static contact(data) {
        const params = {
            url: '/v1/contact',
            method: 'post',
            data
        };
        return Common.request(params);
    }
}
