import Common from './Common';

export default class Favorites {
    static me(filters, offset, limit, orderBy) {
        const params = Common.params(
            {
                url: '/v2/favorites/me',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static getFavorites() {
        return Common.request({
            url: '/v2/favorites/me-id-only',
            method: 'get'
        });
    }

    static hasFavorite(homeId, homeFavorites) {
        return _.contains(homeFavorites, homeId);
    }

    static addFavorite(homeId) {
        return Common.request({
            url: '/v1/favorites',
            method: 'post',
            data: {
                home: homeId,
                status: 1
            }
        }).done(() => {
            // trigger event
            $(document).trigger('USER_FAVORITES_CHANGE');
        });
    }

    static removeFavorite(homeId) {
        return Common.request({
            url: `/v1/favorites/${homeId}`,
            method: 'delete'
        }).done(() => {
            // trigger event
            $(document).trigger('USER_FAVORITES_CHANGE', homeId);
        });
    }
}
