import Api from '../api/Api';

export const SET_REWARDS = 'SET_REWARDS';

export function setRewards(rewards) {
    return {
        type: SET_REWARDS,
        payload: rewards
    };
}

export function fetchRewards() {
    return (dispatch) =>
        Api.User.getTransfertDetails().then((rewards) => {
            dispatch(setRewards(rewards));
            return rewards;
        });
}
