import history from '../history';
import i18n from '../i18n';
import User from '../api/User';
import UserTour from '../api/UserTour';

export default class Tour {
    static shownElement = null;
    static shownAdditionalElements = [];
    static bodyClass = null;
    static currentStep = null;
    static currentTourId = null;
    static lastVisitedStep = null;
    static ignoreBeforeCallback = false;
    static preInit = false;
    static pendingTours = [];

    static defaultTour = {
        bubblePadding: 20,
        arrowWidth: 20,
        onStart: Tour.onStart,
        onShow: Tour.onShow,
        onNext: Tour.onStepChange,
        onPrev: Tour.onStepChange,
        onClose: Tour.onClose,
        onEnd: Tour.onEnd,
        i18n: {
            closeTooltip: '×',
            nextBtn: i18n.t('common:next'),
            prevBtn: i18n.t('common:previous'),
            doneBtn: i18n.t('common:done'),
            skipBtn: i18n.t('common:skip')
        }
    };

    // Cache system
    static loadingIsViewed = false;
    static viewedTours = null;
    static eventsThrown = [];

    /**
     * Define all the tours here
     */
    static searchTour = Object.assign({}, Tour.defaultTour, {
        id: 'search_tour',
        mediaQuery: '(min-width: 992px)',
        steps: [
            {
                title: i18n.t('search:tour_step1_title'),
                content: i18n.t('search:tour_step1_content'),
                target: '.date_range .custom_date_range_elements',
                additionalOverlays: ['.navbar-fixed-top'],
                targetClickable: true,
                placement: 'bottom'
            },
            // this step is the collectionFilterTour for those who didn't pass the search tour yet
            {
                title: i18n.t('tour:collection-filter.title'),
                content: i18n.t('tour:collection-filter.text'),
                target: '.collection-filter',
                targetClickable: true,
                placement: 'bottom',
                nextOnTargetClick: true,
                // set the collectionFilterTour as viewed
                afterCallback: () => {
                    UserTour.setTourAsViewed(Tour.collectionFilterTour.id).then(() => {
                        Tour.viewedTours.push({ tour: Tour.collectionFilterTour.id });
                    });
                }
            },
            // this step is the exchangeTypeFilterTour for those who didn't pass the search tour yet
            {
                title: i18n.t('search:exchange_type_filter_tour_title'),
                content: i18n.t('search:exchange_type_filter_tour_content'),
                target: '.exchange-type-filter-tour',
                targetClickable: true,
                placement: 'bottom',
                nextOnTargetClick: true,
                // set the exchangeTypeFilterTour as viewed
                afterCallback: () => {
                    UserTour.setTourAsViewed(Tour.exchangeTypeFilterTour.id).then(() => {
                        Tour.viewedTours.push({ tour: Tour.exchangeTypeFilterTour.id });
                    });
                }
            },
            {
                title: i18n.t('search:tour_step2_title'),
                content: i18n.t('search:tour_step2_content'),
                target: '.more-filters',
                targetClickable: true,
                placement: 'bottom'
            }
        ]
    });

    static searchTourMobile = Object.assign({}, Tour.defaultTour, {
        id: 'search_tour',
        mediaQuery: '(max-width: 991px)',
        steps: [
            {
                title: i18n.t('search:tour_step1_title'),
                content: i18n.t('search:tour_step1_content'),
                target: '.search-filter-row .custom_date_range_elements',
                targetClickable: true,
                placement: 'bottom'
            },
            {
                title: i18n.t('search:tour_step2_title'),
                content: i18n.t('search:tour_step2_content'),
                target: '.more-filters',
                targetClickable: true,
                placement: 'bottom',
                xOffset: 'left',
                arrowOffset: 270
            }
        ]
    });

    static messagingTour = Object.assign({}, Tour.defaultTour, {
        id: 'messaging_tour',
        smoothScroll: false,
        mediaQuery: '(min-width: 1024px)',
        steps: [
            {
                title: i18n.t('messaging:messaging_tour_step1_title'),
                content: i18n.t('messaging:messaging_tour_step1_content'),
                target: '.conversations-container',
                targetClickable: false,
                placement: 'right'
            },
            {
                title: i18n.t('messaging:messaging_tour_step2_title'),
                content: i18n.t('messaging:messaging_tour_step2_content'),
                target: '.exchange-conversation',
                targetClickable: false,
                placement: 'right',
                additionalOverlays: ['.exchange-container']
            },
            {
                title: i18n.t('messaging:messaging_tour_step4_title'),
                content: i18n.t('messaging:messaging_tour_step4_content'),
                target: '.tab-pane.active .exchange-resume article.home',
                targetClickable: false,
                placement: 'left',
                additionalTargets: ['.tab-pane.active .dates-travelers'],
                additionalOverlays: ['.exchange-container']
            },
            {
                title: i18n.t('messaging:messaging_tour_step3_title'),
                content: i18n.t('messaging:messaging_tour_step3_content'),
                target: '.exchange-body .exchange-type',
                targetClickable: false,
                placement: 'left',
                additionalOverlays: ['.exchange-container'],
                beforeCallback: (cb) => {
                    if (!$('.exchange-type-selection').is(':visible')) {
                        $('.exchange-body .modify-exchange > div').click();
                        // scroll to target position
                        $('.exchange-details .tab-content').scrollTop($('.exchange-type').offset().top);
                        cb();
                    }
                },
                afterCallback: () => {
                    if ($('.exchange-type-selection').is(':visible')) {
                        const currentStep = window.hopscotch.getCurrStepNum();
                        $('.exchange-body .modify-exchange > div').click();
                        $('.exchange-details .tab-content').scrollTop(0);
                        // Hide pop-up while closing the drawer and going to a step that is impacted by the drawer status
                        if (currentStep === 2 || currentStep === 3) {
                            window.hopscotch.refreshBubblePosition();
                        }
                    }
                }
            },
            {
                title: i18n.t('messaging:messaging_tour_step5_title'),
                content: i18n.t('messaging:messaging_tour_step5_content'),
                target: '.tab-pane.active .exchange-resume .guestpoint_amount',
                targetClickable: false,
                placement: 'left',
                additionalTargets: [
                    '.tab-pane.active .exchange-resume .home_deposit',
                    '.tab-pane.active .exchange-resume .insurance-required',
                    '.exchange-total'
                ],
                additionalOverlays: ['.exchange-container'],
                beforeCallback: (cb) => {
                    const position = $('.guestpoint_amount').position().top;
                    $('.exchange-list .tab-content').scrollTop(position);
                    setTimeout(() => {
                        cb();
                    }, 1);
                }
            },
            {
                title: i18n.t('messaging:messaging_tour_step6_title'),
                content: i18n.t('messaging:messaging_tour_step6_content'),
                targetClickable: false,
                target: '.exchange-details .exchange-actions',
                placement: 'top',
                xOffset: 'center',
                arrowOffset: 220,
                additionalOverlays: ['.exchange-container']
            }
        ],
        fakeMethods: [
            'Conversation.me',
            'Conversation.meGraphQL',
            'Conversation.get',
            'Conversation.getMessages',
            'Home.get'
        ],
        preinitEvents: ['conversation_list_mount', 'exchange_conversation_mount', 'home_switcher_ready'],
        preinitScript: () => {
            // Redirect only if we have messages
            document.addEventListener('messaging_tour_has_conversations', () => {
                // If we are on the conversation list, display the fake message
                if (window.location.href.match(/conversations(?!\/)/)) {
                    history.push(`/conversations/1`);
                }
            });

            // If the user has no conversation, we don't run the tour
            document.addEventListener('messaging_tour_no_conversations', () => {
                $('body').removeClass('tour-messaging_tour-preinit');
            });

            // Wait until all the elements are loaded to display the Tour
            document.addEventListener('preinit-events-completed', (data) => {
                if (data.detail.tour === 'messaging_tour') {
                    Tour.init('messaging_tour', null, true);
                }
            });
        },
        onEndHandler: () => {
            document.dispatchEvent(new CustomEvent('reload_conversations'));
            history.replace(`/conversations`);
        },
        onCloseHandler: () => {
            document.dispatchEvent(new CustomEvent('reload_conversations'));
            history.replace(`/conversations`);
        }
    });

    static homeVerifiedOnlyTour = Object.assign({}, Tour.defaultTour, {
        id: 'home_verified_only_tour',
        smoothScroll: true,
        steps: [
            {
                title: i18n.t('home:contact_option'),
                content: i18n.t('home:tour_verified_only_text'),
                target: '.verified-only',
                targetClickable: true,
                placement: 'bottom'
            }
        ]
    });

    static reverseSearchTour = Object.assign({}, Tour.defaultTour, {
        id: 'reverse-search-tour',
        mediaQuery: '(min-width: 600px)',
        alwaysShowCloseButton: true,
        i18n: {
            closeTooltip: "<i class='fa fa-times-circle'></i>",
            doneBtn: `${i18n.t('common:learn_more')} <i class="fa fa-chevron-right"></i>`
        },
        steps: [
            {
                title: i18n.t('search:reverse_search_tour_title'),
                content: i18n.t('search:reverse_search_tour_content'),
                target: '.reverse-search',
                placement: 'bottom',
                width: 450
            }
        ],
        onEndHandler: () => {
            window.open(i18n.t('search:reverse_search_tour_redirect_url'));
        }
    });

    static exchangeTypeFilterTour = Object.assign({}, Tour.defaultTour, {
        id: 'exchange-type-filter-tour',
        mediaQuery: '(min-width: 600px)',
        alwaysShowCloseButton: true,
        i18n: {
            closeTooltip: '×'
        },
        steps: [
            {
                title: i18n.t('search:exchange_type_filter_tour_title'),
                content: i18n.t('search:exchange_type_filter_tour_content'),
                target: '.exchange-type-filter-tour',
                placement: 'bottom',
                targetClickable: true,
                showNextButton: false,
                nextOnTargetClick: true
            }
        ]
    });

    static collectionFilterTour = Object.assign({}, Tour.defaultTour, {
        id: 'collection-filter-tour',
        mediaQuery: '(min-width: 600px)',
        alwaysShowCloseButton: true,
        i18n: {
            closeTooltip: '×'
        },
        steps: [
            {
                title: i18n.t('tour:collection-filter.title'),
                content: i18n.t('tour:collection-filter.text'),
                target: '.collection-filter',
                placement: 'bottom',
                targetClickable: true,
                showNextButton: false,
                nextOnTargetClick: true
            }
        ]
    });

    static collectionGPTour = Object.assign({}, Tour.defaultTour, {
        id: 'collection-gp-tour',
        mediaQuery: '(min-width: 600px)',
        alwaysShowCloseButton: true,
        i18n: {
            closeTooltip: '×'
        },
        steps: [
            {
                title: i18n.t('tour:collection-GP.title'),
                content: i18n.t('tour:collection-GP.text'),
                target: '#gp',
                placement: 'bottom',
                xOffset: -100,
                arrowOffset: 140,
                targetClickable: true,
                showNextButton: false,
                nextOnTargetClick: true,
                beforeCallback: (cb) => {
                    const headNavbar = $('#head-nav-band');
                    const navbar = headNavbar.find('.navbar');

                    if (headNavbar.hasClass('navbar-fixed-top')) {
                        headNavbar.css('z-index', 10000);
                    } else {
                        navbar.css('z-index', 10000);
                    }
                    cb();
                },
                afterCallback: () => {
                    $('#head-nav-band').css('z-index', 1030);
                }
            }
        ]
    });

    static calendarNewTypesTour = Object.assign({}, Tour.defaultTour, {
        id: 'calendar-new-types',
        alwaysShowCloseButton: true,
        i18n: {
            doneBtn: `${i18n.t('common:learn_more')} <i class="fa fa-chevron-right"></i>`,
            closeTooltip: '×'
        },
        steps: [
            {
                title: i18n.t('tour:calendar-new-types.step1.title'),
                content: i18n.t('tour:calendar-new-types.step1.content'),
                target: '.home-calendar .calendar-switch-caption-container',
                targetClickable: true,
                placement: 'top',
                afterCallback: (args) => {
                    if (args.onEnd) {
                        // Trigger the redirection only when clicking on the button
                        window.open(i18n.t('url:faq-update-calendar'));
                    }
                }
            }
        ]
    });

    static updateSearchCtaTour = {
        ...Tour.defaultTour,
        id: 'search-in-this-region-cta',
        mediaQuery: '(min-width: 600px)',
        steps: [
            {
                content: i18n.t('search:tour.search-in-this-region.content'),
                target: '.search-in-this-region',
                placement: 'bottom',
                targetClickable: true,
                nextOnTargetClick: true,
                showNextButton: false
            }
        ]
    };

    /**
     * List the existing tours
     */
    static tours = [
        Tour.searchTour,
        Tour.searchTourMobile,
        Tour.messagingTour,
        Tour.homeVerifiedOnlyTour,
        Tour.reverseSearchTour,
        Tour.calendarNewTypesTour,
        Tour.exchangeTypeFilterTour,
        Tour.updateSearchCtaTour,
        Tour.collectionFilterTour,
        Tour.collectionGPTour
    ];

    static escapeListener() {
        $(document).on('keydown', (e) => {
            if (e.keyCode && e.keyCode === 27) {
                Tour.hideForever();
            }
        });
    }

    /**
     * Callback when the tour starts
     */
    static onStart() {
        Tour.currentStep = window.hopscotch.getCurrStepNum();
        Tour.showOverlay();
    }

    /**
     * Callback when the tour pop-up shows
     */
    static onShow() {
        if (Tour.hasBeforeCallback() && !Tour.ignoreBeforeCallback) {
            $('.hopscotch-bubble').hide();
            Tour.executeBeforeCallback();
            return;
        }
        Tour.updateLastVisitedStep();
        Tour.createDots();
        Tour.updateCrossVisibility();
        Tour.ignoreBeforeCallback = false;
    }

    /**
     * Callback when the step is changed
     */
    static onStepChange() {
        Tour.executeAfterCallback();
        Tour.currentStep = window.hopscotch.getCurrStepNum();
        Tour.updateOverlay();
    }

    /**
     * Callback when the tour is closed (not completed)
     */
    static onClose() {
        const currentTour = window.hopscotch.getCurrTour();

        if (currentTour && currentTour.id) {
            currentTour.done = true;
            currentTour.closed = true;

            Tour.executeAfterCallback({
                onClose: true
            });

            if (currentTour.onCloseHandler) {
                currentTour.onCloseHandler();
            }

            Tour.clearOverlay();
            Tour.showNextTour();
        }
    }

    /**
     * Callback when the previous step for the current tour is called
     */
    static onEnd() {
        const currentTour = window.hopscotch.getCurrTour();

        if (currentTour && currentTour.id) {
            currentTour.done = true;

            if (currentTour.reloadOnEnd) {
                window.location.replace(currentTour.reloadUrl);
            }

            if (!currentTour.closed) {
                Tour.executeAfterCallback({
                    onEnd: true
                });
            }

            if (currentTour.onEndHandler) {
                currentTour.onEndHandler();
            }

            if (!currentTour.closed) {
                Tour.clearOverlay();
                Tour.showNextTour();
            }
        }
    }

    static showNextTour() {
        let hasAnotherTour = false;
        Tour.currentTourId = null;

        if (Tour.pendingTours.length > 0) {
            // There is at least one pending tour, start it
            if (window.hopscotch.getCurrTour()) {
                window.hopscotch.endTour(true, false);
            }
            const nextTour = Tour.pendingTours[0];
            if (nextTour.action === 'preinit') {
                hasAnotherTour = true;
                setTimeout(() => {
                    // Hopscotch removes the bubble after calling the callback
                    // Keep this setTimeout to make sure the hopscotch bubbles are displayed
                    Tour.preinit(nextTour.id);
                }, 1);
            } else if (nextTour.action === 'init') {
                hasAnotherTour = true;
                setTimeout(() => {
                    Tour.init(nextTour.id, nextTour.step);
                }, 1);
            }

            Tour.pendingTours.splice(0, 1);
        }

        if (!hasAnotherTour) {
            Tour.hideOverlay();
        } else {
            Tour.hideShownElement();
        }
    }

    /**
     * Pre initialize a tour (allow for method faking but won't show the bubbles)
     */
    static preinit(id) {
        if (Tour.currentTourId && Tour.currentTourId !== id) {
            // A tour is already started, stash it to run after
            Tour.pendingTours.push({
                id,
                action: 'preinit'
            });

            return;
        } else if (Tour.currentTourId && Tour.currentTourId === id) {
            // We are already running this tour
            return;
        }

        const tourOptions = Tour.find(id);

        if (!tourOptions) {
            return;
        }

        Tour.currentTourId = id;
        // Check some events in case they are needed
        if (tourOptions.preinitEvents) {
            tourOptions.preinitEvents.forEach((event) => {
                $(document).on(event, () => {
                    if (Tour.eventsThrown.indexOf(event) < 0) {
                        Tour.eventsThrown.push(event);
                        if (Tour.eventsThrown.length === tourOptions.preinitEvents.length) {
                            document.dispatchEvent(
                                new CustomEvent('preinit-events-completed', {
                                    detail: {
                                        tour: id
                                    }
                                })
                            );
                        }
                    }
                });
            });
        }

        // Check database to see if the user has already completed the tour
        Tour.isViewed(id).done((isViewed) => {
            if (!isViewed) {
                Tour.preInit = true;

                // If a preinitScript exists, run it and let it execute startTour on its own
                if (tourOptions && tourOptions.preinitScript) {
                    $('body').addClass(`tour-${id}-preinit`);
                    tourOptions.preinitScript();
                }
            } else {
                Tour.showNextTour();
            }
        });
    }

    /**
     * Initialize a tour
     * @param  {string} id     The id of the tour to start
     * @param  {int} [stepNum] The step to start with
     */
    static init(id, stepNum, force = false) {
        if (Tour.currentTourId && Tour.currentTourId !== id) {
            // A tour is already started, stash it to run after
            Tour.pendingTours.push({
                id,
                stepNum,
                action: 'init'
            });

            return;
        } else if (!force && Tour.currentTourId && Tour.currentTourId === id) {
            // We are already running this tour
            return;
        }

        // Find corresponding configuration
        const tourOptions = Tour.find(id);

        if (!tourOptions) {
            return;
        }

        Tour.currentTourId = id;
        Tour.preInit = false;
        $('body').removeClass(`tour-${id}-preinit`);

        // Check database to see if the user has already completed the tour
        Tour.isViewed(id).done((isViewed) => {
            if (!isViewed) {
                // Start the tour
                Tour.lastVisitedStep = stepNum ? stepNum : 0;
                window.hopscotch.startTour(tourOptions, stepNum);

                // Set tour as viewed immediately to avoid showing it too much
                if (tourOptions.saveId) {
                    UserTour.setTourAsViewed(tourOptions.saveId);
                } else {
                    UserTour.setTourAsViewed(id);
                }

                // Add current tour to viewed tours
                Tour.viewedTours = Tour.viewedTours ? Tour.viewedTours : [];
                Tour.viewedTours.push({ tour: id });
            } else {
                Tour.showNextTour();
            }
        });

        Tour.escapeListener();
    }

    /**
     * Find tour options based on its id
     * @param  {string} id The id of the tour
     */
    static find(id) {
        const tourOptions = Tour.tours.filter((tour) => {
            let match = true;

            if (tour.id !== id) {
                // Tour id doesn't match
                match = false;
            }
            if (tour.mediaQuery && !window.matchMedia(tour.mediaQuery).matches) {
                // Restriction didn't match
                return false;
            }

            return match;
        });

        if (tourOptions.length === 0) {
            return null;
        }

        return tourOptions[0];
    }

    /**
     * Hide the bubble of a tour
     */
    static hide() {
        $('.hopscotch-bubble').hide();
    }

    /**
     * Show the bubble of a tour
     */
    static show() {
        $('.hopscotch-bubble').show();
    }

    /**
     * Hide the bubble of a tour only if we are at the given step
     * @param  {int} [stepNum] The step at which we can hide the tour
     */
    static hideIfStep(stepNum) {
        if (stepNum === window.hopscotch.getCurrStepNum()) {
            Tour.hide();
        }
    }

    /**
     * Permanently close the tour (won't be displayed on refresh)
     */
    static hideForever() {
        window.hopscotch.endTour(true);
    }

    /**
     * Permanently close the tour only if we are at a given step
     * @param  {int} stepNum The step at which we can hide the tour forever
     */
    static hideForeverIfStep(stepNum) {
        if (stepNum === window.hopscotch.getCurrStepNum()) {
            Tour.hideForever();
        }
    }

    /**
     * Open the tour's next step
     * @param  {string} id Tour id
     */
    static nextStep(id) {
        // Check database to see if the user has already completed the tour
        Tour.isViewed(id).done((isViewed) => {
            if (!isViewed) {
                $('.hopscotch-bubble').show();
                window.hopscotch.nextStep();
            }
        });
    }

    /**
     * Go to the next step only if we are at a given step
     * @param  {string} id   Tour id
     * @param  {int} stepNum Step number at which we can go to the next step
     */
    static nextStepIfAtStep(id, stepNum) {
        // Check database to see if the user has already completed the tour
        Tour.isViewed(id).done((isViewed) => {
            if (!isViewed && stepNum === window.hopscotch.getCurrStepNum()) {
                $('.hopscotch-bubble').show();
                window.hopscotch.nextStep();
            }
        });
    }

    /**
     * Returns true if the tour shouldn't be displayed (it has already been viewed)
     * @param  {string} id Tour id
     * @return {Boolean}   Has the tour been viewed
     */
    static isViewed(id) {
        // Checks in the database
        const deferred = $.Deferred();

        const tourOptions = Tour.find(id);

        if (tourOptions && tourOptions.saveId) {
            id = tourOptions.saveId;
        }

        if (Tour.viewedTours) {
            // Cache system: we already have the result
            if (Tour.viewedTours.length > 0 && Tour.viewedTours.find((tour) => tour.tour == id)) {
                deferred.resolve(true);
            } else {
                deferred.resolve(false);
            }
        } else if (Tour.loadingIsViewed) {
            // Cache system: we are waiting for the request to end
            document.addEventListener('tour_is_viewed_done', (data) => {
                deferred.resolve(data.detail.viewedTours.find((tour) => tour.tour == id));
            });
        } else {
            // Check the database
            Tour.loadingIsViewed = true;
            User.me(true).done((user) => {
                Tour.loadingIsViewed = false;

                Tour.viewedTours = user.tours;
                if (user.tours && user.tours.find((tour) => tour.tour == id)) {
                    // The tour has already been displayed
                    deferred.resolve(true);
                } else {
                    deferred.resolve(false);
                }
                document.dispatchEvent(
                    new CustomEvent('tour_is_viewed_done', {
                        detail: {
                            viewedTours: user.tours
                        }
                    })
                );
            });
        }

        return deferred;
    }

    /**
     * Display an overlay above the whole page to emphasize the tour
     */
    static showOverlay() {
        if ($('.tour-overlay').length === 0) {
            $('body').append('<div class="tour-overlay"></div>');
        }

        Tour.updateOverlay();
    }

    /**
     * Set the z-index and the position of the item to put it in the foreground
     * @param  {JQuery} $elem element in which to prepend the overlay
     */
    static positionElementBefore($elem) {
        const currentStep = window.hopscotch.getCurrStepNum() || 0;
        const currentTour = window.hopscotch.getCurrTour();
        let position = Tour.shownElement.css('position');
        if (position === 'static') {
            position = 'relative';
        }
        $elem.css({
            'z-index': 10000,
            position,
            'pointer-events': 'none'
        });

        if (!currentTour.steps[currentStep].targetClickable) {
            Tour.shownElement.css('pointer-events', 'none');
        }

        Tour.shownAdditionalElements.push($elem);
    }

    /**
     * Create a tour-overlay to hide the content of a div and position a given selector on top of it
     * @param  {JQuery} $elem element in which to prepend the overlay
     */
    static createOverlay($elem) {
        Tour.positionElementBefore($elem);
        $elem.prepend('<div class="tour-overlay"></div>');
    }

    static clearOverlay() {
        if (Tour.shownElement) {
            Tour.shownElement.css({
                'z-index': '',
                position: '',
                'pointer-events': ''
            });
        }

        if (Tour.shownAdditionalElements.length > 0) {
            for (let i = 0, l = Tour.shownAdditionalElements.length; i < l; i++) {
                Tour.shownAdditionalElements[i].css({
                    'z-index': '',
                    position: '',
                    'pointer-events': ''
                });
                Tour.shownAdditionalElements[i].find('.tour-overlay').remove();
            }
        }
    }

    /**
     * Emphasize the overlay for the current step
     */
    static updateOverlay() {
        const currentStep = window.hopscotch.getCurrStepNum() || 0;
        const currentTour = window.hopscotch.getCurrTour();
        const $body = $('body');
        const bodyClass = `tour-${currentTour.id}-step${currentStep}`;

        Tour.clearOverlay();

        if (currentTour.steps[currentStep] && currentTour.steps[currentStep].target) {
            // Add "normal" overlay
            Tour.shownElement = $(currentTour.steps[currentStep].target);
            let position = Tour.shownElement.css('position');

            if (position === 'static') {
                position = 'relative';
            }
            Tour.shownElement.css({
                'z-index': 10000,
                position
            });

            if (!currentTour.steps[currentStep].targetClickable) {
                Tour.shownElement.css('pointer-events', 'none');
            }

            // Add additional target
            if (
                currentTour.steps[currentStep].additionalTargets &&
                currentTour.steps[currentStep].additionalTargets.length > 0
            ) {
                for (let i = 0, l = currentTour.steps[currentStep].additionalTargets.length; i < l; i++) {
                    Tour.positionElementBefore($(currentTour.steps[currentStep].additionalTargets[i]));
                }
            }

            // Add additional overlays
            if (
                currentTour.steps[currentStep].additionalOverlays &&
                currentTour.steps[currentStep].additionalOverlays.length > 0
            ) {
                for (let i = 0, l = currentTour.steps[currentStep].additionalOverlays.length; i < l; i++) {
                    Tour.createOverlay($(currentTour.steps[currentStep].additionalOverlays[i]));
                }
            }
        }

        // Update body class
        if (Tour.bodyClass) {
            $body.removeClass(Tour.bodyClass);
        }
        $body.addClass(bodyClass);
        Tour.bodyClass = bodyClass;
    }

    /**
     * Remove the overlay
     */
    static hideOverlay() {
        $('.tour-overlay').remove();
        $('body').removeClass(Tour.bodyClass);
        Tour.hideShownElement();
    }

    static hideShownElement() {
        if (Tour.shownElement) {
            Tour.shownElement.css({
                'z-index': '',
                position: '',
                'pointer-events': ''
            });
        }
    }

    /**
     * Create a list of dots to indicate the current step
     */
    static createDots() {
        const currentTour = window.hopscotch.getCurrTour();

        if (!currentTour) {
            return;
        }

        if (currentTour.steps.length < 2) {
            return;
        }

        const $popup = $(`.tour-${currentTour.id}`).first();

        if ($popup.find('.hopscotch-dots').length > 0) {
            Tour.updateDots();
            return;
        }

        const $dots = $('<div class="hopscotch-dots"></div>');

        for (let i = 0, l = currentTour.steps.length; i < l; i++) {
            $dots.append('<span class="hopscotch-dot"></span>');
        }

        $popup.find('.hopscotch-actions').prepend($dots);

        // Set current active dot
        Tour.updateDots();
    }

    /**
     * Update the active dot based on the current step
     */
    static updateDots() {
        const currentTour = window.hopscotch.getCurrTour();
        const currentStep = window.hopscotch.getCurrStepNum() || 0;
        const $popup = $(`.tour-${currentTour.id}`).first();
        const $dots = $popup.find('.hopscotch-dot');
        const $currentDot = $dots.eq(currentStep);
        $dots.removeClass('active');
        $currentDot.addClass('active');

        Tour.addDotsEventListener();
    }

    /**
     * Update the active dot based on the current step
     */
    static addDotsEventListener() {
        const currentTour = window.hopscotch.getCurrTour();
        const $popup = $(`.tour-${currentTour.id}`).first();
        const $dots = $popup.find('.hopscotch-dot');

        $dots.off('click');

        for (let i = 0; i <= Tour.lastVisitedStep; i++) {
            $dots.eq(i).on('click', (e) => {
                e.stopPropagation();
                Tour.goToStep(i);
            });
            $dots.eq(i).addClass('visited');
        }
    }

    /**
     * Hide the close cross on last step
     */
    static updateCrossVisibility() {
        const currentStep = window.hopscotch.getCurrStepNum() || 0;
        const currentTour = window.hopscotch.getCurrTour();
        const $popup = $(`.tour-${currentTour.id}`).first();
        const $closeButton = $popup.find('.hopscotch-close');

        // Do not display close button on last step
        if (!currentTour.alwaysShowCloseButton && currentTour.steps.length === currentStep + 1) {
            $closeButton.hide();
        } else {
            $closeButton.show();
        }
    }

    /**
     * Checks if the current step has a "before" callback
     * @return {Boolean} Whete the step has a "before" callback
     */
    static hasBeforeCallback() {
        const currentStep = window.hopscotch.getCurrStepNum();
        const currentTour = window.hopscotch.getCurrTour();
        if (currentTour.steps[currentStep] && currentTour.steps[currentStep].beforeCallback) {
            return true;
        }
        return false;
    }

    /**
     * Run the "beforeCallback" of the currentStep
     */
    static executeBeforeCallback() {
        const currentStep = window.hopscotch.getCurrStepNum();
        const currentTour = window.hopscotch.getCurrTour();
        const cb = () => {
            Tour.ignoreBeforeCallback = true;
            $('.hopscotch-bubble').show();
            window.hopscotch.showStep(window.hopscotch.getCurrStepNum());
        };

        if (currentTour.steps[currentStep] && currentTour.steps[currentStep].beforeCallback) {
            currentTour.steps[currentStep].beforeCallback.call(null, cb);
        }
    }

    /**
     * Run the "afterCallback" of the previous step
     */
    static executeAfterCallback(...args) {
        const prevStep = Tour.currentStep; // Tour.currentStep is updated after this function is called
        const currentTour = window.hopscotch.getCurrTour();
        if (currentTour.steps[prevStep] && currentTour.steps[prevStep].afterCallback) {
            currentTour.steps[prevStep].afterCallback.apply(null, args);
        }
    }

    /**
     * Returns if we should use the Fake API
     * @param  {String} method The name of the method we might fake
     * @return {Boolean}       Whether we should use the Fake API
     */
    static shouldUseFakeApi(tour, method) {
        const deferred = $.Deferred();

        Tour.isViewed(tour).done((isViewed) => {
            const currentTour = window.hopscotch.getCurrTour();
            if (Tour.preInit) {
                deferred.resolve(true);
            } else if (
                !isViewed &&
                currentTour &&
                currentTour.fakeMethods &&
                currentTour.fakeMethods.indexOf(method) >= 0 &&
                !currentTour.done
            ) {
                deferred.resolve(true);
            } else {
                deferred.resolve(false);
            }
        });

        return deferred;
    }

    /**
     * Updates the value of last visited step
     */
    static updateLastVisitedStep() {
        const currentStep = window.hopscotch.getCurrStepNum();

        if (!Tour.lastVisitedStep || currentStep > Tour.lastVisitedStep) {
            Tour.lastVisitedStep = currentStep;
        }
    }

    /**
     * Display step with the id stepNum
     * @param  {int} [stepNum] The step to start show
     */
    static goToStep(stepNum) {
        if (stepNum === window.hopscotch.getCurrStepNum()) {
            return;
        }
        window.hopscotch.showStep(stepNum);
        Tour.executeAfterCallback();
        Tour.currentStep = window.hopscotch.getCurrStepNum();
        Tour.updateOverlay();
    }
}
