import PropTypes from 'prop-types';
import React from 'react';
import RatingWidget from './RatingWidget';

import User from '../../models/User';

export default class RatingList extends React.Component {
    static propTypes = {
        ratings: PropTypes.array.isRequired,
        user: PropTypes.instanceOf(User).isRequired,
        loggedUser: PropTypes.instanceOf(User).isRequired,
        offset: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const ratings = [];
        for (let i = this.props.offset; i < this.props.offset + this.props.limit; i++) {
            if (!_.isUndefined(this.props.ratings[i])) {
                ratings.push(
                    <RatingWidget
                        key={_.uniqueId()}
                        rating={this.props.ratings[i]}
                        user={this.props.user}
                        loggedUser={this.props.loggedUser}
                    />
                );
            }
        }

        return <div className="row">{ratings}</div>;
    }
}
