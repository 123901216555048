import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n';

export class CalendarCaptionUpdate extends React.PureComponent {
    static propTypes = {
        updatedAt: PropTypes.object
    };

    setInterval() {
        this.interval = setInterval(() => {
            // Update every minute
            this.setState({
                updatedAt: this.timeToString(this.props.updatedAt)
            });
        }, 60000);
    }

    UNSAFE_componentWillMount() {
        this.setState({
            updatedAt: this.timeToString(this.props.updatedAt)
        });
    }

    componentDidMount() {
        this.setInterval();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.updatedAt !== this.props.updatedAt) {
            this.setState({
                updatedAt: this.timeToString(nextProps.updatedAt)
            });
        }
    }

    componentDidUpdate() {
        if (this.interval) {
            clearInterval(this.interval);
        }
        this.setInterval();
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    timeToString(date) {
        if (!date) {
            return false;
        }
        let updatedAt;

        if (moment().diff(date, 'seconds') < 30) {
            updatedAt = i18n.t('common:updated_just_now');
        } else {
            updatedAt = date.fromNow();
        }

        return updatedAt;
    }

    render() {
        let dateClasses = '';

        if (!this.props.updatedAt) {
            return false;
        }

        if (moment().diff(this.props.updatedAt, 'seconds') < 30) {
            dateClasses = 'just-now';
        }

        return (
            <div className="calendar-caption-update">
                {i18n.t('common:updated')}: <span className={dateClasses}>{this.state.updatedAt}</span>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    updatedAt: _.result(state.calendar[ownProps.homeId], 'updatedAt', null)
});

export default connect(mapStateToProps)(CalendarCaptionUpdate);
