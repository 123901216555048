import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import User from '../../../models/User';
import Icons from '../../../utils/Icons';
import i18n from '../../../i18n';

const LHSHelpMenuItem = ({ user }) => {
    const isUserFromLHS = () => {
        const userPlatforms = user?.get('user_platform');
        return userPlatforms?.find((platform) => {
            return platform.platform.name === User.SOURCE_LHS;
        });
    };

    return (
        <>
            {isUserFromLHS() && (
                <a href={i18n.t('url:lhs.url-faq')} target="_blank" rel="noreferrer">
                    <span className="icon-lhs">{Icons.transform(4)}</span>
                    {i18n.t('user:lhs.help-menu-item')}
                </a>
            )}
        </>
    );
};

LHSHelpMenuItem.propTypes = {
    user: PropTypes.instanceOf(User)
};
const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(LHSHelpMenuItem);
