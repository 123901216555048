import { useEffect } from 'react';

const useGrabScroll = (containerSelector) => {
    useEffect(() => {
        const flexibleTimeContainer = document.querySelector(containerSelector);

        if (!flexibleTimeContainer) {
            console.error(`Container with selector "${containerSelector}" not found.`);
            return;
        }

        flexibleTimeContainer.style.cursor = 'pointer';

        let isGrabbing = false;
        let startPosition = { left: 0, initialX: 0 };

        const handleMouseMove = function (event) {
            if (isGrabbing) {
                const displacementX = event.clientX - startPosition.initialX;
                flexibleTimeContainer.scrollLeft = startPosition.left - displacementX;
            }
        };

        const handleMouseUp = function () {
            if (isGrabbing) {
                flexibleTimeContainer.style.cursor = 'pointer';
                flexibleTimeContainer.style.removeProperty('user-select');
                isGrabbing = false;
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            }
        };

        const handleMouseDown = function (event) {
            isGrabbing = true;
            flexibleTimeContainer.style.cursor = 'grabbing';
            flexibleTimeContainer.style.userSelect = 'none';

            startPosition = {
                left: flexibleTimeContainer.scrollLeft,
                initialX: event.clientX
            };

            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        };

        flexibleTimeContainer.addEventListener('mousedown', handleMouseDown);

        return () => {
            flexibleTimeContainer.removeEventListener('mousedown', handleMouseDown);
        };
    }, [containerSelector]);
};

export default useGrabScroll;
