import PropTypes from 'prop-types';
import React from 'react';

import PaymentMethod from '../../models/PaymentMethod';

import Utils from '../../utils/utils';

const PaymentMethodPaypal = ({ paymentMethod }) => (
    <React.Fragment>
        <div className="payment-method-logo">
            <img src={`${Utils.getCloudfrontDomain()}/images/website/subscription/paypal.svg`} alt="Paypal" />
        </div>
        <div className="payment-method-email">{paymentMethod.get('email')}</div>
    </React.Fragment>
);

PaymentMethodPaypal.propTypes = {
    paymentMethod: PropTypes.instanceOf(PaymentMethod)
};

export default PaymentMethodPaypal;
