import Api from '../api/Api';

export const LOAD_USER_GROUPS = 'LOAD_USER_GROUPS';

export function loadUserGroups(userId, groups) {
    return {
        type: LOAD_USER_GROUPS,
        payload: {
            [userId]: groups
        }
    };
}

export function fetchUserGroups(userId) {
    return (dispatch) =>
        Api.User.fetchUserGroups(userId).then((groups) => dispatch(loadUserGroups(userId, groups)));
}
