import Model from './Model';
import Product from './Product';

export default class Subscription extends Model {
    static SUBSCRIPTION_TYPE = 7;
    static EUROPE_SERVICE_CHARGE_PRICE = 8;
    static STATUS_COLLECTION = 'collection';
    static STATUS_NOT_ELIGIBLE = 'neutral-not-eligible';

    parse(attributes, options) {
        attributes = super.parse(attributes, options);

        if (attributes.hasOwnProperty('created_at') && attributes.created_at) {
            attributes.created_at = moment(attributes.created_at);
        }

        if (attributes.hasOwnProperty('start_on') && attributes.start_on) {
            attributes.start_on = moment(attributes.start_on);
        }

        if (attributes.hasOwnProperty('end_on') && attributes.end_on) {
            attributes.end_on = moment(attributes.end_on);
        }

        if (attributes.hasOwnProperty('product') && _.isObject(attributes.product)) {
            attributes.product = new Product(attributes.product);
        }

        return attributes;
    }
}
