import UserApi from '../User';
import Conversation from '../Conversation';
import Home from '../Home';

import i18n from '../../i18n';
import Utils from '../../utils/utils';

export default class MessagingTourFakeApi {
    static Conversation = {};
    static Home = {};
}

const host = {
    id: 1,
    email: 'contact@homeexchange.com',
    status: 1,
    verified_status: 1,
    verified_only: false,
    response_rate: 100,
    locale: 'fr',
    first_name: 'HomeExchange',
    last_name: 'Team',
    phone: '',
    created_at: '2011-09-30T00:00:00+0100',
    images: [
        {
            id: 0,
            name: 'profil',
            order_display: 1,
            type: 'USER',
            cdn_link: `${Utils.getCloudfrontDomain()}/images/website/tour/user-logo-he.jpg`
        }
    ],
    global_rating: 5,
    number_exchange: 10,
    number_guestpoint: 1000,
    had_home_full_filled_once: true,
    had_profil_full_filled_once: true,
    verification_pending: false,
    can_send_message: true,
    profil: {
        id: 0,
        birth_date: '2011-09-30T00:00:00+0000',
        language: 3,
        tidiness: 3,
        planning: 3,
        filling_write: 100,
        response_time: 1,
        metric: 1
    }
};

const home = {
    id: 0,
    type: 2,
    address: '45 Rue Chabrol 75010 Paris',
    capacity: 38,
    guestpoint: 1,
    deposit: 0,
    calendar_status: 5,
    exchange_nb: 10,
    status: 5,
    is_verified: 1,
    created_at: '2011-09-30T00:00:00+0100',
    completion_rate: 100,
    insurance: 0,
    full_completion_date: '2011-09-30T00:00:00+0100',
    user: host,
    location: {},
    descriptions: [
        {
            locale: 'en',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'fr',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'es',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'pt',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'it',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'de',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'nl',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'da',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'sv',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'nb',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'hr',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'hu',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'el',
            title: 'La Casa HomeExchange'
        },
        {
            locale: 'tr',
            title: 'La Casa HomeExchange'
        }
    ],
    detail: {
        location_type: 5,
        residence_type: 3,
        description_type: 6,
        size: 100,
        bedroom_nb: 3,
        bathroom_nb: 2,
        double_bed: 1,
        single_bed: 2,
        children_bed: 0,
        baby_bed: 1,
        double_bed_up: 0,
        single_bed_up: 0,
        children_bed_up: 0,
        baby_bed_up: 2
    },
    feature: {
        transportation: 2,
        personal: 6,
        rule: 15,
        multimedia: 207,
        practical: 1982,
        outdoor: 0,
        various: 64,
        others: 2,
        favor: 4
    },
    images: [
        {
            id: 1,
            name: 'home-1',
            order_display: 1,
            type: 'HOME',
            cdn_link: `${Utils.getCloudfrontDomain()}/images/website/tour/home.jpg`
        }
    ],
    global_rating: 5,
    translated_admins: {
        country: 'France',
        admin1: '\u00cele-De-France',
        admin2: 'Paris',
        admin3: 'Paris',
        admin4: '10E Arrondissement'
    },
    max_guestpoints_per_night: 156,
    estimated_deposit: 700,
    estimated_value: 120
};

const exchange = {
    id: 1,
    start_on: '2011-09-30T00:00:00+0000',
    end_on: new Date().toISOString(),
    home_deposit: 0,
    guestpoint_amount: 1,
    home_guestpoint: 1,
    home_capacity: home.capacity,
    night_nb: 100,
    guest_nb: home.capacity,
    insurance: home.insurance,
    type: 1,
    status_gp: 0,
    status: 1,
    guest: {},
    host,
    home,
    rating_users: [],
    comment_users: [],
    rating_homes: [],
    comment_homes: [],
    last_modify_at: new Date().toISOString(),
    has_to_handle_deposit: false,
    has_to_handle_gp: false,
    gp_balance: 600,
    additional_gp_full_price: 0,
    additional_gp_discount: 0,
    max_possible_discount: 0.2,
    gp_full_price: 1,
    gp_discount_price: 0.1
};

const message = {
    id: 1,
    content: i18n.t('messaging:tour_fake_message'),
    status: 0,
    type: 0,
    send_at: new Date().toISOString(),
    author: host
};

const conversation = {
    id: 1,
    title: '',
    message_count: 1,
    unread_messages_count: 1,
    exchanges: [exchange],
    status: 4,
    last_message: message,
    interlocutor: host,
    favorite: false,
    all_exchanges: [exchange],
    modificators: []
};

let messageUpdated = false;
let hasConv = null;

MessagingTourFakeApi.updateMessageContent = () => {
    if (messageUpdated) {
        return $.Deferred().resolve();
    } else {
        return UserApi.me().done((user) => {
            messageUpdated = true;
            message.content = i18n.t('messaging:tour_fake_message', {
                first_name: user.first_name
            });
            exchange.guest = user;
        });
    }
};

MessagingTourFakeApi.userHasConversations = () => {
    // Use Fake API only if the user has conversations, otherwise use the real one
    if (!_.isNull(hasConv)) {
        return $.Deferred().resolve(hasConv);
    } else {
        // Check from the API if the user has any conversations (even archived or deleted ones)
        return Conversation.meGraphQL('ALL').then((c) => {
            if (c && c.data && c.data.conversations && c.data.conversations.totalCount > 0) {
                hasConv = true;
            } else {
                hasConv = false;
            }
            return hasConv;
        });
    }
};

// Override some methods to allow for the Messaging Tour to work
MessagingTourFakeApi.Conversation.me = function () {
    // Return the list of message with a fake tour message at the top
    return MessagingTourFakeApi.updateMessageContent()
        .then(() => Conversation.me())
        .then((c) => {
            const result = {};
            if (c.conversations && c.conversations.length > 0 && c.total_conversations) {
                result.conversations = [conversation, ...c.conversations];
                result.total_conversations = c.total_conversations + 1;
                // Dispatch event to allow for redirection to conversations/1
                const e = document.createEvent('Event');
                e.initEvent('messaging_tour_has_conversations', true, true);
                document.dispatchEvent(e);
            } else {
                // Dispatch event to stop onboarding
                const e = document.createEvent('Event');
                e.initEvent('messaging_tour_no_conversations', true, true);
                document.dispatchEvent(e);

                result.conversations = [];
                result.total_conversations = 0;
            }
            return $.Deferred().resolve(result);
        });
};

MessagingTourFakeApi.Conversation.meGraphQL = function (...params) {
    // Return the list of message with a fake tour message at the top
    return MessagingTourFakeApi.updateMessageContent()
        .then(MessagingTourFakeApi.userHasConversations)
        .then(() => Conversation.meGraphQL(...params))
        .then((c) => {
            let result = {};

            // Show Tour only if it has conversations
            if (hasConv) {
                result = {
                    data: {
                        conversations: {
                            edges: [
                                {
                                    cursor: 'fakemsg',
                                    node: conversation
                                }
                            ],
                            pageInfo: {
                                hasNextPage: false,
                                hasPreviousPage: false
                            },
                            totalCount: 1
                        }
                    }
                };

                // Based on the filter, there might not be conversations to display, only add them if necessary
                if (
                    c.data &&
                    c.data.conversations &&
                    c.data.conversations.edges &&
                    c.data.conversations.edges.length > 0 &&
                    c.data.conversations.totalCount
                ) {
                    result.data.conversations.edges.push(...c.data.conversations.edges);
                    result.data.conversations.totalCount = c.data.conversations.totalCount + 1;
                }

                // Dispatch event to allow for redirection to conversations/1
                const e = document.createEvent('Event');
                e.initEvent('messaging_tour_has_conversations', true, true);
                document.dispatchEvent(e);
            } else {
                // Dispatch event to stop onboarding
                const e = document.createEvent('Event');
                e.initEvent('messaging_tour_no_conversations', true, true);
                document.dispatchEvent(e);

                result = {
                    data: {
                        conversations: {
                            edges: [],
                            pageInfo: {
                                hasNextPage: false,
                                hasPreviousPage: false
                            },
                            totalCount: 0
                        }
                    }
                };
            }
            return $.Deferred().resolve(result);
        });
};

MessagingTourFakeApi.Conversation.get = function (...params) {
    return MessagingTourFakeApi.updateMessageContent()
        .then(MessagingTourFakeApi.userHasConversations)
        .then((hasConversation) => {
            if (hasConversation) {
                return $.Deferred().resolve([conversation]);
            } else {
                return Conversation.get(...params);
            }
        });
};

MessagingTourFakeApi.Conversation.getMessages = function (...params) {
    return MessagingTourFakeApi.updateMessageContent()
        .then(MessagingTourFakeApi.userHasConversations)
        .then((hasConversation) => {
            if (hasConversation) {
                return $.Deferred().resolve([Object.assign({ conversation }, message)]);
            } else {
                return Conversation.getMessages(...params);
            }
        });
};

MessagingTourFakeApi.Home.get = function (...params) {
    return MessagingTourFakeApi.updateMessageContent()
        .then(MessagingTourFakeApi.userHasConversations)
        .then((hasConversation) => {
            if (hasConversation) {
                return $.Deferred().resolve([home]);
            } else {
                return Home.get(...params);
            }
        });
};

MessagingTourFakeApi.Home.getAvailabilities = function (...params) {
    return MessagingTourFakeApi.updateMessageContent()
        .then(MessagingTourFakeApi.userHasConversations)
        .then((hasConversation) => {
            if (hasConversation) {
                return $.Deferred().resolve([]);
            } else {
                return Home.getAvailabilities(...params);
            }
        });
};
