import Model from './Model';

export default class PaymentMethod extends Model {
    static TYPE_CARD = 'card';
    static TYPE_PAYPAL = 'paypal';

    isCard() {
        return this.get('type') === PaymentMethod.TYPE_CARD;
    }

    isPaypal() {
        return this.get('type') === PaymentMethod.TYPE_PAYPAL;
    }

    isExpired() {
        return this.has('is_expired') && this.get('is_expired');
    }

    isDefault() {
        return this.has('is_default') && this.get('is_default');
    }

    getCustomer() {
        return this.get('customer');
    }
}
