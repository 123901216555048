import React from 'react';
import PropTypes from 'prop-types';
import HomeImage from '../../components/home/HomeImage';

const HomeCard = ({ classMedia, classSize, imageSrc, href, title, text }) => (
    <div className={classMedia}>
        <div className={`container-home-inspirational ${classSize}`}>
            <HomeImage className="home-image-inspirational" imageSrc={imageSrc} href={href} />
        </div>
        <div className="container-text">
            <h3 className="home-inspirational-home-image-title">{title}</h3>
            <p>{text}</p>
        </div>
    </div>
);

HomeCard.propTypes = {
    classMedia: PropTypes.string,
    classSize: PropTypes.string,
    imageSrc: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string
};

export default HomeCard;
