import parser from 'query-string-parser';
import { PLACE_LAYERS, COUNTRY_LAYER_ONLY } from '../constants/search';

export default class AutocompleteService {
    static getPlacePredictions(searchText, isFromAddressPicker) {
        return AutocompleteService.getHereSuggestions(searchText, isFromAddressPicker);
    }

    static getAddressSuggestions(searchText) {
        const url = process.env.URL_JAWG_PLACE;
        const query = parser.toQuery(
            Object.assign({
                'access-token': process.env.JAWG_API_KEY,
                layers: 'dependency,address,street,neighbourhood,locality,borough,localadmin,county,macrocounty,region,macroregion,country,postalcode',
                size: 10,
                text: searchText
            })
        );

        const headers = new Headers();
        const locale = document.documentElement.lang;
        headers.append('Accept-Language', locale);

        return fetch(`${url}?${query}`, { headers })
            .then((response) => response.json())
            .then((body) => {
                if (body) {
                    return AutocompleteService.formatSuggestions(body.features);
                }
                return [];
            });
    }

    static async getGidFromReverseGeocoding(geocoordinate) {
        const url = process.env.URL_JAWG_GEOCODING;
        const query = parser.toQuery({
            'access-token': process.env.JAWG_API_KEY,
            layers: 'address,street,neighbourhood,locality,borough,localadmin,county,macrocounty,region,macroregion,country',
            'point.lat': geocoordinate.lat,
            'point.lon': geocoordinate.lon,
            size: 1
        });

        const headers = new Headers();
        const locale = document.documentElement.lang;
        headers.append('Accept-Language', locale);

        try {
            const response = await fetch(`${url}?${query}`, { headers });
            if (!response.ok) {
                throw new Error(`Error fetching geocoding data: ${response.statusText}`);
            }
            const body = await response.json();
            if (body?.features) {
                return AutocompleteService.formatSuggestions(body.features);
            }
            return [];
        } catch (error) {
            console.error('Error in getGidFromReverseGeocoding:', error);
            return [];
        }
    }

    static getPlaceSuggestions(searchText) {
        const url = process.env.URL_JAWG_PLACE;
        const query = parser.toQuery(
            Object.assign({
                'access-token': process.env.JAWG_API_KEY,
                layers: PLACE_LAYERS,
                sources: 'wof,osm',
                size: 10,
                text: searchText
            })
        );

        const headers = new Headers();
        const locale = document.documentElement.lang;
        headers.append('Accept-Language', locale);

        return fetch(`${url}?${query}`, { headers })
            .then((response) => response.json())
            .then((body) => {
                if (body) {
                    return AutocompleteService.formatSuggestions(body.features);
                }
                return [];
            });
    }

    static getCountrySuggestions(searchText) {
        const url = process.env.URL_JAWG_PLACE;
        const query = parser.toQuery(
            Object.assign({
                'access-token': process.env.JAWG_API_KEY,
                layers: COUNTRY_LAYER_ONLY,
                sources: 'wof',
                size: 1,
                text: searchText
            })
        );

        const headers = new Headers();
        const locale = document.documentElement.lang;
        headers.append('Accept-Language', locale);

        return fetch(`${url}?${query}`, { headers })
            .then((response) => response.json())
            .then((body) => {
                if (body) {
                    return AutocompleteService.formatSuggestions(body.features);
                }
                return [];
            });
    }

    static formatSuggestions(suggestions) {
        return suggestions.map((el) => ({
            ...el,
            formatedTitle: el.properties.label
        }));
    }

    static suggestionFilter(suggestion) {
        const category = ['building', 'street-square', 'intersection'];
        const newSuggestion = suggestion.filter((el) => category.indexOf(el.category) === -1);
        return newSuggestion;
    }
}
