import Api from '../api/Api';

export const LOAD_TRAVELERS = 'LOAD_TRAVELERS';
export const ADD_TRAVELER = 'ADD_TRAVELER ';
export const EDIT_TRAVELER = 'EDIT_TRAVELER';
export const REMOVE_TRAVELER = 'REMOVE_TRAVELER';

export function loadTravelers(travelers) {
    return {
        type: LOAD_TRAVELERS,
        payload: travelers
    };
}

export function fetchTravelers(userId) {
    return (dispatch) =>
        Api.Traveler.fetchTravelers(userId).then((response) => dispatch(loadTravelers(response)));
}

export function addTraveler(data) {
    return (dispatch) =>
        Api.Traveler.createTraveler(data).then((response) => {
            dispatch({
                type: ADD_TRAVELER,
                payload: response
            });
        });
}

export function editTraveler(id, data) {
    return (dispatch) =>
        Api.Traveler.editTraveler(id, data).then((response) => {
            dispatch({
                type: EDIT_TRAVELER,
                payload: response
            });
        });
}

export function removeTraveler(id) {
    return (dispatch) =>
        Api.Traveler.removeTraveler(id).then(() => {
            dispatch({
                type: REMOVE_TRAVELER,
                payload: id
            });
        });
}
