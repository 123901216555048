import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18n from '../../../i18n';

import Group from '../../../models/Group';
import { joinGroup, fetchGroupDetails } from '../../../actions/groups';

export class GroupJoinButton extends React.PureComponent {
    static defaultProps = {
        isGroupPage: false
    };

    static propTypes = {
        group: PropTypes.instanceOf(Group).isRequired,
        joinGroup: PropTypes.func.isRequired,
        fetchGroupDetails: PropTypes.func.isRequired,
        isGroupPage: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.getGroupDetails = this.getGroupDetails.bind(this);
    }

    getGroupDetails() {
        this.props
            .joinGroup(this.props.group.get('id'))
            .then(() => this.props.fetchGroupDetails(this.props.group.get('id')));
    }

    render() {
        const additionalClasses = this.props.isGroupPage ? 'btn-consult pull-right' : ``;
        return (
            <a
                className={`btn btn-xs btn-primary ${additionalClasses}`}
                href="#"
                onClick={this.getGroupDetails}
            >
                {i18n.t(`common:join.group`)}
            </a>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    joinGroup: bindActionCreators(joinGroup, dispatch),
    fetchGroupDetails: bindActionCreators(fetchGroupDetails, dispatch)
});

export default connect(null, mapDispatchToProps)(GroupJoinButton);
