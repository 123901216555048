import React from 'react';
import PropTypes from 'prop-types';

export default class InputOption extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['radio', 'checkbox']).isRequired,
        label: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        checked: PropTypes.bool,
        required: PropTypes.bool,
        onChange: PropTypes.func,
        icon: PropTypes.string,
        inputNumber: PropTypes.number,
        trackingData: PropTypes.string
    };

    static defaultProps = {
        inputNumber: 0
    };

    render() {
        return (
            <div className="input-option">
                <input
                    type={this.props.type}
                    name={this.props.name}
                    id={this.props.id}
                    value={this.props.value}
                    checked={this.props.checked}
                    onChange={this.props.onChange}
                    required={this.props.required}
                    data-input-number={this.props.inputNumber}
                    data-track-edition={this.props.trackingData}
                    ref={(ref) => {
                        this.input = ref;
                    }}
                />
                <label
                    className="input-options-label"
                    htmlFor={this.props.id}
                    title={this.props.label.trim()}
                >
                    {this.props.icon && <i className={`icon icon-${this.props.icon}`}></i>}
                    <span>{this.props.label}</span>
                </label>
            </div>
        );
    }
}
