import PropTypes from 'prop-types';
import React from 'react';

import i18n from '../../i18n';

import HomeRating from './HomeRating';
import HomeReviewGP from './HomeReviewGP';
import Tooltip from '../widget/Tooltip';

export default class HomeReview extends React.Component {
    static propTypes = {
        home: PropTypes.object.isRequired
    };

    render() {
        const { home } = this.props;
        return (
            <div className="homebox-review">
                <HomeRating home={home} />
                <Tooltip placement="bottom" title={i18n.t('home:capacity', { count: home.get('capacity') })}>
                    <span className="capacity">{home.get('capacity')}</span>
                </Tooltip>
                <HomeReviewGP guestpointsValue={home.get('guestpoint')} />
            </div>
        );
    }
}
