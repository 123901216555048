import Model from './Model';
import i18n from '../i18n';
import User from './User';
import Message from './Message';
import Exchange from './Exchange';
import Api from '../api/Api';

export default class Conversation extends Model {
    static STATUS_ARCHIVED = 0;
    static STATUS_RECEIVED = 1;
    static STATUS_RECEIVED_UNREAD = 2;
    static STATUS_RECEIVED_W_RESPONSE = 3;
    static STATUS_INITIALIZED = 4;
    static STATUS_INITIALIZED_W_RESPONSE = 5;
    static STATUS_FINALIZED = 6;
    static STATUS_ALL = 7;
    static STATUS_DELETED = 8;
    static STATUS_RECEIVED_W_RESPONSE_AND_UNREAD = 9;
    static STATUS_NOTIFICATION = 10;
    static STATUS_ARCHIVED_FINALIZED = 11;
    static STATUS_CANCELED = 12;

    parse(attributes, options) {
        attributes = super.parse(attributes, options);

        if (attributes.hasOwnProperty('interlocutor') && attributes.interlocutor instanceof User === false) {
            attributes.interlocutor = new User(attributes.interlocutor);
        }

        if (
            attributes.hasOwnProperty('last_message') &&
            attributes.last_message instanceof Message === false
        ) {
            attributes.last_message = new Message(attributes.last_message);
        }

        if (attributes.hasOwnProperty('exchanges') && Array.isArray(attributes.exchanges)) {
            attributes.exchanges.forEach((echange, index) => {
                if (attributes.exchanges[index] instanceof Exchange === false) {
                    attributes.exchanges[index] = new Exchange(attributes.exchanges[index]);
                }
            });
        }

        if (attributes.hasOwnProperty('all_exchanges') && Array.isArray(attributes.all_exchanges)) {
            attributes.all_exchanges.forEach((echange, index) => {
                if (attributes.all_exchanges[index] instanceof Exchange === false) {
                    attributes.all_exchanges[index] = new Exchange(attributes.all_exchanges[index]);
                }
            });
        }

        if (attributes.hasOwnProperty('modificators') && Array.isArray(attributes.modificators)) {
            attributes.modificators.forEach((modificator, index) => {
                if (attributes.modificators[index] instanceof Exchange === false) {
                    attributes.modificators[index] = new Exchange(attributes.modificators[index]);
                }
            });
        }

        return attributes;
    }

    static getAllStatuses() {
        return [
            Conversation.STATUS_ARCHIVED,
            Conversation.STATUS_RECEIVED,
            Conversation.STATUS_RECEIVED_UNREAD,
            Conversation.STATUS_RECEIVED_W_RESPONSE,
            Conversation.STATUS_INITIALIZED,
            Conversation.STATUS_INITIALIZED_W_RESPONSE,
            Conversation.STATUS_FINALIZED,
            Conversation.STATUS_ALL,
            Conversation.STATUS_DELETED,
            Conversation.STATUS_RECEIVED_W_RESPONSE_AND_UNREAD,
            Conversation.STATUS_NOTIFICATION,
            Conversation.STATUS_ARCHIVED_FINALIZED
        ];
    }

    static statusToString(status) {
        switch (parseInt(status, 10)) {
            case Conversation.STATUS_ARCHIVED:
                return 'archived';
            case Conversation.STATUS_RECEIVED:
                return 'received';
            case Conversation.STATUS_RECEIVED_UNREAD:
                return 'received-unread';
            case Conversation.STATUS_RECEIVED_W_RESPONSE:
                return 'received-w-response';
            case Conversation.STATUS_INITIALIZED:
                return 'initialized';
            case Conversation.STATUS_INITIALIZED_W_RESPONSE:
                return 'initialized-w-response';
            case Conversation.STATUS_FINALIZED:
                return 'finalized';
            case Conversation.STATUS_ALL:
                return 'all';
            case Conversation.STATUS_DELETED:
                return 'deleted';
            case Conversation.STATUS_RECEIVED_W_RESPONSE_AND_UNREAD:
                return 'received-w-response-and-unread';
            case Conversation.STATUS_NOTIFICATION:
                return 'notification';
            case Conversation.STATUS_ARCHIVED_FINALIZED:
                return 'archived-finalized';
            case Conversation.STATUS_CANCELED:
                return 'canceled';
            default:
                throw Error(`Unknown conversation status (${status}).`);
        }
    }

    getStatus() {
        return Conversation.statusToString(this.get('status'));
    }

    getDate() {
        const convDate = this.get('updated_at') || this.get('created_at');
        const lastMessageDate = this.get('last_message') ? this.get('last_message').get('send_at') : null;
        if (!lastMessageDate) {
            return moment(convDate);
        } else if (!convDate) {
            return moment(lastMessageDate);
        }
        return moment.max(moment(convDate), moment(lastMessageDate));
    }

    hasUnreadMessages() {
        return this.get('unread_messages_count') > 0;
    }

    isFavorite() {
        return Boolean(this.get('favorite'));
    }

    isArchived() {
        return (
            this.get('status') == Conversation.STATUS_ARCHIVED ||
            this.get('status') == Conversation.STATUS_ARCHIVED_FINALIZED
        );
    }

    isDeleted() {
        return this.get('status') == Conversation.STATUS_DELETED;
    }

    isMutual() {
        return Array.isArray(this.get('exchanges')) && this.get('exchanges').length > 1;
    }

    // For reciprocal exchange
    isBothExchangeFinzalized() {
        const finalizedStatus = [Exchange.STATUS_FINALIZED, Exchange.STATUS_NOTATION, Exchange.STATUS_DONE];
        const exchangeAsGuest = this.getExchangeAsGuest();
        const exchangeAsHost = this.getExchangeAsHost();

        return (
            exchangeAsGuest &&
            finalizedStatus.includes(exchangeAsGuest.get('status')) &&
            exchangeAsHost &&
            finalizedStatus.includes(exchangeAsHost.get('status')) &&
            exchangeAsGuest.get('status') === exchangeAsHost.get('status')
        );
    }

    getExchangeType() {
        if (this.get('exchanges').length > 1) {
            return 'mutual';
        } else {
            return 'simple';
        }
    }

    getExchangeTypeShort() {
        if (this.isMutual()) {
            return 'reciprocal';
        } else {
            return 'non.reciprocal';
        }
    }

    getExchange() {
        return this.isMutual() ? this.getExchangeAsGuest() : _.first(this.get('exchanges'));
    }

    getExchangeAsGuest() {
        const me = Api.User.identity();
        return this.get('exchanges').find((exchange) => exchange.get('guest').id == me.id);
    }

    getExchangeAsHost() {
        const me = Api.User.identity();
        return this.get('exchanges').find((exchange) => exchange.get('host').id == me?.id);
    }

    getExchangeWithLowerStatus() {
        return this.get('exchanges').reduce((memo, exchange) => {
            if (memo && memo.get('status') < exchange.get('status')) {
                return memo;
            }
            return exchange;
        });
    }

    getExchangeStatus() {
        const exchangeAsGuest = this.getExchangeAsGuest();
        const exchangeAsHost = this.getExchangeAsHost();
        const exchange = this.getExchange();

        if (exchangeAsGuest && (!exchangeAsGuest.get('home') || exchangeAsGuest.get('home').isDeleted())) {
            return {
                slug: 'home-deleted',
                label: i18n.t('exchange:status.home-deleted')
            };
        }

        if (exchangeAsHost && (!exchangeAsHost.get('home') || exchangeAsHost.get('home').isDeleted())) {
            return {
                slug: 'home-deleted',
                label: i18n.t('exchange:status.home-deleted')
            };
        }

        if (exchangeAsGuest && exchangeAsGuest.get('home').isOffline()) {
            return {
                slug: 'home-offline',
                label: i18n.t('exchange:status.home-offline')
            };
        }

        if (exchangeAsHost && exchangeAsHost.get('home').isOffline()) {
            return {
                slug: 'home-offline',
                label: i18n.t('exchange:status.home-offline')
            };
        }

        if (exchange && exchange.isCanceled()) {
            return {
                slug: 'canceled',
                label: i18n.t('exchange:status.canceled')
            };
        }

        if (this.get('modificators') && this.get('modificators')?.length !== 0) {
            return {
                slug: 'pending-request',
                icon: 'hourglass-end',
                label: i18n.t('exchange:status.pending-request')
            };
        }

        if (
            (exchangeAsGuest && exchangeAsGuest.hasToRate()) ||
            (exchangeAsHost && exchangeAsHost.hasToRate())
        ) {
            return {
                slug: 'finalized',
                label: i18n.t('exchange:status.finalized'),
                classNames: ['rate']
            };
        }

        if (
            exchange &&
            !this.isMutual() &&
            _.contains(
                [
                    Exchange.STATUS_FINALIZED,
                    Exchange.STATUS_NOTATION,
                    Exchange.STATUS_GUEST_NOTATION,
                    Exchange.STATUS_HOST_NOTATION,
                    Exchange.STATUS_DONE
                ],
                exchange.get('status')
            )
        ) {
            return {
                slug: 'finalized',
                label: i18n.t('exchange:status.finalized')
            };
        }

        if (this.isMutual() && this.isBothExchangeFinzalized()) {
            return {
                slug: 'finalized',
                label: i18n.t('exchange:status.finalized')
            };
        }

        if (exchange && exchange.get('status') == Exchange.STATUS_ACCEPTED) {
            return {
                slug: 'accepted',
                label: i18n.t('exchange:status.accepted')
            };
        }

        if (exchange && exchange.get('status') == Exchange.STATUS_PREAPPROVED) {
            return {
                slug: 'preapproved',
                label: i18n.t('exchange:status.preapproved')
            };
        }

        if (
            _.contains(
                [Conversation.STATUS_RECEIVED_W_RESPONSE_AND_UNREAD, Conversation.STATUS_RECEIVED_W_RESPONSE],
                this.get('status')
            )
        ) {
            return {
                slug: 'exchange-request',
                label: i18n.t('exchange:status.exchange-request-received')
            };
        }

        if (_.contains([Conversation.STATUS_INITIALIZED_W_RESPONSE], this.get('status'))) {
            return {
                slug: 'exchange-request',
                label: i18n.t('exchange:status.exchange-request-sent')
            };
        }

        if (
            _.contains(
                [Conversation.STATUS_ARCHIVED, Conversation.STATUS_ARCHIVED_FINALIZED],
                this.get('status')
            )
        ) {
            return {
                slug: 'archived',
                label: i18n.t('exchange:status.archived')
            };
        }

        if (this.get('status') == Conversation.STATUS_DELETED) {
            return {
                slug: 'deleted',
                label: i18n.t('exchange:status.deleted')
            };
        }

        return {
            slug: 'being-discussed',
            label: i18n.t('exchange:status.being-discussed')
        };
    }

    static getStatusForFilter(filter) {
        switch (filter) {
            case 'archived':
                return [Conversation.STATUS_ARCHIVED, Conversation.STATUS_ARCHIVED_FINALIZED];
            case 'unread':
                return [
                    Conversation.STATUS_RECEIVED_UNREAD,
                    Conversation.STATUS_RECEIVED_W_RESPONSE_AND_UNREAD
                ];
            case 'unanswered':
                return [
                    Conversation.STATUS_RECEIVED_W_RESPONSE,
                    Conversation.STATUS_RECEIVED_W_RESPONSE_AND_UNREAD
                ];
            case 'sent':
                return [Conversation.STATUS_INITIALIZED, Conversation.STATUS_INITIALIZED_W_RESPONSE];
            case 'finalized':
                return [Conversation.STATUS_FINALIZED, Conversation.STATUS_ARCHIVED_FINALIZED];
            case 'canceled':
                return [Conversation.STATUS_CANCELED];
            case 'deleted':
                return [Conversation.STATUS_DELETED];
            default:
                return [];
        }
    }

    getGPSpent() {
        const exchangeAsGuest = this.getExchangeAsGuest();

        if (exchangeAsGuest) {
            return exchangeAsGuest.getGpTotal();
        }
        return 0;
    }

    getGPEarned() {
        const exchangeAsHost = this.getExchangeAsHost();

        if (exchangeAsHost) {
            return exchangeAsHost.getGpTotal();
        }
        return 0;
    }

    getGpBalance() {
        return this.getGPEarned() - this.getGPSpent();
    }

    hasToBuyGP() {
        const echangeAsGuest = this.getExchangeAsGuest();
        if (echangeAsGuest) {
            return echangeAsGuest.getGpFullPriceTotal() + echangeAsGuest.getGpDiscountTotal() > 0;
        }
        return false;
    }

    isFree() {
        return Math.abs(this.getGpBalance()) === 0;
    }

    isTypeEditable() {
        // Edit if exchange is simple not finalized OR if exchange is mutual and not semi-finalized
        const exchangeAsGuest = this.getExchangeAsGuest();
        const exchangeAsHost = this.getExchangeAsHost();

        const isFinalizedGuest =
            exchangeAsGuest && exchangeAsGuest.get('status') === Exchange.STATUS_FINALIZED;
        const isFinalizedHost = exchangeAsHost && exchangeAsHost.get('status') === Exchange.STATUS_FINALIZED;

        return (
            !this.getExchange().isFinalizedOrCanceled() &&
            (!this.isMutual() || (this.isMutual() && !isFinalizedGuest && !isFinalizedHost))
        );
    }

    isExchangeFinalizable(exchange) {
        const exchangeWithLowerStatus = this.getExchangeWithLowerStatus();
        return (
            Boolean(exchange.get('home')) &&
            exchange.get('home').isPublished() &&
            (exchange.get('status') == Exchange.STATUS_PREAPPROVED ||
                (exchange.get('type') == Exchange.TYPE_MUTUAL &&
                    exchangeWithLowerStatus.id == exchange.id &&
                    exchangeWithLowerStatus.get('status') == Exchange.STATUS_ACCEPTED))
        );
    }

    hasOneOrMoreExchangeToBeRatedByUser() {
        return this.get('exchanges').reduce((memo, exchange) => memo && exchange.hasToRate(), true);
    }

    hasChanged(prev) {
        const nextExchangeAsGuest = this.getExchangeAsGuest();
        const prevExchangeAsGuest = prev.getExchangeAsGuest();
        const nextExchangeAsHost = this.getExchangeAsHost();
        const prevExchangeAsHost = prev.getExchangeAsHost();
        return (
            this.isMutual() != prev.isMutual() ||
            moment(this.getDate()).isAfter(prev.getDate()) ||
            Boolean(
                nextExchangeAsGuest &&
                    Boolean(prevExchangeAsGuest) &&
                    moment(nextExchangeAsGuest.get('last_modify_at')).isAfter(
                        prevExchangeAsGuest.get('last_modify_at')
                    )
            ) ||
            Boolean(
                nextExchangeAsHost &&
                    Boolean(prevExchangeAsHost) &&
                    moment(nextExchangeAsHost.get('last_modify_at')).isAfter(
                        prevExchangeAsHost.get('last_modify_at')
                    )
            )
        );
    }

    hasOneOrMoreGPFree() {
        return this.get('exchanges').some((exchange) =>
            Boolean(exchange.get('home') && exchange.get('home').get('prefers_reciprocal'))
        );
    }

    doesInterlocutorWantReciprocalWithoutGP() {
        const exchanges = this.get('exchanges');
        // exchange is not reciprocal
        if (exchanges.length === 1) {
            return false;
        }
        let mismatchOnHomeSettings = false;
        if (exchanges.length > 1) {
            mismatchOnHomeSettings =
                exchanges[0].get('home').get('prefers_reciprocal') !=
                exchanges[1].get('home').get('prefers_reciprocal');
        }
        const home =
            this.getExchangeAsHost() && this.getExchangeAsHost().get('home')
                ? this.getExchangeAsHost().get('home')
                : this.getExchangeAsGuest().get('home');
        if (mismatchOnHomeSettings) {
            return true;
        }
        if (home && home.get('prefers_reciprocal') && !mismatchOnHomeSettings) {
            return true;
        }
        return false;
    }

    exchangeStartsInGivenDateRange(DateRangeStart, nbDays) {
        const givenRange = moment.range(DateRangeStart, moment(DateRangeStart).add(nbDays, 'days'));
        const exchanges = this.get('exchanges');

        if (!exchanges || exchanges.length === 0) {
            return false;
        }

        // exchange is reciprocal
        if (exchanges.length === 2) {
            return (
                givenRange.contains(moment(exchanges[0].get('start_on'))) &&
                givenRange.contains(moment(exchanges[1].get('start_on')))
            );
        } else if (exchanges.length === 1) {
            return givenRange.contains(moment(exchanges[0].get('start_on')));
        }
    }

    getAllExchanges() {
        return this.get('all_exchanges');
    }

    getModificators() {
        return this.get('modificators');
    }

    getSimpleExchangeModificator() {
        const modificators = this.getModificators();

        if (!modificators || modificators.length === 0) {
            return null;
        }

        return modificators[0];
    }

    isInExchangeModificationFlowAndShouldBuyGp(partner = null) {
        const modifiedExchange = this.getExchange();
        if (!modifiedExchange?.get('update_status')) {
            return false;
        }

        return partner
            ? modifiedExchange?.get('update_status') === Exchange.UPDATE_STATUS_NEED_BILLING &&
                  modifiedExchange?.get(partner).get('id') === Api.User.identity().id
            : modifiedExchange?.get('update_status') === Exchange.UPDATE_STATUS_NEED_BILLING;
    }
}
