import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'vanilla-lazyload';
import classnames from 'classnames';
import { fetchBadgeImage } from '../../hooks/useBadge';
import Tooltip from '../widget/Tooltip';
import i18n from '../../i18n';

export default function HomeImage(props) {
    const { children, className, imageSrc, useButton, loyalty, ...rest } = props;
    const [isLoading, setLoading] = useState(true);
    const [hasError, setError] = useState(false);
    const [lazyLoadInstance, setLazyLoadInstance] = useState(null);
    const imgRef = useRef(null);

    const onLoaded = () => {
        setLoading(false);
    };

    const onError = () => {
        setError(true);
        setLoading(false);
    };

    const onReveal = () => {
        // Load the picture on its own (can't track "loaded" event otherwise for background imgs)
        if (imageSrc) {
            const img = new Image();
            img.onload = onLoaded;
            img.onerror = onError;

            img.src = imageSrc;
        }
    };

    const isUserImage = () => className.includes('homebox-user');

    const displayBadge = () => {
        const badge = fetchBadgeImage(loyalty);

        if (badge && isUserImage()) {
            const title =
                loyalty > 1
                    ? i18n.t('home:home-view.badge.text.year', { year: loyalty })
                    : i18n.t('home:home-view.badge.text.new');

            return (
                <Tooltip title={title} placement="bottom">
                    <img className="home-image-user-badge" src={badge.src} alt={badge.alt} />
                </Tooltip>
            );
        }
    };

    const displayImage = () => {
        let dataBg = `url("${imageSrc}")`;
        let lazyClassName;

        if (!imageSrc) {
            dataBg = null;
            lazyClassName = classnames(
                className,
                { 'no-picture': true, 'homebox-user-profile': true },
                { lazyloading: false }
            );
        } else {
            lazyClassName = classnames(
                className,
                { 'no-picture': hasError, 'homebox-user-profile': true },
                { lazyloading: isLoading }
            );
        }

        const lazyLoadingProps = {
            className: lazyClassName,
            'data-bg': dataBg,
            'data-lazy': true
        };

        if (useButton) {
            return (
                <button ref={imgRef} {...lazyLoadingProps} {...rest}>
                    {children}
                    {loyalty > 0 && displayBadge()}
                </button>
            );
        }

        if (loyalty > 0) {
            return (
                <a ref={imgRef} target="_blank" rel="noopener noreferrer" {...rest} {...lazyLoadingProps}>
                    {children}
                    {displayBadge()}
                </a>
            );
        }

        return (
            <a ref={imgRef} alt="" target="_blank" rel="noopener noreferrer" {...rest} {...lazyLoadingProps}>
                {children}
            </a>
        );
    };

    useEffect(() => {
        setLazyLoadInstance(
            new LazyLoad(
                {
                    callback_error: onError,
                    callback_reveal: onReveal
                },
                [imgRef.current]
            )
        );
    }, []);

    useEffect(() => {
        if (lazyLoadInstance) {
            lazyLoadInstance.update();
        }
    }, [imageSrc]);

    return displayImage();
}

HomeImage.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    imageSrc: PropTypes.string,
    useButton: PropTypes.bool,
    loyalty: PropTypes.number
};
