import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icons from '../../utils/Icons';

const CardInfo = (props) => {
    const { icon, text, subtext, onClick, numberPath, classContentCard = false, iconHelp = true } = props;

    return (
        <div className="card-info" onClick={onClick}>
            {iconHelp && (
                <div className="card-info-header">
                    <i className="icon-circle-help" />
                </div>
            )}
            <div className={classNames('card-info-content', { 'direction-content': classContentCard })}>
                {numberPath ? (
                    <span className={`info-card-icon ${icon}`}>{Icons.transform(numberPath)}</span>
                ) : (
                    <i className={`info-card-icon ${icon}`} />
                )}
                <h3 className={`${subtext ? 'card-info-title' : 'card-info-text'}`}>{text}</h3>
                {subtext && <p className="card-info-subtext">{subtext}</p>}
            </div>
        </div>
    );
};

CardInfo.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    subtext: PropTypes.string,
    onClick: PropTypes.func,
    numberPath: PropTypes.number,
    iconHelp: PropTypes.bool,
    classContentCard: PropTypes.bool
};

export default CardInfo;
