import React, { forwardRef, useEffect, useState } from 'react';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import PropTypes from 'prop-types';

import CircleLayer from './mapComponents/circleLayer';
import MarkerPopup from './mapComponents/MarkerPopup';

import Utils from '../../../utils/utils';
import Analytics from '../../../utils/analytics';

const MAP_MOVE_STATUS = {
    MOVEEND: 'moveend',
    ZOOMEND: 'zoomend'
};

const CIRCLE_OPTIONS = {
    RADIUS: 0.5,
    STEPS: 50,
    UNITS: 'kilometers'
};
const MAP_OPTIONS = {
    ZOOM: 14
};
const ZOOM_CIRCLE_BREAKPOINT = 8.5;
const JAWG_URL = 'https://api.jawg.io/styles/jawg-sunny.json?access-token=';
const JAWG_API_KEY = process.env.JAWG_KEY;

const MapComponent = forwardRef(
    (
        {
            latitude = 48.87685,
            longitude = 2.35274,
            bounds = null,
            controlPosition = 'top-right',
            showControls = false,
            markerList = [],
            centeredCircle = false,
            centeredMarker = false,
            height = '100%',
            width = '100%',
            onMoveEnd,
            onZoomEnd
        },
        mapRef
    ) => {
        const [viewState, setViewState] = useState({});
        const [mapLanguage, setMapLanguage] = useState('en');

        const trackMapChange = (event) => {
            if (
                (event.type === MAP_MOVE_STATUS.MOVEEND && event.originalEvent.type === 'mouseup') ||
                event.type === MAP_MOVE_STATUS.ZOOMEND
            ) {
                const eventData = {
                    text: event.originalEvent.type === 'mouseup' ? 'move' : 'zoom',
                    area: 'search_page_map',
                    action: event.originalEvent.type
                };
                Analytics.trackGTM('SearchMap', {
                    event_data: eventData
                });
            }
        };

        const moveEndHandler = async (e) => {
            if (onMoveEnd) {
                e.bounds = mapRef.current.getBounds();
                await onMoveEnd(e);
                trackMapChange(e);
            }
        };

        const zoomEndHandler = async (e) => {
            if (onZoomEnd) {
                e.bounds = mapRef.current.getBounds();
                await onZoomEnd(e);
                trackMapChange(e);
            }
        };

        useEffect(() => {
            if (bounds && mapRef.current?.loaded()) {
                mapRef.current.fitBounds(
                    [
                        [bounds.ne.lon, bounds.ne.lat],
                        [bounds.sw.lon, bounds.sw.lat]
                    ],
                    { animate: false }
                );
            }
        }, [bounds]);

        useEffect(() => {
            if (latitude && longitude && !bounds) {
                setViewState({ ...viewState, latitude, longitude });
            }
        }, [latitude, longitude]);

        useEffect(() => {
            setMapLanguage(Utils.getWebsiteLanguage());
        }, []);

        return (
            <Map
                ref={mapRef}
                {...viewState}
                onMove={(evt) => setViewState(evt.viewState)}
                mapLib={maplibregl}
                initialViewState={{
                    longitude,
                    latitude,
                    zoom: MAP_OPTIONS.ZOOM,
                    bounds: bounds && [
                        [bounds.ne.lon, bounds.ne.lat],
                        [bounds.sw.lon, bounds.sw.lat]
                    ]
                }}
                style={{ width, height }}
                mapStyle={`${JAWG_URL}${JAWG_API_KEY}&lang=${mapLanguage}`}
                onMoveEnd={moveEndHandler}
                onZoomEnd={zoomEndHandler}
            >
                {showControls && <NavigationControl position={controlPosition} />}
                {centeredMarker && (
                    <Marker latitude={latitude} longitude={longitude} color={`#${Utils.PRIMARY_COLOR}`} />
                )}
                {centeredCircle && (
                    <CircleLayer
                        latitude={latitude}
                        longitude={longitude}
                        radius={CIRCLE_OPTIONS.RADIUS}
                        steps={CIRCLE_OPTIONS.STEPS}
                        units={CIRCLE_OPTIONS.UNITS}
                        isMarker={viewState.zoom <= ZOOM_CIRCLE_BREAKPOINT}
                    />
                )}
                {markerList?.length > 0 &&
                    markerList.map(
                        (marker) =>
                            marker.position && (
                                <MarkerPopup
                                    key={`${marker.id}`}
                                    latitude={marker?.position.lat}
                                    longitude={marker?.position.lon}
                                    content={marker?.content}
                                    customMarker={marker?.customMarker}
                                />
                            )
                    )}
            </Map>
        );
    }
);

MapComponent.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    bounds: PropTypes.shape({
        sw: PropTypes.object,
        ne: PropTypes.object
    }),
    showControls: PropTypes.bool,
    centeredCircle: PropTypes.bool,
    centeredMarker: PropTypes.bool,
    markerList: PropTypes.array,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onMoveEnd: PropTypes.func,
    onZoomEnd: PropTypes.func,
    controlPosition: PropTypes.string
};

export default MapComponent;
