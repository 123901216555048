export const VERIFICATION = {
    type: 'verification',
    link: 'common:reward.url.1',
    text: 'user:reward.label.1'
};

export const FILL_PROFILE = {
    type: 'fill-profile',
    link: 'common:reward.url.2',
    text: 'user:reward.label.2'
};

export const FILL_HOME = {
    type: 'fill-home',
    link: 'common:reward.url.3',
    text: 'user:reward.label.3'
};

export const FIRST_SUBSCRIPTION = {
    type: 'first-subscription',
    link: 'url:my-plan.url',
    text: 'user:reward.label.7'
};

export const CONVERSATION_CREATED = {
    type: 'conversations-created',
    link: 'common:reward.url.4',
    text: 'user:reward.label.4'
};

export const SPONSORSHIP = {
    type: 'sponsorship',
    link: 'common:reward.url.5',
    text: 'user:reward.label.5'
};
