import Common from './Common';

export default class Sponsor {
    static getGodfatherBySponsorKey(sponsorKey) {
        const params = Common.params({
            url: `/v1/users/sponsor-info?sponsor_key=${sponsorKey}`,
            method: 'get'
        });
        return Common.request(params);
    }

    static addGodfather(sponsorKey) {
        const params = Common.params({
            url: `/v1/users/godfather`,
            method: 'post',
            data: {
                sponsorship_key: sponsorKey
            }
        });
        return Common.request(params);
    }

    static getGodfatherWhoInvitedMeByEmail(email) {
        const params = Common.params({
            url: `/v1/users/referral-user?email=${email}`,
            method: 'get'
        });
        return Common.request(params);
    }

    static getGodfatherByUserId(id) {
        const params = Common.params({
            url: `/v1/users/${id}/sponsorship`,
            method: 'get'
        });
        return Common.request(params);
    }
}
