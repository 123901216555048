import PropTypes from 'prop-types';
import React from 'react';

import i18n from '../../i18n';
import Utils from '../../utils/utils';

export default class UploadImage extends React.Component {
    static propTypes = {
        image: PropTypes.shape({
            id: PropTypes.number,
            url: PropTypes.string
        }),
        rotateImage: PropTypes.func.isRequired,
        isVerified: PropTypes.bool,
        load: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.rotateLeft = this.rotateLeft.bind(this);
        this.rotateRight = this.rotateRight.bind(this);
    }

    handleMouseEnter(event) {
        $(event.currentTarget).find('.tool-bar').show();
    }

    handleMouseLeave(event) {
        $(event.currentTarget).find('.tool-bar').hide();
    }

    handleClick(e) {
        e.preventDefault();
        $('#inputUploadProfile').trigger('click');
    }

    rotateImage(angle) {
        this.props.rotateImage(this.props.image, angle);
    }

    rotateLeft() {
        this.rotateImage(90);
    }

    rotateRight() {
        this.rotateImage(-90);
    }

    render() {
        if (!this.props.image || this.props.load) {
            return (
                <div className="div-image wait" onClick={this.handleClick}>
                    {this.props.load ? (
                        <p>
                            <img
                                className="loader"
                                src={`${Utils.getCloudfrontDomain()}/images/loader.svg`}
                                alt="Loading"
                            />
                        </p>
                    ) : (
                        <p>
                            <a href="#">
                                <img
                                    src="https://gtg-prod.s3-eu-west-1.amazonaws.com/images/website/icon_default_profile_upload.jpg"
                                    alt="Image"
                                />{' '}
                                <br />+ {i18n.t('upload:uploadImageWait_addImageProfile')}
                            </a>
                        </p>
                    )}
                </div>
            );
        }

        const idToolBar = `tool-bar-${this.props.image.id}`;

        return (
            <div
                className="div-image"
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <img src={this.props.image.url} alt="" onClick={this.handleClick} />
                {this.props.isVerified ? <i className="badge-verified badge-verified-lg"> </i> : ''}
                <div className="tool-bar" id={idToolBar}>
                    <span className="flip-button">
                        <span className="icon-ic-flip-left" aria-hidden="true" onClick={this.rotateLeft}>
                            {' '}
                        </span>
                        <span className="icon-ic-flip-right" aria-hidden="true" onClick={this.rotateRight}>
                            {' '}
                        </span>
                    </span>
                    <span className="flip-loading">
                        <img
                            className="loader"
                            src={`${Utils.getCloudfrontDomain()}/images/website/loader-yellow.svg`}
                            alt={i18n.t('common:loading')}
                        />
                    </span>
                </div>
                <div className="tool-bar bottom" onClick={this.handleClick}>
                    <span>{i18n.t('upload:uploadImageWait_clickToUpdateImage')}</span>
                </div>
            </div>
        );
    }
}
