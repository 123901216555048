import PropTypes from 'prop-types';
import React from 'react';

import i18n from '../../../i18n';

import Transfert from '../../../models/Transfert';

import UserImage from '../../user/UserImage';

export default class Verification extends React.PureComponent {
    static propTypes = {
        user: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        onClose: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.popupRef = React.createRef();
    }

    componentDidMount() {
        // disable links on user block
        $(this.popupRef.current)
            .find('.user-block a')
            .css({ cursor: 'default' })
            .on('click', (e) => {
                e.preventDefault();
            });
    }

    render() {
        const verificationBtnText = i18n.t('common:verify_me');

        return (
            <section className="popup-verification" ref={this.popupRef}>
                <header className="modal-header">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.props.onClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h1 className="modal-title">{this.props.title}</h1>
                </header>
                <div className="modal-body">
                    <header>
                        <i className="badge-verified badge-verified-xl"></i>
                        <div className="title">
                            <h1>{i18n.t('common:become_verified_member_title')}</h1>
                            <h2>{i18n.t('common:become_verified_member_subtitle')}</h2>
                        </div>
                    </header>
                    <div className="row gray-band">
                        <div className="col-sm-6">
                            <h3>{i18n.t('common:why_verification')}</h3>
                            <div className="user-block" itemScope itemType="http://schema.org/Person">
                                <UserImage user={this.props.user} verified />
                                <span className="user-name" itemProp="name">
                                    {this.props.user.first_name}
                                </span>
                            </div>
                            <ul>
                                <li>{i18n.t('common:verification_advantages_1')}</li>
                                <li>{i18n.t('common:verification_advantages_2')}</li>
                                <li>{i18n.t('common:verification_advantages_3')}</li>
                                <li>
                                    {i18n.t('common:verification_advantages_4_value', {
                                        gp: Transfert.MOTIVES_VALUE[Transfert.MOTIVE_PROFILE_VERIFIED]
                                    })}
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-6">
                            <h3>{i18n.t('common:verification_today')}</h3>
                            <ol>
                                <li>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: i18n.t('common:verification_upload_step_1', {
                                                link: i18n.t('user:user.verif.url')
                                            })
                                        }}
                                    />
                                </li>
                                <li>
                                    <span>{i18n.t('common:verification_upload_step_2')}</span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <footer className="modal-footer">
                    <div className="row">
                        <div className="col-sm-7 col-md-8">
                            <p>{i18n.t('common:join_guesttoguest')}</p>
                        </div>
                        <div className="col-sm-5 col-md-4 text-right">
                            <a
                                href={i18n.t('user:user.verif.url')}
                                className="btn btn-block btn-primary pull-right"
                            >
                                {verificationBtnText}
                            </a>
                        </div>
                    </div>
                </footer>
            </section>
        );
    }
}
