import UserApi from '../api/User';
import HomeApi from '../api/Home';

import PaymentMethod from '../models/PaymentMethod';
import { setPaymentMethodsFetchingStatus } from './loading';
import { fetchingStatuses } from '../reducers/loading';

export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';
export const ERROR_ADDING_PAYMENT_METHODS = 'ERROR_ADDING_PAYMENT_METHODS';
export const RECEIVE_CHANGE_PAYMENT_METHOD = 'RECEIVE_CHANGE_PAYMENT_METHOD';
export const REQUEST_CHANGE_PAYMENT_METHOD = 'REQUEST_CHANGE_PAYMENT_METHOD';

export function requestChangeDefaultPayment() {
    return {
        type: REQUEST_CHANGE_PAYMENT_METHOD
    };
}

export function setPaymentMethods(paymentMethods) {
    return {
        type: SET_PAYMENT_METHODS,
        payload: paymentMethods
    };
}

export function deletePaymentMethod(paymentMethod) {
    return {
        type: REMOVE_PAYMENT_METHOD,
        payload: paymentMethod
    };
}

export function changeDefaultPayment(paymentMethods) {
    return {
        type: RECEIVE_CHANGE_PAYMENT_METHOD,
        payload: paymentMethods
    };
}

export function errorAddingPaymentMethod(error, code) {
    return {
        type: ERROR_ADDING_PAYMENT_METHODS,
        payload: {
            error,
            code
        }
    };
}

function mapPaymentMethods(response) {
    if (response.stripe && response.stripe.sources) {
        response.stripe.sources = response.stripe.sources.map((source) => new PaymentMethod(source));
    }
    if (response.braintree && response.braintree.sources) {
        response.braintree.sources = response.braintree.sources.map((source) => new PaymentMethod(source));
    }
    return response;
}

export function fetchPaymentMethods() {
    return (dispatch) => {
        dispatch(setPaymentMethodsFetchingStatus(fetchingStatuses.STARTED));

        return UserApi.getPaymentMethod().then((response) => {
            response = mapPaymentMethods(response);
            dispatch(setPaymentMethods(response));
            dispatch(setPaymentMethodsFetchingStatus(fetchingStatuses.FINISHED));
        });
    };
}

function addPaymentMethod(data, paymentAction, isFunnelCollection, type, couponName) {
    return (dispatch) =>
        paymentAction(...data).then(
            (response) => {
                if (isFunnelCollection) {
                    const dataPaymentMethod = {
                        'payment-method': {
                            type: type === 'credit-card' ? 'stripe' : 'braintree',
                            payment_id:
                                type === 'paypal'
                                    ? response.braintree.sources[0].id
                                    : response.stripe.default_source
                        },
                        ...(couponName && { coupon_name: couponName })
                    };
                    HomeApi.pingCollectionHomePaymentMethod(dataPaymentMethod);
                }
                response = mapPaymentMethods(response);
                dispatch(setPaymentMethods(response));
            },
            (response) => {
                const errorAction = dispatch(
                    errorAddingPaymentMethod(response.responseJSON, response.status)
                );
                return Promise.reject(errorAction);
            }
        );
}

export function addCreditCard(stripeToken, isFunnelCollection = false, type, couponName = false) {
    return addPaymentMethod([stripeToken], UserApi.addCreditCard, isFunnelCollection, type, couponName);
}

export function addPaypal(
    braintreeNonce,
    braintreeDeviceData,
    isFunnelCollection = false,
    type,
    couponName = false
) {
    return addPaymentMethod(
        [braintreeNonce, braintreeDeviceData],
        UserApi.addPaypal,
        isFunnelCollection,
        type,
        couponName
    );
}

export function removePaymentMethod(cardId) {
    return (dispatch) =>
        UserApi.removePaymentMethod(cardId).then((response) => {
            dispatch(deletePaymentMethod(cardId));
            response = mapPaymentMethods(response);
            dispatch(setPaymentMethods(response));
        });
}

export function changePaymentMethod(cardId, type) {
    return (dispatch) => {
        dispatch(requestChangeDefaultPayment());
        return UserApi.changeDefaultPayment(cardId, type).then((response) => {
            response = mapPaymentMethods(response);
            dispatch(changeDefaultPayment(response));
        });
    };
}
