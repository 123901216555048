import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';

export default class Pagination extends React.Component {
    static propTypes = {
        page: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,
        nbresults: PropTypes.number,
        gotoPage: PropTypes.func.isRequired,
        withButtons: PropTypes.bool
    };

    static defaultProps = {
        nbresults: 0,
        withButtons: true
    };

    constructor(props) {
        super(props);

        this.gotoPage = this.gotoPage.bind(this);
        this.pagePrev = this.pagePrev.bind(this);
        this.pageNext = this.pageNext.bind(this);
    }

    gotoPage(event) {
        event.preventDefault();
        const page = parseInt(event.target.getAttribute('data-page'), 10);
        this.props.gotoPage(page);
        // remove focus for fix style issue when history navigation
        event.target.blur();
    }

    pageNext(event) {
        event.preventDefault();
        this.props.gotoPage(this.props.page + 1);
    }

    pagePrev(event) {
        event.preventDefault();
        this.props.gotoPage(this.props.page - 1);
    }

    getPagesList() {
        return _.filter(
            _.range(1, this.getNbPages() + 1),
            (page) => page >= this.props.page - 5 && page <= this.props.page + 5
        );
    }

    getNbPages() {
        return Math.ceil(this.props.nbresults / this.props.limit);
    }

    haveToPaginate() {
        return this.props.nbresults / this.props.limit > 1;
    }

    render() {
        if (!this.haveToPaginate()) {
            return <div className="no-pagination"></div>;
        }
        const current = Math.max(1, Math.min(this.props.page, this.getNbPages()));
        return (
            <div className="pagination search-pagination">
                <a
                    href="#"
                    className="btn btn-default btn-previous"
                    rel="prev"
                    onClick={this.pagePrev}
                    style={{
                        visibility: this.props.withButtons && current > 1 ? 'visible' : 'hidden'
                    }}
                >
                    {i18n.t('search:pagination.previous')}
                </a>

                <span className="pages">
                    {this.getPagesList().map((i) => (
                        <a
                            key={i}
                            href="#"
                            className={i === current ? 'active' : ''}
                            onClick={this.gotoPage}
                            data-page={i}
                        >
                            {i}
                        </a>
                    ))}
                </span>

                <a
                    href="#"
                    className="btn btn-default btn-next"
                    rel="next"
                    onClick={this.pageNext}
                    style={{
                        visibility:
                            this.props.withButtons && current < this.getNbPages() ? 'visible' : 'hidden'
                    }}
                >
                    {i18n.t('search:pagination.next')}
                </a>
            </div>
        );
    }
}
