import Analytics from '../utils/analytics';

export default class CollectionFunnelEvents {
    static funnelStart() {
        const eventData = {
            funnel_name: 'application_collection',
            funnel_step: 'submit_step1_application_collection',
            collection: true
        };
        Analytics.trackGTM('funnel_start', { event_data: eventData });
    }

    static funnelProgress(step) {
        const eventData = {
            funnel_name: 'application_collection',
            funnel_step: `submit_step${step}_application_collection`,
            collection: true
        };
        Analytics.trackGTM('funnel_progress', { event_data: eventData });
    }

    static funnelCompleted() {
        const eventData = {
            funnel_name: 'application_collection',
            funnel_step: 'submit_step7_application_collection',
            collection: true
        };
        Analytics.trackGTM('funnel_completed', { event_data: eventData });
    }
}
