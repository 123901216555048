import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../i18n';

export const getValidationFields = (fields, state, validation) => {
    const auxStateValidation = { ...validation };

    fields.forEach((field) => {
        auxStateValidation[field.name] = field.validation.every((validator) => {
            const regexp = new RegExp(validator?.regexp);
            return regexp.test(state[field.name]) && state[field.name].trim() !== '';
        });
    });
    return auxStateValidation;
};

const generateForm = (fields, state, setState, renderInput, validation, legalRegistrationName) => {
    return fields.map((field) => {
        switch (field.type) {
            case 'text':
                return renderInput(field, state, setState, validation, legalRegistrationName);
            default:
                return null;
        }
    });
};

export const getTextLegaleRegistrations = (baseKeyLocalise, legalRegistrationName, fieldName = '') => {
    const keysToTry = [
        `${baseKeyLocalise}-${fieldName}-${legalRegistrationName}`,
        `${baseKeyLocalise}-${fieldName}`,
        `${baseKeyLocalise}-${legalRegistrationName}`,
        `${baseKeyLocalise}`
    ];

    const existingKey = keysToTry.find((key) => i18n.exists(key));
    return existingKey ? i18n.t(existingKey) : i18n.t(baseKeyLocalise);
};

const renderInput = (data, state, setState, validation, legalRegistrationName) => {
    const { name } = data;
    const value = state[name];
    const showError = validation[name] === false;

    return (
        <>
            <label>
                {getTextLegaleRegistrations('home:legal-registration-label', legalRegistrationName, name)}
            </label>
            <input
                value={value}
                style={{ display: 'block' }}
                onChange={(e) => setState({ ...state, [name]: e.target.value })}
                className={showError ? 'border-error-input' : ''}
            />
            {showError && (
                <p className="text-error-input">
                    <span className="icon-error-registration" />{' '}
                    {getTextLegaleRegistrations('home:legal-registration-error', legalRegistrationName, name)}
                </p>
            )}
            <p className="text-limit-caracter">
                {getTextLegaleRegistrations('home:legal-registration-helper', legalRegistrationName, name)}
            </p>
        </>
    );
};

const LegalRegistration = ({
    onSubmit,
    fields,
    state,
    setState,
    validation,
    canSubmit,
    renderSubmitBtn = false,
    hideBottom = false,
    sending = false,
    legalRegistrationName = ''
}) => {
    return (
        <div className={'wrapper-container'}>
            <h1>{getTextLegaleRegistrations('home:legal-registration-title', legalRegistrationName)}</h1>
            <p className="legal-registration-wording">
                {getTextLegaleRegistrations('home:legal-registration-presentation', legalRegistrationName)}
            </p>
            <div className={'wrapper-form'}>
                <div className={'wrapper-generated-form'}>
                    {generateForm(fields, state, setState, renderInput, validation, legalRegistrationName)}
                </div>
                {renderSubmitBtn && (
                    <button
                        type="submit"
                        className={classNames('btn btn-primary btn-ajax btn-continue', {
                            disabled: !canSubmit,
                            sending
                        })}
                        onClick={onSubmit}
                    >
                        {getTextLegaleRegistrations('common:submit', legalRegistrationName)}
                    </button>
                )}
            </div>
            <div
                className="text-issue"
                dangerouslySetInnerHTML={{
                    __html: getTextLegaleRegistrations(
                        'home:legal-registration-support-text',
                        legalRegistrationName
                    )
                }}
            ></div>
            {!hideBottom && (
                <div className="container-bottom">
                    <h4>
                        {getTextLegaleRegistrations(
                            'home:legal-registration-faq-question',
                            legalRegistrationName
                        )}
                    </h4>
                    <p
                        className="faq-page"
                        dangerouslySetInnerHTML={{
                            __html: getTextLegaleRegistrations(
                                'home:legal-registration-faq-answer',
                                legalRegistrationName
                            )
                        }}
                    ></p>
                </div>
            )}
        </div>
    );
};

LegalRegistration.propTypes = {
    fields: PropTypes.array,
    setState: PropTypes.func,
    state: PropTypes.object,
    validation: PropTypes.object,
    canSubmit: PropTypes.bool,
    renderSubmitBtn: PropTypes.bool,
    onSubmit: PropTypes.func,
    hideBottom: PropTypes.bool,
    sending: PropTypes.bool,
    legalRegistrationName: PropTypes.string
};

export default LegalRegistration;
