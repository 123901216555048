import Model from './Model';
import Utils from '../utils/utils';

export default class Availability extends Model {
    static UNAVAILABLE = {
        id: -1,
        type: 'UNAVAILABLE'
    };

    static AVAILABLE = {
        id: 0,
        type: 'AVAILABLE',
        value: 0,
        className: 'available',
        title: 'home:disponibility.any_type',
        selectPopupText: 'home:availability_popup.any_type',
        caption: 'home:disponibility.any_type.caption',
        captionGuest: 'home:disponibility.any_type.caption_guest',
        description: 'home:disponibility.available.description',
        descriptionGuest: 'home:disponibility.available.description_guest'
    };

    static NON_RECIPROCAL = {
        id: 1,
        type: 'NON_RECIPROCAL',
        value: 1,
        className: 'non-reciprocal',
        title: 'home:disponibility.non_reciprocal',
        selectPopupText: 'home:availability_popup.non_reciprocal',
        caption: 'home:disponibility.non_reciprocal.caption',
        captionGuest: 'home:disponibility.non_reciprocal.caption_guest',
        description: 'home:disponibility.look_for_guests.description',
        descriptionGuest: 'home:disponibility.look_for_guests.description_guest'
    };

    static BOOKED = {
        id: 2,
        type: 'BOOKED',
        className: 'booked',
        title: 'home:disponibility.booked',
        caption: 'home:disponibility.booked_period.caption',
        captionGuest: 'home:disponibility.booked_period.caption',
        description: 'home:disponibility.booked.description',
        descriptionGuest: 'home:disponibility.booked.description_guest'
    };

    static RECIPROCAL = {
        id: 3,
        type: 'RECIPROCAL',
        value: 2,
        className: 'reciprocal',
        title: 'home:disponibility.reciprocal',
        selectPopupText: 'home:availability_popup.reciprocal',
        caption: 'home:disponibility.reciprocal.caption',
        captionGuest: 'home:disponibility.reciprocal.caption_guest'
    };

    static RESERVED = {
        id: 5,
        type: 'RESERVED',
        value: 5,
        className: 'reserved',
        title: 'home:disponibility.reserved_type',
        selectPopupText: 'home:availability_popup.reserved'
    };

    static availabilitiesTypes = [
        Availability.AVAILABLE,
        Availability.RECIPROCAL,
        Availability.NON_RECIPROCAL,
        Availability.BOOKED,
        Availability.RESERVED
    ];

    static availableTypes = [
        Availability.AVAILABLE.type,
        Availability.RECIPROCAL.type,
        Availability.NON_RECIPROCAL.type
    ];

    parse(attributes, options) {
        attributes = super.parse(attributes, options);

        // Convert disponibilites to local time
        if (attributes.hasOwnProperty('start_on')) {
            attributes.start_on = Utils.getDayAtMidnightLocalTime(attributes.start_on);
        }
        if (attributes.hasOwnProperty('end_on')) {
            attributes.end_on = Utils.getDayAtMidnightLocalTime(attributes.end_on);
        }

        return attributes;
    }

    isSame(availability) {
        if (
            this.get('start_on').isSame(availability.get('start_on')) &&
            this.get('end_on').isSame(availability.get('end_on')) &&
            this.get('type') === availability.get('type')
        ) {
            return true;
        }
        return false;
    }

    range() {
        return moment.range(this.get('start_on'), this.get('end_on'));
    }

    clone() {
        const params = {
            start_on: this.get('start_on').format('YYYY-MM-DD'),
            end_on: this.get('end_on').format('YYYY-MM-DD'),
            type: this.get('type')
        };

        if (this.get('details')) {
            params.details = this.get('details');
        }

        return new Availability(params);
    }
}
