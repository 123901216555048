import PropTypes from 'prop-types';
import React from 'react';
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

import Home from './Home';
import HomeFake from '../home/HomeFake';

export default class HomeCarousel extends React.Component {
    static propTypes = {
        homes: PropTypes.array,
        displayFavoritePlaceholder: PropTypes.bool,
        noSearch: PropTypes.bool,
        isLogged: PropTypes.bool,
        fakes: PropTypes.number
    };

    static defaultProps = {
        homes: [],
        fakes: 0
    };

    owlCarouselOptions = {
        margin: 20,
        responsive: {
            0: {
                items: 1
            },
            750: {
                items: 2
            },
            970: {
                items: 3
            },
            1170: {
                items: 4
            }
        },
        startPosition: 1,
        slideBy: 'page',
        dotsContainer: '.homelist-dots'
    };

    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        this.carouselContainerRef = React.createRef();
        this.carouselPrev = this.carouselPrev.bind(this);
        this.carouselNext = this.carouselNext.bind(this);
    }

    componentDidMount() {
        if (this.carouselRef.current) {
            $(this.carouselRef.current).owlCarousel(this.owlCarouselOptions);
        }
    }

    UNSAFE_componentWillUpdate() {
        $(this.carouselRef.current).trigger('destroy.owl.carousel');
    }

    componentDidUpdate() {
        if (this.carouselRef.current) {
            // Owl tends to delete the dots container when destroyed so we need to recreate it
            if ($(this.carouselContainerRef.current).find('.homelist-dots').length <= 0) {
                $(this.carouselContainerRef.current)
                    .find('.owl-dots')
                    .append('<div class="homelist-dots"></div>');
            }

            $(this.carouselRef.current).owlCarousel(this.owlCarouselOptions);
        }
    }

    carouselPrev() {
        $(this.carouselRef.current).trigger('prev.owl.carousel');
    }

    carouselNext() {
        $(this.carouselRef.current).trigger('next.owl.carousel');
    }

    render() {
        return (
            <div ref={this.carouselContainerRef} className="homelist-carousel">
                <div className="homelist-carousel-container">
                    <div
                        onClick={this.carouselPrev}
                        className="fa fa-angle-left homelist-carousel-angle homelist-carousel-angle-left"
                        aria-hidden="true"
                    ></div>
                    <div ref={this.carouselRef} className="owl-carousel owl-theme">
                        {(() => {
                            if (this.props.homes.length > 0) {
                                return this.props.homes.map((home) => (
                                    <Home
                                        key={home.id}
                                        home={home}
                                        displayLocation={true}
                                        displayFavoritePlaceholder={this.props.displayFavoritePlaceholder}
                                        noSearch={this.props.noSearch}
                                        isLogged={this.props.isLogged}
                                    />
                                ));
                            } else {
                                return _.range(0, this.props.fakes).map((i) => <HomeFake key={i} />);
                            }
                        })()}
                    </div>
                    <div
                        onClick={this.carouselNext}
                        className="fa fa-angle-right homelist-carousel-angle homelist-carousel-angle-right"
                        aria-hidden="true"
                    ></div>
                </div>
                <div className="owl-theme">
                    <div className="owl-dots">
                        <div className="homelist-dots"></div>
                    </div>
                </div>
            </div>
        );
    }
}
