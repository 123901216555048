import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchLoyalty, fetchUser, fetchNumberExchangeReady } from '../../actions/user';
import { fetchHomes } from '../../actions/homes';
import { fetchRewards } from '../../actions/rewards';
import { fetch as fetchRoulette } from '../../actions/roulette';
import { fetchUserGroups } from '../../actions/userGroups';
import { fetchAvailabilities } from '../../actions/availabilities';

import Group from '../../models/Group';
import Home from '../../models/Home';
import User from '../../models/User';
import { fetchPaymentMethods } from '../../actions/paymentMethods';

export class UserContext extends React.PureComponent {
    static propTypes = {
        children: PropTypes.element,
        user: PropTypes.instanceOf(User),
        homes: PropTypes.arrayOf(PropTypes.instanceOf(Home)),
        friends: PropTypes.arrayOf(PropTypes.instanceOf(User)),
        groups: PropTypes.arrayOf(PropTypes.instanceOf(Group)),
        fetchUser: PropTypes.func.isRequired,
        fetchNumberExchangeReady: PropTypes.func.isRequired,
        fetchLoyalty: PropTypes.func.isRequired,
        fetchPaymentMethods: PropTypes.func.isRequired,
        fetchRewards: PropTypes.func.isRequired,
        fetchRoulette: PropTypes.func.isRequired,
        fetchHomes: PropTypes.func.isRequired,
        fetchUserGroups: PropTypes.func.isRequired,
        fetchAvailabilities: PropTypes.func.isRequired
    };

    static childContextTypes = {
        user: PropTypes.instanceOf(User),
        homes: PropTypes.arrayOf(PropTypes.instanceOf(Home)),
        friends: PropTypes.arrayOf(PropTypes.instanceOf(User)),
        groups: PropTypes.arrayOf(PropTypes.instanceOf(Group))
    };

    getChildContext() {
        return {
            user: this.props.user,
            homes: this.props.homes,
            friends: this.props.friends,
            groups: this.props.groups
        };
    }

    componentDidMount() {
        this.preload();
    }

    preload() {
        return Promise.all([
            // preload user
            this.props.fetchUser().then((user) => {
                // load user related extra data
                this.props.fetchRoulette(user.id, {
                    'fillingpanel:version': 1,
                    'flexibility:version': 1,
                    'signup:split-step1': 1,
                    'search:additional-search': 1,
                    filteredSearch: 1
                });
                this.props.fetchRewards();
                // preload user's groups
                this.props.fetchUserGroups(user.id);
                this.props.fetchPaymentMethods();
                this.props.fetchLoyalty(user.id);
                this.props.fetchNumberExchangeReady();
            }),
            // preload user's homes
            this.props.fetchHomes().then((homes) => {
                // fetch homes's availabilities
                homes.forEach((home) => {
                    this.props.fetchAvailabilities(home.id);
                });
            })
        ]);
    }

    render() {
        if (this.props.children) {
            return React.Children.only(this.props.children);
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    homes: state.homes,
    friends: state.friends.users,
    groups: state.user && state.user.id ? state.userGroups[state.user.id] : null
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: bindActionCreators(fetchUser, dispatch),
    fetchNumberExchangeReady: bindActionCreators(fetchNumberExchangeReady, dispatch),
    fetchLoyalty: bindActionCreators(fetchLoyalty, dispatch),
    fetchPaymentMethods: bindActionCreators(fetchPaymentMethods, dispatch),
    fetchRewards: bindActionCreators(fetchRewards, dispatch),
    fetchRoulette: bindActionCreators(fetchRoulette, dispatch),
    fetchHomes: bindActionCreators(fetchHomes, dispatch),
    fetchUserGroups: bindActionCreators(fetchUserGroups, dispatch),
    fetchAvailabilities: bindActionCreators(fetchAvailabilities, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserContext);
