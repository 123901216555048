import {
    LOAD_SAVED_SEARCHES,
    ADD_SAVED_SEARCH,
    PATCH_SAVED_SEARCH,
    DELETE_SAVED_SEARCH
} from '../actions/savedSearches';

export default function savedSearches(state = [], { type, payload }) {
    switch (type) {
        case PATCH_SAVED_SEARCH:
            return state.map((search) => {
                if (search.id == payload.id) {
                    return payload;
                }
                return search;
            });
        case ADD_SAVED_SEARCH:
            return state.concat(payload);
        case DELETE_SAVED_SEARCH:
            return state.filter((s) => s.id != payload.id);
        case LOAD_SAVED_SEARCHES:
            return payload;
        default:
            return state;
    }
}
