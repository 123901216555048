import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import Feature from '../../../models/Feature';
import InputOption from '../../form/InputOption';

const InputRulesList = ({ values, onChange, className }) => (
    <div className="row feature-list">
        {Feature.FEATURES.filter(
            (item) => item.category === Feature.FAVOR || item.category === Feature.RULE
        ).map((feature) => {
            const category = Feature.AMENITIES_LABEL[feature.category];
            return (
                <div className={className} key={feature.icon}>
                    <InputOption
                        type="checkbox"
                        label={i18n.t(`home:feature-${category}-${feature.icon}`)}
                        icon={feature.icon}
                        name={`feature[${category}][]`}
                        id={`feature-${feature.icon}`}
                        value={feature.value}
                        checked={Boolean(values[category] & feature.value)}
                        onChange={onChange}
                        trackingData="home_rules_changed"
                    />
                </div>
            );
        })}
    </div>
);

InputRulesList.propTypes = {
    values: PropTypes.shape({
        rule: PropTypes.number,
        favor: PropTypes.number
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string
};

export default InputRulesList;
