import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Provider } from './AnalyticsContext';
import { refreshToken } from '../../actions/auth';
import Analytics from '../../utils/analytics';

export class AnalyticsProvider extends React.Component {
    static propTypes = {
        accessToken: PropTypes.string,
        children: PropTypes.element,
        eventsApiOptions: PropTypes.shape({
            timeout: PropTypes.number
        }),
        expiresAt: PropTypes.number,
        refreshToken: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.accessTokenProvider = this.accessTokenProvider.bind(this);
        if (!window.analytics) {
            window.analytics = new Analytics(this.accessTokenProvider, props.eventsApiOptions);
        }
    }

    accessTokenProvider() {
        if (this.isTokenExpired()) {
            /* eslint-disable camelcase */
            return this.props.refreshToken().then(({ payload: { access_token } }) => access_token);
            /* eslint-enable */
        }
        return Promise.resolve(this.props.accessToken);
    }

    /**
     * @param {number} [safeDelay=10] - Safety delay before token expiration
     */
    isTokenExpired(safeDelay = 10) {
        return new Date().getTime() >= this.props.expiresAt - safeDelay;
    }

    render() {
        return <Provider value={window.analytics}>{this.props.children}</Provider>;
    }
}

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken,
    expiresAt: state.auth.expiresAt
});

const mapDispatchToProps = (dispatch) => ({
    refreshToken: bindActionCreators(refreshToken, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsProvider);
