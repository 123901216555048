import Common from './Common';

export default class Loyalty {
    static async fetchBadge(userId) {
        const params = Common.params({
            method: 'get',
            url: `/v1/achievement/${userId}`
        });
        const response = await Common.request(params);
        return response.find((loyalty) => loyalty.name === 'loyalty');
    }
}
