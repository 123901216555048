import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

const Accordion = (props) => (
    <div className="panel panel-default accordion">
        <a data-toggle="collapse" href={`#${props.id}`}>
            <div className="panel-heading">
                <div className="panel-title">{i18n.t(props.title)}</div>
                <i className="fa fa-angle-down down-arrow" />
            </div>
        </a>
        <div id={props.id} className="panel-collapse collapse">
            <div
                className="panel-body"
                dangerouslySetInnerHTML={{
                    __html: i18n.t(props.description, {
                        price: props.price,
                        symbol: props.symbol
                    })
                }}
            />
        </div>
    </div>
);

Accordion.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired
};

export default Accordion;
