import {
    SEE_MORE_LP,
    SET_FORM_DATA,
    SET_EXTERNAL_LINK,
    SET_REMARKS,
    SET_OPTIONAL
} from '../actions/collection';

export default function collection(state = {}, { type, payload }) {
    switch (type) {
        case SEE_MORE_LP:
            return Object.assign({}, state, {
                redirectToMyPlan: false,
                redirectToSearch: true
            });
        case SET_FORM_DATA:
            return Object.assign({}, state, {
                formCollection: payload
            });
        case SET_EXTERNAL_LINK:
            return {
                ...state,
                externalLink: payload['external-link'] || payload.external_link
            };
        case SET_REMARKS:
            return {
                ...state,
                remarks: payload.remarks
            };
        case SET_OPTIONAL:
            return {
                ...state,
                optional: payload
            };
        default:
            return state;
    }
}
