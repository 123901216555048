import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { setTourAsViewed } from '../../actions/user';

import Checkbox from '../widget/Checkbox';
import i18n from '../../i18n';
import TermsOfUsePopupIframe from './TermsOfUsePopupIframe';

export const termsOfUseTourName = 'terms-of-use-update-march-2023';

export class TermsOfUsePopup extends React.PureComponent {
    static propTypes = {
        hasViewedTermsOfUseTour: PropTypes.bool,
        setTourAsViewed: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            mode: 'main',
            show: false,
            loading: false,
            checkbox: false,
            dataCheckbox: false,
            error: false,
            checkboxTooltip: false
        };

        this.triggerPopup = this.triggerPopup.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onDataCheckboxChange = this.onDataCheckboxChange.bind(this);
        this.onApprove = this.onApprove.bind(this);
        this.onTermsOfUseLinkClick = this.onTermsOfUseLinkClick.bind(this);
        this.onPrivacyPolicyLinkClick = this.onPrivacyPolicyLinkClick.bind(this);
        this.backToMainView = this.backToMainView.bind(this);

        this.loadedIframes = {};
    }

    triggerPopup() {
        this.setState({
            show: true,
            error: false
        });
    }

    onCheckboxChange() {
        this.setState({
            checkbox: !this.state.checkbox,
            checkboxTooltip: false,
            error: false
        });
    }

    onDataCheckboxChange() {
        this.setState({
            dataCheckbox: !this.state.dataCheckbox,
            error: false
        });
    }

    onApprove() {
        if (this.state.checkbox && this.state.dataCheckbox) {
            this.setState({
                loading: true,
                error: false,
                checkboxTooltip: false
            });
            return this.props.setTourAsViewed(termsOfUseTourName).then(
                () => {
                    this.setState({
                        show: false,
                        loading: false
                    });
                },
                () => {
                    // On error, just remove the loader
                    this.setState({
                        loading: false,
                        error: true
                    });
                }
            );
        } else {
            this.setState({
                checkboxTooltip: true
            });
        }
    }

    onTermsOfUseLinkClick(e) {
        e.preventDefault();
        if (this.state.loading) {
            return false;
        }
        this.setState({
            mode: 'terms-of-use'
        });
        this.loadIframe(i18n.t('url:terms-of-use'));
    }

    onPrivacyPolicyLinkClick(e) {
        e.preventDefault();
        if (this.state.loading) {
            return false;
        }
        this.setState({
            mode: 'privacy-policy'
        });
        this.loadIframe(i18n.t('url:privacy-policy'));
    }

    loadIframe(url) {
        this.setState({
            iframeData: null
        });

        // Cache system
        if (this.loadedIframes[url]) {
            this.setState({
                iframeData: this.loadedIframes[url]
            });
            return;
        }

        // Load CMS page for the iFrame
        $.ajax({
            type: 'GET',
            url,
            contentType: 'application/json',
            beforeSend: (xhr) => {
                xhr.setRequestHeader('X-Iframe-Template', '1');
            }
        }).then(
            (data) => {
                if (data) {
                    this.loadedIframes[url] = data;
                    this.setState({
                        iframeData: data
                    });
                }
            },
            () => {
                this.setState({
                    mode: 'main'
                });
            }
        );
    }

    backToMainView() {
        this.setState({
            mode: 'main'
        });
    }

    renderIframe() {
        return (
            <Modal.Body>
                <TermsOfUsePopupIframe data={this.state.iframeData} back={this.backToMainView} />
            </Modal.Body>
        );
    }

    renderMainBody() {
        const termsOfUseLink = (
            <a key="termsOfUseLink" href="" onClick={this.onTermsOfUseLinkClick}>
                link
            </a>
        );
        const privacyPolicyLink = (
            <a key="privacyPolicyLink" href="" onClick={this.onPrivacyPolicyLinkClick}>
                link
            </a>
        );

        return (
            <Modal.Body>
                <h1>{i18n.t('common:terms-of-use-popup.title')}</h1>
                <p>
                    <Trans
                        i18nKey="common:terms-of-use-popup.description"
                        components={[termsOfUseLink, privacyPolicyLink]}
                        i18n={i18n}
                    />
                </p>
                <hr />
                <div className="modal-terms-of-use-checkbox">
                    <Checkbox
                        name="terms-accepted"
                        id="terms-accepted"
                        checked={this.state.checkbox}
                        onChange={this.onCheckboxChange}
                    />
                    {this.state.checkboxTooltip && (
                        <div className="help-block">
                            {i18n.t('common:terms-of-use-popup.checkbox.tooltip')}
                        </div>
                    )}
                    <label htmlFor="terms-accepted">{i18n.t('common:terms-of-use-popup.checkbox')}</label>
                </div>
                <div className="modal-data-migration-checkbox">
                    <Checkbox
                        name="data-terms-accepted"
                        id="data-terms-accepted"
                        checked={this.state.dataCheckbox}
                        onChange={this.onDataCheckboxChange}
                    />
                    <label htmlFor="data-terms-accepted">
                        <Trans
                            i18nKey="common:data-terms-of-use-popup.description"
                            components={[privacyPolicyLink]}
                            i18n={i18n}
                        />
                    </label>
                </div>
                {this.state.error && (
                    <p className="modal-terms-of-use-error">{i18n.t('common:error-retry')}</p>
                )}
                <div className="modal-terms-of-use-actions">
                    <a
                        className="btn btn-lg btn-white"
                        href={i18n.t('url:logout.url')}
                        disabled={this.state.loading}
                    >
                        {i18n.t('common:logout')}
                    </a>
                    <button
                        className={classNames('btn btn-lg btn-primary btn-ajax expand', {
                            sending: this.state.loading,
                            disabled: !(this.state.checkbox && this.state.dataCheckbox)
                        })}
                        type="button"
                        onClick={this.onApprove}
                    >
                        {i18n.t('common:accept')}
                    </button>
                </div>
            </Modal.Body>
        );
    }

    render() {
        const { hasViewedTermsOfUseTour } = this.props;

        if (hasViewedTermsOfUseTour !== null && !hasViewedTermsOfUseTour) {
            this.triggerPopup();
        }

        return (
            <Modal dialogClassName="modal-terms-of-use" show={this.state.show}>
                {(() => {
                    switch (this.state.mode) {
                        case 'terms-of-use':
                        case 'privacy-policy':
                            return this.renderIframe();
                        default:
                            return this.renderMainBody();
                    }
                })()}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    let hasViewedTermsOfUseTour = null;

    if (state?.user?.get('tours')) {
        const hasTours = state?.user?.get('tours').map((t) => t.tour);
        if (hasTours.includes(termsOfUseTourName)) {
            hasViewedTermsOfUseTour = hasTours.includes(termsOfUseTourName);
        } else {
            hasViewedTermsOfUseTour = false;
        }
    }

    return {
        hasViewedTermsOfUseTour
    };
};

const mapDispatchToProps = (dispatch) => ({
    setTourAsViewed: bindActionCreators(setTourAsViewed, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUsePopup);
