import { SEARCH_PROCESSING, SEARCH_RESULTS, SEARCH_SET_DISPLAY_ALERTS } from '../actions/search';

export const defaultState = {
    main: {
        queryString: '',
        placeId: null,
        filters: {},
        offset: 0,
        limit: 10,
        results: null,
        resultsCount: null,
        resultsStats: null,
        bounds: null
    },
    additional: {
        queryString: '',
        placeId: null,
        filters: {},
        offset: 0,
        limit: 10,
        results: null,
        resultsCount: null,
        resultsStats: null,
        bounds: null
    },
    lastSearchId: null,
    displayAlertsComponent: true
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SEARCH_PROCESSING: {
            const searchType = action.isAdditional ? 'additional' : 'main';
            const newValues = Object.assign(
                {},
                {
                    queryString: action.queryString,
                    placeId: action.placeId || null,
                    filters: Object.assign({}, action.filters),
                    offset: action.offset || 0, // _.isNumber(action.offset) ? action.offset : state.offset,
                    limit: _.isNumber(action.limit) ? action.limit : state[searchType].limit,
                    results: action.hasOwnProperty('results') ? action.results : state[searchType].results
                }
            );

            const newSearch = JSON.parse(JSON.stringify(state));
            // reset lastSearchId for new queryString
            if (action.queryString != state[searchType].queryString) {
                newSearch.lastSearchId = null;
            } else {
                newSearch.lastSearchId = state.lastSearchId;
            }
            newSearch[searchType] = Object.assign({}, newSearch[searchType], newValues);
            return newSearch;
        }

        case SEARCH_RESULTS: {
            const searchType = action.isAdditional ? 'additional' : 'main';
            const newValues = Object.assign({}, state[searchType], {
                results: action.results,
                resultsCount: action.resultsCount,
                resultsStats: action.resultsStats,
                bounds: action.bounds
            });
            const newSearch = JSON.parse(JSON.stringify(state)); // Deep clone
            newSearch[searchType] = newValues;
            newSearch.lastSearchId = action.lastSearchId;
            return newSearch;
        }

        case SEARCH_SET_DISPLAY_ALERTS: {
            const newState = JSON.parse(JSON.stringify(state));
            newState.displayAlertsComponent = action.payload;
            return newState;
        }

        default:
            return state;
    }
};
