import PropTypes from 'prop-types';
import React from 'react';

import Loading from '../common/Loading';
import MicrosoftTranslator from '../../utils/MicrosoftTranslator';

export default class TranslateContent extends React.PureComponent {
    static propTypes = {
        translations: PropTypes.object,
        content: PropTypes.string,
        toLanguage: PropTypes.string,
        onLoad: PropTypes.func
    };

    constructor(props) {
        super(props);
        const translation = this.getTranslation(props.toLanguage);
        this.state = {
            translation,
            loading: !translation
        };
    }

    componentDidMount() {
        if (!this.state.translation) {
            this.translate(this.props.toLanguage).then((translation) => {
                this.setState({
                    translation,
                    loading: false
                });
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.toLanguage !== this.props.toLanguage) {
            const translation = this.getTranslation(nextProps.toLanguage);
            if (translation) {
                this.setState({
                    translation,
                    loading: false
                });
            } else {
                this.translate(nextProps.toLanguage).then((trans) => {
                    this.setState({
                        translation: trans,
                        loading: false
                    });
                });
            }
        }
    }

    isLoading(loading) {
        this.setState({ loading });
    }

    getTranslation(language) {
        const { translations, content } = this.props;
        // if translation exists already
        if (language && translations[language] && translations[language][content]) {
            return translations[language][content];
        }
        return false;
    }

    getDefaultTranslation() {
        const { translations, content } = this.props;
        // search content in other translations
        const translation = Object.values(translations).find((trans) => typeof trans[content] === 'string');
        if (translation) {
            return translation;
        }
        return false;
    }

    translate(language) {
        const translation = this.getDefaultTranslation();
        const content = translation[this.props.content];
        if (content && language) {
            this.isLoading(true);
            if (typeof this.props.onLoad === 'function') {
                this.props.onLoad(true);
            }
            return MicrosoftTranslator.translate(content, language).then((res) => {
                this.isLoading(false);
                if (typeof this.props.onLoad === 'function') {
                    this.props.onLoad(false);
                }
                return res;
            });
        } else {
            return Promise.resolve(content);
        }
    }

    render() {
        if (this.state.loading) {
            return <Loading />;
        }
        const { translation } = this.state;
        return (
            <React.Fragment>
                {typeof translation === 'string' &&
                    translation.split('\n').map((text, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <span key={`translation-${i}`}>
                            {text}
                            <br />
                        </span>
                    ))}
            </React.Fragment>
        );
    }
}
