import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import i18n from '../../../i18n';
import Utils from '../../../utils/utils';

import UserApi from '../../../api/User';
import { fetchUser } from '../../../actions/user';
import withAnalytics from '../../analytics/withAnalytics';

export class EmailVerification extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            hasCalledApi: false
        };

        this.showBanner = this.showBanner.bind(this);
    }

    static propTypes = {
        fetchUser: PropTypes.func.isRequired,
        track: PropTypes.func.isRequired
    };

    showBanner(token) {
        const { track } = this.props;

        UserApi.sendEmailTokenVerif(token)
            .then(() => {
                this.props.fetchUser();
                this.setState({ show: true });

                track('Signup email verified', { source: 'email' });

                setTimeout(() => {
                    this.setState({ show: false });
                }, 5500); // 5s+500ms for banner animation
            })
            .catch(() => {
                document.location.assign('/auth/logout-email-token-verification-error');
            });
    }

    componentDidMount() {
        const { hasCalledApi } = this.state;
        const token = Utils.getParameter('token_email_verif');

        if (token && !hasCalledApi) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ hasCalledApi: true }, () => {
                this.showBanner(token);
            });
        }

        if (location.pathname === '/') {
            const fromEmailTokenVerificationError = Utils.getParameter('email-verification-error');

            if (fromEmailTokenVerificationError) {
                sweetAlert({
                    icon: 'error',
                    title: i18n.t('user:error.users.verification_email_rate_limit_reached.title'),
                    text: i18n.t('user:error.users.verification_email_rate_limit_reached.text'),
                    closeOnClickOutside: false
                }).then((isConfirm) => {
                    if (isConfirm) {
                        document.location.assign('/');
                    }
                });
            }
        }
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchUser: bindActionCreators(fetchUser, dispatch)
});

export default compose(connect(null, mapDispatchToProps), withAnalytics)(EmailVerification);
