import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import i18n from '../../i18n';
import { CurrencyFormatter } from '../../utils/Intl';
import Utils from '../../utils/utils';
import Subscription from '../../models/Subscription';

export const PaymentTotal = ({
    totalToPay,
    currency,
    coupon,
    is3xPayment,
    is3xPaymentFromFinalization,
    productPrice
}) => {
    if (coupon && coupon.discount && coupon.couponType !== 'guestpoint') {
        totalToPay -= coupon.discount;

        if (totalToPay < 0) {
            totalToPay = 0;
        }
    }

    // for 3x payment
    const shouldDisplay3xPaymentInfo = _.isNull(is3xPaymentFromFinalization)
        ? is3xPayment
        : is3xPaymentFromFinalization;
    const priceHr = Utils.getPriceHr(shouldDisplay3xPaymentInfo, totalToPay);
    const totalProductPrice = productPrice + Subscription.EUROPE_SERVICE_CHARGE_PRICE;

    return (
        <div className="summary row">
            <div className="text col-xs-8">
                <span>{i18n.t('subscription:total')}</span>
            </div>
            <div className="col-xs-4 amount total">
                <p>
                    {shouldDisplay3xPaymentInfo
                        ? i18n.t('common:annual_membership_price', {
                              annualMembership: CurrencyFormatter.format(totalProductPrice, currency)
                          })
                        : CurrencyFormatter.format(totalToPay, currency)}
                </p>
                {i18n.language === 'hr' && currency.toUpperCase() === 'EUR' && (
                    <p className="hr-total">{`(${Number(priceHr).toLocaleString()} kn)`}</p>
                )}
            </div>
        </div>
    );
};

PaymentTotal.propTypes = {
    totalToPay: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    coupon: PropTypes.shape({
        discount: PropTypes.number,
        couponType: PropTypes.string
    }),
    is3xPayment: PropTypes.bool,
    is3xPaymentFromFinalization: PropTypes.bool,
    productPrice: PropTypes.number
};

const mapStateToProps = (state) => ({
    coupon: state.subscription.coupon
});

export default connect(mapStateToProps)(PaymentTotal);
