import { LOAD_GROUPS } from '../actions/groups';

export default function groups(state = [], { type, payload }) {
    switch (type) {
        case LOAD_GROUPS:
            return Object.assign([], state, payload);
        default:
            return state;
    }
}
