import React from 'react';
import PropTypes from 'prop-types';

const ProgressBar = ({ currentStep, totalSteps }) => (
    <div className="signup-progress">
        <div
            className="signup-progress-bar"
            role="progressbar"
            aria-valuenow={currentStep}
            aria-valuemin={0}
            aria-valuemax={totalSteps}
            style={{
                transform: `translateX(${Math.round(-100 + (currentStep / totalSteps) * 100)}%)`
            }}
        ></div>
    </div>
);

ProgressBar.propTypes = {
    currentStep: PropTypes.number.isRequired,
    totalSteps: PropTypes.number.isRequired
};

export default ProgressBar;
