import PropTypes from 'prop-types';
import React from 'react';

import CreditCardDescription from './CreditCardDescription';
import Payment3x from './Payment3x';

const CreditCardForm = ({
    fields,
    products,
    is3xPayment,
    updateIs3xPayment,
    subscription,
    is3xPaymentFromFinalization,
    updateIs3xPaymentFromFinalization,
    needToBuyGp
}) => {
    const shouldDisplay3xPayment = () => {
        const hasCollectionProduct = products
            ? products.filter((product) => product.isCollection).length !== 0
            : false;

        if (subscription && !hasCollectionProduct) {
            const { userHasSubscription, active, currency, isLoyaltyExpired } = subscription;
            if (currency.toUpperCase() !== 'EUR') {
                return false;
            }
            if (!userHasSubscription) {
                // payment from finalization flow without gp purchase
                if (!_.isNull(needToBuyGp)) {
                    return !needToBuyGp;
                }
                return true;
            } else if (!_.isNull(needToBuyGp) && !_.isUndefined(needToBuyGp)) {
                return !needToBuyGp;
            } else {
                return !active && isLoyaltyExpired;
            }
        } else {
            return false;
        }
    };

    return (
        <React.Fragment>
            <CreditCardDescription />
            {shouldDisplay3xPayment() && (
                <Payment3x
                    is3xPayment={is3xPayment}
                    updateIs3xPayment={updateIs3xPayment}
                    is3xPaymentFromFinalization={is3xPaymentFromFinalization}
                    updateIs3xPaymentFromFinalization={updateIs3xPaymentFromFinalization}
                    subProduct={products[0]}
                    subCurrency={subscription.currency}
                />
            )}
            {fields}
        </React.Fragment>
    );
};

CreditCardForm.propTypes = {
    fields: PropTypes.element.isRequired,
    products: PropTypes.arrayOf(PropTypes.object),
    is3xPayment: PropTypes.bool, // for 3x payment
    updateIs3xPayment: PropTypes.func,
    subscription: PropTypes.shape({
        userHasSubscription: PropTypes.bool,
        autoRenew: PropTypes.bool,
        active: PropTypes.bool,
        currency: PropTypes.string,
        isLoyaltyExpired: PropTypes.bool
    }),
    is3xPaymentFromFinalization: PropTypes.bool,
    updateIs3xPaymentFromFinalization: PropTypes.func,
    needToBuyGp: PropTypes.bool
};

export default CreditCardForm;
