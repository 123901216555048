import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { throttle } from 'underscore';

import i18n from '../../i18n';

export class ImageDropTarget extends React.Component {
    static propTypes = {
        children: PropTypes.element,
        connectDropTarget: PropTypes.func.isRequired,
        isOver: PropTypes.bool.isRequired,
        canDrop: PropTypes.bool.isRequired,
        /* eslint-disable react/no-unused-prop-types */
        index: PropTypes.number.isRequired,
        moveCard: PropTypes.func.isRequired,
        updateOrder: PropTypes.func.isRequired,
        openDialog: PropTypes.func.isRequired,
        uploadFiles: PropTypes.func.isRequired
        /* eslint-enable react/no-unused-prop-types */
    };

    constructor(props) {
        super(props);
        this.openDialog = this.openDialog.bind(this);
    }

    openDialog(e) {
        e.preventDefault();
        this.props.openDialog();
    }

    render() {
        const { connectDropTarget, isOver, canDrop } = this.props;
        return connectDropTarget(
            <div
                className={classNames('col-xs-6 col-sm-3 image-drop-target', {
                    empty: !this.props.children,
                    isOver,
                    canDrop
                })}
            >
                {this.props.children ? (
                    this.props.children
                ) : (
                    <div className="div-image">
                        <a className="add-link" href="#" onClick={this.openDialog}>
                            <i className="icon-filter-picture picture-icon" aria-hidden="true"></i>
                            <span>{i18n.t('upload:uploadImageWait_addImage')}</span>
                        </a>
                    </div>
                )}
            </div>
        );
    }
}

const target = {
    canDrop() {
        return true;
    },
    drop(props, monitor) {
        // drop native files
        if (monitor.getItemType() === NativeTypes.FILE) {
            // upload files dropped
            props.uploadFiles(monitor.getItem().files);
        }
        // drop an image to reorder
        else {
            props.updateOrder();
        }
    },
    hover: throttle((props, monitor) => {
        // do nothing if dragging a native file
        if (!monitor.getItem() || monitor.getItemType() === NativeTypes.FILE) {
            return;
        }
        // indexes
        const imageId = monitor.getItem().id;
        const hoverIndex = props.index;
        // Time to actually perform the action
        if (props.moveCard(imageId, hoverIndex)) {
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            monitor.getItem().order_display = hoverIndex;
        }
    }, 100)
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
    };
}

export default DropTarget(['image', NativeTypes.FILE], target, collect)(ImageDropTarget);
