import React from 'react';
import { Marker, Popup } from 'react-map-gl';
import Utils from '../../../../utils/utils';
import styles from './pinhomemarker.module.scss';
var PinHomeMarker = function (_a) {
    var latitude = _a.latitude, longitude = _a.longitude, tooltipContent = _a.tooltipContent;
    return (React.createElement(Marker, { latitude: latitude, longitude: longitude, offset: [0, -24] },
        React.createElement("div", { style: { position: 'relative', display: 'inline-block' } },
            React.createElement("img", { className: styles['pin-img'], src: "".concat(Utils.getCloudfrontDomain(), "/images/website/search/ellipse.svg"), alt: "Large Circle" }),
            React.createElement("img", { className: styles['pin-img'], src: "".concat(Utils.getCloudfrontDomain(), "/images/website/search/verified_home.svg"), alt: "Pin Home" })),
        React.createElement(Popup, { latitude: latitude, longitude: longitude, closeButton: false, closeOnClick: false, anchor: "bottom", offset: [0, -52], className: styles['popup'] }, tooltipContent)));
};
export default PinHomeMarker;
