import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import User from '../../models/User';

export default class UserLanguagesList extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User),
        mode: PropTypes.oneOf(['home', 'user']),
        languages: PropTypes.arrayOf(PropTypes.string).isRequired,
        onChange: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(userLocale) {
        return (e) => {
            e.preventDefault();
            this.props.onChange(userLocale);
        };
    }

    render() {
        if (this.props.languages && this.props.languages.length > 0) {
            return (
                <p className="translations described-language">
                    <em>
                        {this.props.user && this.props.user.get('first_name')}
                        &nbsp;
                        {this.props.mode === 'user'
                            ? i18n.t('user:user_description_languages')
                            : i18n.t('home:home_description_languages')}
                        &nbsp;
                        {this.props.languages
                            .map((language) => (
                                <a
                                    href="#"
                                    className={`locale-${language} lang-descriptions`}
                                    onClick={this.handleClick(language)}
                                    key={language}
                                >
                                    {i18n.t(language)}
                                </a>
                            ))
                            .reduce((prev, curr) => [prev, ', ', curr])}
                        .
                    </em>
                </p>
            );
        }
        return (
            <p className="translations">
                <em>
                    {this.props.user && this.props.user.get('first_name')}
                    &nbsp;
                    {this.props.mode === 'user'
                        ? i18n.t('user:user_nodescription_profile')
                        : i18n.t('home:home_nodescription')}
                </em>
            </p>
        );
    }
}
