import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

export default class FacebookLoginWrapper extends React.PureComponent {
    static propTypes = {
        children: PropTypes.node,
        scope: PropTypes.string,
        onLogin: PropTypes.func.isRequired,
        onClick: PropTypes.func
    };

    static defaultProps = {
        scope: 'public_profile,email'
    };

    constructor(props) {
        super(props);

        this.state = {
            sendingRequest: false
        };

        this.onClick = this.onClick.bind(this);
        this.statusChanged = this.statusChanged.bind(this);
    }

    onClick() {
        if (this.props.onClick) this.props.onClick();

        if (!window.FB) {
            throw new Error('Facebook SDK is missing.');
        }
        window.FB.login(this.statusChanged, { scope: this.props.scope });

        // Add loader
        this.setState({
            sendingRequest: true
        });
    }

    statusChanged(response) {
        // The response object is returned with a status field that lets the
        // app know the current login status of the person.
        // Full docs on the response object can be found in the documentation
        // for FB.getLoginStatus().
        if (response.status === 'connected') {
            this.props.onLogin(response).then(null, () => {
                // If an error occured, hide the loader
                this.setState({
                    sendingRequest: false
                });
            });
        } else {
            if (response.status === 'not_authorized') {
                // The person is logged into Facebook, but not your app.
                sweetAlert('Oops…', i18n.t('common:facebook.log_app'), 'error');
            } else {
                // The person is not logged into Facebook, so we're not sure if
                // they are logged into this app or not.
                sweetAlert('Oops…', i18n.t('common:facebook.log_fb'), 'error');
            }

            // Remove loader
            this.setState({
                sendingRequest: false
            });
        }
    }

    render() {
        return React.cloneElement(React.Children.only(this.props.children), {
            onClick: this.onClick,
            sendingRequest: this.state.sendingRequest
        });
    }
}
