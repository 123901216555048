import Common from './Common';

const requestLegalRegistration = (homeId, data, legalRegistrationId, method) => {
    const url = `/v1/homes/${homeId}/legal-registration`;
    const params = Common.params({
        url,
        method,
        data: { data, legal_registration_id: legalRegistrationId }
    });
    return Common.request(params);
};

const patch = (homeId, data, legalRegistrationId) => {
    return requestLegalRegistration(homeId, data, legalRegistrationId, 'patch');
};

const post = (homeId, data, legalRegistrationId) => {
    return requestLegalRegistration(homeId, data, legalRegistrationId, 'post');
};

export const LegalRegistration = {
    patch,
    post
};
