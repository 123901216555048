import Api from '../api/Api';

import Product from '../models/Product';

export const ADD_PRODUCTS_FOR_EXCHANGE = 'ADD_PRODUCTS_FOR_EXCHANGE';

export function addProductsForExchange(exchangeId, products) {
    return {
        type: ADD_PRODUCTS_FOR_EXCHANGE,
        payload: {
            [exchangeId]: products
        }
    };
}

export function fetchProductsForExchange(exchangeId) {
    return (dispatch) =>
        Api.Product.getForExchange(exchangeId).then((response) => {
            const products = response.map((product) => new Product(product));
            dispatch(addProductsForExchange(exchangeId, products));
        });
}
