import { SET_TRANSFERTS } from '../actions/transferts';

export default function transferts(state = null, { type, payload }) {
    switch (type) {
        case SET_TRANSFERTS:
            return payload;
        default:
            return state;
    }
}
