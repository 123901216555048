import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import Utils from '../../../utils/utils';
import Api from '../../../api/Api';
import User from '../../../models/User';

const UserAmbassador = ({ user, title }) => {
    const [isAmbassador, setIsAmbassador] = useState(false);

    useEffect(() => {
        if (user) {
            Api.Ambassador.get(user.get('id')).then((response) => {
                setIsAmbassador(response.is_ambassador);
            });
        }
    }, [user]);

    return (
        isAmbassador && (
            <>
                {title ? <h2>{title}</h2> : <div role="separator" className="divider"></div>}
                <div className="user-ambassador-container">
                    <i className="icon-ambassador_sidebar" />
                    <p className="user-ambassador-text">
                        {`${i18n.t('user:homeview.user-ambassador.text', {
                            firstname: Utils.capitalize(user?.get('first_name'))
                        })} `}
                        <a
                            href={`${i18n.t('common:ambassador-link-url')}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {i18n.t('common:learn_more')}
                        </a>
                    </p>
                </div>
            </>
        )
    );
};

UserAmbassador.propTypes = {
    user: PropTypes.instanceOf(User),
    title: PropTypes.string
};

const mapStateToProps = (state) => {
    const id = Utils.getLastUrlPath();
    const home = state.loadedHomes[id];

    if (id && home) {
        return { user: home ? home.get('user') : null };
    }

    return {};
};

export default connect(mapStateToProps)(UserAmbassador);
