import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const useClickOutside = (ref, onClickOutside) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            onClickOutside();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref, onClickOutside]);
};

export function UseClickOutsideContainer({ callback, children }) {
    const ref = useRef(null);
    useClickOutside(ref, callback);
    return <div ref={ref}>{children}</div>;
}
UseClickOutsideContainer.propTypes = {
    callback: PropTypes.func,
    children: PropTypes.node
};
