import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import i18n from '../../../i18n';
import Utils from '../../../utils/utils';

import Home from '../../../models/Home';

import CompletionIndicator from '../../common/CompletionIndicator';
import EditPanel from '../../common/EditPanel';
import InputRulesList from './InputRulesList';
import HomeAllergiesToggle from '../../home-allergies/HomeAllergiesToggle';
import InputAmenitiesList from './InputAmenitiesList';
import Feature from '../../../models/Feature';

export default class HomeEditPanelFeatures extends EditPanel {
    static propTypes = {
        home: PropTypes.instanceOf(Home).isRequired,
        onChange: PropTypes.func.isRequired
    };

    static priority = Home.PRIORITY_FEATURES;

    static checkValidity(props) {
        const featureObject = props.home.get('feature');

        if (!props.home.has('feature') || typeof featureObject !== 'object') {
            return {
                feature: false
            };
        }

        const excludedKeys = ['rule', 'favor', 'surrounding'];
        const filteredFeature = _.omit(featureObject, excludedKeys);

        return {
            feature: _.values(filteredFeature).reduce((total, feature) => total + feature, 0) > 0
        };
    }

    static title = i18n.t('home:amenities');

    render() {
        const { home, onChange } = this.props;
        return (
            <fieldset className="panel panel-default" id={Utils.slugify(this.constructor.title)}>
                <div className="panel-heading">{i18n.t('home:amenities')}</div>
                <div className="panel-body">
                    <div
                        className={classNames('form-group', {
                            'has-error': this.hasError('feature')
                        })}
                    >
                        <div className="label-row">
                            <label>{i18n.t('home:amenities')}</label>
                            <CompletionIndicator object={home} fieldNames={['feature']} page="home" />
                        </div>
                        <div className="amenities-gp-info">
                            <i className="icon-guestpoints" />
                            <span>{i18n.t('home:amenities.guestpoints_info')}</span>
                        </div>
                        {Feature.FEATURES_GROUPS.filter(
                            (item) =>
                                item.type !== Feature.AMENITIES_TYPE_RULE_FAVOR &&
                                item.type !== Feature.SURROUNDING_TYPE_TAGS
                        ).map((item) => {
                            return (
                                <Fragment key={item.type}>
                                    <div className="label-row">
                                        <label>{i18n.t(item.title)}</label>
                                    </div>
                                    <InputAmenitiesList
                                        values={home.get('feature')}
                                        features={item.features}
                                        onChange={onChange}
                                        className="col-xs-6 col-sm-4 col-md-3 col-lg-2"
                                    />
                                </Fragment>
                            );
                        })}
                    </div>
                    <div className="form-group">
                        <label>{i18n.t('home:lifestyle')}</label>
                        <InputRulesList
                            values={home.get('feature')}
                            onChange={onChange}
                            className="col-xs-6 col-sm-4 col-md-3 col-lg-2"
                        />
                    </div>
                    <div className="form-group gtg-form">
                        <HomeAllergiesToggle
                            active={home.get('feature').animal > 0}
                            home={home}
                            onSelect={onChange}
                            onToggle={(e) => {
                                const { name } = e.target;
                                if (name === 'feature-allergies-off') {
                                    onChange({ target: { name: 'feature.animal', value: 0 } });
                                }
                            }}
                        />
                    </div>
                </div>
            </fieldset>
        );
    }
}
