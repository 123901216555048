import React from 'react';
import Kalendae from 'kalendae';
import i18n from '../../i18n';
import InputCalendar from './InputCalendar';
import CalendarUtils from '../../utils/CalendarUtils';
import Utils from '../../utils/Screen';

export default class InputRangeCalendar extends InputCalendar {
    static propTypes = {
        ...InputCalendar.propTypes
    };

    constructor(props) {
        super(props);
        this.dateRangeRef = React.createRef();
        this.startOnRef = React.createRef();
        this.endOnRef = React.createRef();
        this.resetRef = React.createRef();
        this.resetClickHandler = this.resetClickHandler.bind(this);
        this.updateDisplayedValues = this.updateDisplayedValues.bind(this);
        this.onPressEnter = this.onPressEnter.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount() {
        this.drawCalendar();
        this.addInputEvents();
        this.escapeKeyHandler();

        // When the Calendar is extended to be used in a form
        if (this.checkValidity) {
            this.checkValidity();
        }
    }

    /**
     * Draws calendar using Kalendae
     */
    customDrawCalendar(kalendaeOptions) {
        // Add popup mode
        kalendaeOptions = Object.assign(kalendaeOptions, {
            popupMode: true,
            side: this.props.side
        });

        let isDisplayed = false;
        if (this.calendar) {
            // We need to refresh the calendar, the only solution is to destroy it and show it again
            if ($(this.calendar.container).is(':visible')) {
                isDisplayed = true;
            }
            this.calendar.destroy();
        }

        this.calendar = new Kalendae.Input(this.input, kalendaeOptions);

        this.calendar.subscribe('change', this.updateDisplayedValues);

        if (isDisplayed) {
            this.calendar.show();
        }

        this.updateDisplayedValues();
    }

    /**
     * Add event handlers to open/close calendar
     */
    addInputEvents() {
        const $startOn = $(this.startOnRef.current);
        const $endOn = $(this.endOnRef.current);

        // Open/Close calendar based on click event
        $(this.dateRangeRef.current).click((event) => {
            let $target = $(event.target);

            if (!$target.hasClass('start_on') && !$target.hasClass('end_on')) {
                // Target is the start date if the user clicked anywhere
                $target = $startOn;
            } else if (
                ($target.hasClass('start_on') && $startOn.hasClass('active')) ||
                ($target.hasClass('end_on') && $endOn.hasClass('active'))
            ) {
                // We click on the active filter, close calendar
                $target.removeClass('active');
                this.closeCalendar();
                return;
            }

            $startOn.removeClass('active');
            $endOn.removeClass('active');

            $target.addClass('active');
            // Open calendar
            this.calendar.show();
            this.calendar.draw();
            $('body').on('click', this.bodyClickHandler);
        });
    }

    /**
     * Computes what to do on date click
     * @param  {Moment} date Date selected
     * @return {Object} Return value, shouldCloseCalendar, shouldNotChangeToggleInput
     */
    dateClickedAction(date) {
        let returnValue = super.dateClickedHandler(date);
        let shouldCloseCalendar = false;
        let shouldNotChangeToggledInput = false;
        let selectedRange;

        // Inherit from Calendar
        if (!returnValue) {
            return {
                returnValue,
                shouldCloseCalendar,
                shouldNotChangeToggledInput: true
            };
        }

        const selected = this.calendar.getSelectedRaw();

        // Select the right date (start or end)
        if ($(this.endOnRef.current).hasClass('active')) {
            if (selected[0] && date.diff(selected[0]) < 0) {
                // Reset start date if end date is prior and keep calendar open
                if (!CalendarUtils.dayJustBeforeUnavailablePeriod(date, this.props.availabilities)) {
                    // Reset end value
                    selectedRange = [date];
                }
                shouldCloseCalendar = false;
                shouldNotChangeToggledInput = true;
                returnValue = false;
            } else if (selected.length >= 1) {
                // Range is set, close calendar after picking
                shouldCloseCalendar = true;
                shouldNotChangeToggledInput = true;
                selectedRange = [selected[0], date];
                returnValue = false;
            } else {
                // We picked the end date first
                shouldCloseCalendar = false;
                shouldNotChangeToggledInput = true;
                selectedRange = [date];
                returnValue = false;
            }
        } else {
            // Setting the start date
            shouldCloseCalendar = false;
            returnValue = false;

            if (CalendarUtils.dayJustBeforeUnavailablePeriod(date, this.props.availabilities)) {
                // Don't let the user select the start date that is right before an unavailable period
                shouldNotChangeToggledInput = true;
            } else if (selected[1] && date.isSame(selected[1])) {
                // The user selects the end date
                const dateAfterEndDate = selected[1].clone().add(1, 'day');
                selectedRange = [selected[1], dateAfterEndDate];
                shouldNotChangeToggledInput = false;
            } else if (selected.length === 2) {
                // Range already set, close calendar after picking
                if (date.isAfter(selected[1])) {
                    shouldCloseCalendar = false;
                    selectedRange = [date];
                } else {
                    shouldCloseCalendar = true;
                    selectedRange = [date, selected[1]];
                }
            } else if (selected.length === 1) {
                // We are resetting the start date
                shouldNotChangeToggledInput = false;
                selectedRange = [date];
            } else {
                returnValue = true;
            }
        }

        if (selectedRange && selectedRange.length === 2 && selectedRange[0].isAfter(selectedRange[1])) {
            // Reorder selection if needed
            selectedRange = [selectedRange[1], selectedRange[0]];
        }

        // Don't allow for selections overlapping booked periods
        if (selectedRange && selectedRange.length > 1) {
            const range = moment.range(selectedRange);
            const overlapsUnavailablePeriod = CalendarUtils.overlapsUnavailablePeriod(
                range,
                this.props.availabilities,
                this.props.contactOnUnavailablePeriods
            );
            if (overlapsUnavailablePeriod) {
                shouldCloseCalendar = false;
                shouldNotChangeToggledInput = $(this.endOnRef.current).hasClass('active');
                selectedRange = [date];
                returnValue = false;
            }
        }

        return {
            returnValue,
            shouldCloseCalendar,
            shouldNotChangeToggledInput,
            selectedRange
        };
    }

    /**
     * Handles date selection (close calendar, toggleinput etc.)
     * @param  {Moment} date Date selected
     * @return {bool} Whether to prevent the date from being selected
     */
    dateClickedHandler(date) {
        const returnObject = this.dateClickedAction(date);
        const $startOn = $(this.startOnRef.current);
        const $endOn = $(this.endOnRef.current);

        if (returnObject.selectedRange) {
            this.calendar.setSelected(returnObject.selectedRange);
        }

        if (returnObject.shouldCloseCalendar) {
            setTimeout(() => {
                this.closeCalendar();
            }, 1000);
        }

        // Toggle the other input
        if (!returnObject.shouldNotChangeToggledInput) {
            $startOn.toggleClass('active');
            $endOn.toggleClass('active');
        }

        return returnObject.returnValue;
    }

    /**
     * Reset selection handler
     */
    resetClickHandler() {
        if (!this.calendar) {
            return;
        }
        this.calendar.setSelected(null);
        if (this.props.dateResponsive && Utils.isMobile()) {
            $(this.dateRangeRef.current).removeClass('selected-dates');
        }
    }

    /**
     * Close calendar and reset inputs style
     */
    closeCalendar() {
        const $startOn = $(this.startOnRef.current);
        const $endOn = $(this.endOnRef.current);

        $startOn.removeClass('active');
        $endOn.removeClass('active');
        this.calendar.hide();
        $('body').off('click', this.bodyClickHandler);

        if (this.props.onClose) {
            this.props.onClose.call(this);
        }
    }

    /**
     * Update inputs to show selected dates
     */
    updateDisplayedValues() {
        const selected = this.calendar.getSelectedRaw();
        const $startOn = $(this.startOnRef.current);
        const $endOn = $(this.endOnRef.current);
        const $reset = $(this.resetRef.current);

        if (Array.isArray(selected)) {
            if (selected[0]) {
                $startOn.text(selected[0].format(this.format));
            } else {
                $startOn.text($startOn.attr('title'));
            }
            if (selected[1]) {
                $endOn.text(selected[1].format(this.format));
            } else {
                $endOn.text($endOn.attr('title'));
            }

            if (selected.length === 2 && this.props.dateResponsive && Utils.isMobile()) {
                $(this.dateRangeRef.current).addClass('selected-dates');
            }

            // Hide button if no dates are selected
            if (selected.length > 0) {
                $reset.show();
            } else {
                $reset.hide();
            }
        } else {
            $startOn.text($startOn.attr('title'));
            $endOn.text($endOn.attr('title'));
            $reset.hide();
        }
    }

    /**
     * Add specific rules when hovering dates
     * @param  {Moment[]} selectedDates Dates currently selected
     * @param  {jQuery} $target the hovered span
     * @return {bool} Whether we should display a hover or not
     */
    customShowHoverRange(selectedDates, $target) {
        if (selectedDates.length === 1 && $(this.startOnRef.current).hasClass('active')) {
            // We are selecting the start date again, don't show the range
            this.clearHover();
            return false;
        } else if (selectedDates[0].diff(moment($target.attr('data-date'), this.format)) > 0) {
            // Dates prior to the first one selected shouldn't be hovered
            this.clearHover();
            return false;
        } else {
            const range = moment.range(selectedDates[0], moment($target.attr('data-date'), this.format));
            const overlapsUnavailablePeriod = CalendarUtils.overlapsUnavailablePeriod(
                range,
                this.props.availabilities,
                this.props.contactOnUnavailablePeriods
            );
            if (overlapsUnavailablePeriod) {
                // If the range overlaps an unavailable period, don't show hover
                this.clearHover();
                return false;
            }
        }

        return true;
    }

    onPressEnter(e) {
        // Close calendar when press Enter key
        if (e.key === 'Enter') {
            // add a delay to avoid issue on searchbar submission en close calendar
            setTimeout(() => {
                this.closeCalendar();
            }, 1000);
        }
    }

    onFocus() {
        $(this.input).closest('.form-group').addClass('focus');
    }

    onBlur() {
        $(this.input).closest('.form-group').removeClass('focus');
    }

    /**
     * Display the calendar
     */
    showCalendar() {
        super.showCalendar();

        if (this.calendar) {
            const $startOn = $(this.startOnRef.current);
            $startOn.addClass('active');
        }
    }

    render() {
        const { classNames, startOn, endOn } = this.props;
        const startOnText = startOn ? startOn.format(this.format) : i18n.t('search:filters.arrival');
        const endOnText = endOn ? endOn.format(this.format) : i18n.t('search:filters.departure');

        return (
            <div ref={this.calendarRef} className={`${classNames} date_range calendar-v2`}>
                <input
                    ref={(input) => {
                        this.input = input;
                    }}
                    type="radio"
                    name="date_range"
                    className="form-control radioHidden"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                />
                <div ref={this.dateRangeRef} className="custom_date_range_elements">
                    <span className="input-search-text">
                        <span
                            ref={this.startOnRef}
                            className="start_on"
                            title={i18n.t('search:filters.arrival')}
                        >
                            {startOnText}
                        </span>
                        <i className="icon-arrow-right" />
                        <span
                            ref={this.endOnRef}
                            className="end_on"
                            title={i18n.t('search:filters.departure')}
                        >
                            {endOnText}
                        </span>
                    </span>
                    <button
                        ref={this.resetRef}
                        type="button"
                        className="close reset hidden-xs"
                        aria-label="Reset"
                        onClick={this.resetClickHandler}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        );
    }
}
