import React from 'react';
import Utils from '../../utils/utils';

const FakeHeadband = () => (
    <div className="fake-headband">
        <img className="logo-img" src={`${Utils.getCloudfrontDomain()}/images/website/logo/he-logo.svg`} />
    </div>
);

export default FakeHeadband;
