import React from 'react';
import styles from './availabilities.module.scss';
import clsx from 'clsx';
export var AvailabilityCaptionCalendar = function (_a) {
    var title = _a.title, type = _a.type, description = _a.description;
    return (React.createElement("div", { key: type },
        React.createElement("div", { className: clsx(styles['badge'], styles[type]) }, title),
        React.createElement("div", { className: styles['text-caption'] }, description)));
};
export default AvailabilityCaptionCalendar;
