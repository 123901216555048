import MobileDetect from 'mobile-detect';

export default class Mobile {
    static changeLinks() {
        const md = new MobileDetect(window.navigator.userAgent);
        if (md.mobile()) {
            [].forEach.call(document.querySelectorAll('a[target="_blank"]'), (element) => {
                if (!$(element).hasClass('force-blank')) {
                    $(element).removeAttr('target');
                }
            });
        }
    }
}
