import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../i18n';

export const SigninLink = ({ onClick, text }) => {
    return (
        <a
            className={classNames({
                'm-l-5': !text
            })}
            href="#"
            onClick={onClick}
        >
            {i18n.t(`common:${text ? text : 'signin'}`)}
        </a>
    );
};

SigninLink.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string
};
