import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import i18n from '../../i18n';
import Utils from '../../utils/utils';
import HomeCard from './HomeCard';
import useMedia from '../../utils/useMedia';

const Inspirational = ({ locale, userIsHECollection }) => {
    const getImageUrl = (collectionSlug, regularSlug, lang = null, regularENSlug = null) => {
        const imageBasePath = `${Utils.getCloudfrontDomain()}/images/website/dashboard/`;

        if (userIsHECollection) {
            return `${imageBasePath}${collectionSlug}`;
        }

        if (lang && regularENSlug && lang === 'en') {
            return `${imageBasePath}${regularENSlug}`;
        }

        return `${imageBasePath}${regularSlug}`;
    };

    const slides = [
        {
            id: 1,
            sizePicture: 'big-picture',
            title: i18n.t('dashboard:title-inspirational-big-picture-first-block'),
            text: i18n.t('dashboard:text-big-picture-first-block'),
            image: getImageUrl(
                'collection-inspirational-swimming-pool.webp',
                'GettyImages-532881696.jpg',
                locale,
                'GettyImages-109350277.jpg'
            ),
            href: `${i18n.t('search:search_url', {
                slug: 'everywhere'
            })}?filter[]=swimming-pool`
        },
        {
            id: 2,
            sizePicture: 'little-picture',
            title: userIsHECollection
                ? i18n.t('dashboard:collection-inspirational.jacuzzi.title')
                : i18n.t('dashboard:title-inspirational-little-first-picture-first-block'),
            text: userIsHECollection
                ? i18n.t('dashboard:collection-inspirational.jacuzzi.text')
                : i18n.t('dashboard:text-little-first-picture-first-block'),
            image: getImageUrl('collection-inspirational-jacuzzi.webp', 'GettyImages-1332158375.jpg'),
            href: `${i18n.t('search:search_url', { slug: 'everywhere' })}?filter[]=${
                userIsHECollection ? 'jacuzzi' : 'garden'
            }`
        },
        {
            id: 3,
            sizePicture: 'little-picture',
            title: userIsHECollection
                ? i18n.t('dashboard:collection-inspirational.fitness-room.title')
                : i18n.t(
                      `dashboard:title-inspirational-little-second-picture-first-block-${
                          locale === 'en' ? 'com' : 'other'
                      }`
                  ),
            text: userIsHECollection
                ? i18n.t('dashboard:collection-inspirational.fitness-room.text')
                : i18n.t(
                      `dashboard:text-little-second-picture-first-block-${locale === 'en' ? 'com' : 'other'}`
                  ),
            image: getImageUrl(
                'collection-inspirational-fitness-room.webp',
                'GettyImages-185939162.jpg',
                locale,
                'GettyImages-1312118972.jpg'
            ),
            href: `${i18n.t('search:search_url', { slug: 'everywhere' })}?filter[]=${
                userIsHECollection ? 'gym' : locale === 'en' ? 'wifi' : 'bicycle'
            }`
        },
        {
            id: 4,
            sizePicture: 'little-picture',
            title: userIsHECollection
                ? i18n.t('dashboard:collection-inspirational.fireplace.title')
                : i18n.t('dashboard:title-inspirational-little-first-picture-second-block'),
            text: userIsHECollection
                ? i18n.t('dashboard:collection-inspirational.fireplace.text')
                : i18n.t('dashboard:text-little-first-picture-second-block'),
            image: getImageUrl('collection-inspirational-fire-place.webp', 'GettyImages-141776389.jpg'),
            href: `${i18n.t('search:search_url', { slug: 'everywhere' })}?filter[]=${
                userIsHECollection ? 'fireplace' : 'bbq'
            }`
        },
        {
            id: 5,
            sizePicture: 'little-picture',
            title: userIsHECollection
                ? i18n.t('dashboard:text-little-second-picture-second-block')
                : i18n.t('dashboard:title-inspirational-little-second-picture-second-block'),
            text: userIsHECollection
                ? i18n.t('dashboard:collection-inspirational.pets-welcome.text')
                : i18n.t('dashboard:text-little-second-picture-second-block'),
            image: getImageUrl(
                'collection-inspirational-pets-welcome.webp',
                'GettyImages-862514740.jpg',
                locale,
                'GettyImages-1299839588.jpg'
            ),
            href: `${i18n.t('search:search_url', {
                slug: 'everywhere'
            })}?filter[]=pets-welcome`
        },
        {
            id: 6,
            sizePicture: 'big-picture',
            title: userIsHECollection
                ? i18n.t('dashboard:collection-inspirational.family-friendly.title')
                : i18n.t('dashboard:title-inspirational-big-picture-second-block'),
            text: userIsHECollection
                ? i18n.t('dashboard:collection-inspirational.family-friendly.text')
                : i18n.t('dashboard:text-big-picture-second-block'),
            image: getImageUrl('collection-inspirational-family-friendly.webp', 'GettyImages-961026814.jpg'),
            href: `${i18n.t('search:search_url', {
                slug: 'everywhere'
            })}?filter[]=children-welcome`
        }
    ];

    const settings = {
        speed: 500,
        centerPadding: '100px',
        infinite: true,
        variableWidth: true,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 950,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    };

    return (
        <div className="container dashboard-module dashboard-inspirational">
            <header>
                <h2 className="home-page-block-title">
                    {userIsHECollection
                        ? i18n.t('dashboard:collection-inspirational.block.title')
                        : i18n.t('dashboard:title-inspirational')}
                </h2>
                <p className="subtitle-inspirational">
                    {userIsHECollection
                        ? i18n.t('dashboard:collection-inspirational.block.text')
                        : i18n.t('dashboard:subtitle-inspirational')}
                </p>
            </header>
            {useMedia('(max-width: 767px)') ? (
                <Slider {...settings}>
                    {slides.map((slide) => (
                        <HomeCard
                            key={`slide-${slide.id}`}
                            classMedia="slider-media"
                            classSize={slide.sizePicture}
                            imageSrc={slide.image}
                            title={slide.title}
                            text={slide.text}
                            href={slide.href}
                        />
                    ))}
                </Slider>
            ) : (
                <div className="container-block">
                    <div className="first-block">
                        <HomeCard
                            classSize="big-picture"
                            imageSrc={slides[0].image}
                            title={slides[0].title}
                            text={slides[0].text}
                            href={slides[0].href}
                        />
                        <div className="container-two-picture">
                            <HomeCard
                                classMedia="little-media"
                                classSize="little-picture"
                                imageSrc={slides[1].image}
                                title={slides[1].title}
                                text={slides[1].text}
                                href={slides[1].href}
                            />
                            <HomeCard
                                classMedia="little-media"
                                classSize="little-picture"
                                imageSrc={slides[2].image}
                                title={slides[2].title}
                                text={slides[2].text}
                                href={slides[2].href}
                            />
                        </div>
                    </div>
                    <div className="second-block">
                        <div className="container-two-picture">
                            <HomeCard
                                classMedia="little-media"
                                classSize="little-picture"
                                imageSrc={slides[3].image}
                                title={slides[3].title}
                                text={slides[3].text}
                                href={slides[3].href}
                            />
                            <HomeCard
                                classMedia="little-media"
                                classSize="little-picture"
                                imageSrc={slides[4].image}
                                title={slides[4].title}
                                text={slides[4].text}
                                href={slides[4].href}
                            />
                        </div>
                        <HomeCard
                            classSize="big-picture"
                            imageSrc={slides[5].image}
                            title={slides[5].title}
                            text={slides[5].text}
                            href={slides[5].href}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

Inspirational.propTypes = {
    locale: PropTypes.string,
    userIsHECollection: PropTypes.bool
};

export default Inspirational;
