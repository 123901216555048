import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import i18n from '../../i18n';

import PaymentOptions from './PaymentOptions';
import CreditCardForm from './CreditCardForm';
import PaypalForm from './PaypalForm';
import PromoCodeForm from './PromoCodeForm';
import { isCollectionPage } from '../../utils/url';

class PaymentDetails extends React.PureComponent {
    static propTypes = {
        fields: PropTypes.element.isRequired,
        products: PropTypes.arrayOf(PropTypes.object),
        errorMessage: PropTypes.string,
        selectedPaymentMethod: PropTypes.string,
        onPaymentMethodChange: PropTypes.func,
        is3xPayment: PropTypes.bool,
        updateIs3xPayment: PropTypes.func,
        subscription: PropTypes.shape({
            // for 3x payment
            userHasSubscription: PropTypes.bool,
            autoRenew: PropTypes.bool,
            active: PropTypes.bool,
            currency: PropTypes.string
        }),
        is3xPaymentFromFinalization: PropTypes.bool,
        updateIs3xPaymentFromFinalization: PropTypes.func,
        needToBuyGp: PropTypes.bool,
        forwardedRef: PropTypes.object,
        isFunnelCollection: PropTypes.bool,
        getCouponName: PropTypes.func,
        hasHome: PropTypes.bool
    };

    disabledSubscriptionForm() {
        return (
            this.props.isFunnelCollection === undefined &&
            (this.props.hasHome === undefined || this.props.hasHome === false) &&
            isCollectionPage(window.location.href)
        );
    }

    render() {
        const {
            fields,
            errorMessage,
            selectedPaymentMethod,
            onPaymentMethodChange,
            forwardedRef,
            isFunnelCollection
        } = this.props;

        const termsOfUseLink = (
            <a
                key="termsOfUseLink"
                href={i18n.t('url:terms-of-use')}
                target="_blank"
                rel="noopener noreferrer"
            >
                link
            </a>
        );
        const privacyPolicyLink = (
            <a
                key="privacyPolicyLink"
                href={i18n.t('url:privacy-policy')}
                target="_blank"
                rel="noopener noreferrer"
            >
                link
            </a>
        );

        return (
            <div
                className={classNames({
                    'disabled-cursor-payment-form': this.disabledSubscriptionForm()
                })}
            >
                <div
                    className={`payment-details ${classNames({
                        'disabled-form': this.disabledSubscriptionForm()
                    })}`}
                    ref={forwardedRef}
                >
                    {errorMessage && (
                        <div className="payment-error">
                            <p className="emphasize">{i18n.t('billing:error.title')}</p>
                            <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
                        </div>
                    )}
                    <PaymentOptions
                        selectedPaymentMethod={selectedPaymentMethod}
                        onPaymentMethodChange={onPaymentMethodChange}
                    />
                    {selectedPaymentMethod === 'credit-card' && (
                        <CreditCardForm
                            fields={fields}
                            products={this.props.products}
                            is3xPayment={this.props.is3xPayment}
                            updateIs3xPayment={this.props.updateIs3xPayment}
                            subscription={this.props.subscription}
                            is3xPaymentFromFinalization={this.props.is3xPaymentFromFinalization}
                            updateIs3xPaymentFromFinalization={this.props.updateIs3xPaymentFromFinalization}
                            needToBuyGp={this.props.needToBuyGp}
                            isFunnelCollection={isFunnelCollection}
                        />
                    )}
                    {selectedPaymentMethod === 'paypal' && <PaypalForm />}
                    {isFunnelCollection && (
                        <>
                            <span className="separator" />
                            <PromoCodeForm getCouponName={this.props.getCouponName} />
                            <span className="separator" />
                            <div className="form-group terms-of-use-checkbox">
                                <Trans
                                    i18nKey="collection:tou-approuval-collection"
                                    components={[termsOfUseLink, privacyPolicyLink]}
                                    i18n={i18n}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

// eslint-disable-next-line react/no-multi-comp
export default forwardRef((props, ref) => <PaymentDetails {...props} forwardedRef={ref} />);
