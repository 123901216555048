import React from 'react';
import Utils from './utils';

export default class Icons {
    static transform(numberOfPaths) {
        const paths = [];
        for (let i = 0; i < numberOfPaths; i++) {
            paths[i] = `path${i + 1}`;
        }

        return paths.map((path) => <span key={path} className={path}></span>);
    }

    static getDefaultProfile() {
        return `${Utils.getCloudfrontDomain()}/images/website/icon_default_profile.jpg`;
    }
}
