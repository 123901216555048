import {
    SET_USER_FETCHING_STATUS,
    SET_LOYALTY_FETCHING_STATUS,
    SET_PAYMENT_METHODS_FETCHING_STATUS
} from '../actions/loading';

export const fetchingStatuses = {
    NOT_STARTED: 'NOT_STARTED',
    STARTED: 'STARTED',
    FINISHED: 'FINISHED'
};

const initialState = {
    userFetchingStatus: fetchingStatuses.NOT_STARTED,
    loyaltyFetchingStatus: fetchingStatuses.NOT_STARTED,
    paymentMethodsFetchingStatus: fetchingStatuses.NOT_STARTED
};

export default function loading(state = initialState, { type, payload }) {
    switch (type) {
        case SET_USER_FETCHING_STATUS:
            return { ...state, userFetchingStatus: payload };
        case SET_LOYALTY_FETCHING_STATUS:
            return { ...state, loyaltyFetchingStatus: payload };
        case SET_PAYMENT_METHODS_FETCHING_STATUS:
            return { ...state, paymentMethodsFetchingStatus: payload };
        default:
            return state;
    }
}
