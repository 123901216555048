import Common from './Common';

export default class Settings {
    static getMailSubscribeList(filters, orderBy, limit, offset) {
        const params = Common.params(
            {
                url: '/v1/settings/mail-subscribe/list',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static getMailSubscribe(filters, orderBy, limit, offset) {
        const params = Common.params(
            {
                url: '/v1/settings/mail-subscribe',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static subscribeToMail(mail) {
        const params = Common.params({
            url: `/v1/settings/mail-subscribe`,
            method: 'post',
            data: {
                mail: mail.id
            }
        });
        return Common.request(params);
    }

    static toggleSubscribtion(subscribtion) {
        const params = Common.params({
            url: `/v1/settings/mail-subscribe/${subscribtion.id}`,
            method: 'patch',
            data: {
                subscribe: !subscribtion.subscribe
            }
        });
        return Common.request(params);
    }
}
