import Api from '../api/Api';
import Group from '../models/Group';

export const LOAD_GROUPS = 'LOAD_GROUPS';
export const LOAD_GROUP_DETAILS = 'LOAD_GROUP_DETAILS';
export const LEAVE_GROUP = 'LEAVE_GROUP';
export const JOIN_GROUP = 'JOIN_GROUP';

export function loadGroups(groups) {
    return {
        type: LOAD_GROUPS,
        payload: groups.map((group) => new Group(group))
    };
}

export function fetchGroups() {
    return (dispatch) => Api.User.fetchGroups().then((response) => dispatch(loadGroups(response)));
}

export function fetchGroupDetails(id, limit, offset = 0) {
    return (dispatch) =>
        Api.User.getGroup(id, limit, offset).then((group) => {
            group.id = Number(id);
            dispatch({
                type: LOAD_GROUP_DETAILS,
                payload: new Group(group)
            });
        });
}

export function leaveGroup(id) {
    return (dispatch) => {
        dispatch({
            type: LEAVE_GROUP,
            payload: id
        });
        return Api.User.leaveGroup(id).fail(() => {
            fetchGroupDetails(id);
        });
    };
}

export function joinGroup(id) {
    return (dispatch, getState) => {
        const group = getState().groups.find((g) => g.get('id') == id);
        const userId = getState().user.get('id');
        dispatch({
            type: JOIN_GROUP,
            payload: { group, userId }
        });
        return Api.User.joinGroup(id).fail(() => {
            fetchGroupDetails(id);
        });
    };
}
