import Model from './Model';

export default class Transfert extends Model {
    static MOTIVE_FILLING_WRITE_PROFIL = 1;
    static MOTIVE_FILLING_WRITE_HOME = 2;
    static MOTIVE_PROFILE_VERIFIED = 3;
    static MOTIVE_REFER = 4;
    static MOTIVE_HELP_US = 5;
    static MOTIVE_GUESTPOINT = 6;
    static MOTIVE_SIGNUP = 7;
    static MOTIVE_RESERVATION = 8;
    static MOTIVE_CANCEL = 9;
    static MOTIVE_REFUND = 10;
    static MOTIVE_GODSON = 11;
    static MOTIVE_GRP_JOIN = 12;
    static MOTIVE_HOME_VERIFIED = 13;
    static MOTIVE_GODSON_HOME = 14;
    static MOTIVE_GODSON_USER = 15;
    static MOTIVE_WELCOME_SPONSOR = 16;
    static MOTIVE_GTG_STAFF = 17;
    static MOTIVE_GODSON_MESSAGE = 18;
    static MOTIVE_CONV_CREATE = 19;
    static MOTIVE_FIRST_SUBSCRIPTION = 20;
    static MOTIVE_BALLOON = 21;
    static MOTIVE_HAPINESS_TEAM_CHANGE_FINALIZED_EXCHANGE_DATES = 25;
    static MOTIVE_HOME_QUALIFY = 26;
    static MOTIVE_GODSON_HOME_QUALIFY = 27;
    static MOTIVE_GODSON_SUBSCRIPTION = 28;
    static MOTIVE_FIRST_SUBSCRIPTION_SPONSORSHIP = 29;
    static MOTIVE_PROMO_CODE = 30;
    static MOTIVE_PROMO_GP = 31;
    static MOTIVE_FIRST_SUBSCRIPTION_COLLECTION = 32;
    static MOTIVE_HAPINESS_TEAM_UPDATE_HOME_ID = 33;
    static MOTIVE_SPONSORSHIP_CANCEL = 35;
    static MOTIVE_LHS_BALANCE_IMPORT = 36;
    static MOTIVE_EXCHANGE_MODIFICATION_DECREASE_NUMBER_OF_NIGHTS = 37;

    static CONVERSATION_NUMBER_OBJECTIVE = 10;
    static REAL_ID_FIRST_SUBSCRIPTION = 7;
    static FAKE_ID_FIRST_SUBSCRIPTION = 6;

    static MOTIVES_DYNAMIC_DISPLAY = [
        Transfert.MOTIVE_GODSON_HOME,
        Transfert.MOTIVE_GODSON_USER,
        Transfert.MOTIVE_WELCOME_SPONSOR,
        Transfert.MOTIVE_GODSON_MESSAGE,
        Transfert.MOTIVE_GODSON,
        Transfert.MOTIVE_HOME_QUALIFY,
        Transfert.MOTIVE_GODSON_HOME_QUALIFY,
        Transfert.MOTIVE_GODSON_SUBSCRIPTION,
        Transfert.MOTIVE_FIRST_SUBSCRIPTION_SPONSORSHIP,
        Transfert.MOTIVE_SPONSORSHIP_CANCEL
    ];

    static MOTIVES_VALUE = {
        [Transfert.MOTIVE_SIGNUP]: 50, // This case is never use
        [Transfert.MOTIVE_FILLING_WRITE_HOME]: 200,
        [Transfert.MOTIVE_FILLING_WRITE_PROFIL]: 100,
        [Transfert.MOTIVE_PROFILE_VERIFIED]: 200,
        [Transfert.MOTIVE_CONV_CREATE]: 250,
        [Transfert.MOTIVE_WELCOME_SPONSOR]: 25, // This case is never use
        [Transfert.MOTIVE_FIRST_SUBSCRIPTION]: 500
    };

    static getFirstSubscriptionTransfert(transferts) {
        if (transferts) {
            return transferts.find(
                (transfert) => transfert.get('motive') == Transfert.MOTIVE_FIRST_SUBSCRIPTION
            );
        }
        return false;
    }
}
