import PropTypes from 'prop-types';
import React from 'react';

import Average from './Average';
import RatingList from './RatingList';
import UserModel from '../../models/User';
import Pagination from '../common/Pagination';

export default class Rating extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(UserModel).isRequired,
        loggedUser: PropTypes.instanceOf(UserModel).isRequired,
        ratings: PropTypes.array.isRequired,
        ratingsData: PropTypes.object,
        mode: PropTypes.oneOf(['user', 'home'])
    };

    static defaultProps = {
        mode: 'user'
    };

    constructor() {
        super();

        this.state = {
            limit: 5,
            offset: 0
        };

        this.goToPage = this.goToPage.bind(this);
    }

    goToPage(page) {
        if (page < 1 && page > this.getNbPages()) {
            return; // out of range
        }
        const offset = (page - 1) * this.state.limit;
        this.setState({
            offset
        });
        // Scroll
        $('html, body').animate({ scrollTop: $('#anchor_pagination').offset().top }, 'slow');
    }

    getCurrentPage() {
        const page = Math.ceil(this.state.offset / this.state.limit) + 1;
        return Math.min(Math.max(1, page), this.getNbPages());
    }

    getNbPages() {
        return Math.ceil(this.props.ratings.length / this.state.limit);
    }

    render() {
        return (
            <div id="anchor_pagination">
                <div className="row">
                    {this.props.mode === 'user' && (
                        <Average
                            globalAvg={this.props.ratingsData.avg.global}
                            guestAvg={this.props.ratingsData.avg.guest}
                            hostAvg={this.props.ratingsData.avg.host}
                            nbExchangesGuest={this.props.ratingsData.exchangeNb.guest}
                            nbExchangesHost={this.props.ratingsData.exchangeNb.host}
                        />
                    )}
                </div>
                {this.props.mode === 'home' ? null : <hr />}
                <RatingList
                    ratings={this.props.ratings}
                    loggedUser={this.props.loggedUser}
                    user={this.props.user}
                    offset={this.state.offset}
                    limit={this.state.limit}
                />
                <Pagination
                    page={this.getCurrentPage()}
                    limit={this.state.limit}
                    nbresults={this.props.ratings.length}
                    gotoPage={this.goToPage}
                />
            </div>
        );
    }
}
