import React from 'react';

import i18n from '../../i18n';
import Icons from '../../utils/Icons';

const RatingRGPDRequirement = () => (
    <div className="rating-certification">
        <span className="icon-i-see-the-light">{Icons.transform(9)}</span>
        <p>
            {`${i18n.t('rating:ratings.listing.authentication')}`}
            <a
                href={i18n.t('rating:listing_rgpd_requirement_learn_more')}
                target="_blank"
                rel="noopener noreferrer"
            >{`${i18n.t('common:learn_more')}.`}</a>
        </p>
    </div>
);

export default RatingRGPDRequirement;
