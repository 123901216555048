import PropTypes from 'prop-types';
import React from 'react';
import MobileDetect from 'mobile-detect';
import Slider from 'react-slick';
import className from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleFavorite } from '../../actions/homeFavorites';
import i18n from '../../i18n';
import Utils from '../../utils/utils';

import HomeModel from '../../models/Home';
import UserModel from '../../models/User';

import BadgeVerified from '../common/BadgeVerified';
import PrevArrow from '../common/PrevArrow';
import NextArrow from '../common/NextArrow';
import HomeReview from './HomeReview';
import HomeReviewGP from './HomeReviewGP';
import Tooltip from '../widget/Tooltip';
import HomeImage from './HomeImage';
import Analytics from '../../utils/analytics';
import CollectionLabel from './CollectionLabel';
import { NAVIGATION_ACTION, NAVIGATION_AREAS, NAVIGATION_TEXT } from '../analytics/analytics-constants';
import useGTM from '../analytics/useGTM';

const TRACKING_CLICK_SOURCE = {
    homeImage: 'home-image',
    favorite: 'favorite',
    userImage: 'user-image'
};

export class Home extends React.Component {
    static propTypes = {
        home: PropTypes.object.isRequired,
        user: PropTypes.instanceOf(UserModel),
        homeFavorites: PropTypes.array,
        homeRollOver: PropTypes.func,
        homeRollOut: PropTypes.func,
        displayWithReview: PropTypes.bool,
        isLogged: PropTypes.bool,
        tooltipBadgeVerified: PropTypes.bool,
        displayFavoritePlaceholder: PropTypes.bool,
        displayCarousel: PropTypes.bool,
        displayLocation: PropTypes.bool,
        displayProfilePicture: PropTypes.bool,
        abTest: PropTypes.bool,
        action: PropTypes.shape({
            toggleFavorite: PropTypes.func
        }),
        source: PropTypes.string
    };

    static defaultProps = {
        displayFavoritePlaceholder: true,
        displayWithReview: true,
        displayCarousel: false,
        displayLocation: true,
        isLogged: true,
        displayProfilePicture: true
    };

    constructor(props) {
        super(props);
        this.homeRef = React.createRef();
        this.responseRateRef = React.createRef();
        this.toggleFavorite = this.toggleFavorite.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.handleRedirectToHomeViewWithTracking = this.handleRedirectToHomeViewWithTracking.bind(this);
        this.handleRedirectToUserViewWithTracking = this.handleRedirectToUserViewWithTracking.bind(this);
        this.shouldHandleRedirectWithTrackingAccordingToSource =
            this.shouldHandleRedirectWithTrackingAccordingToSource.bind(this);
        this.handleHomeboxContentClick = this.handleHomeboxContentClick.bind(this);
        this.openSignup = this.openSignup.bind(this);
    }

    componentDidMount() {
        const home = new HomeModel(this.props.home);
        // open links in new window
        $(this.homeRef.current)
            .find('a')
            .not('[href="#"]')
            .not('.location')
            .on('click', (e) => {
                e.preventDefault();
                const md = new MobileDetect(window.navigator.userAgent);
                if (md.mobile()) {
                    window.open(e.currentTarget.href, '_self');
                } else {
                    window.open(e.currentTarget.href, '_blank');
                }
            });

        // init popovers
        $(this.responseRateRef.current).tooltip({
            container: 'body',
            placement: 'bottom',
            trigger: 'hover',
            title: `<div class="tooltip-response-rate"><p><span class="grey-sm-color">${i18n.t(
                'user:response_delay'
            )}</span>&nbsp;<span class="pull-right">${home
                .get('user')
                .getResponseTimeLabel()}</span></p><p><span class="grey-sm-color">${i18n.t(
                'user:last_connexion'
            )}</span>&nbsp;<span class="pull-right">${moment(home.get('user').get('last_login_date')).format(
                'L'
            )}</span></p></div>`,
            html: true,
            template:
                '<div class="tooltip tooltip-response-rate-home" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
        });
    }

    onMouseOver() {
        if (this.props.homeRollOver) {
            this.props.homeRollOver(this.props.home);
        }
    }

    onMouseOut() {
        if (this.props.homeRollOut) {
            this.props.homeRollOut(this.props.home);
        }
    }

    getBadgeLoyalty(home) {
        const user = home.get('user');
        let loyalty = home.get('loyalty_level');

        if (home.has('user_last_achievement')) {
            loyalty = home.get('user_last_achievement');
        } else if (user && user.has('last_loyalty_achievement')) {
            loyalty = user.get('last_loyalty_achievement');
        }

        return parseInt(loyalty, 10);
    }

    toggleFavorite(event) {
        event.preventDefault();
        if (this.props.isLogged) {
            Analytics.trackGTM('MixpanelAddFavorites', {
                userStatus: this.props.user.getStatus()
            });
            this.sendGTMTrackingData(this.props.source, TRACKING_CLICK_SOURCE.favorite);
            this.props.action.toggleFavorite(this.props.home.id, this.props.homeFavorites);
        } else {
            const { fetchNavigation } = useGTM(this.props.user);

            const area =
                this.props.source === 'map'
                    ? NAVIGATION_AREAS.HOMECARD_MAP_FAVORITES
                    : NAVIGATION_AREAS.HOMECARD_RESULTS_FAVORITES;

            Analytics.trackGTM(
                'button',
                fetchNavigation(NAVIGATION_ACTION.CLICK, area, NAVIGATION_TEXT.ADD_TO_FAVORITES_SIGN_UP)
            );
            document.dispatchEvent(new CustomEvent('open_signup_popup'));
        }

        // don't keep focus after clicked
        event.currentTarget.blur();
    }

    /* eslint-disable no-case-declarations */
    sendGTMTrackingData(pageSource, clickSource) {
        const getCustomizeData = (eventData, eventName) => {
            const event = {
                data: { ...eventData },
                name: eventName
            };
            if (clickSource === TRACKING_CLICK_SOURCE.userImage) {
                event.data.text = 'user_profile_image';
            } else if (clickSource === TRACKING_CLICK_SOURCE.favorite) {
                event.data.text = 'heart_home_card';
                event.name = 'HandleFavorites';
            }
            return event;
        };

        switch (pageSource) {
            case 'map':
                const mapEventData = getCustomizeData(
                    {
                        action: 'click',
                        text: 'home_card',
                        area: 'search_page_map'
                    },
                    'SearchMap'
                );
                Analytics.trackGTM(mapEventData.name, { event_data: mapEventData.data });
                break;
            case 'user-favorites':
                const favoriteEventData = getCustomizeData(
                    {
                        action: 'click',
                        text: 'home_card',
                        area: 'user_favorites'
                    },
                    'HandleFavorites'
                );
                Analytics.trackGTM(favoriteEventData.name, { event_data: favoriteEventData.data });
                break;
            case 'dashboard-favorites':
                const dashboardEventData = getCustomizeData(
                    {
                        action: 'click',
                        text: 'home_card',
                        area: 'dashboard'
                    },
                    'HandleFavorites'
                );
                Analytics.trackGTM(dashboardEventData.name, { event_data: dashboardEventData.data });
                break;
            case 'search-results':
                const searchEventData = getCustomizeData(
                    {
                        action: 'click',
                        text: 'home_card',
                        area: 'search'
                    },
                    'SearchResults'
                );
                Analytics.trackGTM(searchEventData.name, { event_data: searchEventData.data });
                break;
            default:
                break;
        }
    }

    shouldHandleRedirectWithTrackingAccordingToSource() {
        const { source } = this.props;
        const trackingSources = ['search-results', 'map', 'user-favorites', 'dashboard-favorites'];
        return source && trackingSources.includes(source);
    }

    handleRedirect(e, pageSource, clickSource) {
        this.sendGTMTrackingData(pageSource, clickSource);
        window.open(e.target.href, '_blank');
    }

    handleRedirectToHomeViewWithTracking(e) {
        e.preventDefault();
        this.handleRedirect(e, this.props.source, TRACKING_CLICK_SOURCE.homeImage);
    }

    handleRedirectToUserViewWithTracking(e) {
        e.preventDefault();
        this.handleRedirect(e, this.props.source, TRACKING_CLICK_SOURCE.userImage);
    }

    handleHomeboxContentClick(e, url) {
        if (this.shouldHandleRedirectWithTrackingAccordingToSource()) {
            e.target.href = url;
            this.handleRedirectToHomeViewWithTracking(e);
        } else {
            window.location.href = url;
        }
    }

    openSignup() {
        const { fetchNavigation } = useGTM(this.props.user);
        const area =
            this.props.source === 'map'
                ? NAVIGATION_AREAS.HOMECARD_MAP_PHOTO
                : NAVIGATION_AREAS.HOMECARD_RESULTS_PHOTO;

        Analytics.trackGTM(
            'button',
            fetchNavigation(NAVIGATION_ACTION.CLICK, area, NAVIGATION_TEXT.PHOTO_SIGN_UP)
        );
        document.dispatchEvent(new CustomEvent('open_signup_popup'));
    }

    getClassNameAndTranslationWithMinNightsAndUserAlreadyContacted(home) {
        const user = home.get('user');
        const minNights = home.get('min_nights');

        let result = null;

        if (minNights) {
            result = {
                className: 'min-nights-badge',
                translation: i18n.t('home:home_cards_minimum_number_of_nights', {
                    number: minNights
                })
            };
        }

        if (user.alreadyContacted()) {
            result = {
                className: 'already-contacted-badge',
                translation: i18n.t('home:already_contacted')
            };
        }

        return result;
    }

    render() {
        const {
            displayCarousel,
            displayFavoritePlaceholder,
            displayProfilePicture,
            displayLocation,
            displayWithReview,
            isLogged,
            tooltipBadgeVerified,
            user
        } = this.props;
        const home = new HomeModel(this.props.home);

        const url = i18n.t('home:home.view.url', { id: home.id });
        const userUrl = i18n.t('user:user.view.url', { id: home.get('user').id });
        const sliderSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            lazyLoad: true,
            draggable: false,
            fade: true,
            focusOnSelect: false,
            swipeToSlide: false,
            swipe: false,
            accessibility: false,
            adaptiveHeight: false,
            autoplay: false,
            touchMove: false,
            useCSS: true
        };

        if (this.props.abTest) {
            sliderSettings.arrows = false;
        }

        const tagInfo = this.getClassNameAndTranslationWithMinNightsAndUserAlreadyContacted(home);

        const classNameForTag = tagInfo ? tagInfo.className : '';
        const translationForTag = tagInfo ? tagInfo.translation : '';

        return (
            <article
                ref={this.homeRef}
                className={className('homebox', {
                    'already-contacted': home.get('user').alreadyContacted()
                })}
                itemScope
                itemType="http://schema.org/Place"
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
            >
                <div className="homebox-container">
                    <div className="homebox-picture">
                        <link itemProp="image" href={home.picture()} />

                        {displayCarousel && home.attributes.images && home.attributes.images.length > 1 ? (
                            <Slider {...sliderSettings} prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
                                {home.pictures(545, 310).map((image, index) => (
                                    <div key={image.src}>
                                        {index === 0 ? (
                                            <HomeImage
                                                className="home-image-search"
                                                href={url}
                                                {...(this.shouldHandleRedirectWithTrackingAccordingToSource() && {
                                                    onClick: this.handleRedirectToHomeViewWithTracking
                                                })}
                                                imageSrc={image.src}
                                            />
                                        ) : (
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="home-image-search"
                                                href={url}
                                                {...(this.shouldHandleRedirectWithTrackingAccordingToSource() && {
                                                    onClick: this.handleRedirectToHomeViewWithTracking
                                                })}
                                                style={{
                                                    backgroundImage: `url(${image.src})`
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <HomeImage
                                className="homebox-image"
                                {...(this.shouldHandleRedirectWithTrackingAccordingToSource() && {
                                    onClick: this.handleRedirectToHomeViewWithTracking
                                })}
                                href={url}
                                title={home.title()}
                                imageSrc={home.picture(400, 225, false)}
                            />
                        )}

                        {(() => {
                            if (displayProfilePicture) {
                                if (isLogged) {
                                    return (
                                        <HomeImage
                                            className="homebox-user"
                                            {...(this.shouldHandleRedirectWithTrackingAccordingToSource() && {
                                                onClick: this.handleRedirectToUserViewWithTracking
                                            })}
                                            href={userUrl}
                                            imageSrc={home.get('user').picture(60, 60, true)}
                                            loyalty={this.getBadgeLoyalty(home)}
                                        >
                                            <span className="sr-only">
                                                {home.get('user').get('first_name')}
                                            </span>
                                        </HomeImage>
                                    );
                                } else {
                                    return (
                                        <HomeImage
                                            className="homebox-user"
                                            onClick={this.openSignup}
                                            useButton={true}
                                            imageSrc={home.get('user').picture(60, 60, true)}
                                            loyalty={this.getBadgeLoyalty(home)}
                                        />
                                    );
                                }
                            }
                        })()}

                        <div className="tag">
                            {classNameForTag && (
                                <a className={classNameForTag} href={url}>
                                    {translationForTag}
                                </a>
                            )}

                            <CollectionLabel user={user} home={home} />
                        </div>

                        <Tooltip
                            placement="left"
                            title={
                                displayFavoritePlaceholder ? i18n.t('home:favorite.toggle_favorites') : null
                            }
                        >
                            <a
                                href="#"
                                className="changeFavorite item active"
                                data-link={i18n.t('home:setfavorite')}
                                data-id={home.get('id')}
                                onClick={this.toggleFavorite}
                            >
                                <span
                                    className={className('heart', 'icon-heart-fill', {
                                        'heart-favorite': home.isFavorite(this.props.homeFavorites)
                                    })}
                                />
                            </a>
                        </Tooltip>
                    </div>

                    <div className="homebox-content" onClick={(e) => this.handleHomeboxContentClick(e, url)}>
                        <header>
                            <div className="title">
                                <h3 itemProp="name">
                                    {home.isVerified() && (
                                        <BadgeVerified
                                            home={home}
                                            isLogged={isLogged}
                                            withTooltip={tooltipBadgeVerified}
                                        />
                                    )}
                                    <a className="ellipsis" href={url} title={Utils.ucfirst(home.title())}>
                                        {Utils.ucfirst(home.title())}
                                    </a>
                                </h3>
                            </div>
                        </header>

                        {displayLocation && (
                            <div className="home-location">
                                <span>{home.location()}</span>
                                {!displayWithReview && (
                                    <HomeReviewGP guestpointsValue={home.get('guestpoint')} />
                                )}
                            </div>
                        )}

                        {displayWithReview && <HomeReview home={home} />}
                    </div>
                </div>
            </article>
        );
    }
}

const mapStateToProps = (state) => ({
    homeFavorites: state.homeFavorites,
    user: state.user
});

const mapDispatchToProps = (dispatch) => ({
    action: bindActionCreators({ toggleFavorite }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
