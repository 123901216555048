import {
    REQUEST_SENT_REQUESTS,
    SET_SENT_FRIEND_REQUESTS,
    REMOVE_SENT_FRIEND_REQUESTS
} from '../actions/friends';

export default function sentFriendRequests(
    state = {
        isFetching: false,
        users: []
    },
    { type, payload }
) {
    switch (type) {
        case REQUEST_SENT_REQUESTS:
            return Object.assign({}, state, {
                isFetching: true
            });
        case SET_SENT_FRIEND_REQUESTS:
            return Object.assign({}, state, {
                isFetching: false,
                users: payload
            });
        case REMOVE_SENT_FRIEND_REQUESTS:
            return Object.assign({}, state, {
                isFetching: false,
                users: state.users.filter((friend) => friend.id != payload)
            });
        default:
            return state;
    }
}
