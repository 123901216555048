import PropTypes from 'prop-types';
import React from 'react';

export default class Checkbox extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        onChange: PropTypes.func.isRequired,
        checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['mixed'])]),
        required: PropTypes.bool
    };

    static defaultProps = {
        checked: false,
        required: false
    };

    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked == 'mixed' ? 'mixed' : Boolean(this.props.checked)
        };
        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setChecked(nextProps.checked);
    }

    setChecked(checked) {
        this.setState({
            checked: checked == 'mixed' ? 'mixed' : Boolean(checked)
        });
    }

    getChecked() {
        return this.state.checked;
    }

    onChange() {
        this.setState(
            {
                checked: this.state.checked !== true
            },
            () => {
                this.props.onChange.call(this, this.props.value, this.state.checked);
            }
        );
    }

    onClick(event) {
        event.stopPropagation();
    }

    render() {
        return (
            <label htmlFor={this.props.id} className="custom-checkbox" onClick={this.onClick}>
                <input
                    type="checkbox"
                    name={this.props.name}
                    id={this.props.id}
                    checked={this.state.checked}
                    value={this.props.value}
                    onChange={this.onChange}
                    required={this.props.required}
                />
                <span role="checkbox" aria-checked={this.state.checked}></span>
            </label>
        );
    }
}
