import User from '../models/User';

export default class IntlTel {
    static getPhoneType(telInput) {
        const type = telInput.getNumberType();
        const acceptedType = new Map();
        acceptedType.set(0, User.PHONE_TYPE_FIXED_LINE);
        acceptedType.set(1, User.PHONE_TYPE_MOBILE);

        if (acceptedType.get(type)) {
            return `${acceptedType.get(type)}`;
        } else {
            return `${User.PHONE_TYPE_UNDEFINED}`;
        }
    }
}
