import PropTypes from 'prop-types';
import React from 'react';

export default class Stars extends React.Component {
    static propTypes = {
        stars: PropTypes.number
    };

    constructor() {
        super();
        this.nbStar = 5;
    }

    render() {
        if (!_.isUndefined(this.props.stars)) {
            const avgRound = Math.round(this.props.stars * 2) / 2;

            return (
                <span className="stars">
                    {_.times(this.nbStar, (i) => {
                        if (i <= avgRound - 1) {
                            return (
                                <span className="star-icon full" key={i}>
                                    &#9733;
                                </span>
                            );
                        } else if (i < avgRound) {
                            return (
                                <span className="star-icon half" key={i}>
                                    &#9733;
                                </span>
                            );
                        } else {
                            return (
                                <span className="star-icon" key={i}>
                                    &#9733;
                                </span>
                            );
                        }
                    })}
                </span>
            );
        }

        return false;
    }
}
