import React from 'react';
import PropTypes from 'prop-types';

import StarRatingCompact from './StarRatingCompact';

const RatingWidgetStar = ({ nbStars, type }) => (
    <li itemProp="reviewRating" itemScope itemType="http://schema.org/Rating">
        <StarRatingCompact nbStars={nbStars} />
        <span itemProp="reviewAspect">{type}</span>
    </li>
);

RatingWidgetStar.propTypes = {
    nbStars: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
};

export default RatingWidgetStar;
