import { UPDATE_HOME_LOCALE_DESCRIPTION } from '../actions/localeDescription';
import i18n from '../i18n';

export default function localeDescription(state = i18n.language, { type, payload }) {
    switch (type) {
        case UPDATE_HOME_LOCALE_DESCRIPTION:
            return payload;
        default:
            return state;
    }
}
