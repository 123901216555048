import Common from './Common';
import Storage from '../utils/Storage';

export default class Exchange {
    static getByConversation(conversationId) {
        const params = {
            url: `/v1/exchanges/${conversationId}/get-exchanges`,
            method: 'get'
        };
        return Common.request(params);
    }

    static changeToReciprocal(conversationId, homeId) {
        const params = {
            url: `/v1/exchanges/${conversationId}/change-to-reciprocal/${homeId}`,
            method: 'patch'
        };
        return Common.request(params);
    }

    static deleteReciprocal(conversationId, exchangeId) {
        const params = {
            url: `/v1/exchanges/${conversationId}/delete-reciprocal/${exchangeId}`,
            method: 'patch'
        };
        return Common.request(params);
    }

    static patch(exchangeId, fields) {
        const params = {
            url: `/v1/exchanges/${exchangeId}`,
            method: 'patch',
            data: fields
        };
        return Common.request(params);
    }

    static payment(exchangeId, locale) {
        const params = {
            url: `/v1/paybox/exchange`,
            method: 'post',
            data: {
                exchange_id: exchangeId,
                locale
            }
        };
        return Common.request(params);
    }

    static manageDeposit(conversationId, givenback = true) {
        const params = {
            url: `/v1/exchanges/${conversationId}/giveback-deposit`,
            method: 'patch',
            data: {
                givenback
            }
        };
        return Common.request(params);
    }

    static manageGuestpoints(conversationId, givenback = true) {
        const params = {
            url: `/v1/exchanges/${conversationId}/giveback-guestpoints`,
            method: 'patch',
            data: {
                givenback
            }
        };
        return Common.request(params);
    }

    static getExchangesFinalized() {
        const params = {
            url: '/v1/exchanges/user/me',
            method: 'get'
        };
        return Common.request(params);
    }

    static getMoreExchangesFinalized(filter, limit, offset) {
        const params = {
            url: `/v1/exchanges/user/me?filter=${filter}&limit=${limit}&offset=${offset}`,
            method: 'get'
        };
        return Common.request(params);
    }

    static getExchangeRestriction() {
        const params = {
            url: '/v1/exchanges/restrictions',
            method: 'get'
        };

        return Common.request(params);
    }

    static requestExchangeModification(convId, data) {
        const params = {
            url: `/v1/exchanges/${convId}/modify/check`,
            method: 'post',
            data
        };

        return Common.request(params);
    }

    static modifyExchange(convId, exchangeData) {
        const params = {
            url: `/v1/exchanges/${convId}/modify`,
            method: 'patch',
            data: exchangeData
        };
        return Common.request(params);
    }

    static acceptModificator(convId) {
        const params = {
            url: `/v1/exchanges/${convId}/modify/accept`,
            method: 'patch'
        };
        return Common.request(params);
    }

    static cancelModificator(convId) {
        const params = {
            url: `/v1/exchanges/${convId}/modify`,
            method: 'delete'
        };
        return Common.request(params);
    }

    static approveModificationRequest(convId) {
        return Common.request({
            url: `/v1/exchanges/${convId}/modify/approve`,
            method: 'patch'
        });
    }

    static rejectModificationRequest(convId) {
        return Common.request({
            url: `/v1/exchanges/${convId}/modify/refuse`,
            method: 'patch'
        });
    }

    static getCancellationReasons() {
        return Common.request({
            url: `/v1/exchanges/cancellation_reasons`,
            method: 'get'
        });
    }

    static getNumberExchangeReady() {
        const promise = Common.request({
            url: `/v1/exchanges/approved/ready`,
            method: 'get'
        });
        return promise.then((number) => {
            const user = Storage.get('user', 'session');
            if (user) {
                user.number_exchange_to_finalize = number;
                Storage.set('user', user, 'session');
            }
        });
    }
}
