import React from 'react';
import i18n from '../../i18n';
import { trackHomeview, BUTTON_BLOCKER_EVENT } from '../../utils/utils-ts';

const BlurredRatingHeadband = () => (
    <div className="blurred-rating-headband">
        <span className="icon-chat-lock" />
        <div>{i18n.t('rating:ratings.blurred_comments_header.text')}</div>
        <a
            className="blurred-rating-headband-button"
            href={i18n.t('url:subscription_payment')}
            onClick={(e) => trackHomeview(BUTTON_BLOCKER_EVENT, 'review_subscription', e)}
            target="_blank"
            rel="noopener noreferrer"
        >
            {i18n.t('rating:rating.blurred_comments_header.cta')}
        </a>
    </div>
);

export default BlurredRatingHeadband;
