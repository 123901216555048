import { RESET_TOKEN, TOKEN_RESPONSE } from '../actions/auth';

export default function auth(state = {}, { type, payload, clockCorrection }) {
    switch (type) {
        case TOKEN_RESPONSE:
            return {
                accessToken: payload.access_token,
                refreshToken: payload.refresh_token,
                expiresAt: new Date().getTime() + payload.expires_in * 1000 + clockCorrection
            };
        case RESET_TOKEN:
            return {};
        default:
            return state;
    }
}
