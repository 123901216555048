import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import classNames from 'classnames';

import i18n from '../../i18n';

export default class AddPaymentMethod extends React.PureComponent {
    static propTypes = {
        children: PropTypes.element,
        is3xPaymentFromFinalization: PropTypes.bool,
        needToBuyGp: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        const { open } = this.state;
        const { children } = this.props;

        const childrenWithProps = React.Children.map(children, (child) =>
            React.cloneElement(child, {
                collapseParentPanel: this.toggleCollapse,
                is3xPaymentFromFinalization: this.props.is3xPaymentFromFinalization,
                needToBuyGp: this.props.needToBuyGp
            })
        );

        return (
            <Panel className="add-payment-method" expanded={open} onToggle={true}>
                <Panel.Heading>
                    <Panel.Title>
                        <button type="button" onClick={this.toggleCollapse}>
                            <span>{i18n.t('subscription:payment-method.add')}</span>
                            <i
                                className={classNames('fa fa-angle-down down-arrow', {
                                    open
                                })}
                            ></i>
                        </button>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Collapse>
                    <Panel.Body>{childrenWithProps}</Panel.Body>
                </Panel.Collapse>
            </Panel>
        );
    }
}
