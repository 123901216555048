import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Utils from '../../utils/utils';
import i18n from '../../i18n';

function InputSearchTabLastMinute() {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const handleImageLoad = () => {
            setLoaded(true);
        };

        const images = document.querySelectorAll('.content-flexible img');
        images.forEach((image) => {
            image.addEventListener('load', handleImageLoad);
        });

        return () => {
            images.forEach((image) => {
                image.removeEventListener('load', handleImageLoad);
            });
        };
    }, []);

    return (
        <div className={classNames('content-flexible last-minutes', { loaded })} id="last-minutes">
            <picture>
                <img src={`${Utils.getCloudfrontDomain()}/images/website/New_illustrations.svg`} />
            </picture>
            <div className="wording">
                <img
                    // TODO: Should be implemented on Icomoon's side
                    src={`${Utils.getCloudfrontDomain()}/images/website/Vector_24.svg`}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: i18n.t('search:filter.last-minute-text')
                    }}
                />
            </div>
        </div>
    );
}

export default InputSearchTabLastMinute;
