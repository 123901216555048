import React, { useCallback, useState } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import PropTypes from 'prop-types';
import Utils from '../../utils/utils';
import i18n from '../../i18n';
import Icons from '../../utils/Icons';
import GuestPointsIcon from '../common/GuestPointsIcon';

export default function SponsorFriend(props) {
    const defaultProfile = `${Utils.getCloudfrontDomain()}/images/website/icon_default_profile.jpg`;
    const [isVisible, setVisibility] = useState(true);

    const handleOnCancel = useCallback(() => {
        setVisibility(false);

        if (props.onCancel) {
            props.onCancel();
        }
    }, [props.onCancel]);

    const handleOnConfirm = useCallback(() => {
        setVisibility(false);

        if (props.onConfirm) {
            props.onConfirm(props.godfather);
        }
    }, [props.onConfirm, props.godfather]);

    return (
        <Modal dialogClassName="modal-sponsor-friend" show={isVisible}>
            <Modal.Body>
                <h4 className="modal-sponsor-friend-title">
                    {i18n.t('sponsorship:popup.sponsor.title', {
                        firstname: props.godfather.first_name
                    })}
                </h4>
                <span className="modal-sponsor-friend-subtitle">
                    {i18n.t('sponsorship:popup.sponsor.subtitle')}
                </span>
                <div className="modal-sponsor-friend-profile">
                    <img
                        className="modal-sponsor-friend-profile-img"
                        src={props.godfather.image ? props.godfather.image.cdn_link : defaultProfile}
                    />
                    <span className="modal-sponsor-friend-profile-label">{props.godfather.first_name}</span>
                </div>

                <div className="modal-sponsor-friend-about">
                    <p>{i18n.t('sponsorship:popup.sponsor.text')}</p>
                    <div className="modal-sponsor-friend-about-container">
                        <div className="badge-container">
                            <span className="icon icon-badge_referral">{Icons.transform(82)}</span>
                            <span
                                className="first-badge-text badge-text"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('sponsorship:popup.sponsor.first-badge')
                                }}
                            />
                        </div>
                        <div className="badge-container">
                            <GuestPointsIcon value={250} />
                            <span
                                className="second-badge-text badge-text"
                                dangerouslySetInnerHTML={{
                                    __html: i18n.t('sponsorship:popup.sponsor.second-badge')
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="modal-sponsor-friend-footer">
                    <button className="btn btn-primary" onClick={handleOnConfirm}>
                        {i18n.t('sponsorship:popup.sponsor.main-cta')}
                    </button>
                    <button className="btn btn-no" onClick={handleOnCancel}>
                        {i18n.t('user:push-in-app.close-cta')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

SponsorFriend.propTypes = {
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    godfather: PropTypes.object.isRequired
};
