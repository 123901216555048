import React from 'react';
import PropTypes from 'prop-types';

const StarRatingCompact = ({ nbStars }) => (
    <span className="star-rating-compact">
        <span className="star-icon full">&#9733;</span>
        {nbStars && <span itemProp="ratingValue">{nbStars}</span>}
    </span>
);

StarRatingCompact.propTypes = {
    nbStars: PropTypes.number
};

export default StarRatingCompact;
