import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import HomeApi from '../../api/Home';
import Home from '../../models/Home';
import AutocompleteService from '../../utils/AutocompleteService';

import PlaceAutocomplete from '../widget/PlaceAutocomplete';

export default class QueryField extends PlaceAutocomplete {
    static propTypes = {
        ...super.propTypes,
        updateDisplayAlertsStatus: PropTypes.func
    };

    getPredictions(value) {
        const promises = [AutocompleteService.getPlaceSuggestions(value)];

        // search a home if query is an integer
        if (Number.isInteger(Number(value))) {
            promises.push(
                HomeApi.get({ id: value, status: Home.STATUS_ONLINE }).then((res) => {
                    if (res && res.length > 0) {
                        return new Home(res[0]);
                    }
                })
            );
        }

        // merge results
        return Promise.all(promises).then(([predictions, home]) => {
            if (home) {
                predictions.push(home);
            }
            return predictions;
        });
    }

    handleSelect(value, prediction) {
        // redirect to url if selected value is a home
        if (prediction instanceof Home) {
            this.setState({ value });
            return document.location.assign(
                i18n.t('home:home.view.url', {
                    id: value
                })
            );
        }
        return super.handleSelect(value, prediction, {
            action: this.props.updateDisplayAlertsStatus,
            payload: true
        });
    }

    getItemValue(item) {
        return item instanceof Home ? String(item.id) : item.formatedTitle;
    }

    renderItem(item, isHighlighted) {
        return (
            <div
                key={item.properties?.id || item?.id}
                className={classNames('menu-item', { highlight: isHighlighted })}
            >
                {item instanceof Home ? (
                    <span
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('search:autocomplete.predictions.home', {
                                id: item.id
                            })
                        }}
                    />
                ) : (
                    item.formatedTitle
                )}
            </div>
        );
    }
}
