import Common from './Common';

export default class Leads {
    static addLeads(leads) {
        const params = Common.params({
            method: 'post',
            url: '/v1/leads',
            data: leads
        });
        return Common.request(params);
    }
}
