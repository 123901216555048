import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import i18n from '../../../i18n';

import User from '../../../models/User';

export class PhoneBanner extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User)
    };

    state = {
        show: false
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.user &&
            this.props.user.isPhoneVerified() != nextProps.user.isPhoneVerified() &&
            nextProps.user.isPhoneVerified()
        ) {
            this.setState({ show: true });
            setTimeout(() => {
                this.setState({ show: false });
            }, 5500); // 5s+500ms for banner animation
        }
    }

    render() {
        return (
            <div
                className={classNames('alert alert-verification text-center', {
                    off: !this.state.show
                })}
            >
                <span>{i18n.t('user:verification.banner.phone')}</span>
                <i className="icon icon-ok" />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(PhoneBanner);
