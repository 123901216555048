import {
    SET_PAYMENT_METHODS,
    REMOVE_PAYMENT_METHOD,
    RECEIVE_CHANGE_PAYMENT_METHOD,
    REQUEST_CHANGE_PAYMENT_METHOD
} from '../actions/paymentMethods';

export default function paymentMethods(state = { isLoading: false }, { type, payload }) {
    switch (type) {
        case REQUEST_CHANGE_PAYMENT_METHOD:
            return {
                ...state,
                isLoading: true
            };
        case RECEIVE_CHANGE_PAYMENT_METHOD:
        case SET_PAYMENT_METHODS:
            if (payload) {
                payload.allSources = [];
                if (payload.stripe && payload.stripe.sources) {
                    payload.allSources.push(...payload.stripe.sources);
                }
                if (payload.braintree && payload.braintree.sources) {
                    payload.allSources.push(...payload.braintree.sources);
                }
            }
            return { ...payload, isLoading: false };
        case REMOVE_PAYMENT_METHOD: {
            const stateCopy = Object.assign({}, state);
            stateCopy.allSources = [];
            if (stateCopy.stripe && stateCopy.stripe.sources) {
                stateCopy.stripe.sources = stateCopy.stripe.sources.filter(
                    (paymentMethod) => paymentMethod.id != payload
                );
                stateCopy.allSources.push(...stateCopy.stripe.sources);
            }
            if (stateCopy.braintree && stateCopy.braintree.sources) {
                stateCopy.braintree.sources = stateCopy.braintree.sources.filter(
                    (paymentMethod) => paymentMethod.id != payload
                );
                stateCopy.allSources.push(...stateCopy.braintree.sources);
            }
            return stateCopy;
        }
        default:
            return state;
    }
}
