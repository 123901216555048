import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import classNames from 'classnames';

import AutocompleteService from '../../utils/AutocompleteService';
import Utils from '../../utils/utils';
import i18n from '../../i18n';
import Screen from '../../utils/Screen';

export default class PlaceAutocomplete extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        delay: PropTypes.number,
        iconSearch: PropTypes.bool,
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        onSelect: PropTypes.func,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        onFocus: PropTypes.func,
        onClick: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        setError: PropTypes.func,
        isFromAddressPicker: PropTypes.bool,
        setOpenedModals: PropTypes.func,
        closeSuggestion: PropTypes.func,
        isFlexibleSearch: PropTypes.bool
    };

    static defaultProps = {
        delay: 500,
        value: '',
        required: false,
        onSelect: () => {},
        isFromAddressPicker: false,
        closeSuggestion: () => {},
        setOpenedModals: () => {},
        isFlexibleSearch: false
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            value: props.value,
            prevValue: props.value,
            predictions: []
        };

        this.input = React.createRef();

        this.renderInput = this.renderInput.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleMenuVisibilityChange = this.handleMenuVisibilityChange.bind(this);

        this.timer = null;

        this.getPredictionsWithDebounce = this.debounce(this.getPredictions, props.delay);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.prevValue) {
            return Object.assign({}, state, {
                value: props.value,
                prevValue: props.value,
                predictions: []
            });
        }
        return null;
    }

    handleChange(event, value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        if (this.props.setError) {
            this.props.setError(null);
        }
        this.setState({ value });
        if (value !== '') {
            return this.getPredictionsWithDebounce(value).then((predictions) => {
                if (_.isEmpty(predictions) && this.props.setError) {
                    this.props.setError(
                        i18n.t('home:home.edit.address_not_recognized_error_message', {
                            contact_email: i18n.t('common:contact-email')
                        })
                    );
                } else {
                    this.setState({ predictions });
                }
            });
        } else {
            if (this.props.setError) {
                this.props.setError(i18n.t('home:error_empty_address'));
            }
            this.handleSelect('', null);
            return Promise.resolve();
        }
    }

    handleSelect(value, suggestion, cb = null) {
        this.setState({ value }, () => {
            /* update alerts display status */
            if (cb) {
                cb.action(cb.payload);
            }
        });
        if (value && this.props.setError) {
            this.props.setError(null);
        }
        Utils.selectSuggestion(suggestion, this.props);

        if (Screen.isMobile() && value.length > 0 && this.props.isFlexibleSearch) {
            this.props.closeSuggestion();
        }
    }

    focus() {
        if (this.input.current) {
            this.input.current.focus();
        }
    }

    blur() {
        if (this.input.current) {
            this.input.current.blur();
        }
    }

    debounce(func, wait) {
        let timeout;
        return (...args) =>
            new Promise((resolve) => {
                const later = () => {
                    timeout = null;
                    resolve(func.apply(this, args));
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            });
    }

    getPredictions(value) {
        if (this.props.isFromAddressPicker) {
            return AutocompleteService.getAddressSuggestions(value);
        } else {
            return AutocompleteService.getPlaceSuggestions(value);
        }
    }

    getItemValue(item) {
        return item.formatedTitle;
    }

    renderInput(props) {
        return (
            <React.Fragment>
                {this.props.iconSearch && <i className="icon-search" />}
                <input {...props} />
            </React.Fragment>
        );
    }

    renderItem(item, isHighlighted) {
        return (
            <div key={item.id} className={classNames('menu-item', { highlight: isHighlighted })}>
                {item.formatedTitle}
            </div>
        );
    }

    renderMenu(items, value, style) {
        return (
            <div
                className={classNames('menu', {
                    hide: items.length === 0 || value.length === 0
                })}
                style={{
                    ...style,
                    ...this.menuStyle,
                    background: 'white'
                }}
            >
                {items}
            </div>
        );
    }

    handleMenuVisibilityChange(isOpen) {
        if (Screen.isMobile() && isOpen && this.state.value.length > 0 && this.props.isFlexibleSearch) {
            const isSearchBarClicked = Utils.isSearchBarClicked(event);
            Utils.openModalsFullScreen(isSearchBarClicked);
            this.props.setOpenedModals(true);
        }
        this.setState({ isOpen });
    }

    render() {
        const { className, disabled, name, placeholder, required, onFocus, onBlur, onChange, onClick } =
            this.props;
        const { value, predictions } = this.state;

        return (
            <Autocomplete
                autoHighlight={true}
                getItemValue={this.getItemValue}
                inputProps={{
                    className: classNames('form-control autocomplete', className),
                    name,
                    placeholder,
                    required,
                    disabled,
                    onFocus,
                    onClick,
                    onBlur,
                    onChange
                }}
                items={predictions}
                onChange={this.handleChange}
                onMenuVisibilityChange={this.handleMenuVisibilityChange}
                onSelect={this.handleSelect}
                ref={this.input}
                renderInput={this.renderInput}
                renderItem={this.renderItem}
                renderMenu={this.renderMenu}
                value={value}
                wrapperProps={{
                    className: 'form-group'
                }}
                wrapperStyle={{}}
            />
        );
    }
}
