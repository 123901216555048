import React, { useCallback, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import i18n from '../../../i18n';
import InputNumber from '../../../../src/components/form/InputNumber';
import Utils from '../../../utils/utils';
import usePortal from '../../../utils/usePortal';
import Screen from '../../../utils/Screen';

const CustomGuestNb = (props) => {
    const [guestNb, setGuestsNb] = useState(0);
    const [adultsNb, setAdultsNb] = useState(0);
    const [childrenNb, setChildrenNb] = useState(0);
    const [babiesNb, setBabiesNb] = useState(0);
    const guests = [
        { name: i18n.t('user:traveler.type_adult_plural'), value: adultsNb },
        { name: i18n.t('user:traveler.type_child_plural'), value: childrenNb },
        { name: i18n.t('user:traveler.type_baby_plural'), value: babiesNb }
    ];
    const formId = Utils.uniqueId('guestNb');

    const calculateNumberOfGuest = useCallback((adults, children, babies) => {
        props.onChange(adults, children, babies);
        setGuestsNb(adults + children + babies);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (props.popupRef?.current) {
                const refButtonAndPopup =
                    props.popupRef.current.contains(event.target) ||
                    props.customGuestNbRef.current.contains(event.target);
                props.openGuestNb(refButtonAndPopup);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [props.popupRef, adultsNb, childrenNb, babiesNb]);

    // Update from url
    useEffect(() => {
        const params = queryString.parse(window.location.search);
        if (!(props.hashPreferredDestination && window.location.hash === '#preferred-destination-form')) {
            const adults = params.adults ? parseInt(params.adults, 10) : null;
            const children = params.children ? parseInt(params.children, 10) : null;
            const babies = params.babies ? parseInt(params.babies, 10) : null;
            if (params.adults) {
                setAdultsNb(adults);
            }
            if (params.children) {
                setChildrenNb(children);
            }
            if (params.babies) {
                setBabiesNb(babies);
            }
            if (params.adults || params.children || params.babies) {
                setGuestsNb(adults + children + babies);
            }
        }
    }, []);

    // Handle props.home updates
    useEffect(() => {
        if (props.home?.size?.beds) {
            const { adults = 0, children = 0, babies = 0 } = props.home.size.beds;
            setAdultsNb(adults);
            setChildrenNb(children);
            setBabiesNb(babies);
            setGuestsNb(adults + children + babies);
        }
    }, [props.home]);

    const validateNumberOfGuest = () => {
        calculateNumberOfGuest(adultsNb, childrenNb, babiesNb);
        props.openGuestNb(false);
    };

    const onChangeGuestNb = useCallback(
        (value) => {
            const valueGuest = parseInt(value.target.value, 10);
            const { name } = value.target;

            switch (name) {
                case guests[0].name:
                    setAdultsNb(valueGuest);
                    break;
                case guests[1].name:
                    setChildrenNb(valueGuest);
                    break;
                case guests[2].name:
                    setBabiesNb(valueGuest);
                    break;
                default:
                    return false;
            }
        },
        [guests]
    );

    const onFocus = useCallback(() => {
        const { divRef } = props;
        if (divRef?.current) {
            divRef.current.classList.add('focus');
        }
    }, []);

    const onBlur = useCallback(() => {
        const { divRef } = props;
        if (divRef?.current) {
            divRef.current.classList.remove('focus');
        }
    }, []);

    const showPopup = (portal = false) => {
        const popup = props.open && (
            <div
                ref={props.popupRef}
                className={`popup-select-guest-nb ${portal ? 'portal' : ''}`}
                {...(portal &&
                    !Screen.isMobile() && {
                        style: {
                            marginLeft: props.widthSearchFilterRow + 15,
                            marginTop: -2
                        }
                    })}
            >
                {Screen.isMobile() && props.showTitle && (
                    <>
                        <i className="icon-shape-close" onClick={() => props.openGuestNb(false)} />
                        <h2>{i18n.t('search:guests-nb-mobile.title')}</h2>
                    </>
                )}
                <div className="input-number-container">
                    {guests.map((guest) => (
                        <div key={guest.name} className="container-input-number">
                            <div className="name">{guest.name}</div>
                            <InputNumber
                                id={guest.name}
                                name={guest.name}
                                value={guest.value}
                                onChange={onChangeGuestNb}
                                required
                                placeholder="30"
                                min={0}
                                max={19}
                            />
                        </div>
                    ))}
                    <span className="span-guest-nb">
                        <button
                            type="button"
                            className="btn btn-primary btn-guest-nb"
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={(event) => {
                                event.stopPropagation();
                                validateNumberOfGuest();
                            }}
                        >
                            {i18n.t('user:validate')}
                        </button>
                    </span>
                </div>
            </div>
        );
        if (portal) {
            const target = usePortal('search-filter');
            return createPortal(popup, target);
        } else {
            return popup;
        }
    };

    const openFromSimulateClickRef = props.openFromSimulateClick ? { ref: props.openFromSimulateClick } : {};
    const customGuestNbRef = props.customGuestNbRef ? { ref: props.customGuestNbRef } : {};
    return (
        <div
            className={`container-select-guest-nb ${props.className ? props.className : ''}`}
            {...customGuestNbRef}
        >
            <div
                className={`select-guest-nb ${guestNb > 0 ? 'selected-guest-nb' : ''}`}
                {...openFromSimulateClickRef}
                onClick={props.openGuestNb}
            >
                <div className="text-guest-nb">
                    <input
                        className="form-control"
                        name="guests_nb"
                        id={formId}
                        value={
                            guestNb <= 0
                                ? i18n.t('common:guests')
                                : i18n.t('search:filter.guests_nb', { count: guestNb })
                        }
                        onFocus={onFocus}
                        onBlur={onBlur}
                        readOnly
                    />
                </div>
                <div className="icon-arrow-down">
                    <i className="fa fa-angle-down down-arrow" />
                </div>
            </div>
            {showPopup(props.portal)}
        </div>
    );
};

CustomGuestNb.propTypes = {
    home: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    className: PropTypes.string,
    onChange: PropTypes.func,
    openGuestNb: PropTypes.func,
    open: PropTypes.bool,
    customGuestNbRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),
    popupRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),
    openFromSimulateClick: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.object })
    ]),
    divRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),
    hashPreferredDestination: PropTypes.bool,
    portal: PropTypes.bool,
    widthSearchFilterRow: PropTypes.number,
    showTitle: PropTypes.bool
};

export default CustomGuestNb;
