import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import Utils from '../../../utils/utils';
import Group from '../../../models/Group';
import ModalJoinClub from './../groupview/ModalJoinClub';

export default class GroupJoinClubButton extends React.PureComponent {
    static propTypes = {
        group: PropTypes.instanceOf(Group).isRequired,
        innerRef: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        this.togglePopup = this.togglePopup.bind(this);
    }

    togglePopup() {
        this.setState({ showModal: !this.state.showModal });
    }

    getDescription() {
        if (!this.props.group.get('description')) {
            const locale = document.documentElement.lang;
            const groupDescriptionsByLocale = Utils.indexBy(this.props.group.get('descriptions'), 'locale');
            return groupDescriptionsByLocale[locale] && groupDescriptionsByLocale[locale].description
                ? groupDescriptionsByLocale[locale].description
                : this.props.group.get('description');
        } else {
            return this.props.group.get('description');
        }
    }

    render() {
        const { showModal } = this.state;
        const { group } = this.props;
        if (!group) {
            return false;
        }
        return (
            <span>
                <a className="btn btn-xs btn-primary btn-consult pull-right" onClick={this.togglePopup}>
                    {i18n.t(`user:groups.join_club`)}
                </a>
                <ModalJoinClub
                    innerRef={this.props.innerRef}
                    showModal={showModal}
                    description={this.getDescription()}
                    togglePopup={this.togglePopup}
                    id={Number(group.get('id'))}
                />
            </span>
        );
    }
}
