import Api from '../api/Api';

export const FETCH_HOME_FAVORITES = 'FETCH_HOME_FAVORITES';
export const ADD_HOME_FAVORITE = 'ADD_HOME_FAVORITE';
export const REMOVE_HOME_FAVORITE = 'REMOVE_HOME_FAVORITE';

export function getFavorites(homeFavorites) {
    return {
        type: FETCH_HOME_FAVORITES,
        homeFavorites
    };
}

export function addFavorite(homeId) {
    return {
        type: ADD_HOME_FAVORITE,
        homeFavorites: homeId
    };
}

export function removeFavorite(homeId) {
    return {
        type: REMOVE_HOME_FAVORITE,
        homeFavorites: homeId
    };
}

export function fetchHomeFavorites() {
    return (dispatch) => {
        Api.Favorites.getFavorites().then((response) => {
            dispatch(getFavorites(response.map((res) => Number(res))));
        });
    };
}

export function toggleFavorite(homeId, homeFavorites) {
    return (dispatch) => {
        if (Api.Favorites.hasFavorite(homeId, homeFavorites)) {
            Api.Favorites.removeFavorite(homeId).then(() => dispatch(removeFavorite(homeId)));
        } else {
            Api.Favorites.addFavorite(homeId).then(() => {
                dispatch(addFavorite(homeId));
            });
        }
    };
}
