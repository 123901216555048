import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import Utils from '../../utils/utils';

export default class HomeRating extends React.Component {
    static propTypes = {
        home: PropTypes.object.isRequired
    };

    render() {
        const { home } = this.props;
        let homeImageUrl = `${Utils.getCloudfrontDomain()}/images/website/logo/he-logo.svg`;
        if (home?.get('images')?.length > 0) {
            homeImageUrl = home.get('images')[0].cdn_link;
        }
        let homeDescription = '';
        if (home?.get('descriptions')?.length > 0) {
            homeDescription =
                home.get('descriptions').find((d) => !_.isEmpty(d.title) && d.locale === i18n.language) || '';
        }

        if (home.get('exchange_count') > 0) {
            return (
                <span
                    className="home-rating"
                    itemProp="aggregateRating"
                    itemScope
                    itemType="http://schema.org/AggregateRating"
                >
                    <span itemProp="itemReviewed" itemScope itemType="https://schema.org/LocalBusiness">
                        <meta itemProp="image" content={homeImageUrl?.cdn_link} />
                        <meta itemProp="name" content={homeDescription?.title} />
                        <meta itemProp="address" content={home?.location()} />
                    </span>

                    {/* eslint-disable */}
                    {home.has('global_rating') && !_.isNull(home.get('global_rating')) && (
                        <span className="rating" itemProp="ratingValue">
                            {parseFloat(home.get('global_rating'), 10).toFixed(1)}
                        </span>
                    )}

                    {/* eslint-enable */}
                    <i className="icon-home-exchange" />
                    <span>{home.get('exchange_count')}</span>
                    <meta itemProp="reviewCount" content={home.get('exchange_count')} />
                </span>
            );
        }
        return false;
    }
}
