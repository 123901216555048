import Model from './Model';

export default class Traveler extends Model {
    static TRAVELER_TYPE_ADULT = 1;
    static TRAVELER_TYPE_TEENAGER = 2;
    static TRAVELER_TYPE_CHILD = 3;
    static TRAVELER_TYPE_BABY = 4;

    static getLabel(type) {
        switch (type) {
            case Traveler.TRAVELER_TYPE_ADULT:
                return 'user:traveler.type_adult';
            case Traveler.TRAVELER_TYPE_TEENAGER:
                return 'user:traveler.type_teenager';
            case Traveler.TRAVELER_TYPE_CHILD:
                return 'user:traveler.type_child';
            case Traveler.TRAVELER_TYPE_BABY:
                return 'user:traveler.type_baby';
            default:
                return '';
        }
    }
}
