import React from 'react';
import PropTypes from 'prop-types';

const CarouselButton = ({ className, style, onClick, iconName }) => (
    <button type="button" className={className} style={style} onClick={onClick}>
        <i className={iconName} />
    </button>
);

CarouselButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    iconName: PropTypes.string
};

export default CarouselButton;
