export const SET_USER_FETCHING_STATUS = 'SET_USER_FETCHING_STATUS';
export const SET_LOYALTY_FETCHING_STATUS = 'SET_LOYALTY_FETCHING_STATUS';
export const SET_PAYMENT_METHODS_FETCHING_STATUS = 'SET_PAYMENT_METHODS_FETCHING_STATUS';

export function setUserFetchingStatus(status) {
    return {
        type: SET_USER_FETCHING_STATUS,
        payload: status
    };
}

export function setLoyaltyFetchingStatus(status) {
    return {
        type: SET_LOYALTY_FETCHING_STATUS,
        payload: status
    };
}

export function setPaymentMethodsFetchingStatus(status) {
    return {
        type: SET_PAYMENT_METHODS_FETCHING_STATUS,
        payload: status
    };
}
