import React from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from '../../hooks';
import Utils from '../../utils/utils';
import i18n from '../../i18n';
import { BREAKPOINTS } from '../../constants';
import { isCollectionPage } from '../../utils/url';
import Analytics from '../../utils/analytics';

const DiscoverCollection = ({ displayOnMobile }) => {
    const size = useWindowSize();

    const isMobile = size?.width <= BREAKPOINTS.MOBILE;
    const backgroundImgUrl = `${Utils.getCloudfrontDomain()}/images/website/collection/discover/discover${
        isMobile ? '_mobile' : ''
    }.jpg`;
    const textContent = i18n.t(`collection:discoverCollection.text${isMobile ? 'Mobile' : ''}`);
    const isDisplayed =
        !isCollectionPage(window.location.href) &&
        ((isMobile && displayOnMobile) || (!isMobile && !displayOnMobile));

    const handleClick = (event) => {
        event.preventDefault();
        const href = `${i18n.t('common:gtg.url')}collection`;

        const eventData = {
            area: 'homepage_regular',
            action: 'click',
            text: 'banner_collection_footer',
            collection: true
        };
        Analytics.trackGTM('banner_collection_footer', { event_data: eventData });

        window.location.href = href;
    };

    return (
        isDisplayed && (
            <div className="container dashboard-module discover-section">
                <div className="discover-container">
                    <a href={`${i18n.t('common:gtg.url')}collection`} onClick={handleClick}>
                        <div className="discover-content">
                            <div className="discover-logo">
                                <img
                                    src={`${Utils.getCloudfrontDomain()}/images/website/collection/collection-logo.svg`}
                                />
                            </div>
                            <div
                                className="discover-text"
                                dangerouslySetInnerHTML={{ __html: textContent }}
                            ></div>
                            <div className="discover-cta">
                                <div className="background-cta"></div>
                                <p
                                    className="cta-link"
                                    dangerouslySetInnerHTML={{
                                        __html: i18n.t('collection:discoverCollection.cta')
                                    }}
                                />
                                <i className="icon-right_arrow" />
                            </div>
                        </div>
                        <img className="discover-img" src={backgroundImgUrl} />
                    </a>
                </div>
            </div>
        )
    );
};

DiscoverCollection.propTypes = {
    displayOnMobile: PropTypes.bool
};

export default DiscoverCollection;
