import PropTypes from 'prop-types';
import React from 'react';
import Api from '../../api/Api';
import i18n from '../../i18n';
import Home from '../../models/Home';
import User from '../../models/User';

export default class BadgeVerified extends React.Component {
    static propTypes = {
        home: PropTypes.instanceOf(Home),
        withTooltip: PropTypes.bool,
        noLink: PropTypes.bool,
        isLogged: PropTypes.bool
    };

    static defaultProps = {
        withTooltip: true,
        noLink: false
    };

    constructor(props) {
        super(props);
        this.badgeRef = React.createRef();
        this.state = {
            user: new User(Api.User.identity())
        };
    }

    componentDidMount() {
        if (this.props.withTooltip) {
            $(this.badgeRef.current).tooltip({
                placement: 'right',
                title: i18n.t('home:verified')
            });
        }
    }

    url() {
        if (this.state.user.get('verified_status') === User.STATUS_NOT_VERIFIED) {
            return i18n.t('user:user.verif.url', { id: this.state.user.id });
        } else if (this.props.home) {
            return i18n.t('home:home.view.url', { id: this.props.home.id });
        } else {
            return i18n.t('user:user.edit.url', { id: this.state.user.id });
        }
    }

    render() {
        const { noLink, isLogged } = this.props;
        if (!noLink && this.state.user && isLogged) {
            return <a href={this.url()} className="badge-verified" ref={this.badgeRef} />;
        } else {
            return <span className="badge-verified" ref={this.badgeRef} />;
        }
    }
}
