import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import i18n from '../../i18n';

import { CurrencyFormatter } from '../../utils/Intl';
import Subscription from '../../models/Subscription';
import Utils from '../../utils/utils';

export const PaymentSubTotal = ({
    currency,
    mainText,
    subText,
    price,
    type,
    coupon,
    is3xPayment,
    is3xPaymentFromFinalization
}) => {
    // for 3x payment
    const shouldDisplay3xPaymentInfo = _.isNull(is3xPaymentFromFinalization)
        ? is3xPayment
        : is3xPaymentFromFinalization;

    const totalProductPrice = price + Subscription.EUROPE_SERVICE_CHARGE_PRICE;

    return (
        <div className="summary row">
            <div className="text col-xs-8">
                <div>{mainText}</div>
            </div>
            <div className="col-xs-4 amount subtotal">{CurrencyFormatter.format(price, currency)}</div>
            <div className="text col-xs-12">
                <div className="small-print">{subText}</div>
            </div>
            {shouldDisplay3xPaymentInfo && (
                <div className="payment3x">
                    <div className="service-fees col-xs-8">
                        {i18n.t('billing:payment_subtotal.3xpayment.charge')}
                    </div>
                    <div className="service-fees col-xs-4 amount subtotal">
                        {CurrencyFormatter.format(Subscription.EUROPE_SERVICE_CHARGE_PRICE, currency)}
                    </div>
                    <div className="deadlines-text col-xs-12">
                        {i18n.t('billing:payment_subtotal.3xpayment.deadlines.title')}
                    </div>
                    {['first', 'second', 'third'].map((index) => (
                        <>
                            <div className="deadlines-text col-xs-8">
                                {i18n.t(`billing:payment_subtotal.3xpayment.deadlines.${index}`)}
                            </div>
                            <div className="deadlines-text col-xs-4 amount subtotal">
                                {CurrencyFormatter.format(
                                    Utils.get2DigitsDecimal(totalProductPrice / 3),
                                    currency
                                )}
                            </div>
                        </>
                    ))}
                </div>
            )}
            {type === 'subscription' && coupon.discount && (
                <React.Fragment>
                    <div className="text col-xs-8">
                        <div>
                            {i18n.t('billing:promo_code.discount')} : {coupon.couponName}
                        </div>
                    </div>
                    <div className="col-xs-4 amount discount">
                        {coupon.couponType === 'guestpoint'
                            ? `+ ${coupon.discount} GP`
                            : CurrencyFormatter.format(coupon.discount, currency)}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

PaymentSubTotal.propTypes = {
    currency: PropTypes.string.isRequired,
    mainText: PropTypes.string.isRequired,
    subText: PropTypes.string,
    price: PropTypes.number,
    type: PropTypes.string,
    coupon: PropTypes.shape({
        discount: PropTypes.number,
        couponName: PropTypes.string,
        couponType: PropTypes.string
    }),
    is3xPayment: PropTypes.bool,
    is3xPaymentFromFinalization: PropTypes.bool
};

const mapStateToProps = (state) => ({
    coupon: state.subscription.coupon
});

export default connect(mapStateToProps)(PaymentSubTotal);
