import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Toggle = (props) => {
    const handleClasses = classNames({
        handle: true,
        withText: Boolean(props.text)
    });

    const onChange = () => {
        props.onChange({
            target: {
                name: props.name,
                value: !props.checked
            }
        });
    };

    return (
        <label className="toggle">
            <input
                type="checkbox"
                id={props.id || props.name}
                name={props.name}
                checked={props.checked}
                onChange={onChange}
            />
            <span className={handleClasses}>
                {props.text && (
                    <div>
                        <span className="off-text">{props.text.off}</span>
                        <span className="on-text">{props.text.on}</span>
                    </div>
                )}
            </span>
        </label>
    );
};

Toggle.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    text: PropTypes.shape({
        on: PropTypes.string.isRequired,
        off: PropTypes.string.isRequired
    })
};

export default Toggle;
