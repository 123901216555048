import { LOAD_HOME_RATINGS } from '../actions/homeRatings';

function computeAverageRating(ratings) {
    const ratingNb = ratings.length;
    let unratedRatingsNb = 0;
    let average = ratings.reduce((avg, rating) => {
        if (!_.isNull(rating.clean) && !_.isNull(rating.communication) && !_.isNull(rating.expectation)) {
            return avg + (rating.clean + rating.communication + rating.expectation) / 3;
        }
        unratedRatingsNb++;
        return avg;
    }, 0);

    if (unratedRatingsNb === ratingNb) {
        // The home has never been rated
        average = null;
    } else {
        average /= ratingNb - unratedRatingsNb;
        average = Math.round(average * 10) / 10;
    }

    return average;
}

export default function homeRatings(state = {}, { type, payload }) {
    switch (type) {
        case LOAD_HOME_RATINGS:
            for (const homeId in payload) {
                payload[homeId] = {
                    average: computeAverageRating(payload[homeId]),
                    ratings: payload[homeId]
                };
            }
            return Object.assign({}, state, payload);
        default:
            return state;
    }
}
