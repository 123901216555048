import Api from '../api/Api';

import Home from '../models/Home';

export const LOAD_HOME = 'LOAD_HOME';

export function loadHome(home) {
    return {
        type: LOAD_HOME,
        payload: {
            [home.id]: new Home(home)
        }
    };
}

export function loadHomes(homes = []) {
    const homeList = {};
    homes.forEach((home) => {
        homeList[home.id] = new Home(home);
    });
    return {
        type: LOAD_HOME,
        payload: homeList
    };
}

export function fetchHome(id) {
    return (dispatch) =>
        Api.Home.getOne(id).then((home) => {
            dispatch(loadHome(home));
        });
}

export function fetchHomes(filters, orderBy, limit, offset) {
    return (dispatch) =>
        Api.Home.get(filters, orderBy, limit, offset).then((homes) => dispatch(loadHomes(homes)));
}
