import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

const HomeReviewGP = ({ guestpointsValue }) => (
    <span className="homebox-review-gp">
        {guestpointsValue} {i18n.t('home:gp_per_night')}
    </span>
);

HomeReviewGP.propTypes = {
    guestpointsValue: PropTypes.number.isRequired
};

export default HomeReviewGP;
