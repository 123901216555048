import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Loading from '../common/Loading';
import i18n from '../../i18n';

export default class TermsOfUsePopupIframe extends React.PureComponent {
    static propTypes = {
        data: PropTypes.string,
        back: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };

        this.renderIframeContent = this.renderIframeContent.bind(this);
    }

    componentDidMount() {
        this.renderIframeContent();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.renderIframeContent();
        }
    }

    renderIframeContent() {
        if (this.props.data && this.iframe) {
            this.iframe.contentWindow.document.open();
            this.iframe.contentWindow.document.write(this.props.data);
            this.iframe.contentWindow.document.close();
            this.setState({
                loading: false
            });
        } else {
            this.setState({
                loading: true
            });
        }
    }

    render() {
        return (
            <div className="terms-of-use-popup-iframe-container">
                {this.state.loading && <Loading width="40px" />}
                <div
                    className={classNames('iframe-scroll', {
                        loading: this.state.loading
                    })}
                >
                    <iframe
                        ref={(ref) => (this.iframe = ref)}
                        sandbox="allow-same-origin"
                        className="terms-of-use-popup-iframe"
                    />
                </div>
                <hr />
                <div className="modal-terms-of-use-actions">
                    <button className="btn btn-primary btn-lg" type="button" onClick={this.props.back}>
                        {i18n.t('common:close')}
                    </button>
                </div>
            </div>
        );
    }
}
