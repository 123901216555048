import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../i18n';
import CarouselComponent from '../common/carousel/carousel';
import InputSearchTabFixedDates from './InputSearchTabFixedDates';
import InputSearchTabFlexibleDates from './InputSearchTabFlexibleDates';
import InputSearchTabLastMinute from './InputSearchTabLastMinute';
import Filters from '../../models/Filters';
import { searchTypeLabels, ParametersContext } from './InputSearch';
import Utils from '../../utils/utils';
import useMedia from '../../utils/useMedia';
import { hasAncestorLike } from './utils';

const [TYPE_FIXED_DATES, TYPE_FLEXIBLE_DATES, TYPE_LAST_MINUTE] = Filters.SEARCH_TYPES;

function InputSearchModal({ onValidate, context }) {
    const ref = useRef();
    const [selectedItem, setSelectedItem] = useState(0);
    const { setShowModal, searchType, setSearchType, isValid } = useContext(ParametersContext);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            const contains =
                (ref?.current && ref.current.contains(e.target)) ||
                hasAncestorLike(e.target, 'div.kalendae.block-calendar');
            if (contains) {
                setShowModal(true);
            } else {
                onValidate();
            }
        };
        setSelectedItem(Filters.SEARCH_TYPES.indexOf(searchType));
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
            document.body.style.overflow = 'auto';
        };
    }, [onValidate, searchType]);

    const handleSelectTab = (_searchType, index) => {
        setSelectedItem(index);
        setSearchType(_searchType);
    };

    const handleCloseCalendar = () => {
        Utils.closeModalsFullScreen();
        setShowModal(false);
    };

    const handleValidateTextBtn = (searchTypeDate) => {
        const defaultText = 'continue';
        const mediaValue = useMedia('(max-width: 767px)');

        if (mediaValue) {
            return defaultText;
        } else {
            switch (searchTypeDate) {
                case TYPE_FIXED_DATES:
                case TYPE_FLEXIBLE_DATES:
                    return 'select_these_dates';
                case TYPE_LAST_MINUTE:
                    return 'select_last_minute';
                default:
                    return defaultText;
            }
        }
    };

    const slides = Filters.SEARCH_TYPES.filter(
        (type) => context !== 'alert' || type !== TYPE_LAST_MINUTE
    ).map((type, index) => {
        return (
            <div key={type}>
                <div
                    key={type}
                    onClick={() => handleSelectTab(type, index)}
                    className={classNames('flexible-tab-title', { active: index === selectedItem })}
                >
                    {searchTypeLabels[type]}
                </div>
            </div>
        );
    });

    return (
        <div
            className={classNames('modal-flexible-search', {
                'container-flexible-dates': searchType === TYPE_FLEXIBLE_DATES,
                'container-last-minute': searchType === TYPE_LAST_MINUTE
            })}
            ref={ref}
        >
            <i className="icon-shape-close" onClick={() => handleCloseCalendar()} />
            <h2>{i18n.t('search:flexible-search-mobile.title')}</h2>
            <nav>
                <CarouselComponent
                    className="dashboard-module"
                    classNameHeader="container"
                    nbSlides={1}
                    variableWidth={true}
                    swipe={false}
                    slides={slides}
                />
            </nav>
            {searchType === TYPE_FIXED_DATES && <InputSearchTabFixedDates />}
            {searchType === TYPE_FLEXIBLE_DATES && <InputSearchTabFlexibleDates />}
            {searchType === TYPE_LAST_MINUTE && <InputSearchTabLastMinute />}
            <div
                className={classNames('modal-buttons', {
                    'container-button-fixed-dates': searchType === TYPE_FIXED_DATES,
                    'container-button-flexible-dates': searchType === TYPE_FLEXIBLE_DATES,
                    'container-button-last-minute': searchType === TYPE_LAST_MINUTE
                })}
            >
                <button className="btn btn-cancel" onClick={() => setShowModal(false)}>
                    {i18n.t('common:cancel')}
                </button>
                <button className="btn btn-primary" onClick={onValidate} disabled={!isValid}>
                    {i18n.t(`common:${handleValidateTextBtn(searchType)}`)}
                </button>
            </div>
        </div>
    );
}

InputSearchModal.propTypes = {
    onValidate: PropTypes.func,
    context: PropTypes.string
};

export default InputSearchModal;
