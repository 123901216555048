import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

const FileToolbar = ({ file, onRemove }) => {
    if (file) {
        return (
            <div className="file-toolbar">
                <button
                    type="button"
                    className="btn btn-link btn-xs btn-remove"
                    aria-label={i18n.t('common:remove')}
                    onClick={onRemove}
                >
                    <span aria-hidden="true" className="fa fa-trash" />
                </button>
            </div>
        );
    }
    return null;
};

FileToolbar.propTypes = {
    file: PropTypes.instanceOf(window.File),
    onRemove: PropTypes.func
};

export default FileToolbar;
