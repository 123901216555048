import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Utils from '../../utils/utils';
import NewNextArrow from './NewNextArrow';
import NewPrevArrow from './NewPrevArrow';
import i18n from '../../i18n';

const CarouselHeader = () => {
    const [randonArrayId, setRandomArrayId] = useState([]);

    const arrayIdPicture = [1, 2, 3, 4, 5, 6];

    const settings = {
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NewNextArrow />,
        prevArrow: <NewPrevArrow />
    };

    const shuffleArray = (arr) => arr.sort(() => Math.random() - 0.5);

    useEffect(() => {
        setRandomArrayId(shuffleArray(arrayIdPicture));
    }, []);

    return (
        <Slider {...settings}>
            {randonArrayId.length > 0 &&
                randonArrayId.map((id) => (
                    <div key={id}>
                        <img
                            src={`${Utils.getCloudfrontDomain()}/images/website/collection/header-carousel/header_carousel_${id}.jpg`}
                        />
                        <div className="home-description">
                            <p className="home-name">
                                {i18n.t(`collection:collection-carousel-home-name-${id}`)}
                            </p>
                            <p className="home-location">
                                {i18n.t(`collection:collection-carousel-home-location-${id}`)}
                            </p>
                        </div>
                    </div>
                ))}
        </Slider>
    );
};

export default CarouselHeader;
