import { getInAppMessages, initializeWithConfig, track } from '@iterable/web-sdk';

import Api from '../api/Api';

export default class Iterable {
    static instance;

    constructor() {
        if (!Iterable.instance) {
            Iterable.instance = this;
            const { setEmail, setUserID } = initializeWithConfig({
                authToken: process.env.ITERABLE_API_JWT_KEY,
                generateJWT: Iterable.getUserJWTToken,
                configOptions: {
                    dangerouslyAllowJsPopups: true
                }
            });

            this.iterableAuthObject = { setEmail, setUserID };
        }

        return Iterable.instance;
    }

    /**
     * Get user jwt token
     * @returns { string } current user's token
     */
    static getUserJWTToken = () =>
        Api.User.getUserJWTToken()
            .then(({ token }) => {
                return token;
            })
            .catch((error) => console.error(error));

    /**
     * get class instance
     * functions used to identify the user to the iterable API
     */
    static getIterableInstance() {
        if (!Iterable.instance) {
            return new Iterable();
        }

        return Iterable.instance;
    }

    getIterableAuthObject() {
        return this.iterableAuthObject;
    }

    static inBrowserMessageParamsSetting = {
        count: 4,
        packageName: process.env.ITERABLE_WEB_PACKAGE_IDENTIFIER,
        displayInterval: 5000,
        closeButton: {
            size: '16px',
            topOffset: '20px'
        }
    };

    static inBrowserMessageDisplayOption = {
        display: 'immediate'
    };

    /**
     * Get user's in-browser messages from iterable API
     * Return utility functions to manipulate the message to be displayed
     * Exemple of functions that could be returned: request, pauseMessageStream, resumeMessageStream
     * @typedef { Object } messageDisplayUtilities
     * @property { IterablePromise<InAppMessageResponse> } request
     * @returns { messageDisplayUtilities }
     */
    static fetchInBrowserMessages = () => {
        const { request } = getInAppMessages(
            Iterable.inBrowserMessageParamsSetting,
            Iterable.inBrowserMessageDisplayOption
        );
        return { request };
    };

    static track = (args) => track(args);
}
