import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import i18n from '../../i18n';
import { ParametersContext } from './InputSearch';
import { useSmoothScrollToActive, useGrabScroll } from '../../hooks';

const TagFixedDay = [null, 1, 3, 7];

function FixedNumberDay() {
    const { flexibility, setFlexibility } = useContext(ParametersContext);
    const sliderRef = useRef(null);

    useGrabScroll('.nb-day');
    useSmoothScrollToActive(sliderRef, '.wrapper.active', true);

    const fixedNumberDay = (number, index) => {
        return (
            <div
                className={classNames('wrapper', { active: number === flexibility })}
                onClick={() => {
                    setFlexibility(number);
                }}
                key={index}
            >
                <div className="number-day">
                    {i18n.t(`search:${!number ? 'fixed-dates.tag' : 'flexible-dates-day'}`, {
                        number
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="container-nb-day">
            <div className="nb-day" ref={sliderRef}>
                {TagFixedDay.map((tag, index) => fixedNumberDay(tag, index))}
            </div>
        </div>
    );
}

export default FixedNumberDay;
