export const getActiveFiltersList = (filters, excludes) => {
    const list = [];
    const sortWeight = {
        preferred_destination: 1,
        filters: 2
    };

    for (const name in filters) {
        let currentName = name;
        let value = filters[currentName];
        // exclude some filters
        if (
            _.contains(excludes, currentName) ||
            _.isUndefined(value) ||
            _.isNull(value) ||
            (_.isObject(value) && _.isEmpty(value))
        ) {
            continue;
        }
        if (currentName === 'calendar') {
            if (!value.exchange_types) {
                continue;
            } else {
                value = value.exchange_types;
            }
        }
        if (currentName === 'filters' && Array.isArray(value)) {
            // Guestwanted should be first in the list of filters
            value.sort((a) => (a === 'guestwanted' ? -1 : 0));
        }
        list.push({
            name: currentName,
            value
        });
    }

    // Re-order items
    list.sort((a, b) => {
        const weightA = sortWeight[a.name] || 3;
        const weightB = sortWeight[b.name] || 3;

        return weightA - weightB;
    });
    return list;
};

export function hasAncestorLike(node, querySelector) {
    while (node && node.nodeType === 1) {
        if (node.matches(querySelector)) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}
