import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import { withCurrency } from '../currency';

const CurrencySelector = React.lazy(() => import('../widget/CurrencySelector'));

export const CurrencySwitcher = ({ currency, changeCurrency }) => (
    <Suspense fallback={<div>{`${i18n.t('common:loading')}...`}</div>}>
        <CurrencySelector currency={currency} onChange={changeCurrency} placement="bottom-start" />
    </Suspense>
);

CurrencySwitcher.propTypes = {
    currency: PropTypes.string,
    changeCurrency: PropTypes.func
};

export default withCurrency(CurrencySwitcher);
