import React, { useState } from 'react';
import { Radio } from '@homeexchange/design';
import i18n from '../../../../i18n';
import { generateReverseRange } from '../../../../utils-ts/utils';
var LOCATION_TYPE_START = 5;
var LOCATION_TYPE_END = 1;
export var LocationTypeSelector = function (_a) {
    var childRef = _a.childRef, home = _a.home, updateHome = _a.updateHome, onChangeHome = _a.onChangeHome, textIsRequired = _a.textIsRequired;
    var locationTypes = generateReverseRange(LOCATION_TYPE_START, LOCATION_TYPE_END);
    var _b = useState(String(home.get('detail').location_type)), selectedLocationType = _b[0], setSelectedLocationType = _b[1];
    var handleChange = function (value) {
        setSelectedLocationType(value);
        if (updateHome) {
            updateHome(Number(value));
        }
        if (onChangeHome) {
            triggerChange(value);
        }
    };
    var triggerChange = function (changedValue) {
        var inputEvent = {
            target: {
                value: changedValue,
                name: 'detail.location_type'
            }
        };
        onChangeHome(inputEvent);
    };
    return (React.createElement("div", { ref: childRef },
        React.createElement(Radio, { label: i18n.t('home:distance_from_tourist_sites'), helperContent: React.createElement("span", { dangerouslySetInnerHTML: {
                    __html: i18n.t('home:list_distance_from')
                } }), value: selectedLocationType, errorMessage: textIsRequired ? i18n.t('common:field_required') : null, onChange: handleChange }, locationTypes.map(function (type) { return (React.createElement(Radio.Item, { key: type, id: "location-type-".concat(type), label: i18n.t("home:location_type_".concat(type)), value: String(type) })); }))));
};
