import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import User from '../../../models/User';
import Transfert from '../../../models/Transfert';
import Notification from '../../../models/Notification';

import { Icon, Text, ProgressBar } from '@homeexchange/design';
export default class MenuProfileUser extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User).isRequired,
        notifications: PropTypes.instanceOf(Notification)
    };

    getUserFillingWriteMessage() {
        const profileNotif = this.props.notifications.getProfileNotification();
        if (profileNotif) {
            if (profileNotif.reason == Notification.PROFILE_REASON_COMPLETE_YOUR_PROFILE) {
                return i18n.t('fillingpanel:complete_profile');
            }
            return i18n.t('fillingpanel:complete_profile_to_receive_gp2', {
                gp: Transfert.MOTIVES_VALUE[Transfert.MOTIVE_FILLING_WRITE_PROFIL]
            });
        }
    }

    render() {
        const { user, notifications } = this.props;
        if (!user || _.isEmpty(notifications)) {
            return false;
        }
        const userFillingWriteMessage = this.getUserFillingWriteMessage();
        return (
            <li role="itemmenu">
                <a
                    className="link-top-profile item-link"
                    href={i18n.t('user:user.edit.url', { id: user.id })}
                >
                    <div className="item">
                        <div className="dropdown-title">
                            <Icon size="2xl" name="user"></Icon>
                            <span>{i18n.t('fillingpanel:my_profile')}</span>
                        </div>
                        {user.get('profil').filling_write == 100 && (
                            <Text isSpan size="sm" color="eco-green">
                                100%
                            </Text>
                        )}
                    </div>
                    <div>
                        {(userFillingWriteMessage || user.get('profil').filling_write < 100) && (
                            <div className="section-status-container">
                                {userFillingWriteMessage && (
                                    <Text color="secondary" size="sm" isBold className="top-menu-messages">
                                        {userFillingWriteMessage}
                                    </Text>
                                )}
                                {user.get('profil').filling_write < 100 && (
                                    <div className="section-status">
                                        <span>{user.get('profil').filling_write}%</span>

                                        <ProgressBar
                                            ariaLabel="Progress bar"
                                            color="secondary"
                                            size="small"
                                            value={user.get('profil').filling_write}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </a>
            </li>
        );
    }
}
