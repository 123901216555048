import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import classNames from 'classnames';

const PopupSweet = ({
    className,
    show,
    link = false,
    title,
    image,
    content,
    approvedOrPayment,
    textButton,
    underTextButton,
    href,
    closeModal,
    marginTopBtn = false,
    inlineCustomStyle = null
}) => (
    <Modal
        show={show}
        dialogClassName={`modal-pre-approve ${className}`}
        onHide={closeModal}
        bsClass="modal-sweet modal"
    >
        <button type="button" className="modal-btn-close" onClick={closeModal}>
            <i className="icon-shape-close" />
        </button>
        <p className="title-modal" style={inlineCustomStyle && inlineCustomStyle.title}>
            {title}
        </p>
        <img className="image-modal" style={inlineCustomStyle && inlineCustomStyle.image} src={image} />
        <div className="container-content-modal">
            <p dangerouslySetInnerHTML={{ __html: content }} />
            <button
                style={inlineCustomStyle && inlineCustomStyle.cta}
                className={classNames('btn btn-primary', { 'margin-top-btn': marginTopBtn })}
                onClick={approvedOrPayment}
            >
                {textButton}
            </button>
            {link ? (
                <a href={href} className="link-modal">
                    {underTextButton}
                </a>
            ) : (
                <p className="under-text-button" style={inlineCustomStyle && inlineCustomStyle.footer}>
                    {underTextButton ? underTextButton : ''}
                </p>
            )}
        </div>
    </Modal>
);

PopupSweet.propTypes = {
    className: PropTypes.string,
    show: PropTypes.bool,
    link: PropTypes.bool,
    title: PropTypes.string,
    image: PropTypes.string,
    content: PropTypes.string,
    approvedOrPayment: PropTypes.func,
    textButton: PropTypes.string,
    underTextButton: PropTypes.string,
    href: PropTypes.string,
    closeModal: PropTypes.func,
    marginTopBtn: PropTypes.bool,
    inlineCustomStyle: PropTypes.shape({
        title: PropTypes.object,
        image: PropTypes.object,
        cta: PropTypes.object,
        footer: PropTypes.object
    })
};

export default PopupSweet;
