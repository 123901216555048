// import Utils from "./utils";

export default class Storage {
    static _data = {};

    static getStorage(type) {
        switch (type) {
            case 'local':
                return window.localStorage;
            case 'session':
                return window.sessionStorage;
            default:
                throw new Error(`Unkonw storage type (${type}).`);
        }
    }

    /**
     * Set local/session storage with backup
     * @param {string} key - The key
     * @param {string} value - The value to save
     * @param {string} type - Either "local" or "session"
     */
    static set(key, value, type = 'local') {
        const storage = Storage.getStorage(type);
        // insures that value is a string or a number
        if (typeof value != 'string' && typeof value != 'number' && typeof value != 'boolean') {
            value = JSON.stringify(value);
        }
        // store value and handle issue on Safari when private mode is enable
        try {
            if (storage) {
                // Safari with privacy options will have localStorage
                // but won't let us write to it.
                storage.setItem(key, value);
            } else {
                // Android WebView might not have localStorage at all.
                this._data[key] = value;
            }
        } catch (e) {
            // localStorage not available; falling back on memory storage
            this._data[key] = value;
        }
    }

    /**
     * Get local/session storage value with backup
     * @param {string} key - The key of the item to retrieve
     * @param {string} type - Either "local" or "session"
     */
    static get(key, type = 'local') {
        const storage = Storage.getStorage(type);
        let value;
        if (storage) {
            value = storage.getItem(key) || this._data[key] || null;
        } else {
            // falling back on memory storage
            value = this._data[key] || null;
        }
        // restore object or array if needed
        if (typeof value === 'string') {
            try {
                return JSON.parse(value);
            } catch (e) {
                return value;
            }
        }
        return value;
    }

    /**
     * Remove local/session storage value
     * @param {string} key - The key of the item to retrieve
     * @param {string} type - Either "local" or "session"
     */
    static remove(key, type = 'local') {
        const storage = Storage.getStorage(type);
        if (storage) {
            storage.removeItem(key);
        }
        delete this._data[key];
    }

    /**
     * Remove all the localStorage not linked with current user
     * @param {int} userId - Id of the user
     */
    static clearLocalStorage() {
        try {
            if (window.localStorage) {
                for (const key in window.localStorage) {
                    localStorage.removeItem(key);
                }
            }
        } catch (e) {
            console.warn('Unable to clear localStorage', e);
        }
    }

    /**
     * Add an element to an array in local or session storage
     * @param {string} key - The key of the item to retrieve
     * @param {string} value - The value to save
     * @param {string} type - Either "local" or "session"
     */
    static addItem(key, value, type = 'local', unshift = false, avoidDuplicates = false) {
        let currentArray = Storage.get(key, type);
        if (!currentArray) {
            currentArray = [];
        }
        if (avoidDuplicates) {
            const indexOfValue = currentArray.indexOf(value);
            if (indexOfValue >= 0) {
                currentArray.splice(indexOfValue, 1);
            }
        }
        if (unshift) {
            currentArray.unshift(value);
        } else {
            currentArray.push(value);
        }
        Storage.set(key, currentArray, type);
    }
}
