import { UPDATE_ROULETTE, CLEAR_ROULETTE } from '../actions/roulette';

export default function roulette(state = {}, { type, variables }) {
    switch (type) {
        case UPDATE_ROULETTE:
            return variables;
        case CLEAR_ROULETTE:
            return {};
        default:
            return state;
    }
}
