import { CALENDAR_UPDATED_AT } from '../actions/calendar';

export default function calendar(state = {}, { type, homeId, updatedAt }) {
    switch (type) {
        case CALENDAR_UPDATED_AT: {
            const data = Object.assign({}, state[homeId], {
                updatedAt
            });
            return Object.assign({}, state, {
                [homeId]: data
            });
        }
        default:
            return state;
    }
}
