import React, { useEffect, useRef, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputOption from '../form/InputOption';
import i18n from '../../i18n';
import Feature from '../../models/Feature';

const TRACKING_ALLERGIE_CHANGED = 'allergies_changed';

const HomeAllergiesToggle = forwardRef((props, childRef) => {
    const {
        active,
        onToggle,
        onSelect,
        home,
        shouldScroll = false,
        classCol = false,
        asteriskIsRequired = false
    } = props;
    const [isActive, setActive] = useState(undefined);
    const cardsChoiceRef = useRef();
    const features = home.get('feature');

    useEffect(() => {
        if (features.animal !== undefined && features.animal > 0) {
            setActive(true);
        }
    }, [home]);

    useEffect(() => {
        if (shouldScroll && cardsChoiceRef.current && cardsChoiceRef.current.scrollIntoView) {
            cardsChoiceRef.current.scrollIntoView();
        }
    }, [isActive]);

    useEffect(() => {
        if (active !== undefined) {
            setActive(active);
        }
    }, [active]);

    const handleOnChange = (e) => {
        const value = e.target.value === '1';

        setActive(value);
        if (onToggle) {
            onToggle(e, features);
        }
    };

    const displayCards = () => (
        <div
            ref={cardsChoiceRef}
            className={classNames('row home-creation-toggle-cards-choice', { hidden: !isActive })}
        >
            {Feature.FEATURES.filter((item) => item.category === 10).map((allergy) => {
                const category = Feature.AMENITIES_LABEL[allergy.category];

                return (
                    <div
                        className={classNames('col-xs-6 col-sm-5', { 'no-icon': !allergy.icon })}
                        key={allergy.label}
                    >
                        <InputOption
                            type="checkbox"
                            label={i18n.t(`home:${allergy.label}`)}
                            icon={allergy.icon}
                            name={`feature[${category}][]`}
                            id={`feature-${allergy.label}`}
                            value={allergy.value}
                            checked={Boolean(features[category] & allergy.value)}
                            inputNumber={1}
                            onChange={onSelect}
                        />
                    </div>
                );
            })}
        </div>
    );

    const displayRadios = () => (
        <div className="form-group shared-part-container">
            <div className="row">
                <div className="col-sm-10">
                    <div className="radio">
                        <label>
                            <div className="radio-button-container">
                                <input
                                    type="radio"
                                    name="feature-allergies-on"
                                    id="shared-part-1"
                                    data-track-edition={TRACKING_ALLERGIE_CHANGED}
                                    value={1}
                                    checked={isActive}
                                    required
                                    onClick={handleOnChange}
                                />
                                <div className="radio-button"></div>
                                <span className="shared-part">{i18n.t('common:yes')}</span>
                            </div>
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <div className="radio-button-container">
                                <input
                                    type="radio"
                                    name="feature-allergies-off"
                                    id="shared-part-2"
                                    data-track-edition={TRACKING_ALLERGIE_CHANGED}
                                    value={0}
                                    checked={isActive === undefined || !isActive}
                                    required
                                    onClick={handleOnChange}
                                />
                                <div className="radio-button"></div>
                                <span className="shared-part">{i18n.t('common:no')}</span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );

    const title = `${i18n.t('home:home-creation.allergies.title')} ${asteriskIsRequired ? '*' : ''}`;

    return (
        <div ref={childRef} className={classNames('home-creation-toggle', { 'form-group': !classCol })}>
            <h3 className={'home-creation-title'}>{title}</h3>
            <div {...(classCol && { className: 'form-group' })}>
                <div {...(classCol && { className: 'col-sm-10' })}>
                    <div className="home-creation-toggle-container">
                        <div className="home-creation-text-container">
                            <p className="home-creation-text">
                                {i18n.t('home:home-creation.allergies.text')}
                            </p>
                            <p className="home-creation-information">
                                {i18n.t('home:home-creation.allergies.information')}
                            </p>
                        </div>
                        {displayRadios()}
                    </div>
                    {displayCards()}
                </div>
            </div>
        </div>
    );
});

HomeAllergiesToggle.propTypes = {
    active: PropTypes.bool,
    onToggle: PropTypes.func,
    onSelect: PropTypes.func,
    home: PropTypes.object,
    classCol: PropTypes.bool,
    shouldScroll: PropTypes.bool,
    asteriskIsRequired: PropTypes.bool,
    textIsRequired: PropTypes.bool,
    childRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })])
};

export default HomeAllergiesToggle;
