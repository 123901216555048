import Availability from '../models/Availability';

export default class CalendarUtils {
    format = moment.localeData().longDateFormat('L');
    dayFormat = 'YYYY-MM-DD';

    /**
     * Checks if the current range overlaps a booked period
     * @param  {Moment.range} range Range to check
     * @param  {Availability[]} availabilities Availabilities to check with
     * @return {bool} Whether the range overlaps a booked period
     */
    static overlapsBookedPeriod(range, availabilities) {
        return availabilities.some((availability) => {
            if (availability.get('type') === Availability.BOOKED.type) {
                const availabilityRange = moment.range(
                    availability.get('start_on'),
                    availability.get('end_on')
                );

                if (range.overlaps(availabilityRange)) {
                    return true;
                }
            }
            return false;
        });
    }

    /**
     * Calculate the number of nights of the selected dates
     * @return {int}
     * @param dates
     */
    static calculateNumberOfNightsOfSelectedDates(dates) {
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);
        const diff = endDate.getTime() - startDate.getTime();
        const nights = diff / (1000 * 60 * 60 * 24);
        return Math.abs(nights);
    }

    /**
     * Checks if the current range overlaps a period with no available period
     * @param  {Moment.range} range Range to check
     * @param  {Availability[]} availabilities Availabilities to check with
     * @return {bool} Whether the range overlaps a period with no available period
     */
    static overlapsUnsetPeriod(range, availabilities) {
        // List dates with availabilities
        const availableNights = [];
        availabilities.forEach((availability) => {
            if (availability.get('type') !== Availability.BOOKED.type) {
                const start = availability.get('start_on').clone();
                // Remove 1 day to only consider nights (to take 1 nights unavailabilities into consideration)
                const end = availability.get('end_on').clone().subtract(1, 'day');
                const availabilityRange = moment.range(start, end);
                for (const day of availabilityRange.by('days')) {
                    availableNights.push(day.format(this.dayFormat));
                }
            }
        });

        for (const day of range.by('days')) {
            // Exclude end date to only compare nights
            if (
                day.format(this.dayFormat) != range.end.format(this.dayFormat) &&
                availableNights.indexOf(day.format(this.dayFormat)) < 0
            ) {
                // A date in the range is not available
                return true;
            }
        }
        return false;
    }

    /**
     * Checks if the current range overlaps an unavailable period
     * @param  {Moment.range} range Range to check
     * @param  {Availability[]} availabilities Availabilities to check with
     * @param  {bool} bookedOnly Whether it should check for booked periods only or any unavailability
     * @return {bool} Whether the range overlaps a period with no available period
     */
    static overlapsUnavailablePeriod(range, availabilities, bookedOnly) {
        if (bookedOnly) {
            return CalendarUtils.overlapsBookedPeriod(range, availabilities);
        }
        return CalendarUtils.overlapsUnsetPeriod(range, availabilities);
    }

    /**
     * Merge available periods together (Available and GuestWanted)
     * @param  {Availability[]} availabilities Availabilities to use
     * @return {Availability[]} Array of availabilities
     */
    static mergeAvailablePeriods(availabilities) {
        // Remove all other availabilities
        const availablePeriods = availabilities.reduce((availPeriods, availability) => {
            if (
                availability.get('type') === Availability.AVAILABLE.type ||
                availability.get('type') === Availability.NON_RECIPROCAL.type ||
                availability.get('type') === Availability.RECIPROCAL.type
            ) {
                availPeriods.push(availability.clone());
            }
            return availPeriods;
        }, []);
        let i = 0,
            l = availablePeriods.length;
        while (i < l - 1) {
            let j = i + 1;
            while (j < l) {
                if (availablePeriods[i].get('end_on').isSame(availablePeriods[j].get('start_on'))) {
                    availablePeriods[i].set('end_on', availablePeriods[j].get('end_on'));
                    availablePeriods.splice(j, 1);
                    l--;
                    if (i < l - 1) {
                        j = i + 1;
                    }
                } else if (availablePeriods[i].get('start_on').isSame(availablePeriods[j].get('end_on'))) {
                    availablePeriods[j].set('end_on', availablePeriods[i].get('end_on'));
                    availablePeriods.splice(i, 1);
                    l--;
                    if (i < l - 1) {
                        j = i + 1;
                    }
                } else {
                    j++;
                }
            }
            i++;
        }
        return availablePeriods;
    }

    /**
     * Checks that the range is in a available period
     * @param  {Moment.range} range Range to check
     * @param  {Availability[]} availabilities Availabilities to use
     * @return {bool} Whether the range is in an available period
     */
    static inAvailablePeriod(range, availabilities) {
        // Merge consecutive available periods
        const mergedAvailabilities = CalendarUtils.mergeAvailablePeriods(availabilities);

        // Check if the availabilities contains the current range
        return mergedAvailabilities.some((availability) => {
            const availabilityRange = moment.range(availability.get('start_on'), availability.get('end_on'));

            if (availability.get('type') !== Availability.BOOKED && availabilityRange.contains(range)) {
                return true;
            }
            return false;
        });
    }

    /**
     * Tells if the date is right before an unavailable period
     * @param  {Moment} date Date to check
     * @param  {Availability[]} availabilities Availabilities to use
     * @return {bool} Whether the range is in an available period
     */
    static dayJustBeforeUnavailablePeriod(date, availabilities) {
        return availabilities.some((availability) => {
            if (availability.get('type') === Availability.BOOKED.type) {
                if (availability.get('start_on').isSame(date)) {
                    return true;
                }
            }

            return false;
        });
    }
}
