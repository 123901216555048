import { SET_USER, USER_LOGOUT, ADD_FACEBOOK_ID, ADD_TOUR, SET_LOYALTY } from '../actions/user';

import User from '../models/User';

export default function user(state = null, { type, payload }) {
    switch (type) {
        case SET_USER:
            return payload;
        case USER_LOGOUT:
            return {};
        case ADD_FACEBOOK_ID:
            return new User(
                Object.assign({}, state.toJSON(), {
                    facebook: {
                        facebook_id: payload
                    }
                })
            );
        case ADD_TOUR: {
            const newTours = state.get('tours').slice(0);
            newTours.push({
                tour: payload
            });
            return new User(
                Object.assign({}, state.toJSON(), {
                    tours: newTours
                })
            );
        }
        case SET_LOYALTY: {
            return new User(
                Object.assign({}, state.toJSON(), {
                    loyalty: payload
                })
            );
        }
        default:
            return state;
    }
}
