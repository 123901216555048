import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icons from '../../utils/Icons';
import i18n from '../../i18n';
import Api from '../../api/Api';

export default function EmailVerificationSignup(props) {
    const { email } = props;
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);

    const handleOnClick = () => {
        Api.User.resendEmailVerif(email)
            .then(() => {
                setEmailHasBeenSent(true);
            })
            .catch(() => {
                sweetAlert({
                    icon: 'error',
                    title: i18n.t('user:error.users.verification_email_resending_rate_limit_reached.title'),
                    text: i18n.t('user:error.users.verification_email_resending_rate_limit_reached'),
                    closeOnClickOutside: false
                });
            });
    };

    return (
        <div className="email-verification-signup">
            <div className="email-verification-signup-header">
                <div className="icon-email-information">{Icons.transform(16)}</div>
            </div>
            <div className="email-verification-signup-content">
                <h1 className="email-verification-signup-title">
                    {i18n.t('home:email-verification-signup.title')}
                </h1>
                <p
                    className="email-verification-signup-text"
                    dangerouslySetInnerHTML={{
                        __html: i18n.t('home:email-verification-signup.text', { email })
                    }}
                />
                <button className="email-verification-signup-button btn" onClick={handleOnClick}>
                    {i18n.t('user:verif.email.error.button')}
                </button>
                <p className={classNames('email-verification-signup-error', { hidden: !emailHasBeenSent })}>
                    {i18n.t('user:signup-verification-email-has-been-sent.text')}
                </p>
            </div>
        </div>
    );
}

EmailVerificationSignup.propTypes = {
    email: PropTypes.string.isRequired
};
