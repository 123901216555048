import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class InputNumber extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        required: PropTypes.bool,
        min: PropTypes.number,
        max: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        valueTransformer: PropTypes.func,
        inputNumber: PropTypes.number,
        disabledBtn: PropTypes.bool,
        trackingData: PropTypes.string
    };

    static defaultProps = {
        inputNumber: 0,
        disabledBtn: false
    };

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? Number(this.props.value) : 0
        };
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value ? Number(nextProps.value) : 0
        });
    }

    increment() {
        if (typeof this.props.max !== 'number' || this.state.value < this.props.max) {
            this.setState({ value: this.state.value + 1 }, this.triggerChange);
        }
    }

    decrement() {
        if (typeof this.props.min !== 'number' || this.state.value > this.props.min) {
            this.setState({ value: this.state.value - 1 }, this.triggerChange);
        }
    }

    triggerChange() {
        // simulate onChange event
        const input = document.createElement('input');
        input.type = 'number';
        input.name = this.props.name;
        input.value = this.state.value;
        input.onchange = this.props.onChange;
        input.setAttribute('data-input-number', this.props.inputNumber);
        input.setAttribute('data-track-edition', this.props.trackingData);
        input.dispatchEvent(new CustomEvent('change'));
    }

    render() {
        return (
            <div className="input-number">
                <input
                    ref={(input) => {
                        this.input = input;
                    }}
                    type="number"
                    id={this.props.id}
                    name={this.props.name}
                    value={this.state.value}
                    required={this.props.required}
                    min={this.props.min}
                    max={this.props.max}
                    onChange={this.props.onChange}
                    style={{ display: 'none' }}
                    data-input-number={this.props.inputNumber}
                />
                <button
                    type="button"
                    className={classNames('input-number-btn', { 'disabled-btn': this.props.disabledBtn })}
                    onClick={this.decrement}
                    disabled={
                        (typeof this.props.min === 'number' && this.state.value <= this.props.min) ||
                        this.props.disabledBtn
                    }
                >
                    <i className="icon-minus" />
                </button>
                <span className="input-number-value">
                    {typeof this.props.valueTransformer === 'function'
                        ? this.props.valueTransformer(this.state.value)
                        : this.state.value}
                </span>
                <button
                    type="button"
                    className={classNames('input-number-btn', { 'disabled-btn': this.props.disabledBtn })}
                    onClick={this.increment}
                    disabled={
                        (typeof this.props.max === 'number' && this.state.value >= this.props.max) ||
                        this.props.disabledBtn
                    }
                >
                    <i className="icon-plus" />
                </button>
            </div>
        );
    }
}
