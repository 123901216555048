import { SET_AVAILABILITIES } from '../actions/availabilities';

export default function homes(state = {}, { type, payload }) {
    switch (type) {
        case SET_AVAILABILITIES:
            return Object.assign({}, state, payload);
        default:
            return state;
    }
}
