import Common from './Common';

export default class Conversation {
    static sendMessage(data) {
        const params = {
            url: '/v1/messages',
            method: 'post',
            data
        };
        return Common.request(params);
    }

    static getMessages(filters, offset, limit, orderBy) {
        const params = Common.params(
            {
                url: '/v1/messages',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static getMessagesGraphQL(conversationId) {
        const params = Common.params({
            url: '/v3/messages',
            method: 'get',
            data: {
                conversation_id: conversationId
            }
        });
        return Common.request(params);
    }

    static me(filters, offset, limit, orderBy) {
        const params = Common.params(
            {
                url: '/v2/conversations/me',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static meGraphQL(filter, offset, limit) {
        const params = {
            url: '/v3/conversations/me',
            method: 'get',
            data: {
                filter: (filter && filter.toUpperCase()) || null,
                after: offset,
                first: limit
            }
        };
        return Common.request(params);
    }

    static get(id) {
        const params = {
            url: '/v1/conversations/me',
            method: 'get',
            data: {
                filters: {
                    'c.id': id
                }
            }
        };
        return Common.request(params);
    }

    static getGraphQL(id, polling) {
        const params = {
            url: `/v3/conversations/me/${id}`,
            method: 'get'
        };
        if (polling) {
            params.headers = {
                'X-polling': '1'
            };
        }
        return Common.request(params);
    }

    static search(query, offset, limit) {
        const params = Common.params(
            {
                url: '/v1/conversations/search',
                method: 'post',
                data: {
                    query
                }
            },
            null,
            null,
            limit,
            offset
        );
        return Common.request(params);
    }

    static stats() {
        const params = {
            url: '/v1/conversations/stats/me',
            method: 'get'
        };
        return Common.request(params);
    }

    static meta() {
        const params = Common.params({
            url: '/v1/conversations/me?meta=true',
            method: 'get'
        });
        return Common.request(params);
    }

    static delete(id) {
        const params = {
            url: `/v1/conversations/${id}`,
            method: 'delete'
        };
        return Common.request(params);
    }

    static archive(id) {
        const params = {
            url: `/v1/conversations/${id}/archive`,
            method: 'patch'
        };
        return Common.request(params);
    }

    static unarchive(id) {
        const params = {
            url: `/v1/conversations/${id}/unarchive`,
            method: 'patch'
        };
        return Common.request(params);
    }

    static toggleFavorite(id, favorite) {
        const params = {
            url: `/v1/conversations/${id}/favorite`,
            method: favorite ? 'post' : 'delete'
        };
        return Common.request(params);
    }

    static approve(id) {
        const params = {
            url: `/v1/exchanges/${id}/approve`,
            method: 'patch'
        };
        return Common.request(params);
    }

    static finalize(id) {
        const params = {
            url: `/v1/exchanges/${id}/finalize`,
            method: 'patch'
        };
        return Common.request(params);
    }

    static finalizeBilling(id, data) {
        const params = {
            url: `/v1/exchanges/${id}/finalizeWithBilling`,
            method: 'patch',
            data
        };
        return Common.request(params);
    }

    // for 3x payment
    static finalizeBilling3x(id, data) {
        const params = {
            url: `/v1/exchanges/${id}/finalizeWithBilling3x`,
            method: 'patch',
            data
        };
        return Common.request(params);
    }

    static finalizeWithToken3x(id, stripeToken, promoCode) {
        return Conversation.finalizeBilling3x(id, {
            stripeToken,
            coupon_name: promoCode
        });
    }

    static finalizeWithCardId3x(id, cardId, promoCode) {
        return Conversation.finalizeBilling3x(id, {
            card_id: cardId,
            coupon_name: promoCode
        });
    }

    static finalizeWithToken(id, stripeToken, promoCode) {
        return Conversation.finalizeBilling(id, {
            stripeToken,
            coupon_name: promoCode
        });
    }

    static finalizeWithCardId(id, cardId, promoCode) {
        return Conversation.finalizeBilling(id, {
            card_id: cardId,
            coupon_name: promoCode
        });
    }

    static finalizeWithPaypal(id, braintreeNonce, braintreeDeviceData, promoCode) {
        return Conversation.finalizeBilling(id, {
            braintreeNonce,
            braintreeDeviceData,
            coupon_name: promoCode
        });
    }

    static finalizeWithExistingPaypalAccount(id, braintreeId, promoCode) {
        return Conversation.finalizeBilling(id, {
            braintreeId,
            coupon_name: promoCode
        });
    }

    static cancel(id, reasonData) {
        const params = {
            url: `/v1/exchanges/${id}/cancel`,
            method: 'patch'
        };
        if (reasonData) {
            params.data = {
                reason: reasonData
            };
        }
        return Common.request(params);
    }

    static rateHome(conversationId, data) {
        const params = {
            url: `/v1/homes/rate/${conversationId}`,
            method: 'post',
            data
        };
        return Common.request(params);
    }

    static rateUser(conversationId, data) {
        const params = {
            url: `/v1/users/rate/${conversationId}`,
            method: 'post',
            data
        };
        return Common.request(params);
    }
}
