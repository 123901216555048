import Api from '../api/Api';

export const LOAD_HOME_RATINGS = 'FETCH_HOME_RATINGS';

export function loadHomeRatings(homeId, ratings) {
    return {
        type: LOAD_HOME_RATINGS,
        payload: {
            [homeId]: ratings
        }
    };
}

export function fetchHomeRatings(homeId) {
    return (dispatch) =>
        Api.Home.getRatings(homeId).then((result) => {
            dispatch(loadHomeRatings(homeId, result));
        });
}
