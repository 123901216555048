import Api from '../api/Api';

import User from '../models/User';

export const LOAD_USER = 'LOAD_USER';

export function loadUser(user) {
    return {
        type: LOAD_USER,
        payload: {
            [user.id]: new User(user)
        }
    };
}

export function loadUsers(users = []) {
    const userList = {};
    users.forEach((user) => {
        userList[user.id] = new User(user);
    });
    return {
        type: LOAD_USER,
        payload: userList
    };
}

export function fetchUser(id) {
    return (dispatch) =>
        Api.User.getOne(id).then((user) => {
            dispatch(loadUser(user));
        });
}
