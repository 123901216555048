import { SET_HOMES, ADD_HOME, UPDATE_HOME, SAVE_HOME, REMOVE_HOME, REFRESH_HOMES } from '../actions/homes';

export default function homes(state = [], { type, payload }) {
    switch (type) {
        case SET_HOMES:
        case REFRESH_HOMES:
            return payload;
        case ADD_HOME:
            return state.concat(payload);
        case SAVE_HOME:
        case UPDATE_HOME:
            return state.map((home) => {
                if (home.id == payload.id) {
                    return payload;
                }
                return home;
            });
        case REMOVE_HOME:
            return state.filter((home) => home.id != payload.id);
        default:
            return state;
    }
}
