export default class Roulette {
    static get(userId, defaultValues) {
        const promise = new $.Deferred();

        return $.ajax({
            url: `${process.env.ROULETTE_HOST}/values/${userId}`,
            method: 'GET',
            dataType: 'json',
            contentType: 'application/json',
            timeout: 1000
        }).then(
            (success) => {
                promise.resolve(_.defaults(success, defaultValues));
                return promise;
            },
            () => {
                promise.resolve(defaultValues);
                return promise;
            }
        );
    }
}
