import _ from 'underscore';

export default class Model {
    constructor(attributes = {}, options = {}) {
        if (attributes instanceof Model) {
            // already instancied
            return attributes;
        }
        let attrs = JSON.parse(JSON.stringify(attributes)) || {};
        this.idAttribute = options.idAttribute ? options.idAttribute : 'id';
        this.attributes = {};
        attrs = this.parse(attrs, options) || {};
        const defaults = _.result(this, 'defaults');
        attrs = _.defaults(Object.assign({}, defaults, attrs), defaults);
        this.set(attrs, options);
        this.initialize(attributes, options);
    }

    // Initialize is an empty function by default. Override it with your own initialization logic.
    initialize() {
        // this is an interface
    }

    // **parse** converts a response into the hash of attributes to be `set` on
    // the model. The default implementation is just to pass the response along.
    parse(resp, options) {
        // rewrite keys if mapping option is defined
        if (options.mapping) {
            for (const property in options.mapping) {
                if (resp.hasOwnProperty(property)) {
                    if (_.isObject(options.mapping[property])) {
                        resp[property] = this.parse(resp[property], {
                            mapping: options.mapping[property]
                        });
                    } else {
                        resp[options.mapping[property]] = resp[property];
                        delete resp[property];
                    }
                }
            }
        }
        return resp;
    }

    get(attr) {
        return this.attributes[attr];
    }

    has(attr) {
        return this.get(attr) !== null && this.get(attr) !== undefined;
    }

    set(key, val) {
        if (key === null) {
            return this;
        }

        // Handle both `"key", value` and `{key: value}` -style arguments.
        let attrs;
        if (typeof key === 'object') {
            attrs = key;
        } else {
            (attrs = {})[key] = val;
        }

        // Update current value for each `set` attribute.
        for (const attr in attrs) {
            if ({}.hasOwnProperty.call(attrs, attr)) {
                val = attrs[attr];
                this.attributes[attr] = val;
            }
        }

        // Update the `id`.
        if (this.idAttribute in attrs) {
            this.id = this.get(this.idAttribute);
        }
    }

    toJSON() {
        return JSON.parse(JSON.stringify(this.attributes));
    }
}
