import { LOAD_TRAVELERS, ADD_TRAVELER, EDIT_TRAVELER, REMOVE_TRAVELER } from '../actions/travelers';
import Traveler from '../models/Traveler';

function compareTravelersObjects(tr1, tr2) {
    if (tr1.get('id') < tr2.get('id')) {
        return -1;
    }

    if (tr1.get('id') > tr2.get('id')) {
        return 1;
    }

    return 0;
}

export default function travelers(state = [], { type, payload }) {
    switch (type) {
        case LOAD_TRAVELERS: {
            const travelersObj = payload.map((tr) => new Traveler(tr));
            return Object.assign([], state, travelersObj);
        }
        case ADD_TRAVELER:
            return [...state, new Traveler(payload)];
        case EDIT_TRAVELER: {
            const filteredState = state.filter((tr) => tr.get('id') !== payload.id);
            const newState = [...filteredState, new Traveler(payload)].sort(compareTravelersObjects);
            return newState;
        }
        case REMOVE_TRAVELER: {
            return state.filter((tr) => tr.get('id') !== payload);
        }
        default:
            return state;
    }
}
