import Common from './Common';
import Model from '../models/Model';

export default class Home {
    static me(filters, orderBy, limit, offset) {
        const params = Common.params(
            {
                url: '/v1/homes/me',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static get(filters, orderBy, limit, offset) {
        const params = Common.params(
            {
                url: '/v1/homes',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static getOne(homeId) {
        const params = Common.params({
            url: `/v1/homes/${homeId}`,
            method: 'get'
        });
        return Common.request(params);
    }

    static getGraphQL(userId, status) {
        const params = Common.params({
            url: '/v3/homes',
            method: 'get',
            data: {
                user_id: userId,
                status: (status && status.toUpperCase()) || undefined
            }
        });
        return Common.request(params).then((response) => response.data.homes);
    }

    static post(home) {
        const params = Common.params(
            Object.assign({
                url: '/v1/homes',
                method: 'post',
                data: home
            })
        );
        return Common.request(params);
    }

    static patch(homeId, data, options, refreshGP = false) {
        let url = `/v1/homes/${homeId}`;
        if (refreshGP) {
            url += `?signup=1`;
        }
        const params = Common.params(
            Object.assign(
                {
                    url,
                    method: 'patch',
                    data
                },
                options
            )
        );
        return Common.request(params);
    }

    static delete(homeId) {
        const params = Common.params({
            url: `/v1/homes/${homeId}`,
            method: 'delete'
        });
        return Common.request(params);
    }

    static getOffline(filters, orderBy, limit, offset) {
        const params = Common.params(
            {
                url: '/v1/homes',
                method: 'get',
                auth: false
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static postImage(homeId, image, callback, fail) {
        const formData = new FormData();
        formData.append('file', image);
        const params = {
            url: `/v1/images/home/${homeId}`,
            method: 'post',
            data: formData,
            contentType: false,
            processData: false
        };
        return Common.fetch(params, callback, fail);
    }

    static deleteImage(imageId, callback, fail) {
        const params = {
            url: `/v1/images/${imageId}`,
            method: 'delete'
        };
        return Common.fetch(params, callback, fail);
    }

    static getImages(homeId, callback, fail, offline = false) {
        const params = {
            url: `/v1/homes/${homeId}`,
            method: 'get'
        };

        if (offline) {
            params.auth = false;
        }

        return Common.fetch(params, callback, fail);
    }

    static getHomesByFavorite(filters, orderBy, limit, offset) {
        const params = Common.params(
            {
                url: '/v1/homes/get-homes-by-favorite',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static getLastCompletedHomesWithCompletedUserProfile(limit, locale) {
        const params = Common.params(
            {
                url: `/v1/homes/last-completed-homes-with-completed-user-profile/${locale}`,
                method: 'get',
                auth: false
            },
            null,
            null,
            limit,
            null
        );
        return Common.request(params);
    }

    static getDisponibilities(filters, orderBy, offset, limit) {
        const params = Common.params(
            {
                url: '/v1/homes/disponibilities',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static addDisponibility(startOn, endOn, type, homeId) {
        const params = Common.params({
            url: '/v1/homes/disponibilities',
            method: 'post',
            data: {
                start_on: startOn,
                end_on: endOn,
                type,
                home: homeId
            }
        });
        return Common.request(params);
    }

    static sortByStatusAndCompletionRate(homes) {
        return homes.sort((a, b) => {
            a = a instanceof Model ? a.toJSON() : a;
            b = b instanceof Model ? b.toJSON() : b;
            if (a.status < b.status) {
                return 1;
            }
            if (a.status > b.status) {
                return -1;
            }
            if (a.completion_rate < b.completion_rate) {
                return 1;
            }
            if (a.completion_rate > b.completion_rate) {
                return -1;
            }
            return 0;
        });
    }

    static getAvailabilities(homeId, filters, orderBy, limit, offset) {
        const params = Common.params(
            {
                url: `/v1/homes/${homeId}/calendar`,
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );

        return Common.request(params);
    }

    static setAvailability(homeId, data) {
        const params = Common.params({
            url: `/v1/homes/${homeId}/calendar`,
            method: 'post',
            data
        });

        return Common.request(params);
    }

    static getRatings(homeId) {
        const params = Common.params({
            url: `/v1/homes/${homeId}/ratings`,
            method: 'get'
        });

        return Common.request(params);
    }

    static getSimilarHomes(homeId) {
        const params = Common.params({
            url: `/api/home/similar/${homeId}`,
            method: 'get'
        });

        return Common.request(params, true, true);
    }

    static getHomesCollection() {
        const params = {
            url: '/v1/homes/collection',
            method: 'get'
        };
        return Common.request(params);
    }

    static addHomeCollection(home) {
        const params = Common.params({
            url: `/v1/collection/home`,
            method: 'post',
            data: home
        });
        return Common.request(params);
    }

    static getHomeCollection() {
        const params = {
            url: '/v1/collection/recatch/home',
            method: 'get'
        };
        return Common.request(params);
    }

    static setExternalLink(data) {
        const params = {
            url: '/v1/collection/home/external-link',
            method: 'post',
            data
        };
        return Common.request(params);
    }

    static getExternalLink() {
        const params = {
            url: '/v1/collection/recatch/external-link',
            method: 'get'
        };
        return Common.request(params);
    }

    static setRemarks(data) {
        const params = {
            url: '/v1/collection/home/remarks',
            method: 'post',
            data
        };
        return Common.request(params);
    }

    static getRemarks() {
        const params = {
            url: '/v1/collection/recatch/remarks',
            method: 'get'
        };
        return Common.request(params);
    }

    static pingCollectionHomeImages() {
        const params = {
            url: '/v1/collection/home/images',
            method: 'post'
        };
        return Common.request(params);
    }

    static pingCollectionHomePaymentMethod(data) {
        const params = {
            url: '/v1/collection/user/payment-method',
            method: 'post',
            data
        };
        return Common.request(params);
    }

    static getStatusHomeFlag() {
        const params = {
            url: '/v1/homes/flag',
            method: 'get'
        };
        return Common.request(params);
    }

    static updateStatusHomeFlag(homeId) {
        const params = {
            url: `/v1/homes/${homeId}/flag`,
            method: 'post'
        };
        return Common.request(params);
    }

    static getVerificationHomes() {
        const params = {
            url: '/v1/collection/homes/verification',
            method: 'get'
        };

        return Common.request(params).then((response) => response.homes);
    }
}
