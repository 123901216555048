import { createRoot } from 'react-dom/client';
import transformer, { bindActions } from './sweetAlertTransformer';
import React from 'react';

/*
 * Convert <Element /> to pure DOM node using ReactDOM
 * (remember that ReactDOM.render() is async!)
 */
const getDOMNodeFromJSX = (Element) => {
    const wrapper = document.createElement('div');
    const root = createRoot(wrapper);
    root.render(Element);
    return wrapper;
};

const swal = async (...params) => {
    return new Promise((resolve) => {
        resolve(
            transformer(params, {
                identifier: React.isValidElement,
                transformer: getDOMNodeFromJSX
            })
        );
    });
};

bindActions(swal);

export default swal;
