import Model from './Model';
import i18n from '../i18n';

export default class Group extends Model {
    static TYPE_GROUP = 1;
    static TYPE_CLUB = 2;

    static GTG_GROUP_ID = 192;

    static AUTORIZATION_LIST = [
        96, 97, 103, 107, 111, 112, 119, 193, 194, 196, 198, 199, 200, 203, 204, 205, 207, 208, 209, 210, 211,
        212, 213, 215, 216, 217, 219, 221, 222, 227, 234, 237
    ];

    static CATEGORY_LIFESTYLE = 1;

    static MEMBER_PER_PAGE_DISPLAY = 15;

    label() {
        const description = this.get('descriptions').find((desc) => desc.locale == i18n.language);
        return _.result(description, 'label');
    }

    description() {
        const description = this.get('descriptions').find((desc) => desc.locale == i18n.language);
        return _.result(description, 'description');
    }
}
