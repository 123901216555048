import React from 'react';
import PropTypes from 'prop-types';
import Calendar from './Calendar';
import Availability from '../../models/Availability';

export default class InputCalendar extends Calendar {
    customClass = 'input-calendar';

    static propTypes = {
        ...Calendar.propTypes,
        required: PropTypes.bool,
        contactOnUnavailablePeriods: PropTypes.bool,
        side: PropTypes.string,
        onShow: PropTypes.func,
        onClose: PropTypes.func
    };

    static defaultProps = {
        ...Calendar.defaultProps,
        required: false,
        contactOnUnavailablePeriods: true,
        side: 'bottom'
    };

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();

        if (this.constructor === InputCalendar) {
            throw new Error("Can't instantiate abstract class!");
        }

        this.bodyClickHandler = this.bodyClickHandler.bind(this);
    }

    componentWillUnmount() {
        if (this.calendar) {
            this.calendar.destroy();
        }
    }

    /**
     * Override escapeKeyHandler to close calendar when the escape key is pressed
     */
    escapeKeyHandler() {
        $(document).keyup((e) => {
            if (e.keyCode === 27 && this.calendar) {
                // In input mode, we close the calendar
                this.closeCalendar();
            }
        });
    }

    /**
     * When clicking outside the calendar, close the calendar
     */
    bodyClickHandler(event) {
        const notCloseClasses = ['date_range', 'kalendae'];
        const $target = $(event.target);
        const shouldNotClosed = notCloseClasses.some(
            (className) => $target.hasClass(className) || $target.closest(`.${className}`).length > 0
        );

        if (!shouldNotClosed || $target.hasClass('k-btn-close')) {
            this.closeCalendar();
            $(document).off('click touchend', this.bodyClickHandler);
        }
    }

    isVisible() {
        return this.calendar.container.style.display != 'none';
    }

    /**
     * Display the calendar
     */
    showCalendar() {
        if (this.calendar) {
            this.calendar.show();
            setTimeout(() => {
                $(document).on('click touchend', this.bodyClickHandler);
            }, 100);
            if (this.props.onShow) {
                this.props.onShow.call(this);
            }
        }
    }

    /**
     * Close calendar
     */
    closeCalendar() {
        if (this.calendar) {
            this.calendar.hide();
            $(document).off('click touchend', this.bodyClickHandler);
            if (this.props.onClose) {
                this.props.onClose.call(this);
            }
        }
    }

    /**
     * Fill dateClassMap for a given availability
     * @param {Array} dateClassMap dateClassMap to fill
     * @param {Availability} availability
     */
    setClassesForAvailability(dateClassMap, availability) {
        // Add class for the last day of each week
        const lastWeekDay = moment().endOf('week').isoWeekday();

        const start = availability.get('start_on');
        const end = availability.get('end_on');
        const range = moment.range(start, end);

        for (const day of range.by('days')) {
            // Add custom classes for each day
            const classes = [];
            let classType;

            if (
                availability.get('type') === Availability.AVAILABLE.type ||
                availability.get('type') === Availability.NON_RECIPROCAL.type ||
                availability.get('type') === Availability.RECIPROCAL.type
            ) {
                classType = 'selectable';
            } else if (availability.get('type') === Availability.BOOKED.type) {
                // First and last day of the booked period are still selectable
                classType = 'unselectable';
            }
            if (day.isSame(start)) {
                classType += '-start';
            } else if (day.isSame(end)) {
                classType += '-end';
            }

            classes.push(classType);

            if (dateClassMap[day.format(this.format)]) {
                // If dates overlap
                classes.push(dateClassMap[day.format(this.format)]);
            }

            if (day.isoWeekday() === lastWeekDay && classes.indexOf('end-week') < 0) {
                // If it's the last day of the week
                classes.push('end-week');
            }

            // Merge if a date is both a "start" and a "end"
            const classArray = classes.join(' ').split(' ');
            this.mergeStartEndPeriods(classArray);

            dateClassMap[day.format(this.format)] = classArray.join(' ');
        }
    }

    /**
     * If a date has both "start" and "end" classes, remove them and create a "full" class
     * Example: "selectable-start" and "selectable-end" becomes "selectable"
     * @param  {Array} classArray Array of HTML classes for a given date
     */
    mergeStartEndPeriods(classArray) {
        const mergeForGivenType = (type) => {
            if (classArray.indexOf(`${type}-start`) >= 0 && classArray.indexOf(`${type}-end`) >= 0) {
                classArray.push(type);
                classArray.splice(classArray.indexOf(`${type}-start`), 1);
                classArray.splice(classArray.indexOf(`${type}-end`), 1);
            }
        };

        mergeForGivenType('selectable');
        mergeForGivenType('unselectable');
    }
}
