import Api from '../api/Api';

export const UPDATE_ROULETTE = 'UPDATE_ROULETTE';
export const CLEAR_ROULETTE = 'CLEAR_ROULETTE';

export function update(variables) {
    return {
        type: UPDATE_ROULETTE,
        variables
    };
}

export function clear() {
    return {
        type: CLEAR_ROULETTE
    };
}

export function fetch(userId, defaults) {
    return (dispatch) => Api.Roulette.get(userId, defaults).then((response) => dispatch(update(response)));
}
