import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

/**
 * Specifies the drop target contract.
 * All methods are optional.
 */
const target = {
    canDrop() {
        return true;
    },

    drop(props, monitor) {
        if (monitor.didDrop()) {
            return;
        }

        const { files } = monitor.getItem();

        if (files.length !== 1) {
            return;
        }

        if (props.accepts && !props.accepts.includes(files[0].type)) {
            return;
        }

        if (props.maxSize && props.maxSize > 0 && files[0].size > props.maxSize) {
            return;
        }

        props.onDrop(files[0]);

        return { file: files[0] };
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType()
    };
}

export class FileTarget extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        accepts: PropTypes.arrayOf(PropTypes.string),
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
        canDrop: PropTypes.bool.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        isOver: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        maxSize: PropTypes.number,
        onClick: PropTypes.func,
        // eslint-disable-next-line react/no-unused-prop-types
        onDrop: PropTypes.func
    };

    render() {
        const { isOver, canDrop, children, connectDropTarget, onClick } = this.props;
        return connectDropTarget(
            <div className={classNames('file-target', { isOver, canDrop })} onClick={onClick}>
                {children}
            </div>
        );
    }
}

export default DropTarget(NativeTypes.FILE, target, collect)(FileTarget);
