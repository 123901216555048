import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export default class Switch extends React.Component {
    static propTypes = {
        checked: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        extraData: PropTypes.any,
        text: PropTypes.shape({
            on: PropTypes.string,
            off: PropTypes.string
        })
    };

    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked
        };
        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            checked: nextProps.checked
        });
    }

    onChange() {
        const originalValue = this.state.checked;
        this.setState({
            checked: !this.state.checked
        });
        const returnValue = this.props.onChange.call(this, this.props.extraData);

        // If the callback returns false, set the value to it's original state
        if (returnValue === false) {
            this.setState({
                checked: originalValue
            });
        }
    }

    render() {
        const handleClasses = classNames({
            handle: true,
            withText: Boolean(this.props.text)
        });

        return (
            <label className="toggle">
                <input type="checkbox" checked={this.state.checked} onChange={this.onChange} />
                <span className={handleClasses}>
                    {(() => {
                        if (this.props.text) {
                            return (
                                <div>
                                    <span className="off-text">{this.props.text.off}</span>
                                    <span className="on-text">{this.props.text.on}</span>
                                </div>
                            );
                        }
                    })()}
                </span>
            </label>
        );
    }
}
