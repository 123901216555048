import React from 'react';
import PropTypes from 'prop-types';

export default function GuestPointsIcon(props) {
    return (
        <div className="guest-points-icon">
            <span>+{props.value}</span>
        </div>
    );
}

GuestPointsIcon.propTypes = {
    value: PropTypes.number
};
