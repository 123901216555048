import { LOAD_USER_GROUPS } from '../actions/userGroups';

import { JOIN_GROUP } from '../actions/groups';

export default function userGroups(state = {}, { type, payload }) {
    switch (type) {
        case LOAD_USER_GROUPS:
            return Object.assign({}, state, payload);
        case JOIN_GROUP:
            if (payload.userId && payload.group) {
                return Object.assign({}, state, {
                    [payload.userId]: state[payload.userId].concat(payload.group)
                });
            }
            return state;
        default:
            return state;
    }
}
