export default class Screen {
    static matchesMedia(query) {
        return window && window.matchMedia(query).matches;
    }

    static isMobile() {
        return this.matchesMedia('(max-width: 767px)');
    }

    static noDesktop() {
        return this.matchesMedia('(max-width: 991px)');
    }

    static isTabletOrUpper() {
        return this.matchesMedia('(min-width: 992px)');
    }

    static isDesktop() {
        return this.matchesMedia('(min-width: 1200px)');
    }

    static addResizeListener(callback) {
        const listener = () => {
            callback({
                isMobile: this.isMobile(),
                noDesktop: this.noDesktop(),
                isTabletOrUpper: this.isTabletOrUpper(),
                isDesktop: this.isDesktop()
            });
        };

        window.addEventListener('resize', listener);

        return () => {
            window.removeEventListener('resize', listener);
        };
    }
}
