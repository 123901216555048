import Common from './Common';
import Storage from '../utils/Storage';
import Group from '../models/Group';

export default class User {
    static getUserId() {
        return window && window.gtg && window.gtg.userId ? window.gtg.userId : null;
    }

    static identity() {
        return Storage.get('user', 'session');
    }

    static me(noCache) {
        if (!noCache && Storage.get('user', 'session')) {
            const user = Storage.get('user', 'session');
            const deferred = $.Deferred();
            deferred.resolve(user);
            return deferred;
        }
        const params = {
            url: '/v1/users/me',
            method: 'get'
        };

        if (noCache) {
            params.cache = false;
        }

        const promise = Common.request(params);
        promise.then((user) => {
            Storage.set('user', user, 'session');
        });
        return promise;
    }

    static get(filters, orderBy, limit, offset) {
        const params = Common.params(
            {
                url: '/v1/users',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static getOne(userId) {
        const params = Common.params({
            url: `/v1/users/${userId}`,
            method: 'get'
        });
        return Common.request(params);
    }

    static getUserJWTToken() {
        const params = Common.params({
            url: '/v1/marketing/token',
            method: 'get'
        });
        return Common.request(params);
    }

    static getLastVerified(offset, limit) {
        const params = Common.params(
            {
                url: '/v1/users/get-last-verified',
                method: 'get',
                data: {
                    filters: {
                        completionRate: 100
                    }
                }
            },
            null,
            null,
            limit,
            offset
        );
        return Common.request(params);
    }

    static patch(userId, data, options) {
        // compute birth_date fucking input format
        if (typeof data.profil === 'object' && data.profil.birth_date) {
            const birthdate = data.profil.birth_date;
            data.profil.birth_date = typeof birthdate === 'number' ? birthdate : moment(birthdate).year();
        }

        return Common.request(
            Object.assign(
                {
                    url: `/v1/users/${userId}`,
                    method: 'patch',
                    data
                },
                options
            )
        ).done((user) => {
            Storage.set('user', user, 'session');
        });
    }

    static postImage(image, callback, fail) {
        const formData = new FormData();
        formData.append('file', image);
        const params = {
            url: '/v1/images/user',
            method: 'post',
            data: formData,
            contentType: false,
            processData: false,
            progressBarProfile: true
        };
        return Common.fetch(params, callback, fail);
    }

    static fetchGroups() {
        const params = Common.params({
            url: '/v3/groups',
            method: 'get'
        });

        return Common.request(params)
            .then((response) => {
                if (response.data) {
                    return response.data.groups;
                } else {
                    return response;
                }
            })
            .then((groups) => {
                Storage.set('groups', groups);
                return groups;
            });
    }

    static getGroup(groupId, limit = 15, offset = 0) {
        const params = Common.params(
            {
                url: `/v1/groups/view/${groupId}`,
                method: 'get'
            },
            null,
            null,
            limit,
            offset
        );
        return Common.request(params);
    }

    static getGroups() {
        const deferred = $.Deferred();

        let groups = [];
        if (Storage.get('groups')) {
            groups = Storage.get('groups').map((group) => new Group(group));
            deferred.resolve(groups);
        } else {
            User.fetchGroups().done((grps) => {
                grps = grps || [];
                grps = grps.map((group) => new Group(group));
                deferred.resolve(grps);
            });
        }

        return deferred.promise();
    }

    static joinGroup(id) {
        const params = Common.params({
            url: `/v1/groups/join`,
            method: 'post',
            data: {
                group_id: id
            }
        });
        return Common.request(params);
    }

    static leaveGroup(id) {
        const params = Common.params({
            url: `/v1/groups/leave`,
            method: 'patch',
            data: {
                group_id: id
            }
        });
        return Common.request(params);
    }

    static fetchFriends(offset, limit) {
        const params = Common.params(
            {
                url: `/v1/friends`,
                method: 'get'
            },
            null,
            null,
            limit,
            offset
        );
        return Common.request(params);
    }

    static fetchPendingFriends(offset, limit) {
        const params = Common.params(
            {
                url: `/v1/friends/pending`,
                method: 'get'
            },
            null,
            null,
            limit,
            offset
        );
        return Common.request(params);
    }

    static fetchSentFriendRequests(offset, limit) {
        const params = Common.params(
            {
                url: `/v1/friends/requests`,
                method: 'get'
            },
            null,
            null,
            limit,
            offset
        );
        return Common.request(params);
    }

    static acceptFriend(userId) {
        const params = Common.params({
            url: `/v1/friends/${userId}/accept`,
            method: 'patch'
        });
        return Common.request(params);
    }

    static refuseFriend(userId) {
        const params = Common.params({
            url: `/v1/friends/${userId}/refuse`,
            method: 'patch'
        });
        return Common.request(params);
    }

    static addFriend(userId) {
        const params = Common.params({
            url: `/v1/friends/${userId}`,
            method: 'post'
        });
        return Common.request(params);
    }

    static deleteFriend(userId) {
        const params = Common.params({
            url: `/v1/friends/${userId}`,
            method: 'delete'
        });
        return Common.request(params);
    }

    static getFriends() {
        return Storage.get('userFriends');
    }

    static fetchUserGroups(userId) {
        const params = Common.params({
            url: '/v3/groups',
            method: 'get',
            data: {
                user_id: userId
            }
        });

        return Common.request(params)
            .then((response) => {
                if (response.data) {
                    return response.data.groups;
                } else {
                    return response;
                }
            })
            .then((groups) => {
                Storage.set(`${userId}_groups`, groups);
                return groups;
            })
            .then((groups) => groups.map((group) => new Group(group)));
    }

    static getUserGroups(userId) {
        const deferred = $.Deferred();
        let groups = [];

        if (Storage.get(`${userId}_groups`)) {
            groups = Storage.get(`${userId}_groups`);
            groups = groups.map((group) => new Group(group));
            deferred.resolve(groups);
        } else {
            User.fetchUserGroups(userId).done((grps) => {
                if (Array.isArray(grps)) {
                    grps = grps.map((group) => new Group(group));
                    return deferred.resolve(grps);
                }
                deferred.reject('#fetchUserGroups() should return an array');
            });
        }
        return deferred.promise();
    }

    static getContactedMembers() {
        return Storage.get('contactedMembers', 'session');
    }

    static delete(userId) {
        const params = {
            url: `/v1/users/delete/${userId}`,
            method: 'delete'
        };
        return Common.request(params);
    }

    static create(user, isCollection = false) {
        return Common.request({
            url: isCollection ? '/v1/collection/user' : '/v1/users',
            method: 'post',
            data: user,
            auth: false
        });
    }

    static createFromFacebook(accessToken, email) {
        return Common.request({
            url: '/v1/users/facebook',
            method: 'post',
            data: {
                facebook_token: accessToken,
                email
            },
            auth: false
        });
    }

    static generatePhoneVerificationCode(user) {
        const params = Common.params({
            url: '/v1/verification/phone',
            method: 'post',
            data: {
                number: user.get('phone'),
                method: 'sms',
                language: user.get('locale'),
                userId: user.get('id')
            }
        });
        return Common.request(params);
    }

    static sendPhoneVerificationCode(user, code) {
        const params = Common.params({
            url: '/v1/verification/phone/code',
            method: 'patch',
            data: {
                number: user.get('phone'),
                userId: user.get('id'),
                code
            }
        });
        return Common.request(params);
    }

    static getTransferts(filters, orderBy, limit = 10000, offset) {
        const params = Common.params(
            {
                url: '/v1/users/transferts',
                method: 'get'
            },
            filters,
            orderBy,
            limit,
            offset
        );
        return Common.request(params);
    }

    static getTransfertDetails() {
        const params = Common.params({
            url: `/v1/users/transferts-details`,
            method: 'get'
        });
        return Common.request(params);
    }

    static getNotifications() {
        const params = Common.params({
            url: '/v1/notification/headband',
            method: 'get'
        });
        return Common.request(params).then((res) => {
            if (!res) {
                return [];
            } else if (Array.isArray(res)) {
                return res;
            } else {
                return [res];
            }
        });
    }

    static linkFacebook(facebookId, facebookAccessToken) {
        const params = Common.params({
            url: '/v1/users/link-facebook',
            method: 'post',
            data: {
                facebookId,
                facebookAccessToken
            }
        });
        return Common.request(params);
    }

    static requestEmailTokenVerif(id) {
        const params = Common.params({
            url: `/v1/verification/email/${id}`,
            method: 'post',
            data: {
                callbackUrl: '/dashboard'
            }
        });
        return Common.request(params);
    }

    static resendEmailVerif(email) {
        const params = Common.params({
            url: '/v1/verification/email/resend',
            method: 'post',
            data: { email }
        });
        return Common.request(params);
    }

    static sendEmailTokenVerif(token) {
        const params = Common.params({
            url: '/v1/verification/token',
            method: 'patch',
            data: {
                token
            }
        });
        return Common.request(params);
    }

    static getPaymentMethod() {
        const params = Common.params({
            url: '/v1/payment-methods',
            method: 'get'
        });
        return Common.request(params);
    }

    static addPaymentMethod(data) {
        const params = Common.params({
            url: '/v1/payment-methods',
            method: 'post',
            data
        });
        return Common.request(params);
    }

    static addCreditCard(stripeToken) {
        return User.addPaymentMethod({
            stripeToken
        });
    }

    static addPaypal(braintreeNonce, braintreeDeviceData) {
        return User.addPaymentMethod({
            braintreeNonce,
            braintreeDeviceData
        });
    }

    static removePaymentMethod(cardId) {
        const params = Common.params({
            url: `/v1/payment-methods/${cardId}`,
            method: 'delete'
        });
        return Common.request(params);
    }

    static getTopMenuNotifications() {
        const params = Common.params({
            url: `/v1/notification/topmenu`,
            method: 'get'
        });
        return Common.request(params);
    }

    static getPromoCode(promoCode, productType) {
        const params = Common.params({
            url: `/v1/coupon?product_type=${productType}`,
            method: 'post',
            data: {
                coupon_name: promoCode
            }
        });
        return Common.request(params);
    }

    static getPromoCodeByType(promoCode) {
        const params = Common.params({
            url: `/v1/coupon`,
            method: 'post',
            data: {
                coupon_type: promoCode
            }
        });
        return Common.request(params);
    }

    static changeDefaultPayment(cardId, type) {
        const params = Common.params({
            url: '/v1/payment-methods',
            method: 'patch',
            data: {
                [type === 'card' || type === 'payment_method' ? 'card_id' : 'braintree_id']: cardId
            }
        });
        return Common.request(params);
    }

    static getSponsorshipKey() {
        const params = Common.params({
            url: `/v1/users/sponsorship-key`,
            method: 'get'
        });
        return Common.request(params);
    }

    static getSponsoredFriends() {
        const params = Common.params({
            url: `/v1/user/sponsored`,
            method: 'get'
        });
        return Common.request(params);
    }

    static sendInvite(emails) {
        const formData = new FormData();
        // const arrayEmails = [];
        // data.default.forEach(item => arrayEmails.push(item.email));
        // const emails = arrayEmails.join();
        formData.append('emails', emails.default[0].email);
        const params = Common.params({
            url: '/v1/users/send-referral-mail',
            method: 'post',
            data: formData,
            enctype: 'multipart/form-data',
            contentType: false,
            processData: false
        });
        return Common.request(params);
    }

    static signupCollectionFlowAbandonned() {
        const params = Common.params({
            url: '/v1/collection/user/abandoned-flow',
            method: 'get'
        });
        return Common.request(params);
    }
}
