import { ADD_PRODUCTS_FOR_EXCHANGE } from '../actions/productsByExchange';

export default function productsByExchange(state = {}, { type, payload }) {
    switch (type) {
        case ADD_PRODUCTS_FOR_EXCHANGE:
            return Object.assign({}, state, payload);
        default:
            return state;
    }
}
