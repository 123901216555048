import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Overlay, Tooltip as BsTooltip } from 'react-bootstrap';

export default class Tooltip extends React.PureComponent {
    static propTypes = {
        children: PropTypes.element,
        id: PropTypes.string,
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
        container: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    };

    static defaultProps = {
        children: null,
        container: false,
        id: 'tooltip',
        placement: 'right'
    };

    constructor(props) {
        super(props);

        this.target = React.createRef();

        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.getContainer = this.getContainer.bind(this);

        this.state = {
            show: false
        };
    }

    handleShow() {
        this.setState({
            show: true
        });
    }

    handleHide() {
        this.setState({
            show: false
        });
    }

    getContainer() {
        if (this.props.container) {
            return document.querySelector(this.props.container);
        }
    }

    render() {
        const { title, placement } = this.props;
        let { children } = this.props;

        if (!title) {
            return children;
        }

        if (!children) {
            children = <i className="icon-circle-help" />;
        }

        const CustomTooltip = (props) => {
            const newProps = Object.assign({}, props, {
                className: classNames(props.className, 'tooltip-editablefield')
            });
            return (
                <BsTooltip {...newProps}>
                    {typeof title === 'string' ? <span dangerouslySetInnerHTML={{ __html: title }} /> : title}
                </BsTooltip>
            );
        };

        return (
            <React.Fragment>
                {React.cloneElement(children, {
                    ref: this.target,
                    onMouseOver: this.handleShow,
                    onMouseOut: this.handleHide,
                    onFocus: this.handleShow,
                    onBlur: this.handleHide
                })}
                <Overlay
                    placement={placement}
                    container={this.getContainer}
                    target={this.target.current}
                    show={this.state.show}
                >
                    <CustomTooltip id={this.props.id} />
                </Overlay>
            </React.Fragment>
        );
    }
}
