import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import UserModel from '../../models/User';
import i18n from '../../i18n';
import RatingWidgetStar from './RatingWidgetStar';
import UserImage from '../user/UserImage';
import TranslateContent from './../common/TranslateContent';

export class RatingWidget extends React.PureComponent {
    static propTypes = {
        rating: PropTypes.shape({
            author: PropTypes.object.isRequired,
            home: PropTypes.object,
            clean: PropTypes.number,
            communication: PropTypes.number,
            trust: PropTypes.number,
            expectation: PropTypes.number,
            polite: PropTypes.number,
            feedback: PropTypes.string,
            extra_data: PropTypes.shape({
                start_on: PropTypes.string,
                end_on: PropTypes.string,
                rating_year: PropTypes.string,
                type: PropTypes.string.isRequired,
                nb_night: PropTypes.number
            }).isRequired
        }).isRequired,
        user: PropTypes.instanceOf(UserModel).isRequired,
        loggedUser: PropTypes.instanceOf(UserModel).isRequired,
        isConnected: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            feedbackLanguage: null
        };

        this.translateFeedback = this.translateFeedback.bind(this);
    }

    translateFeedback() {
        if (this.state.feedbackLanguage) {
            this.setState({
                feedbackLanguage: null
            });
        } else {
            this.setState({
                feedbackLanguage: i18n.language
            });
        }
    }

    render() {
        const { feedbackLanguage } = this.state;
        const { rating, user, isConnected, loggedUser } = this.props;
        const author = new UserModel(rating.author);
        const startDate = rating.extra_data.start_on ? moment(rating.extra_data.start_on) : null;
        const nbNights = rating.extra_data.nb_night;
        const ratingYear = rating.extra_data.rating_year;
        let firstName = author.get('first_name');
        const { type } = rating.extra_data;
        let home = null;
        let linkHome = null;

        if (author && author.getPrimaryHome()) {
            home = author.getPrimaryHome();
            if (home.get('status') == 5) {
                linkHome = (
                    <a href={i18n.t('home:home.view.url', { id: home.get('id') })}>
                        {i18n.t('home:see-home', { firstname: firstName })}
                    </a>
                );
            }
        }

        if (type === 'host') {
            firstName = user.get('first_name');
        }

        let explanations;
        if (startDate && nbNights > 0) {
            if (type === 'guest') {
                explanations = i18n.t('rating:explanations-guest', {
                    user: user.get('first_name'),
                    days: nbNights,
                    date: startDate.format('LL')
                });
            } else {
                explanations = i18n.t('rating:explanations2', {
                    user: firstName,
                    days: nbNights,
                    date: startDate.format('LL')
                });
            }
        } else if (ratingYear) {
            if (type === 'guest') {
                explanations = i18n.t('rating:explanations_no_dates-guest', {
                    user: user.get('first_name'),
                    year: ratingYear
                });
            } else {
                explanations = i18n.t('rating:explanations_no_dates2', {
                    user: firstName,
                    year: ratingYear
                });
            }
        }

        let feedback = '';
        if (rating.feedback) {
            feedback = (
                <span className="text-feedback">
                    <TranslateContent
                        translations={{ rating }}
                        toLanguage={feedbackLanguage}
                        content={'feedback'}
                    />
                </span>
            );
        } else if (rating.clean) {
            feedback = <em>{i18n.t('rating:no_comment')}</em>;
        } else {
            feedback = <em>{i18n.t('rating:not_rating')}</em>;
        }

        return (
            <div
                className="col-sm-12 rating-widget"
                itemProp="review"
                itemScope
                itemType="http://schema.org/Review"
            >
                <div className="rating-widget-wrapper">
                    <div className="rating-widget-header">
                        <div className="rating-widget-header-left">
                            <UserImage user={author} isConnected={isConnected} />
                            <div>
                                <div
                                    className="rating-widget-header-author"
                                    itemProp="author"
                                    itemScope
                                    itemType="https://schema.org/Person"
                                >
                                    <span itemProp="name">{author.get('first_name')}</span>
                                </div>
                                <div className="rating-widget-header-status">{explanations}</div>
                            </div>
                        </div>
                        {loggedUser.get('is_subscribed') && (
                            <button className="btn btn-secondary hidden-xs" onClick={this.translateFeedback}>
                                {feedbackLanguage
                                    ? i18n.t('common:translate_original_text')
                                    : i18n.t('rating:translate-one-comment')}
                            </button>
                        )}
                    </div>

                    {rating.clean ? (
                        <ul className="rating-widget-stars">
                            {type === 'host' ? (
                                <RatingWidgetStar
                                    nbStars={rating.communication}
                                    type={i18n.t('rating:communication')}
                                />
                            ) : (
                                <RatingWidgetStar
                                    nbStars={rating.trust}
                                    type={i18n.t('rating:trustworthiness')}
                                />
                            )}
                            {type === 'host' ? (
                                <RatingWidgetStar
                                    nbStars={rating.expectation}
                                    type={i18n.t('rating:home_matches_description')}
                                />
                            ) : (
                                <RatingWidgetStar
                                    nbStars={rating.polite}
                                    type={i18n.t('rating:politeness')}
                                />
                            )}
                            <RatingWidgetStar nbStars={rating.clean} type={i18n.t('rating:cleanliness')} />
                        </ul>
                    ) : null}

                    {loggedUser.get('is_subscribed') && (
                        <button className="btn btn-secondary visible-xs" onClick={this.translateFeedback}>
                            {feedbackLanguage
                                ? i18n.t('common:translate_original_text')
                                : i18n.t('rating:translate-one-comment')}
                        </button>
                    )}

                    <div
                        className={classNames('rating-widget-feedback', {
                            'blurred-comment': !loggedUser.get('is_subscribed'),
                            'user-select-none': !loggedUser.get('is_subscribed')
                        })}
                        itemProp="reviewBody"
                        onMouseDown={(e) => {
                            if (!loggedUser.get('is_subscribed')) {
                                e.preventDefault();
                            }
                        }}
                    >
                        {feedback}
                    </div>

                    {!_.isNull(linkHome) ? <div className="rating-widget-link">{linkHome}</div> : null}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isConnected: Boolean(state.user)
});

export default connect(mapStateToProps)(RatingWidget);
