import React from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';

const CustomStripeProvider = (props) => (
    <StripeProvider apiKey={process.env.STRIPE_KEY}>
        <Elements
            fonts={[
                {
                    family: 'Averta',
                    src: `url('https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-regular.eot?#iefix') format('embedded-opentype'),
                        url('https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-regular.woff2') format('woff2'),
                        url('https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-regular.woff') format('woff'),
                        url('https://d2kzpkef14nfxb.cloudfront.net/fonts/avertastdpe-regular.ttf') format('truetype')`
                }
            ]}
        >
            {props.children}
        </Elements>
    </StripeProvider>
);

CustomStripeProvider.propTypes = {
    children: PropTypes.element
};

export default CustomStripeProvider;
