import { SET_PRODUCTS } from '../actions/products';

export default function products(state = [], { type, payload }) {
    switch (type) {
        case SET_PRODUCTS:
            return payload;
        default:
            return state;
    }
}
