import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

const ResponseTime = (props) => {
    const renderResponseTimeLabel = useCallback((data, isHomeView) => {
        if (data && !_.isEmpty(data)) {
            if (data.response_time !== null && data.response_time !== undefined) {
                const rt = data.response_time;
                switch (true) {
                    case rt < 60 /* less than one hour */:
                        return isHomeView
                            ? i18n.t('home:responsetime.less-than-one-hour')
                            : i18n.t('user:responsetime.less-than-one-hour');
                    case rt < 60 * 24 /* less than 24 hours */:
                        return isHomeView
                            ? i18n.t('home:responsetime.less-than-one-day')
                            : i18n.t('user:responsetime.less-than-one-day');
                    case rt < 60 * 24 * 2 /* less than 48 hours */:
                        return isHomeView
                            ? i18n.t('home:responsetime.less-than-two-days')
                            : i18n.t('user:responsetime.less-than-two-days');
                    case rt < 60 * 24 * 7:
                        return isHomeView
                            ? i18n.t('home:responsetime.less-than-one-week')
                            : i18n.t('user:responsetime.less-than-one-week');
                    case rt >= 60 * 24 * 7:
                        return isHomeView
                            ? i18n.t('home:responsetime.within-a-week-or-more')
                            : i18n.t('user:responsetime.within-a-week-or-more');
                    default:
                        return false;
                }
            } else if (!data.is_new_user) {
                return i18n.t('user:responsetime.no-recent-activity');
            }
        }
    }, []);

    return <>{renderResponseTimeLabel(props.responseTime, props.isHomeView)}</>;
};

ResponseTime.propTypes = {
    responseTime: PropTypes.shape({
        response_time: PropTypes.number,
        is_new_user: PropTypes.bool
    }),
    isHomeView: PropTypes.bool
};

export default ResponseTime;
