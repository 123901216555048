import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class FilePicker extends PureComponent {
    static propTypes = {
        accepts: PropTypes.arrayOf(PropTypes.string),
        children: PropTypes.element,
        file: PropTypes.instanceOf(window.File),
        multiple: PropTypes.bool,
        onFile: PropTypes.func.isRequired
    };

    static defaultProps = {
        multiple: false
    };

    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate() {
        if (!this.props.file) {
            this.resetInput();
        }
    }

    handleClick() {
        this.input.current.click();
    }

    handleChange() {
        const { files } = this.input.current;
        if (this.props.multiple) {
            this.props.onFile(files);
        } else if (files.length > 0) {
            this.props.onFile(files[0]);
        }
    }

    resetInput() {
        this.input.current.value = '';
        this.input.current.type = '';
        this.input.current.type = 'file';
    }

    render() {
        const { accepts, children, multiple } = this.props;
        return (
            <Fragment>
                <input
                    type="file"
                    className="hidden"
                    accept={accepts.join(', ')}
                    multiple={multiple}
                    onChange={this.handleChange}
                    ref={this.input}
                />
                {React.cloneElement(children, {
                    onClick: this.handleClick
                })}
            </Fragment>
        );
    }
}
