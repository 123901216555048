import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';

import Group from '../../../models/Group';
import {
    joinGroup as joinGroupAction,
    fetchGroupDetails as fetchGroupDetailsAction
} from '../../../actions/groups';

const ModalJoinClub = ({
    togglePopup,
    id,
    showModal,
    description,
    joinGroup,
    fetchGroupDetails,
    innerRef
}) => {
    const displayForm = () => {
        if (Group.AUTORIZATION_LIST.indexOf(id) === -1) {
            return (
                <div className="input-group form-group input-group-lg">
                    <div className="form-group">
                        <input name="email" type="email" placeholder="Email" className="form-control" />
                    </div>
                    <input type="hidden" name="groupId" className="groupId" value={id} />
                    <span className="input-group-addon">@</span>
                </div>
            );
        }
    };

    const submit = () => {
        if (Group.AUTORIZATION_LIST.indexOf(id) != -1) {
            joinGroup(id).then(() => fetchGroupDetails(id));
        }
    };

    return (
        <Modal show={showModal} onHide={togglePopup}>
            <Modal.Header closeButton>
                <h4 className="modal-title" id="joinGueststarsClubLabel">
                    {i18n.t('common:join.club')}
                </h4>
            </Modal.Header>
            <div ref={innerRef}>
                <Modal.Body>
                    <div className="message-club">{description}</div>
                    <br />
                    {displayForm()}
                    <button
                        type="submit"
                        name="submit"
                        id="submit-club-modal-button"
                        className="btn btn-primary btn-lg btn-block"
                        onClick={submit}
                    >
                        {i18n.t('user:groups.join_club')}
                    </button>
                </Modal.Body>
            </div>
        </Modal>
    );
};
const mapDispatchToProps = (dispatch) => ({
    joinGroup: bindActionCreators(joinGroupAction, dispatch),
    fetchGroupDetails: bindActionCreators(fetchGroupDetailsAction, dispatch)
});

export default connect(null, mapDispatchToProps)(ModalJoinClub);

ModalJoinClub.propTypes = {
    togglePopup: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    showModal: PropTypes.bool,
    description: PropTypes.string,
    joinGroup: PropTypes.func.isRequired,
    fetchGroupDetails: PropTypes.func,
    innerRef: PropTypes.object
};

ModalJoinClub.defaultProps = {
    showModal: false
};
