var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Map, { NavigationControl } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import CircleLayer from '../../map/mapComponents/circleLayer';
import MarkerPopup from '../../map/mapComponents/MarkerPopup';
import Utils from '../../../../utils/utils';
import PinHomeMarker from '../pin-home-marker/PinHomeMarker';
import i18n from '../../../../i18n';
var CIRCLE_OPTIONS = {
    RADIUS: 0.5,
    STEPS: 50,
    UNITS: 'kilometers'
};
var MAP_OPTIONS = {
    ZOOM: 14
};
var ZOOM_CIRCLE_BREAKPOINT = 8.5;
var JAWG_URL = 'https://api.jawg.io/styles/jawg-sunny.json?access-token=';
var JAWG_API_KEY = process.env.JAWG_KEY;
var InteractiveMap = forwardRef(function (_a, ref) {
    var _b = _a.latitude, latitude = _b === void 0 ? 48.87685 : _b, _c = _a.longitude, longitude = _c === void 0 ? 2.35274 : _c, _d = _a.bounds, bounds = _d === void 0 ? null : _d, _e = _a.controlPosition, controlPosition = _e === void 0 ? 'top-right' : _e, _f = _a.showControls, showControls = _f === void 0 ? false : _f, _g = _a.markerList, markerList = _g === void 0 ? [] : _g, _h = _a.centeredCircle, centeredCircle = _h === void 0 ? false : _h, _j = _a.centeredMarker, centeredMarker = _j === void 0 ? false : _j, _k = _a.height, height = _k === void 0 ? '100%' : _k, _l = _a.width, width = _l === void 0 ? '100%' : _l, onMoveEnd = _a.onMoveEnd, onZoomEnd = _a.onZoomEnd;
    var _m = useState({
        longitude: longitude,
        latitude: latitude,
        zoom: MAP_OPTIONS.ZOOM
    }), viewState = _m[0], setViewState = _m[1];
    var _o = useState('en'), mapLanguage = _o[0], setMapLanguage = _o[1];
    var mapRef = useRef(null);
    var moveEndHandler = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(onMoveEnd && mapRef.current)) return [3 /*break*/, 2];
                    e.bounds = mapRef.current.getMap().getBounds();
                    return [4 /*yield*/, onMoveEnd(e)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var zoomEndHandler = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(onZoomEnd && mapRef.current)) return [3 /*break*/, 2];
                    e.bounds = mapRef.current.getMap().getBounds();
                    return [4 /*yield*/, onZoomEnd(e)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var _a;
        if (bounds && ((_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.getMap())) {
            mapRef.current.getMap().fitBounds([
                [bounds.ne.lon, bounds.ne.lat],
                [bounds.sw.lon, bounds.sw.lat]
            ], { animate: false });
        }
    }, [bounds]);
    useEffect(function () {
        if (latitude && longitude && !bounds) {
            setViewState(function (prevState) { return (__assign(__assign({}, prevState), { latitude: latitude, longitude: longitude })); });
        }
    }, [latitude, longitude]);
    useEffect(function () {
        setMapLanguage(Utils.getWebsiteLanguage());
    }, []);
    return (React.createElement(Map, __assign({ ref: mapRef }, viewState, { onMove: function (event) { return setViewState(event.viewState); }, mapLib: maplibregl, initialViewState: {
            longitude: longitude,
            latitude: latitude,
            zoom: MAP_OPTIONS.ZOOM,
            bounds: bounds && [
                [bounds.ne.lon, bounds.ne.lat],
                [bounds.sw.lon, bounds.sw.lat]
            ]
        }, style: { width: width, height: height }, mapStyle: "".concat(JAWG_URL).concat(JAWG_API_KEY, "&lang=").concat(mapLanguage), onMoveEnd: moveEndHandler, onZoomEnd: zoomEndHandler }),
        showControls && React.createElement(NavigationControl, { position: controlPosition }),
        centeredMarker && (React.createElement(PinHomeMarker, { latitude: latitude, longitude: longitude, tooltipContent: i18n.t('search:location_radius_1km') })),
        centeredCircle && (React.createElement(CircleLayer, { latitude: latitude, longitude: longitude, radius: CIRCLE_OPTIONS.RADIUS, steps: CIRCLE_OPTIONS.STEPS, units: CIRCLE_OPTIONS.UNITS, isMarker: viewState.zoom <= ZOOM_CIRCLE_BREAKPOINT })),
        (markerList === null || markerList === void 0 ? void 0 : markerList.length) > 0 &&
            markerList.map(function (marker) {
                return marker.position && (React.createElement(MarkerPopup, { key: "".concat(marker.id), latitude: marker === null || marker === void 0 ? void 0 : marker.position.lat, longitude: marker === null || marker === void 0 ? void 0 : marker.position.lon, content: marker === null || marker === void 0 ? void 0 : marker.content, customMarker: marker === null || marker === void 0 ? void 0 : marker.customMarker }));
            })));
});
export default InteractiveMap;
