import React from 'react';
import PropTypes from 'prop-types';

const NewNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button type="button" className={className} style={style} onClick={onClick}>
            <i className="icon-right_arrow" />
        </button>
    );
};

NewNextArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object
};

export default NewNextArrow;
