import React from 'react';

import i18n from '../../i18n';
import Utils from '../../utils/utils';

const CreditCardDescription = () => (
    <div className="row credit-card-description">
        <div className="col-md-7">
            <div className="text">{i18n.t('subscription:safe_money_transfer')}</div>
        </div>

        <div className="col-md-5">
            <div className="card-icons">
                <img src={`${Utils.getCloudfrontDomain()}/images/website/subscription/visa.svg`} />
                <img src={`${Utils.getCloudfrontDomain()}/images/website/subscription/mastercard.svg`} />
                <img src={`${Utils.getCloudfrontDomain()}/images/website/subscription/discover.svg`} />
                <img src={`${Utils.getCloudfrontDomain()}/images/website/subscription/american.svg`} />
            </div>
        </div>
    </div>
);

export default CreditCardDescription;
