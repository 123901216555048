import Common from './Common';

export default class ResponseTime {
    static fetchResponseTime(userId) {
        const params = Common.params({
            url: `/v1/users/${userId}/response/time`,
            method: 'get'
        });
        return Common.request(params);
    }
}
