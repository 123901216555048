import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../i18n';
import Separator from '../separator/Separator';
import { fetchBadgeImage } from '../../hooks/useBadge';

export default function UserBadge(props) {
    const { level, isClickable = true } = props;

    const getText = () =>
        level > 1
            ? i18n.t('home:home-view.badge.text.year', { year: level })
            : i18n.t('home:home-view.badge.text.new');

    return (
        level > 0 && (
            <>
                <Separator />
                <a
                    className={classNames('user-badge-link', { 'user-badge-not-clickable': !isClickable })}
                    href={i18n.t('url:subscription-he-cms')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className="user-badge-container">
                        <img
                            className="user-badge-img"
                            src={fetchBadgeImage(level).src}
                            alt={fetchBadgeImage(level).alt}
                        />
                        <div className="user-badge-text-container">
                            <div className="user-badge-title-container">
                                <h2 className="user-badge-title">{i18n.t('home:home-view.badge.title')}</h2>
                                {isClickable && <i className="icon-open-link visible-xs" />}
                            </div>
                            <p className="user-badge-text">{getText()}</p>
                        </div>
                    </div>
                </a>
                <Separator />
            </>
        )
    );
}

UserBadge.propTypes = {
    level: PropTypes.number.isRequired,
    isClickable: PropTypes.bool
};
