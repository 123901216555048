import emojione from 'emojione';

import Model from './Model';
import i18n from '../i18n';
import User from './User';
import Utils from '../utils/utils';
import Api from '../api/Api';

emojione.ascii = true;

export default class Message extends Model {
    static STATUS_RECEIVED_UNREAD = 0;
    static STATUS_RECEIVED_READ = 1;

    static TYPE_USER = 0;
    static TYPE_AUTO = 1;
    static TYPE_EXCHANGE = 2;
    static TYPE_PAYMENT = 3;
    static TYPE_MODIFICATION = 5;
    static TYPE_INFO = 6;

    static TYPE_AUTO_MESSAGE_APPROVED = 1;
    static TYPE_AUTO_MESSAGE_FINALIZED = 2;
    static TYPE_AUTO_MESSAGE_CANCEL_APPROVAL = 3;
    static TYPE_AUTO_MESSAGE_CANCEL = 4;
    static TYPE_AUTO_MESSAGE_RELEASE_DEPOSIT = 5;
    static TYPE_AUTO_MESSAGE_KEEP_DEPOSIT = 6;
    static TYPE_AUTO_MESSAGE_RELEASE_GP = 7;
    static TYPE_AUTO_MESSAGE_KEEP_GP = 8;
    static TYPE_AUTO_MESSAGE_CHANGE_STARTON = 9;
    static TYPE_AUTO_MESSAGE_CHANGE_ENDON = 10;
    static TYPE_AUTO_MESSAGE_CHANGE_DEPOSIT = 11;
    static TYPE_AUTO_MESSAGE_CHANGE_INSURANCE = 12;
    static TYPE_AUTO_MESSAGE_CHANGE_NBGUEST = 13;
    static TYPE_AUTO_MESSAGE_CHANGE_GP = 14;
    static TYPE_AUTO_MESSAGE_CHANGE_TO_SIMPLE = 15;
    static TYPE_AUTO_MESSAGE_CHANGE_TO_RECIPROCAL = 16;
    static TYPE_AUTO_MESSAGE_CHANGE_SERVICE_PLUS = 17;
    static TYPE_MESSAGE_SUPPORT_CHANGE_DATE = 21;
    static TYPE_MESSAGE_SUPPORT_CHANGE_HOME = 22;
    static TYPE_MESSAGE_SUPPORT_CHANGE_GP = 23;
    static TYPE_MESSAGE_SUPPORT_CHANGE_GUESTS = 24;
    static TYPE_MESSAGE_SUPPORT_CHANGE_MANY_DETAILS = 25;
    static TYPE_AUTO_MESSAGE_UPDATE_REQUEST_CANCELLED = 31;
    static TYPE_AUTO_MESSAGE_UPDATE_REQUEST_APPROVED = 32;
    static TYPE_AUTO_MESSAGE_UPDATE_REQUEST_REJECTED = 33;
    static TYPE_AUTO_MESSAGE_UPDATE_FINALIZED = 35;

    static AUTOMESSAGE_FINALIZE = 0;

    static KEY_AUTO_MESSAGE_NAME_APPROVED_MODIFICATION = 'auto_message_name_approved_modification';
    static KEY_AUTO_MESSAGE_RECEIVER_REJECTED_MODIFICATION = 'auto_message_receiver_rejected_modification';
    static KEY_AUTO_MESSAGE_REQUESTER_CANCELED_MODIFICATION = 'auto_message_requester_cancels_modification';

    parse(attributes, options) {
        attributes = super.parse(attributes, options);

        if (attributes.hasOwnProperty('author') && _.isObject(attributes.author)) {
            if (attributes.author instanceof User === false) {
                attributes.author = new User(attributes.author);
            }
        }

        if (
            attributes.hasOwnProperty('type') &&
            attributes.type != Message.TYPE_USER &&
            attributes.hasOwnProperty('type_auto') &&
            attributes.type_auto
        ) {
            const key = this.typeAutoToSlug(attributes.type_auto).replace(new RegExp('-', 'g'), '_');
            if (key !== '' && key !== undefined) {
                attributes.content = i18n.t(`messaging:auto_message_${key}`, {
                    context: attributes.author.id === Api.User.identity().id ? 'me' : 'you',
                    first_name: attributes.author.get('first_name')
                });
            }
        }

        return attributes;
    }

    getType() {
        switch (this.get('type')) {
            case Message.TYPE_USER:
                return 'user';
            case Message.TYPE_AUTO:
                return 'auto';
            case Message.TYPE_EXCHANGE:
                return 'exchange';
            case Message.TYPE_PAYMENT:
                return 'payment';
            case Message.TYPE_MODIFICATION:
                return 'modification';
            case Message.TYPE_INFO:
                return 'info';
            default:
                return '';
        }
    }

    typeAutoToSlug(typeAuto) {
        switch (typeAuto) {
            case Message.TYPE_AUTO_MESSAGE_APPROVED:
                return 'approved';
            case Message.TYPE_AUTO_MESSAGE_FINALIZED:
                return 'finalized';
            case Message.TYPE_AUTO_MESSAGE_CANCEL_APPROVAL:
                return 'cancel-approval';
            case Message.TYPE_AUTO_MESSAGE_CANCEL:
                return 'cancel';
            case Message.TYPE_AUTO_MESSAGE_RELEASE_DEPOSIT:
                return 'release-deposit';
            case Message.TYPE_AUTO_MESSAGE_KEEP_DEPOSIT:
                return 'keep-deposit';
            case Message.TYPE_AUTO_MESSAGE_RELEASE_GP:
                return 'release-gp';
            case Message.TYPE_AUTO_MESSAGE_KEEP_GP:
                return 'keep-gp';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_STARTON:
                return 'change-starton';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_ENDON:
                return 'change-endon';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_DEPOSIT:
                return 'change-deposit';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_INSURANCE:
                return 'change-insurance';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_NBGUEST:
                return 'change-nbguest';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_GP:
                return 'change-gp';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_TO_SIMPLE:
                return 'change-to-simple';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_TO_RECIPROCAL:
                return 'change-to-reciprocal';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_SERVICE_PLUS:
                return 'change-service-plus';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_DATE:
                return 'support-change-exchange-dates';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_HOME:
                return 'support-change-exchange-home';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_GP:
                return 'support-change-exchange-GP';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_GUESTS:
                return 'support-change-exchange-guests';
            case Message.TYPE_MESSAGE_SUPPORT_CHANGE_MANY_DETAILS:
                return 'support-change-exchange-many-details';
            case Message.TYPE_AUTO_MESSAGE_UPDATE_REQUEST_APPROVED:
                return 'status-name-approved-modification';
            case Message.TYPE_AUTO_MESSAGE_UPDATE_REQUEST_REJECTED:
                return 'status-receiver-rejected-modification';
            case Message.TYPE_AUTO_MESSAGE_UPDATE_REQUEST_CANCELLED:
                return 'status-requester-cancels-modification';
            case Message.TYPE_AUTO_MESSAGE_UPDATE_FINALIZED:
                return 'status-name-finalized-modification';
            default:
                return '';
        }
    }

    getTypeAuto() {
        return this.typeAutoToSlug(this.get('type_auto'));
    }

    getTypeAutoField() {
        switch (this.get('type_auto')) {
            case Message.TYPE_AUTO_MESSAGE_CHANGE_STARTON:
                return 'start_on';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_ENDON:
                return 'end_on';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_DEPOSIT:
                return 'deposit';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_INSURANCE:
                return 'insurance';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_NBGUEST:
                return 'guest_nb';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_GP:
                return 'guestpoints';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_TO_SIMPLE:
                return 'simple';
            case Message.TYPE_AUTO_MESSAGE_CHANGE_TO_RECIPROCAL:
                return 'reciprocal';
            default:
                return '';
        }
    }

    isTypeChanged() {
        return _.contains(
            [Message.TYPE_AUTO_MESSAGE_CHANGE_TO_SIMPLE, Message.TYPE_AUTO_MESSAGE_CHANGE_TO_RECIPROCAL],
            this.get('type_auto')
        );
    }

    getHTMLContent(nl2br = true) {
        let content = this.get('content');

        if (!content) return content;

        if (nl2br) {
            content = Utils.nl2br(content);
        }
        content = emojione.shortnameToImage(content);
        return content;
    }

    static dedupeMessages(messages) {
        let dedupes = [];

        // remove message auto without type_auto
        messages = _.reject(
            messages,
            (message) =>
                message.get('type') == Message.TYPE_AUTO &&
                !message.has('type_auto') &&
                !message.get('content')
        );

        messages.forEach((message) => {
            // display user message normaly
            if (message.get('type') == Message.TYPE_USER) {
                return dedupes.push(message);
            }
            // display workflow auto message normally
            if (
                message.get('type_auto') < Message.TYPE_AUTO_MESSAGE_CHANGE_STARTON ||
                message.get('type_auto') == Message.TYPE_AUTO_MESSAGE_UPDATE_REQUEST_APPROVED ||
                message.get('type_auto') == Message.TYPE_AUTO_MESSAGE_UPDATE_FINALIZED ||
                message.get('type_auto') == Message.TYPE_AUTO_MESSAGE_UPDATE_REQUEST_REJECTED ||
                message.get('type_auto') == Message.TYPE_AUTO_MESSAGE_UPDATE_REQUEST_CANCELLED ||
                message.get('type_auto') == Message.TYPE_MESSAGE_SUPPORT_CHANGE_DATE ||
                message.get('type_auto') == Message.TYPE_MESSAGE_SUPPORT_CHANGE_GP ||
                message.get('type_auto') == Message.TYPE_MESSAGE_SUPPORT_CHANGE_GUESTS ||
                message.get('type_auto') == Message.TYPE_MESSAGE_SUPPORT_CHANGE_HOME ||
                message.get('type_auto') == Message.TYPE_MESSAGE_SUPPORT_CHANGE_MANY_DETAILS
            ) {
                return dedupes.push(message);
            }
            const lastGroup = _.last(dedupes);
            // create new group if last item is not an array
            if (!Array.isArray(lastGroup)) {
                return dedupes.push([message]);
            }
            const lastMessage = _.last(lastGroup);
            // create new group if last message of last group is from a different  author
            if (lastMessage.get('author').id != message.get('author').id) {
                return dedupes.push([message]);
            }
            // create new group if last message was sent more than 30 minutes ago
            if (
                !moment(message.get('send_at')).isBetween(
                    lastMessage.get('send_at'),
                    moment(lastMessage.get('send_at')).add(30, 'minutes'),
                    null,
                    '[]'
                )
            ) {
                return dedupes.push([message]);
            }
            // create new group if message is a type changed and not the previous one
            if (message.isTypeChanged() && !lastMessage.isTypeChanged()) {
                return dedupes.push([message]);
            }
            // create new group if message is not a type changed but the previous one was
            if (lastMessage.isTypeChanged() && !message.isTypeChanged()) {
                return dedupes.push([message]);
            }
            lastGroup.push(message);
        });

        dedupes = dedupes.map((message) => {
            if (Array.isArray(message)) {
                // message for type transformation should only display the last transformation
                // or nothing if the last cancel the previous
                if (
                    _.contains(
                        [
                            Message.TYPE_AUTO_MESSAGE_CHANGE_TO_SIMPLE,
                            Message.TYPE_AUTO_MESSAGE_CHANGE_TO_RECIPROCAL
                        ],
                        _.first(message).get('type_auto')
                    )
                ) {
                    if (message.length % 2 === 0) {
                        return null;
                    } else {
                        return _.last(message);
                    }
                }
                // format message for multiple changes
                return Message.formatMessageAutoMultiple(message);
            }
            return message;
        });

        return _.compact(dedupes);
    }

    static formatMessageAutoMultiple(messages) {
        if (!Array.isArray(messages)) {
            throw new Error('#formatMessageAutoMultiple() first argument should be and array.');
        }

        // merge messages by type_auto
        messages = _.values(_.groupBy(messages, (message) => message.get('type_auto'))).map((mess) =>
            _.last(mess)
        );

        // return the only message if appropriate
        if (messages.length === 1) {
            return _.first(messages);
        }

        // extract fields names
        const fields = _.chain(messages)
            .map((mess) => mess.getTypeAutoField())
            .uniq()
            .compact()
            .value();

        // format new message to combine changes
        const message = _.last(messages);
        message.set(
            'content',
            i18n.t('messaging:auto_message_changes', {
                context: message.get('author').id == Api.User.identity().id ? 'me' : 'you',
                first_name: message.get('author').get('first_name'),
                fields: fields
                    .map((field) => i18n.t(`exchange:${field}`))
                    .join(', ')
                    .toLowerCase()
            })
        );
        return message;
    }
}
