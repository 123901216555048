/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Cookies, withCookies } from 'react-cookie';

import i18n from '../i18n';

export const currencies = {
    USD: '$', // USA
    EUR: '€', // France
    CAD: '$', // Canada
    GBP: '£', // Royaume Uni
    AUD: '$', // Australie
    DKK: 'kr', // Danemark
    SEK: 'öre', // Suède
    CHF: 'CHF', // Suisse
    NZD: '$', // Nouvelle-Zelande
    NOK: 'øre', // Norvège
    ISK: 'kr', // Islande
    MXN: '$', // Méxique
    HUF: 'Ft', // Hongrie
    ZAR: 'R', // Afrique du Sud
    ARS: '$', // Argentine
    HRK: 'kn', // Croatie
    CZK: 'Kč', // République Thèque
    ILS: '₪', // Israël
    TRY: '₺', // Turquie
    CRC: '₡', // Costa Rica
    BRL: 'R$', // Brésil
    CNY: '¥' // Chine
};

export const getCurrencyForLanguage = (language) => {
    switch (language) {
        case 'da':
            return 'DKK';
        case 'en':
            return 'USD';
        case 'no':
        case 'nb':
            return 'NOK';
        case 'sv':
            return 'SEK';
        case 'de':
        case 'es':
        case 'fr':
        case 'it':
        case 'nl':
        case 'pt':
        case 'hr':
        default:
            return 'EUR';
    }
};

export const getDisplayName = (locale, currency) => {
    const matches = Number(0)
        .toLocaleString(locale, {
            style: 'currency',
            currency,
            currencyDisplay: 'name'
        })
        .match(/^(\D+)?[0.,]+(\D+)?$/);
    if (matches && matches.length >= 3) {
        return (matches[1] || matches[2]).trim().replace(/^./, (str) => str.toUpperCase());
    }
    return currency;
};

const { Provider, Consumer } = React.createContext();

class CurrencyProviderBase extends React.PureComponent {
    static propTypes = {
        children: PropTypes.element.isRequired,
        cookies: PropTypes.instanceOf(Cookies).isRequired,
        currency: PropTypes.string
    };

    static defaultProps = {
        currency: 'EUR'
    };

    constructor(props) {
        super(props);
        this.changeCurrency = this.changeCurrency.bind(this);
    }

    changeCurrency(currency) {
        // Check that currency is supported
        if (!currencies.hasOwnProperty(currency)) {
            // Send issue to Sentry
            Sentry.captureException(new Error(`Currency is not supported (${currency})`));
            return;
        }
        this.props.cookies.set('currency', currency, { path: '/' });
        // reload page to sync multipe CookiesProvider
        document.location.reload();
    }

    render() {
        const { children, currency } = this.props;
        return (
            <Provider
                value={{
                    currency,
                    changeCurrency: this.changeCurrency
                }}
            >
                {children}
            </Provider>
        );
    }
}

export const CurrencyProviderConnected = compose(
    connect((state, ownProps) => {
        if (state.user) {
            return {
                currency: state.user.get('currency')
            };
        }
        const { cookies } = ownProps;
        // has currency in cookies
        if (cookies && cookies.get('currency')) {
            const currency = cookies.get('currency');
            // is valid currency
            if (currencies.hasOwnProperty(currency)) {
                return {
                    currency
                };
            }
        }
        // default currency for locale
        return {
            currency: getCurrencyForLanguage(i18n.language)
        };
    })
)(CurrencyProviderBase);

export const CurrencyProvider = withCookies(CurrencyProviderConnected);

export const withCurrency = (Component) => {
    class CurrencyConsumer extends React.PureComponent {
        render() {
            return <Consumer>{(context) => <Component {...this.props} {...context} />}</Consumer>;
        }
    }

    return CurrencyConsumer;
};
