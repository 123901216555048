import PropTypes from 'prop-types';
import React from 'react';
import noUiSlider from 'nouislider';

export default class SingleSlider extends React.Component {
    static propTypes = {
        value: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        onSlide: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.slider = React.createRef();
        this.onSlideHandler = _.throttle(this.onSlideHandler.bind(this), 100);
    }

    componentDidMount() {
        const { min, max, value, onChange, onSlide } = this.props;

        noUiSlider.create(this.slider.current, {
            start: value || 0,
            step: 1,
            connect: [true, false],
            range: {
                min,
                max
            },
            keyboardSupport: true
        });

        this.slider.current.noUiSlider.on('set', (values) => {
            const val = values[0] ? parseInt(values[0], 10) : this.props.min;
            onChange(val);
        });

        if (onSlide) {
            this.slider.current.noUiSlider.on('slide', this.onSlideHandler);
        }
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.min !== this.props.min || nextProps.max !== this.props.max;
    }

    componentDidUpdate() {
        const { min, max, value } = this.props;

        this.slider.current.noUiSlider.updateOptions({
            range: {
                min,
                max
            }
        });
        const val = value || max;
        this.slider.current.noUiSlider.set(val);
    }

    onSlideHandler(values) {
        const { onSlide } = this.props;
        const val = values[0] ? parseInt(values[0], 10) : this.props.min;
        onSlide(val);
    }

    render() {
        return <div className="slider noUi-extended" ref={this.slider} />;
    }
}
