import React from 'react';
import PropTypes from 'prop-types';
import SearchBar from '../search/SearchBar';

export default function SearchBlock({ className, renderSearchInformation, context }) {
    return (
        <div className={className}>
            <div className="search-block-container">
                {renderSearchInformation()}
                <SearchBar className="search-block-form" context={context} />
            </div>
        </div>
    );
}

SearchBlock.propTypes = {
    className: PropTypes.string,
    renderSearchInformation: PropTypes.func.isRequired,
    context: PropTypes.string
};
