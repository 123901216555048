import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import Utils from '../../utils/utils';

function PrevArrow(props) {
    const { className, style, onClick, isCollection } = props;
    return (
        <button
            type="button"
            className={`${isCollection ? 'previous-arrow' : `${className} icon-angle-left`}`}
            style={style}
            onClick={onClick}
        >
            {isCollection ? (
                <>
                    <div className="background-img" />
                    <img src={`${Utils.getCloudfrontDomain()}/images/website/collection/previous.svg`} />
                </>
            ) : (
                i18n.t('common:previous')
            )}
        </button>
    );
}

PrevArrow.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    isCollection: PropTypes.bool
};

export default PrevArrow;
