import React from 'react';
import PropTypes from 'prop-types';
import { Layer, Marker, Source } from 'react-map-gl';
import * as turf from '@turf/turf';

import Utils from '../../../../utils/utils';

const CircleLayer = ({
    latitude,
    longitude,
    radius,
    steps,
    units,
    color = `#${Utils.PRIMARY_COLOR}`,
    opacity = 0.4,
    borderWidth = 2,
    isMarker = false
}) => {
    const center = [longitude, latitude];
    const options = { steps, units };
    const circle = turf.circle(center, radius, options);
    const line = turf.lineString(...circle.geometry.coordinates);

    return isMarker ? (
        <Marker latitude={latitude} longitude={longitude} color={`#${Utils.PRIMARY_COLOR}`} />
    ) : (
        <>
            <Source type="geojson" data={circle}>
                <Layer
                    type="fill"
                    paint={{
                        'fill-color': color,
                        'fill-opacity': opacity
                    }}
                />
            </Source>
            <Source type="geojson" data={line}>
                <Layer
                    type="line"
                    paint={{
                        'line-color': color,
                        'line-width': borderWidth
                    }}
                />
            </Source>
        </>
    );
};

CircleLayer.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    radius: PropTypes.number,
    steps: PropTypes.number,
    units: PropTypes.string,
    color: PropTypes.string,
    opacity: PropTypes.number,
    borderWidth: PropTypes.number,
    isMarker: PropTypes.bool
};

export default CircleLayer;
