import Api from '../api/Api';

import Product from '../models/Product';

export const SET_PRODUCTS = 'SET_PRODUCTS';

export function setProducts(products) {
    return {
        type: SET_PRODUCTS,
        payload: products
    };
}

export function fetchProductsMe(currency, fetchCollectionProduct = false) {
    return (dispatch) =>
        Api.Product.me(currency, {}, fetchCollectionProduct).then((response) => {
            const products = fetchCollectionProduct
                ? [new Product({ ...response, isCollection: fetchCollectionProduct })]
                : response.map((product) => new Product(product));

            dispatch(setProducts(products));
        });
}
