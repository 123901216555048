import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../../i18n';
import Feature from '../../../models/Feature';
import InputOption from '../../form/InputOption';

const InputAmenitiesList = ({ values, features, onChange, className, type }) => (
    <div className="row feature-list">
        {Feature.FEATURES.filter((item) => item.icon !== 'cook' && item.icon !== 'guide').map((feature) => {
            if (features.includes(feature.category)) {
                const category = Feature.AMENITIES_LABEL[feature.category];
                return (
                    <div className={className} key={feature.icon}>
                        <InputOption
                            type="checkbox"
                            label={i18n.t(`home:feature-${category}-${feature.icon}`)}
                            icon={feature.icon}
                            name={`feature[${category}][]`}
                            id={`feature-${feature.icon}`}
                            value={feature.value}
                            checked={Boolean(values[category] & feature.value)}
                            onChange={onChange}
                            trackingData={
                                type === 'surrounding-tags' ? 'surrounding_tags_changed' : 'amenities_changed'
                            }
                        />
                    </div>
                );
            }
            return null;
        })}
    </div>
);

InputAmenitiesList.propTypes = {
    values: PropTypes.object,
    features: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    type: PropTypes.string
};

export default InputAmenitiesList;
