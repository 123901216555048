import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

import Home from './Home';
import HomeFake from '../home/HomeFake';

export default class HomeList extends React.Component {
    static propTypes = {
        error: PropTypes.string,
        homes: PropTypes.array,
        homesClassName: PropTypes.string,
        fakes: PropTypes.number,
        displayWithReview: PropTypes.bool,
        displayProfilePicture: PropTypes.bool,
        source: PropTypes.string
    };

    static defaultProps = {
        error: null,
        homes: [],
        homesClassName: 'col-xs-12 col-sm-6 col-lg-3',
        fakes: 0,
        displayProfilePicture: true
    };

    render() {
        if (this.props.error) {
            return (
                <div className="row home-list">
                    <div
                        className="col-sm-12 no-results"
                        dangerouslySetInnerHTML={{ __html: this.props.error }}
                    ></div>
                </div>
            );
        } else if (this.props.homes.length > 0) {
            return (
                <div className="row home-list">
                    {this.props.homes.map((home, i) => (
                        <div key={`home-${home.id}`} className={classNames(this.props.homesClassName)}>
                            <CSSTransitionGroup
                                transitionName="home-list-item"
                                transitionAppear={true}
                                transitionAppearTimeout={500 + 100 * i}
                                transitionEnterTimeout={500}
                                transitionLeaveTimeout={300}
                                component="div"
                            >
                                <Home
                                    home={home}
                                    source={this.props.source}
                                    displayLocation={true}
                                    displayWithReview={this.props.displayWithReview}
                                    displayProfilePicture={this.props.displayProfilePicture}
                                />
                            </CSSTransitionGroup>
                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="row home-list">
                    {_.range(0, this.props.fakes).map((i) => (
                        <div key={`home-fake-${i}`} className={this.props.homesClassName}>
                            <HomeFake />
                        </div>
                    ))}
                </div>
            );
        }
    }
}
