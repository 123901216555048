import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import Home from '../../../models/Home';
import { Text, Switch } from '@homeexchange/design';

const isNumeric = (str) => {
    return /^\d+$/.test(str);
};

const MIN_NUMBER_OF_NIGHTS = 1;

const MAX_NUMBER_OF_NIGHTS = 365;

const validateMinNbOfNights = (minNbOfNights) => {
    return (
        isNumeric(minNbOfNights) &&
        minNbOfNights !== '' &&
        minNbOfNights !== null &&
        Number(minNbOfNights) > 0 &&
        Number(minNbOfNights) >= MIN_NUMBER_OF_NIGHTS &&
        Number(minNbOfNights) <= MAX_NUMBER_OF_NIGHTS
    );
};

const HomeEditMinNights = ({ home, onChange }) => {
    const minNumberOfNights = home.get('min_nights') || '';
    const [showInput, setShowInput] = useState(Boolean(minNumberOfNights));
    const [currentMinNbOfNights, setCurrentMinNbOfNights] = useState(minNumberOfNights);
    const [switchChecked, setSwitchChecked] = useState(Boolean(minNumberOfNights));
    const handleSwitchNumberOfNights = () => {
        if (switchChecked) {
            setShowInput(false);
            setSwitchChecked(false);
            if (currentMinNbOfNights) {
                setCurrentMinNbOfNights(null);
                onChange({
                    target: {
                        name: 'min_nights',
                        value: null
                    }
                });
            }
        } else {
            setShowInput(true);
            setSwitchChecked(true);
        }
    };

    const handleInputNumberOfNights = (e) => {
        const minimumNumberOfNightsEntered = e.target.value;
        setCurrentMinNbOfNights(minimumNumberOfNightsEntered);
        if (validateMinNbOfNights(minimumNumberOfNightsEntered)) {
            onChange(e);
        }
    };

    return (
        <>
            <Switch
                checked={switchChecked}
                onChange={handleSwitchNumberOfNights}
                label={i18n.t('home:min-nb-nights-switch')}
                description={i18n.t('home:min-nb-nights-switch-description')}
                name={'nb-nights-switch'}
            />
            {showInput && (
                <label>
                    <Text isBold className="input-min-nights-title">
                        {i18n.t('home:min-nb-nights-input')}
                    </Text>
                    <input
                        className={`form-control input-min-nights-control ${
                            !validateMinNbOfNights(currentMinNbOfNights) && 'input-min-nights-control-error'
                        }`}
                        id="min-nights"
                        data-track-edition="min_number_of_nights_changed"
                        name="min_nights"
                        placeholder={i18n.t('home:placeholder_input_enter_min_nights')}
                        value={currentMinNbOfNights}
                        onChange={handleInputNumberOfNights}
                        type="number"
                        min={MIN_NUMBER_OF_NIGHTS}
                        max={MAX_NUMBER_OF_NIGHTS}
                    />
                    {!validateMinNbOfNights(currentMinNbOfNights) && (
                        <p className="text-error-input">
                            <span className="icon-error-registration" />
                            <span>{i18n.t('home:min-nb-nights-error')}</span>
                        </p>
                    )}
                </label>
            )}
        </>
    );
};
HomeEditMinNights.propTypes = {
    home: PropTypes.instanceOf(Home).isRequired,
    onChange: PropTypes.func
};
export default HomeEditMinNights;
