import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withDragDropContext from '../../../components/upload/withDragDropContext';

import Common from '../../../api/Common';
import FileTarget from './FileTarget';
import FilePlaceholder from './FilePlaceholder';
import FileToolbar from './FileToolbar';
import FilePicker from '../../widget/FilePicker';
import Utils from '../../../utils/utils';
import i18n from '../../../i18n';
import Analytics from '../../../utils/analytics';
import useGTM from '../../analytics/useGTM';
import User from '../../../models/User';

export class FileUpload extends PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User),
        accepts: PropTypes.arrayOf(PropTypes.string),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                icon: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired
            })
        ).isRequired,
        maxSize: PropTypes.number,
        homeId: PropTypes.number,
        userEmailAddress: PropTypes.string
    };

    static defaultProps = {
        accepts: ['application/pdf', 'image/png', 'image/jpeg'],
        maxSize: 5e6 // 5 Mega octets
    };

    constructor(props) {
        super(props);

        const files = {};
        props.items.forEach((item) => {
            files[item.id] = null;
        });

        this.state = {
            files,
            uploading: false
        };

        this.handleFile = this.handleFile.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleFile(id, file) {
        this.setState((state) => ({
            files: Object.assign({}, state.files, {
                [id]: file
            })
        }));
    }

    handleClear(e, id) {
        e.stopPropagation();
        this.setState((state) => ({
            files: Object.assign({}, state.files, {
                [id]: null
            })
        }));
    }

    isSizeValid() {
        const { maxSize } = this.props;
        return Object.values(this.state.files)
            .filter((file) => Boolean(file))
            .map((file) => file.size <= maxSize)
            .reduce((prev, curr) => prev && curr, true);
    }

    isTypeValid() {
        const { accepts } = this.props;
        return Object.values(this.state.files)
            .filter((file) => Boolean(file))
            .map((file) => accepts.includes(file.type))
            .reduce((prev, curr) => prev && curr, true);
    }

    handleSubmit() {
        this.setState({ uploading: true });
        this.upload().then(
            () => {
                const { fetchAccount } = useGTM(this.props.user);
                this.setState({ uploading: false });
                // track iterable
                Analytics.trackIterable('HomeVerificationRequestInitiated', {
                    email: this.props.userEmailAddress,
                    homeId: this.props.homeId
                });

                Analytics.trackGTM('verification', fetchAccount('upload_verification_document'));
                window
                    .sweetAlert({
                        icon: 'success',
                        title: i18n.t('user:verification.upload.success.title'),
                        text: i18n.t('user:verification.upload.success.text'),
                        buttons: i18n.t('user:verification.upload.success.confirm'),
                        closeOnEsc: false,
                        closeOnClickOutside: false
                    })
                    .then(() => {
                        document.location.assign(i18n.t('url:dashboard'));
                    });
            },
            (error) => {
                this.setState({ uploading: false });
                Sentry.captureException(error);
                window.sweetAlert({
                    icon: 'error',
                    title: i18n.t('user:verification.upload.failure.title'),
                    text: error.message
                        ? i18n.t(`user:${error.message}`)
                        : i18n.t('user:verification.invalid.request')
                });
            }
        );
    }

    upload() {
        const { files } = this.state;

        const formData = new FormData();
        Object.values(files).forEach((file) => {
            formData.append('files[]', file);
        });

        return Common.request({
            url: '/v1/verification/upload',
            method: 'POST',
            data: formData,
            processData: false,
            contentType: false
        });
    }

    render() {
        const { accepts, items, maxSize } = this.props;
        const { files, uploading } = this.state;
        const fileCount = Object.values(files).filter((file) => Boolean(file)).length;
        const isSizeValid = this.isSizeValid();
        const isTypeValid = this.isTypeValid();
        return (
            <div className="file-uploader">
                <div className="row">
                    {items.map((item) => {
                        const handleFile = (file) => this.handleFile(item.id, file);
                        const handleClear = (e) => this.handleClear(e, item.id);
                        /* eslint-disable react/jsx-no-bind */
                        return (
                            <div className="col-sm-5 col-md-6" key={item.id}>
                                <FilePicker accepts={accepts} file={files[item.id]} onFile={handleFile}>
                                    <FileTarget accepts={accepts} maxSize={maxSize} onDrop={handleFile}>
                                        <FilePlaceholder file={files[item.id]}>
                                            <i className={item.icon}></i>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: item.label
                                                }}
                                            />
                                        </FilePlaceholder>
                                        <FileToolbar file={files[item.id]} onRemove={handleClear} />
                                    </FileTarget>
                                </FilePicker>
                            </div>
                        );
                        /* eslint-enable react/jsx-no-bind */
                    })}
                </div>
                <div className={classNames({ 'has-error': !isSizeValid || !isTypeValid })}>
                    {!isSizeValid && (
                        <span
                            className="help-block"
                            dangerouslySetInnerHTML={{
                                __html: i18n.t('user:verification.invalid.size', {
                                    maxSize: Utils.humanFileSize(maxSize)
                                })
                            }}
                        />
                    )}
                    {!isTypeValid && (
                        <span
                            className="help-block"
                            dangerouslySetInnerHTML={{
                                __html: i18n.t('user:verification.invalid.type')
                            }}
                        />
                    )}
                </div>
                <button
                    type="button"
                    className={classNames('btn btn-primary btn-ajax', {
                        sending: uploading
                    })}
                    disabled={!(fileCount === items.length && isTypeValid && isSizeValid && !uploading)}
                    onClick={this.handleSubmit}
                >
                    {i18n.t('user:verification.upload')}
                </button>
            </div>
        );
    }
}

export default withDragDropContext(FileUpload);
