import { LOAD_GROUP_DETAILS, LEAVE_GROUP, JOIN_GROUP } from '../actions/groups';

import Group from '../models/Group';

export default function groupDetails(state = null, { type, payload }) {
    switch (type) {
        case LOAD_GROUP_DETAILS:
            if (!payload) {
                return state;
            }
            return payload;
        case LEAVE_GROUP:
            if (state) {
                return new Group(
                    Object.assign({}, state.toJSON(), {
                        in_group: false,
                        users: null
                    })
                );
            }
            return state;
        case JOIN_GROUP:
            if (state) {
                return new Group(
                    Object.assign({}, state.toJSON(), {
                        in_group: true
                    })
                );
            }
            return state;
        default:
            return state;
    }
}
