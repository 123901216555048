import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import i18n from '../../../i18n';
import User from '../../../models/User';
import Loading from '../../common/Loading';

export class VerificationIndicators extends React.PureComponent {
    static propTypes = {
        user: PropTypes.instanceOf(User),
        showUnverifiedItems: PropTypes.bool,
        withDivider: PropTypes.bool
    };

    static defaultProps = {
        showUnverifiedItems: false,
        withDivider: false
    };

    getVerifications() {
        if (!this.props.user) {
            return [];
        }

        const verifications = [
            {
                name: 'identity',
                show: this.props.user.isVerified(),
                text: i18n.t('user:verification.identity')
            },
            {
                name: 'phone',
                show: this.props.user.isPhoneVerified(),
                text: i18n.t('user:verification.phone')
            },
            {
                name: 'email',
                show: this.props.user.isEmailVerified(),
                text: i18n.t('user:verification.email')
            }
        ];

        if (this.props.showUnverifiedItems) {
            return verifications;
        } else {
            return verifications.filter((verification) => verification.show);
        }
    }

    renderMain(verifications) {
        if (!this.props.user) {
            return <Loading />;
        }

        return (
            <ul>
                {verifications.map((verification) => (
                    <li key={verification.name} className={classNames({ verified: verification.show })}>
                        <i className="icon icon-verification-check" />
                        {verification.text}
                    </li>
                ))}
            </ul>
        );
    }

    render() {
        const verifications = this.getVerifications();

        if (this.props.user && verifications.length <= 0) {
            // No verification indicator to display
            return false;
        }

        return (
            <div className="verification-indicators">
                {this.props.withDivider && <div role="separator" className="divider"></div>}
                {this.renderMain(verifications)}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.loadedUsers[ownProps.userId]
});

export default connect(mapStateToProps)(VerificationIndicators);
