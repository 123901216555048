import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';

import Home from '../../models/Home';
import User from '../../models/User';

import Tooltip from '../widget/Tooltip';

export default class CompletionIndicator extends React.PureComponent {
    static propTypes = {
        object: PropTypes.oneOfType([PropTypes.instanceOf(Home), PropTypes.instanceOf(User)]).isRequired,
        fieldNames: PropTypes.arrayOf(PropTypes.string).isRequired,
        page: PropTypes.oneOf(['home', 'user']).isRequired,
        isCompleted: PropTypes.func
    };

    render() {
        const { object, fieldNames, page, isCompleted } = this.props;
        const unacheivedFieldNames = fieldNames.filter((fieldName) => {
            if (isCompleted) {
                return !isCompleted();
            } else {
                return !object.isCompletionAchieved(fieldName);
            }
        });

        if (unacheivedFieldNames.length === 0) {
            return null;
        } else {
            const fieldName = unacheivedFieldNames[0];
            const title = i18n.t([
                `${page}:completion-tooltip.${fieldName}.title`,
                `${page}:completion-tooltip.${page}.title`
            ]);
            const text = i18n.t([
                `${page}:completion-tooltip.${fieldName}.text`,
                `${page}:completion-tooltip.${page}.text`
            ]);

            const content = (
                <div className="completion-indicator-tooltip">
                    <p className="title">{title}</p>
                    <p>{text}</p>
                </div>
            );
            return (
                <Tooltip placement="bottom" title={content}>
                    <div className="completion-indicator">+{object.completionEarnings[fieldName]}%</div>
                </Tooltip>
            );
        }
    }
}
