import UserApi from '../api/User';

import Notification from '../models/Notification';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_TOP_MENU_NOTIFICATIONS = 'SET_TOP_MENU_NOTIFICATIONS';

export function setNotifications(notifications) {
    return {
        type: SET_NOTIFICATIONS,
        payload: notifications
    };
}

export function setTopMenuNotifications(notifications) {
    return {
        type: SET_TOP_MENU_NOTIFICATIONS,
        payload: new Notification(notifications)
    };
}

export function fetchNotifications() {
    return (dispatch) =>
        UserApi.getNotifications().then((response) => {
            dispatch(setNotifications(response));
        });
}

export function fetchTopMenuNotifications() {
    return (dispatch) =>
        UserApi.getTopMenuNotifications().then((response) => {
            dispatch(setTopMenuNotifications(response));
        });
}
