import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CurrencyFormatter } from '../../utils/Intl';
import Utils from '../../utils/utils';
import Subscription from '../../models/Subscription';
import Toggle from '../widget/Toggle';
import i18n from '../../i18n';
import Product from '../../models/Product';

const Payment3x = ({
    updateIs3xPayment,
    is3xPaymentFromFinalization,
    updateIs3xPaymentFromFinalization,
    subProduct,
    subCurrency
}) => {
    const [checkedOn, setCheckedOn] = useState(false);
    const onChange = useCallback(() => {
        setCheckedOn(!checkedOn);
    }, [checkedOn]);

    const threeTimesPaymentPrice = Utils.getThreeTimesPaymentPrice(subProduct.price, subCurrency);

    useEffect(() => {
        if (/conversations\/([0-9]+)\/payment/.test(window.location.href)) {
            updateIs3xPaymentFromFinalization(checkedOn);
        } else if (is3xPaymentFromFinalization === null) {
            updateIs3xPayment(checkedOn);
        }
    }, [checkedOn]);

    return (
        <div className="payment3x">
            <span className="new">{i18n.t('common:new')}</span>
            <div className="payment3x-body">
                <div className="payment3x-header">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('subscription:price.increase.3xpayment.info_block.title', {
                                threeTimesPaymentPrice,
                                annualMembership: CurrencyFormatter.format(subProduct.price, subCurrency),
                                serviceFeePrice: CurrencyFormatter.format(
                                    Subscription.EUROPE_SERVICE_CHARGE_PRICE,
                                    subCurrency
                                )
                            })
                        }}
                    />
                    <div className="toggle-button">
                        <Toggle
                            name="payment3x"
                            checked={checkedOn}
                            text={{
                                on: i18n.t('common:yes'),
                                off: i18n.t('common:no')
                            }}
                            onChange={onChange}
                        />
                    </div>
                </div>
                {checkedOn && (
                    <div className="payment3x-content">
                        <p>{i18n.t('subscription:3xpayment.info_block.content')}</p>
                        <a
                            href={i18n.t('url:url.3xpayment_option.learn_more')}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {i18n.t('common:learn_more')}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

Payment3x.propTypes = {
    updateIs3xPayment: PropTypes.func,
    is3xPaymentFromFinalization: PropTypes.bool,
    updateIs3xPaymentFromFinalization: PropTypes.func,
    subProduct: PropTypes.instanceOf(Product),
    subCurrency: PropTypes.string
};

export default Payment3x;
