import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';

const PaymentOptions = ({ selectedPaymentMethod, onPaymentMethodChange }) => (
    <div className="row payment-options">
        <div className="col-md-12">
            <div className="radio">
                <label htmlFor="credit-card">
                    <div className="radio-button-container">
                        <input
                            type="radio"
                            name="payment-method"
                            id="credit-card"
                            value="credit-card"
                            checked={selectedPaymentMethod === 'credit-card'}
                            onChange={onPaymentMethodChange}
                        />
                        <div className="radio-button"></div>
                        <span>{i18n.t('subscription:credit_card')}</span>
                    </div>
                </label>
                <label htmlFor="paypal">
                    <div className="radio-button-container">
                        <input
                            type="radio"
                            name="payment-method"
                            id="paypal"
                            value="paypal"
                            checked={selectedPaymentMethod === 'paypal'}
                            onChange={onPaymentMethodChange}
                        />
                        <div className="radio-button"></div>
                        <span>{i18n.t('subscription:paypal')}</span>
                    </div>
                </label>
            </div>
        </div>
    </div>
);

PaymentOptions.propTypes = {
    selectedPaymentMethod: PropTypes.string,
    onPaymentMethodChange: PropTypes.func
};

export default PaymentOptions;
