import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { refreshToken, handleTokenResponse } from '../../actions/auth';

export class AuthProvider extends React.PureComponent {
    static propTypes = {
        children: PropTypes.element,
        refreshToken: PropTypes.func.isRequired,
        handleTokenResponse: PropTypes.func.isRequired,
        accessToken: PropTypes.string,
        expiresAt: PropTypes.number
    };

    static childContextTypes = {
        isAuthenticated: PropTypes.func
    };

    refreshing = null;
    requestsCount = 0;

    constructor(props) {
        super(props);

        // Try to refresh token if expired
        if (this.hasToken() && this.isTokenExpired()) {
            this.props.refreshToken().catch(() => {
                document.location.reload();
            });
        }

        this.xhrOpen = XMLHttpRequest.prototype.open;
        this.enhanceXMLHttpRequest();
    }

    componentWillUnmount() {
        // restore XMLHttpRequest#open()
        XMLHttpRequest.prototype.open = this.xhrOpen;
    }

    enhanceXMLHttpRequest() {
        /* eslint consistent-this: ["error", "that"] */
        const that = this;
        const attachBehaviors = this.attachBehaviors.bind(this);
        const open = this.xhrOpen;
        XMLHttpRequest.prototype.open = function (...args) {
            // Should be executed before "open" to catch the event "readystatechange"
            that.countRequests(this);
            open.apply(this, args);
            attachBehaviors(this, args);
        };
    }

    attachBehaviors(xhr, args) {
        const [, url] = args; // [method, url, async, user, password]
        this.injectAuthorizationHeader(xhr, url);
        this.handleTokenResponse(xhr, url);
    }

    countRequests(xhr) {
        xhr.addEventListener('readystatechange', () => {
            if (xhr.readyState == XMLHttpRequest.OPENED) {
                this.requestsCount++;
            } else if (xhr.readyState == XMLHttpRequest.DONE) {
                this.requestsCount--;
            }
        });
    }

    injectAuthorizationHeader(xhr, url) {
        // Inject Authorization header in API requests
        if (
            (this.isRelativeUrl(url) || url.match(process.env.URL_API)) &&
            this.hasToken() &&
            (xhr.hasOwnProperty('auth') === false || Boolean(xhr.auth) === true)
        ) {
            xhr.setRequestHeader('Authorization', `Bearer ${this.props.accessToken}`);

            // refresh token if request failed with status 401
            xhr.addEventListener(
                'load',
                () => {
                    // requestsCount = 0 because load event is fired AFTER readystatechange's done.
                    // requestsCount > 0 means that another request has been started beforehand and needs to end
                    if (xhr.status === 401 && this.requestsCount === 0) {
                        if (!this.refreshing) {
                            this.refreshing = this.props
                                .refreshToken()
                                .then(() => {
                                    this.refreshing = null;
                                })
                                .catch((err) => Sentry.captureException(err));
                        }
                    }
                },
                false
            );
        }
    }

    handleTokenResponse(xhr, url) {
        // Add token response handler if it is an auth requests
        if (url.match('/auth/signin')) {
            xhr.addEventListener(
                'load',
                () => {
                    this.props.handleTokenResponse(xhr);
                },
                false
            );
        }
    }

    isRelativeUrl(url) {
        const r = new RegExp('^(?:[a-z]+:)?//', 'i');
        return !r.test(url);
    }

    hasToken() {
        return Boolean(this.props.accessToken);
    }

    isAuthenticated() {
        return this.hasToken() && !this.isTokenExpired();
    }

    isTokenExpired() {
        return new Date().getTime() >= this.props.expiresAt;
    }

    getChildContext() {
        return {
            isAuthenticated: this.isAuthenticated
        };
    }

    render() {
        if (this.props.children && this.isAuthenticated()) {
            return React.Children.only(this.props.children);
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    accessToken: state.auth.accessToken,
    expiresAt: state.auth.expiresAt || null
});

const mapDispatchToProps = (dispatch) => ({
    refreshToken: bindActionCreators(refreshToken, dispatch),
    handleTokenResponse: bindActionCreators(handleTokenResponse, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
