import Api from '../api/Api';

export const REQUEST_EXCHANGES_FINALIZED = 'REQUEST_EXCHANGES_FINALIZED';
export const RECEIVE_EXCHANGES_FINALIZED = 'RECEIVE_EXCHANGES_FINALIZED';
export const REQUEST_MORE_EXCHANGES_UPCOMING_FINALIZED = 'REQUEST_MORE_EXCHANGES_UPCOMING_FINALIZED';
export const RECEIVE_MORE_EXCHANGES_UPCOMING_FINALIZED = 'RECEIVE_MORE_EXCHANGES_UPCOMING_FINALIZED';
export const REQUEST_MORE_EXCHANGES_ONGOING_FINALIZED = 'REQUEST_MORE_EXCHANGES_ONGOING_FINALIZED';
export const RECEIVE_MORE_EXCHANGES_ONGOING_FINALIZED = 'RECEIVE_MORE_EXCHANGES_ONGOING_FINALIZED';
export const REQUEST_MORE_EXCHANGES_PAST_FINALIZED = 'REQUEST_MORE_EXCHANGES_PAST_FINALIZED';
export const RECEIVE_MORE_EXCHANGES_PAST_FINALIZED = 'RECEIVE_MORE_EXCHANGES_PAST_FINALIZED';

export function requestExchangesFinalized() {
    return {
        type: REQUEST_EXCHANGES_FINALIZED
    };
}

export function receiveExchangesFinalized(response) {
    return {
        type: RECEIVE_EXCHANGES_FINALIZED,
        exchanges: response
    };
}

export function requestTypeExchangesFinalized(typeExchange) {
    switch (typeExchange) {
        case 'upcoming':
            return { type: REQUEST_MORE_EXCHANGES_UPCOMING_FINALIZED };
        case 'ongoing':
            return { type: REQUEST_MORE_EXCHANGES_ONGOING_FINALIZED };
        case 'past':
            return { type: REQUEST_MORE_EXCHANGES_PAST_FINALIZED };
        default:
            return null;
    }
}

export function receiveTypeExchangesFinalized(response, typeExchange) {
    switch (typeExchange) {
        case 'upcoming':
            return {
                type: RECEIVE_MORE_EXCHANGES_UPCOMING_FINALIZED,
                exchanges: response
            };
        case 'ongoing':
            return {
                type: RECEIVE_MORE_EXCHANGES_ONGOING_FINALIZED,
                exchanges: response
            };
        case 'past':
            return {
                type: RECEIVE_MORE_EXCHANGES_PAST_FINALIZED,
                exchanges: response
            };
        default:
            return null;
    }
}

export function fetchExchangesFinalized() {
    return (dispatch) => {
        dispatch(requestExchangesFinalized());
        return Api.Exchange.getExchangesFinalized().then((response) =>
            dispatch(receiveExchangesFinalized(response))
        );
    };
}

export function fetchMoreExchangesFinalized(filter, limit, offset) {
    return (dispatch) => {
        dispatch(requestTypeExchangesFinalized(filter));
        return Api.Exchange.getMoreExchangesFinalized(filter, limit, offset).then((response) =>
            dispatch(receiveTypeExchangesFinalized(response, filter))
        );
    };
}
