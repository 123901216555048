import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import i18n from '../../i18n';
import Utils from '../../utils/utils';

import Api from '../../api/Api';
import User from '../../models/User';

import UploadImage from './UploadImage';
import UploadForm from './UploadForm';

export class Upload extends React.Component {
    static propTypes = {
        user: PropTypes.instanceOf(User),
        onChange: PropTypes.func
    };

    state = {
        image: null,
        load: false,
        welldone: false,
        error: false
    };

    constructor(props) {
        super(props);

        this.addImage = this.addImage.bind(this);
        this.rotateImage = this.rotateImage.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.getImage(this.props.user);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getImage(nextProps.user);
    }

    getImage(user) {
        if (!user) {
            return;
        }
        let image = null;
        const images = user.get('images');
        if (images && images.length > 0) {
            image = images[0];
            image.url = `${images[0].cdn_link}?${$.param(Api.Image.getImageParams(200, 200, true))}`;
        }
        this.setState({ image });
    }

    addImage(image) {
        Api.User.postImage(
            image,
            (response) => {
                image = response;
                image.url = `${image.cdn_link}?${$.param(Api.Image.getImageParams(200, 200, true))}`;

                document.dispatchEvent(new CustomEvent('refresh_top_menu_profile'));
                $(`.progress-bar-user-${this.props.user.id}, .progress-filling .progress-bar-success`).css(
                    'width',
                    `${response.user.profil.filling_write}%`
                );
                $(`.filling-user-${this.props.user.id}, .progress-filling span`).text(
                    `${response.user.profil.filling_write}%`
                );
                $('#profil a img').attr('src', image.url);
                this.setState({
                    image,
                    load: false,
                    welldone: true,
                    error: false
                });
                if (this.props.onChange) {
                    this.props.onChange([image]);
                }
            },
            () => {
                this.setState({
                    load: false,
                    welldone: false,
                    error: true
                });
            }
        );
        this.setState({ load: true });
    }

    rotateImage(image, angle) {
        const newAngle = (image.rotate || 0) + angle;
        const imageState = this.state.image;
        imageState.rotate = newAngle;
        $(`#tool-bar-${imageState.id}`).find('.flip-button').hide();
        $(`#tool-bar-${imageState.id}`).find('.flip-loading').show();
        this.setState({ image: imageState });

        Api.Image.update(
            image.id,
            { rotate: newAngle },
            (response) => {
                imageState.url = `${response.cdn_link}?${$.param(Api.Image.getImageParams(200, 200, true))}`;
                $('#profil a img').attr('src', imageState.url);
                $(`#tool-bar-${imageState.id}`).find('.flip-button').show();
                $(`#tool-bar-${imageState.id}`).find('.flip-loading').hide();
                this.setState({ image: imageState });
            },
            () => {
                $(`#tool-bar-${imageState.id}`).find('.flip-button').show();
                $(`#tool-bar-${imageState.id}`).find('.flip-loading').hide();
            }
        );
    }

    render() {
        if (!this.props.user) {
            return (
                <div className="upload-init">
                    <p>
                        <img
                            className="loader"
                            src={`${Utils.getCloudfrontDomain()}/images/website/loader-yellow.svg`}
                            alt="Loading"
                        />
                    </p>
                </div>
            );
        }

        return (
            <div className="row">
                <div className="col-md-3">
                    <UploadImage
                        key="1"
                        image={this.state.image}
                        load={this.state.load}
                        isVerified={this.props.user.get('verified_status') > User.STATUS_NOT_VERIFIED}
                        rotateImage={this.rotateImage}
                    />
                    <p className="error-upload-profile">
                        {this.state.error ? i18n.t('upload:uploadForm_error_details') : ''}
                    </p>
                </div>
                <div id="profile-action" className="col-md-9">
                    <UploadForm
                        addImage={this.addImage}
                        load={this.state.load}
                        image={this.state.image}
                        welldone={this.state.welldone}
                        error={this.state.error}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(Upload);
