import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import i18n from '../../i18n';

const AddPaymentMethodFormActions = ({ requestInProgress, formHasErrors, onCancel }) => (
    <div className="add-payment-method-actions">
        <button disabled={requestInProgress} className="btn btn-white" type="button" onClick={onCancel}>
            {i18n.t('common:cancel')}
        </button>
        <button
            id={'save-payment-method-cta-my-plan'}
            className={classNames('btn btn-primary btn-ajax expand', {
                sending: requestInProgress
            })}
            type="submit"
            disabled={requestInProgress || formHasErrors}
        >
            {i18n.t('billing:payment-method.add-button')}
        </button>
    </div>
);

AddPaymentMethodFormActions.propTypes = {
    requestInProgress: PropTypes.bool.isRequired,
    formHasErrors: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default AddPaymentMethodFormActions;
