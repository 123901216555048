import Common from './Common';

export default class Traveler {
    static fetchTravelers(userId) {
        const params = Common.params({
            url: `/v1/users/${userId}/travellers`,
            method: 'get'
        });
        return Common.request(params);
    }

    static createTraveler(data) {
        const params = Common.params({
            url: '/v1/travellers',
            method: 'post',
            data
        });
        return Common.request(params);
    }

    static editTraveler(id, data) {
        const params = Common.params({
            url: `/v1/travellers/${id}`,
            method: 'patch',
            data
        });
        return Common.request(params);
    }

    static removeTraveler(id) {
        const params = Common.params({
            url: `/v1/travellers/${id}`,
            method: 'delete'
        });
        return Common.request(params);
    }

    static fetchTravelersComposition(userId) {
        const params = Common.params({
            url: `/v1/users/${userId}/travellers/compositions`,
            method: 'get'
        });
        return Common.request(params);
    }
}
