import React from 'react';
import PropTypes from 'prop-types';

export default class Helper extends React.Component {
    static propTypes = {
        content: PropTypes.string.isRequired,
        placement: PropTypes.string,
        fixed: PropTypes.bool,
        classname: PropTypes.string,
        marginTop: PropTypes.string,
        text: PropTypes.string
    };

    static defaultProps = {
        placement: 'right',
        text: ''
    };

    isOpen = false;
    isHiding = false;

    constructor(props) {
        super(props);
        this.state = {
            style: {
                ...(this.props.fixed && { position: 'fixed' }),
                ...(this.props.marginTop && { marginTop: this.props.marginTop })
            }
        };
        this.clickCloseHandler = this.clickCloseHandler.bind(this);
    }

    componentDidMount() {
        const $help = $(this.help);
        $help
            .tooltip({
                placement: this.props.placement,
                title: this.props.content,
                html: true,
                trigger: 'manual',
                template:
                    '<div class="tooltip helper-tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
            })
            .click(() => {
                if (this.isOpen) {
                    this.close();
                } else {
                    this.open();
                }
            })
            .on('hide.bs.tooltip', () => {
                this.isHiding = true;
            })
            .on('hidden.bs.tooltip', () => {
                this.isHiding = false;
            });
    }

    componentWillUnmount() {
        $(this.help).tooltip('destroy');
    }

    open(noClose = false) {
        $(this.help).tooltip('show');
        this.isOpen = true;
        if (!noClose) {
            // setTimeout to prevent callback to be called immediatly
            setTimeout(() => {
                $('body').one('click', () => {
                    this.close();
                });
            }, 0);
        } else {
            // Will close when opening another Helper
            setTimeout(() => {
                $('.helper').one('click', this.clickCloseHandler);
            }, 0);
        }
    }

    close() {
        $(this.help).tooltip('hide');
        this.isOpen = false;
        $('.helper').off('click', this.clickCloseHandler);
    }

    clickCloseHandler() {
        this.close();
    }

    render() {
        const { classname } = this.props;
        return (
            <div style={this.state.style} className={classname}>
                <button
                    type="button"
                    className="btn helper"
                    ref={(element) => {
                        this.help = element;
                    }}
                >
                    <i className="icon icon-circle-help"></i>
                    <span>{this.props.text}</span>
                </button>
            </div>
        );
    }
}
