import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragSource } from 'react-dnd';

import i18n from '../../i18n';
import ImageApi from '../../api/Image';
import Loading from '../common/Loading';

export class ImageContainer extends React.Component {
    static propTypes = {
        image: PropTypes.object,
        rotate: PropTypes.func.isRequired,
        delete: PropTypes.func.isRequired,
        connectDragSource: PropTypes.func.isRequired,
        isDragging: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
        this.rotateLeft = this.rotateLeft.bind(this);
        this.rotateRight = this.rotateRight.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.image.cdn_link != this.props.image.cdn_link) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        // abort image loading
        if (this.img) {
            this.img.src = '';
        }
    }

    loadImage() {
        this.setState({ loading: true });
        this.img = new Image();
        this.img.onload = () => {
            this.setState({ loading: false });
        };
        this.img.src = this.getImageSrc();
    }

    getImageSrc() {
        return `${this.props.image.cdn_link}?${$.param(ImageApi.getImageParams(400, 300))}`;
    }

    rotateLeft() {
        this.props.rotate(this.props.image, 90);
    }

    rotateRight() {
        this.props.rotate(this.props.image, -90);
    }

    delete() {
        this.props.delete(this.props.image);
    }

    render() {
        const { image, connectDragSource, isDragging } = this.props;
        return connectDragSource(
            <div
                className={classNames('div-image uploaded-image', {
                    isLoading: this.state.loading,
                    isDragging
                })}
                style={{ backgroundImage: `url(${this.getImageSrc()})` }}
            >
                <Loading />
                <div className="first-image">{i18n.t('upload:uploadImage_mainImage')}</div>
                <div className="drop-image">
                    <span className="icon-ic-move" aria-hidden="true"></span>
                    <span>{i18n.t('upload:uploadImage_updateSort')}</span>
                </div>
                <div className="tool-bar" id={`tool-bar-${image.id}`}>
                    <span className="flip-button">
                        <span className="icon-ic-flip-left" aria-hidden="true" onClick={this.rotateLeft}>
                            {' '}
                        </span>
                        <span className="icon-ic-flip-right" aria-hidden="true" onClick={this.rotateRight}>
                            {' '}
                        </span>
                    </span>
                    <span className="icon-home-bin bin-icon" aria-hidden="true" onClick={this.delete}>
                        {' '}
                    </span>
                </div>
            </div>
        );
    }
}

const imageSource = {
    beginDrag(props) {
        return props.image;
    }
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    };
}

export default DragSource('image', imageSource, collect)(ImageContainer);
