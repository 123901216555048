import { useEffect, useState } from 'react';
import Api from '../api/Api';
import Utils from '../utils/utils';

export function fetchBadgeImage(level) {
    return {
        src: `${Utils.getCloudfrontDomain()}/images/website/badge/badge-${Math.min(level, 9)}.svg`,
        alt: `badge-${level}`
    };
}

export default function useBadge(userId) {
    const [badge, setBadge] = useState(null);
    const [level, setLevel] = useState(0);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const fetchBadge = async () => {
            try {
                setLoading(true);
                const achievement = await Api.Loyalty.fetchBadge(userId);

                if (achievement) {
                    setLevel(achievement.level);
                    setBadge(fetchBadgeImage(achievement.level));
                }
            } catch (e) {
                console.error('error fetch badge');
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchBadge();
        }
    }, [userId]);

    return { badge, level, isLoading };
}
