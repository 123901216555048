import PropTypes from 'prop-types';
import React from 'react';
import i18n from '../../i18n';
import Availability from '../../models/Availability';
import Utils from '../../utils/utils';
import User from '../../models/User';

export default class Calendar extends React.Component {
    static propTypes = {
        nbMonths: PropTypes.number,
        availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability)),
        mode: PropTypes.string.isRequired,
        viewOnly: PropTypes.bool,
        showBookingDetails: PropTypes.bool,
        startOn: PropTypes.instanceOf(moment),
        endOn: PropTypes.instanceOf(moment),
        selectedHandler: PropTypes.func,
        viewChangedHandler: PropTypes.func,
        classname: PropTypes.arrayOf(PropTypes.string),
        style: PropTypes.object,
        isOwner: PropTypes.bool,
        contactAllowed: PropTypes.number
    };

    static defaultProps = {
        nbMonths: 1,
        availabilities: [],
        viewOnly: false,
        showBookingDetails: false,
        style: {},
        isOwner: false
    };

    format = moment.localeData().longDateFormat('L');

    constructor(props) {
        super(props);

        if (this.constructor === Calendar) {
            throw new Error("Can't instantiate abstract class!");
        }

        this.state = {
            dateClassMap: [],
            nbMonths: this.props.nbMonths
        };

        this.calendarRef = React.createRef();
        this.dateClickedHandler = this.dateClickedHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.viewChangedHandler = this.viewChangedHandler.bind(this);

        this.checkMode();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.nbMonths === nextProps.nbMonths &&
            _.isEqual(this.props.availabilities, nextProps.availabilities) &&
            this.props.showBookingDetails === nextProps.showBookingDetails &&
            ((!this.props.startOn && !nextProps.startOn) ||
                (this.props.startOn && this.props.startOn.isSame(nextProps.startOn))) &&
            ((!this.props.endOn && !nextProps.endOn) ||
                (this.props.endOn && this.props.endOn.isSame(nextProps.endOn))) &&
            this.props.style === nextProps.style &&
            this.state.dateClassMap === nextState.dateClassMap &&
            this.state.nbMonths === nextState.nbMonths &&
            this.props.contactAllowed === nextProps.contactAllowed
        ) {
            return false;
        }

        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.availabilities && !_.isEqual(this.props.availabilities, nextProps.availabilities)) {
            this.setDateClassMap(nextProps);
        }
        if (nextProps.nbMonths !== this.props.nbMonths) {
            this.setState({
                nbMonths: nextProps.nbMonths
            });
        }
    }

    UNSAFE_componentWillMount() {
        this.setDateClassMap(this.props);
    }

    componentDidUpdate(prevProps, prevState) {
        const currentSelectedDates = this.calendar.getSelectedRaw();

        if (
            prevState.dateClassMap !== this.state.dateClassMap ||
            prevState.nbMonths !== this.state.nbMonths ||
            prevProps.showBookingDetails !== this.props.showBookingDetails ||
            (!currentSelectedDates[0] && this.props.startOn) ||
            (currentSelectedDates[0] && !currentSelectedDates[0].isSame(this.props.startOn)) ||
            (!currentSelectedDates[1] && this.props.endOn) ||
            (currentSelectedDates[1] && !currentSelectedDates[1].isSame(this.props.endOn))
        ) {
            this.drawCalendar();
        }
    }

    /**
     * Writes a console error if the mode is not allowed
     */
    checkMode() {
        const allowedModes = ['single', 'multiple', 'range'];
        if (allowedModes.indexOf(this.props.mode) < 0) {
            console.error(`Calendar - Mode ${this.props.mode} not allowed, use ${allowedModes.join(', ')}`);
        }
    }

    /**
     * Generate default Kalendae options
     * @return {Object} Default Kalendae options
     */
    getDefaultKalendaeOptions() {
        const blackout = this.getBlackout();

        return {
            attachTo: this.calendarRef.current,
            format: this.format,
            dayAttributeFormat: this.format,
            months: this.state.nbMonths,
            weekStart: moment().startOf('week').isoWeekday(),
            direction: 'today-future',
            mode: this.props.mode,
            useYearNav: false,
            dateClassMap: this.state.dateClassMap,
            blackout,
            popupMode: false
        };
    }

    /**
     * Draws calendar using Kalendae
     * It requires customDrawCalendar to be implemented in child class
     */
    drawCalendar() {
        const kalendaeOptions = this.getDefaultKalendaeOptions();

        kalendaeOptions.selected = [this.props.startOn, this.props.endOn];

        this.customDrawCalendar(kalendaeOptions);

        this.calendar.subscribe('change', this.changeHandler);
        this.calendar.subscribe('date-clicked', this.dateClickedHandler);
        this.calendar.subscribe('view-changed', this.viewChangedHandler);
        this.addClassOnContainer();
        if (!Utils.isIOS()) {
            // iOS handles click event differently (https://css-tricks.com/annoying-mobile-double-tap-link-issue/)
            this.dateHoveredHandler();
        }
        this.hideHoverOnMouseout();
        if (this.props.showBookingDetails) {
            this.addBookingDetails();
        }
        if (this.props.viewOnly && !this.props.isOwner) {
            this.addTooltipOnSpanWithNoAvailabilityType();
        }

        // When the Calendar is extended to be used in a form
        if (this.checkValidity) {
            this.checkValidity();
        }
    }

    /**
     * Add a class to the calendar's container
     */
    addClassOnContainer() {
        const $container = $(this.calendar.container);

        if (this.props.classname) {
            this.props.classname.forEach((classname) => {
                if (!$container.hasClass(classname)) {
                    $container.addClass(classname);
                }
            });
        }

        // Add class based on calendar type (block or input)
        if (this.customClass && !$container.hasClass(this.customClass)) {
            $container.addClass(this.customClass);
        }
    }

    /**
     * Add the user's picture, a link and a tooltip to the booked periods
     */
    addBookingDetails() {
        this.props.availabilities.forEach((availability) => {
            if (availability.has('details') && availability.get('type') === Availability.BOOKED.type) {
                const addedLinks = [];

                // Add user picture
                const details = availability.get('details');
                const user = new User(details.user);
                const $img = $(
                    `<img class="calendar-user-picture" src="${user.picture(25, 25, true, false, true)}"/>`
                );

                const $firstDaySpan = this.getSpanByDate(availability.get('start_on').format(this.format));
                $firstDaySpan.append($img);

                // Add link
                for (const day of availability.range().by('days')) {
                    const $daySpan = this.getSpanByDate(day.format(this.format));

                    const $link = $(
                        `<a class="calendar-conversation-link" href="${i18n.t('exchange:conversation.url', {
                            id: details.conversation_id
                        })}"></a>`
                    );
                    if (
                        this.props.mode === 'range' &&
                        !this.props.viewOnly &&
                        (day.isSame(availability.get('start_on')) || day.isSame(availability.get('end_on')))
                    ) {
                        // The first and last days are not clickable (the user can select this day in a range)
                        continue;
                    }
                    $daySpan.append($link);
                    addedLinks.push($link);
                }

                // Add tooltip
                const title = i18n.t('home:calendar.booked_range_popup', {
                    firstname: user.get('first_name'),
                    start: availability.get('start_on').format('L'),
                    end: availability.get('end_on').format('L')
                });
                this.addBookingDetailsTooltip(title, $img, addedLinks);
            }
        });
    }

    /**
     * Create the booking details tooltip
     * @param {string} title Text of the tooltip
     * @param {jQuery} $elem jQuery element on which to add the tooltip
     * @param {jQuery[]} $triggers jQuery elements which triggers the opening of the tooltip
     */
    addBookingDetailsTooltip(title, $elem, $triggers) {
        let timeOut;
        let isShowing = false;
        let isHiding = false;
        let hideWhenShowed = false;
        let showWhenHidden = false;

        $elem.tooltip({
            placement: 'top',
            title,
            container: this.calendarRef.current
        });

        const showTooltip = () => {
            $elem.tooltip('show');
            isShowing = true;
        };

        const hideTooltip = () => {
            $elem.tooltip('hide');
            isHiding = true;
        };

        // Showing the tooltip is asyncronous, wait for tooltip to be displayed to hide it
        $elem.on('shown.bs.tooltip', () => {
            isShowing = false;
            if (hideWhenShowed) {
                // We requested the tooltip to be hidden while it was not yet showed, hide it now
                hideWhenShowed = false;
                hideTooltip();
            }
        });

        // Hiding the tooltip is asyncronous, wait for tooltip to be hidden to show it
        $elem.on('hidden.bs.tooltip', () => {
            isHiding = false;
            if (showWhenHidden) {
                // We requested the tooltip to be shown while it was not yet hidden, show it now
                showWhenHidden = false;
                showTooltip();
            }
        });

        $triggers.forEach(($trigger) => {
            $trigger.on('mouseover', () => {
                if (timeOut) {
                    // The tooltip is showed but was scheduled to hide, unschedule it
                    clearTimeout(timeOut);
                } else if (isHiding) {
                    // The tooltip is being hidden, wait until it is to show it again
                    showWhenHidden = true;
                } else {
                    showTooltip();
                }
            });

            $trigger.on('mouseout', () => {
                if (timeOut) {
                    // Remove previous timeout to start a new one
                    clearTimeout(timeOut);
                }
                // Set a timeout to make sure the tooltip doesn't close when going between two dates
                timeOut = setTimeout(() => {
                    if (isShowing) {
                        // The tooltip is not yet shown, hide it once it's shown
                        hideWhenShowed = true;
                    } else {
                        hideTooltip();
                    }
                    // Resert timeout
                    timeOut = null;
                }, 200);
            });
        });
    }

    /**
     * Prevent some dates from being selected
     * @param  {Moment} date clicked date
     * @return {bool} true if the date is ok, false while prevent selection
     */
    dateClickedHandler(date) {
        const selectedDates = this.calendar.getSelectedRaw();
        // Set date at midnight
        date.hour(0);

        if (selectedDates.length >= 1) {
            // We are going to select the second date
            if (date.isSame(selectedDates[0])) {
                // Start date = end date, remove hover and selected dates
                this.calendar.setSelected([]);
                this.clearHover();
                return false;
            }

            const selectedRange = moment.range(selectedDates[0], date);
            const matchBookedPeriod = this.props.availabilities.some(
                (availability) =>
                    availability.get('type') === Availability.BOOKED.type &&
                    availability.range().isSame(selectedRange)
            );

            if (matchBookedPeriod) {
                // The period selected is a booked period
                return false;
            }
        }

        return true;
    }

    /**
     * When pressing the escape key, remove current selection
     */
    escapeKeyHandler() {
        $(document).keyup((e) => {
            if (e.keyCode === 27 && this.calendar) {
                this.calendar.setSelected(null);
            }
        });
    }

    /**
     * Call dedicated callback on view-changed
     */
    viewChangedHandler() {
        if (this.props.viewChangedHandler) {
            // viewChangedHandler is thrown before the calendar is drawn again
            setTimeout(this.props.viewChangedHandler, 1);
        }
        if (this.props.showBookingDetails) {
            setTimeout(this.addBookingDetails.bind(this), 1);
        }
        return true;
    }

    /**
     * Handle date hover
     */
    dateHoveredHandler() {
        if (!this.calendar) {
            return;
        }

        // Show range on hover when one date is selected
        $(this.calendar.container)
            .find('.k-calendar')
            .on('mouseenter', 'span.k-in-month, span.k-out-of-month', (e) => {
                const selectedDates = this.calendar.getSelectedRaw();
                const $target = $(e.currentTarget);

                if (this.shouldHoverDate(selectedDates, $target)) {
                    this.addHover(
                        selectedDates[0],
                        moment($(e.currentTarget).attr('data-date'), this.format)
                    );
                }
            });
    }

    /**
     * Remove the hover effect when the cursor mouves out of the calendar
     */
    hideHoverOnMouseout() {
        // When the cursor leaves the calendar, hide the hover
        $(this.calendar.container)
            .find('.k-calendar')
            .on('mouseleave', '.k-days', () => {
                this.clearHover();
            });

        // When hovering out of month or reserved days, hide hover
        $(this.calendar.container)
            .find('.k-calendar')
            .on('mouseenter', '.k-out-of-month, .reserved', () => {
                this.clearHover();
            });
    }

    /**
     * Returns true if a hover should be displayed for this date
     * @param  {Moment[]} selectedDates Dates currently selected
     * @param  {jQuery} $target the hovered span
     * @return {bool} Whether we should display a hover or not
     */
    shouldHoverDate(selectedDates, $target) {
        // Show hover only when one date is selected or the two are identical
        const showHoverRange =
            selectedDates.length === 1 ||
            (selectedDates.length === 2 && selectedDates[0].isSame(selectedDates[1]));

        if (showHoverRange && $target.length > 0 && $target.attr('data-date')) {
            if ($target.hasClass('booked') || $target.hasClass('past')) {
                // Booked range and past dates are not clickable
                this.clearHover();
                return false;
            } else if ($target.hasClass('booked-start') || $target.hasClass('booked-end')) {
                // When hovering the start/end of a booked period, show hover only if we didn't exactly select the booked period
                const matchBookedPeriod = this.props.availabilities.some((availability) => {
                    if (availability.get('type') === Availability.BOOKED.type) {
                        const availabilityRange = moment.range(
                            availability.get('start_on'),
                            availability.get('end_on')
                        );
                        const hoveredDate = moment($target.attr('data-date'), this.format);
                        let selectedRange;
                        if (hoveredDate.isBefore(selectedDates[0])) {
                            selectedRange = moment.range(hoveredDate, selectedDates[0]);
                        } else {
                            selectedRange = moment.range(selectedDates[0], hoveredDate);
                        }

                        if (availabilityRange.isSame(selectedRange)) {
                            return true;
                        }
                    }
                    return false;
                });

                if (matchBookedPeriod) {
                    this.clearHover();
                    return false;
                }
            }

            if (this.customShowHoverRange) {
                return this.customShowHoverRange(selectedDates, $target);
            }

            // Any other cases, show hover
            return true;
        }

        return false;
    }

    /**
     * Add classes to display hovered dates
     * @param {Moment} pickedDate The first date selected
     * @param {Moment} hoverDate The date currently hovered
     */
    addHover(pickedDate, hoverDate) {
        let startDate = pickedDate;
        let endDate = hoverDate;

        // Reorder dates
        if (startDate.isAfter(endDate)) {
            const temp = startDate.clone();
            startDate = endDate;
            endDate = temp;
        }

        // Remove previous hover
        this.clearHover();

        const range = moment.range(startDate, endDate);
        for (const day of range.by('days')) {
            // For each day between the selected and the hovered date, add the k-hover class
            if (day.isSame(startDate)) {
                this.getSpanByDate(day.format(this.format)).addClass('k-hover-start');
            } else if (day.isSame(endDate)) {
                this.getSpanByDate(day.format(this.format)).addClass('k-hover-end');
            } else {
                this.getSpanByDate(day.format(this.format)).addClass('k-hover');
            }
        }
    }

    /**
     * Remove current hover styles
     */
    clearHover() {
        $(this.calendar.container).find('.k-days span.k-hover').removeClass('k-hover');
        $(this.calendar.container).find('.k-days span.k-hover-start').removeClass('k-hover-start');
        $(this.calendar.container).find('.k-days span.k-hover-end').removeClass('k-hover-end');
    }

    /**
     * Creates a dateClassMap to create the availabilities
     * @param {Object}   nextProps Next properties (after update)
     */
    setDateClassMap(nextProps) {
        const dateClassMap = this.getDateClassMap(nextProps);

        this.setState({
            dateClassMap
        });
    }

    /**
     * Returns a dateClassMap object, easy to override
     * @param {Object}   nextProps Next properties (after update)
     */
    getDateClassMap(nextProps) {
        const dateClassMap = {};

        // Days before today
        if (moment().diff(moment().startOf('month'), 'days') > 0) {
            // Only if we are not the first day of the month
            const beforeTodayRange = moment.range(moment().startOf('month'), moment().subtract(1, 'day'));
            for (const day of beforeTodayRange.by('days')) {
                dateClassMap[day.format(this.format)] = 'past';
            }
        }

        // Last day of the month
        const everyDisplayedMonthsRange = moment.range(moment(), moment().add(nextProps.nbMonths, 'months'));
        for (const month of everyDisplayedMonthsRange.by('months')) {
            dateClassMap[month.endOf('month').format(this.format)] = 'end-month';
        }

        nextProps.availabilities.forEach((availability) => {
            this.setClassesForAvailability(dateClassMap, availability);
        });

        return dateClassMap;
    }

    /**
     * Fill dateClassMap for a given availability
     * @param {Array} dateClassMap dateClassMap to fill
     * @param {Availability} availability
     */
    setClassesForAvailability(dateClassMap, availability) {
        // Add class for the last day of each week
        const lastWeekDay = moment().endOf('week').isoWeekday();

        const start = availability.get('start_on');
        const end = availability.get('end_on');
        const range = moment.range(start, end);

        for (const day of range.by('days')) {
            // Add custom classes for each day
            const classes = [];
            let suffix = '';

            if (day.isSame(start)) {
                // First day of the range
                suffix += '-start';
            }

            if (day.isSame(end)) {
                // Last day of the range
                suffix += '-end';
            }

            classes.push(Availability[availability.get('type')].className + suffix);

            if (dateClassMap[day.format(this.format)]) {
                // Get previous classes
                classes.push(dateClassMap[day.format(this.format)]);
            }

            if (day.isoWeekday() === lastWeekDay) {
                // If it's the last day of the week
                classes.push('end-week');
            }
            dateClassMap[day.format(this.format)] = classes.join(' ');
        }
    }

    /**
     * Compute blackout based on availabilities
     * @return {Array} Array of blackout dates
     */
    getBlackout() {
        let blackout = [];

        // On view mode, dates should never be selectable
        if (this.props.viewOnly) {
            blackout = () => true;
        } else {
            // Past dates are not selectable
            if (moment().diff(moment().startOf('month'), 'days') > 0) {
                // Only if we are not the first day of the month
                const beforeTodayRange = moment.range(moment().startOf('month'), moment().subtract(1, 'day'));
                for (const day of beforeTodayRange.by('days')) {
                    blackout.push(day.format(this.format));
                }
            }

            // To detect overlapping booked periods
            const overlappingBookedPeriods = {
                start: [],
                end: []
            };

            // Booked periods are not selectable
            this.props.availabilities.forEach((availability) => {
                if (availability.get('type') === Availability.BOOKED.type) {
                    const startOn = availability.get('start_on').clone();
                    const endOn = availability.get('end_on').clone();
                    const range = moment.range(startOn.add(1, 'day'), endOn.subtract(1, 'day'));

                    if (
                        overlappingBookedPeriods.start.indexOf(
                            availability.get('end_on').format(this.format)
                        ) >= 0
                    ) {
                        blackout.push(availability.get('end_on').format(this.format));
                    }

                    if (
                        overlappingBookedPeriods.end.indexOf(
                            availability.get('start_on').format(this.format)
                        ) >= 0
                    ) {
                        blackout.push(availability.get('start_on').format(this.format));
                    }

                    overlappingBookedPeriods.start.push(availability.get('start_on').format(this.format));
                    overlappingBookedPeriods.end.push(availability.get('end_on').format(this.format));

                    // Make sure the range covers more than two days
                    if (Math.abs(availability.get('start_on').diff(availability.get('end_on'), 'days')) > 1) {
                        for (const day of range.by('days')) {
                            blackout.push(day.format(this.format));
                        }
                    }
                }
            });
        }

        return blackout;
    }

    /**
     * Call a callback when selected dates have changed
     */
    changeHandler() {
        if (this.props.selectedHandler) {
            const selectedDates = this.calendar.getSelectedRaw();
            // Call handler only if no dates or two are selected
            if (
                (this.props.mode === 'range' && selectedDates.length === 0) ||
                selectedDates.length === 2 ||
                this.props.mode === 'single'
            ) {
                this.props.selectedHandler(selectedDates);
            }
        }

        // When the Calendar is extended to be used in a form
        if (this.checkValidity) {
            this.checkValidity();
        }
    }

    /**
     * Return the span corresponding to a given date
     * @param  {Moment} date the date for which to find the span
     * @return {jQuery} span with the given date
     */
    getSpanByDate(date) {
        return $(this.calendar.container).find(`span.k-in-month[data-date="${date}"]`);
    }

    /**
     * add a jQuery tooltip on non selected calendar periods
     */
    addTooltipOnSpanWithNoAvailabilityType() {
        const allCalendarSpanElmts = $(this.calendar.container).find('span.k-in-month');
        const availabilityTypesAndPastDateFilter = /^(non-reciprocal|reciprocal|booked|available|past)/;
        const tooltipElmts = _.filter(allCalendarSpanElmts, (elmt) => {
            const elmtClasses = $(elmt).attr('class').split(' ');
            return !elmtClasses.some((cl) => availabilityTypesAndPastDateFilter.test(cl));
        });

        tooltipElmts.forEach((sp) => {
            $(sp).tooltip({
                placement: 'bottom',
                title: i18n.t('home:calendar.white_availability_popup_text')
            });
            $(sp).on('mouseover', () => $(sp).tooltip('show'));
            $(sp).on('mouseout', () => $(sp).tooltip('hide'));
        });
    }
}
