import React, { useState } from 'react';
import { Marker, Popup } from 'react-map-gl';
import PropTypes from 'prop-types';

import Utils from '../../../../utils/utils';

const MarkerPopup = ({ latitude, longitude, content, customMarker }) => {
    const [openPopup, setOpenPopup] = useState(false);

    if (customMarker) {
        return customMarker;
    } else {
        return openPopup ? (
            <Popup latitude={latitude} longitude={longitude} onClose={() => setOpenPopup(false)}>
                {content}
            </Popup>
        ) : (
            <Marker
                color={`#${Utils.PRIMARY_COLOR}`}
                latitude={latitude}
                longitude={longitude}
                onClick={() => setOpenPopup(true)}
            />
        );
    }
};

MarkerPopup.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    content: PropTypes.any,
    customMarker: PropTypes.any
};

export default MarkerPopup;
