import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormControl from 'react-bootstrap/lib/FormControl';

export default class InputPassword extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        className: PropTypes.string
    };

    static defaultProps = {
        name: 'password',
        className: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            passwordVisible: false
        };

        this.togglePassword = this.togglePassword.bind(this);
    }

    togglePassword() {
        this.setState({
            passwordVisible: !this.state.passwordVisible
        });
    }

    render() {
        const passwordIconClasses = classNames({
            icon: true,
            'toggle-password': true,
            'icon-eye': !this.state.passwordVisible,
            'icon-eye-slash': this.state.passwordVisible
        });
        const inputClassnames = classNames(this.props.className, 'input-password');

        return (
            <span>
                <FormControl
                    type={this.state.passwordVisible ? 'text' : 'password'}
                    {...this.props}
                    className={inputClassnames}
                />
                <i className={passwordIconClasses} onClick={this.togglePassword} aria-hidden="true"></i>
            </span>
        );
    }
}
