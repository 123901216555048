import Common from './Common';

export default class Subscription {
    static getSubscription() {
        const params = Common.params({
            url: `/v1/subscription`,
            method: 'get'
        });
        return Common.request(params);
    }

    static updateSubscription(data) {
        const params = Common.params({
            url: `/v1/subscription`,
            method: 'patch',
            data
        });
        return Common.request(params);
    }

    static paySubscription(data, hasCollectionProduct) {
        if (hasCollectionProduct) {
            data.product = 'collection';
        }

        const params = Common.params({
            url: `/v1/subscription`,
            method: 'post',
            data
        });
        return Common.request(params);
    }

    static paySubscription3x(data) {
        const params = Common.params({
            url: `/v1/subscription/3x`,
            method: 'post',
            data
        });
        return Common.request(params);
    }

    static paySubscriptionWithToken(stripeToken, promoCode, hasCollectionProduct) {
        return Subscription.paySubscription(
            {
                stripeToken,
                coupon_name: promoCode
            },
            hasCollectionProduct
        );
    }

    static paySubscriptionWithToken3x(stripeToken, promoCode, hasCollectionProduct) {
        return Subscription.paySubscription3x(
            {
                stripeToken,
                coupon_name: promoCode
            },
            hasCollectionProduct
        );
    }

    static paySubscriptionWithPaypal(braintreeNonce, braintreeDeviceData, promoCode, hasCollectionProduct) {
        return Subscription.paySubscription(
            {
                braintreeNonce,
                braintreeDeviceData,
                coupon_name: promoCode
            },
            hasCollectionProduct
        );
    }

    static paySubscriptionWithExistingCard(cardId, promoCode, hasCollectionProduct) {
        return Subscription.paySubscription(
            {
                card_id: cardId,
                coupon_name: promoCode
            },
            hasCollectionProduct
        );
    }

    static paySubscriptionWithExistingCard3x(cardId, promoCode, hasCollectionProduct) {
        return Subscription.paySubscription(
            {
                card_id: cardId,
                coupon_name: promoCode
            },
            hasCollectionProduct
        );
    }

    static paySubscriptionWithExistingPaypalAccount(id, promoCode, hasCollectionProduct) {
        return Subscription.paySubscription(
            {
                braintreeId: id,
                coupon_name: promoCode
            },
            hasCollectionProduct
        );
    }
}
