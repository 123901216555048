import {
    REQUEST_MESSAGES,
    RECEIVE_MESSAGES,
    SENDING_MESSAGE,
    ADD_MESSAGE,
    CLEAR_MESSAGES
} from '../actions/messages';

const messages = (
    state = {
        isFetching: false,
        isSending: false,
        messages: []
    },
    action
) => {
    switch (action.type) {
        case REQUEST_MESSAGES:
            return Object.assign({}, state, {
                isFetching: true,
                messages: []
            });
        case RECEIVE_MESSAGES:
            return Object.assign({}, state, {
                isFetching: false,
                messages: action.messages
            });
        case SENDING_MESSAGE:
            return Object.assign({}, state, {
                isSending: true
            });
        case ADD_MESSAGE:
            return Object.assign({}, state, {
                isSending: false,
                messages: [...state.messages, action.message]
            });
        case CLEAR_MESSAGES:
            return Object.assign({}, state, {
                messages: []
            });
        default:
            return state;
    }
};

export default messages;
