import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import Utils from '../../utils/utils';

import CarouselComponent from '../common/carousel/carousel';

const DestinationsCarousel = ({ destinations, title, subtitle, hasText = false }) => {
    const slides = destinations.map((destination) => {
        return (
            <a
                key={destination.destination}
                href={hasText ? destination.slug : i18n.t('search:search_url', { slug: destination.slug })}
            >
                <div className="destination-card">
                    <h3 className="card-title">{destination.destination}</h3>
                    <img
                        className="background-image"
                        src={Utils.getCloudfrontDomain() + destination.imgSrc}
                    />
                </div>
            </a>
        );
    });
    const responsive = [
        {
            breakpoint: 991,
            settings: {
                infinite: true
            }
        }
    ];

    return (
        <>
            <CarouselComponent
                className="dashboard-module"
                classNameHeader="container"
                title={title}
                subtitle={subtitle}
                slides={slides}
                nbSlides={3}
                variableWidth={true}
                responsive={responsive}
            />
            {hasText && (
                <div className="container details">
                    <p
                        className="home-page-block-text"
                        dangerouslySetInnerHTML={{
                            __html: i18n.t('home:find-home-exchange')
                        }}
                    />
                </div>
            )}
        </>
    );
};

DestinationsCarousel.propTypes = {
    destinations: PropTypes.array,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    hasText: PropTypes.bool
};

export default DestinationsCarousel;
