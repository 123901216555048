import React from 'react';
import PropTypes from 'prop-types';

const NewUserLabel = ({ label }) => <span id="new-user-label">{label}</span>;

NewUserLabel.propTypes = {
    label: PropTypes.string
};

export default NewUserLabel;
