import React from 'react';
import classNames from 'classnames';
import CustomEvent from 'custom-event';
import Kalendae from 'kalendae';
import Calendar from './Calendar';

export default class NewInputRangeCalendar extends Calendar {
    customClass = 'block-calendar';

    componentDidMount() {
        this.drawCalendar();
        this.escapeKeyHandler();
        this.removeSelectionHandler();

        const event = new CustomEvent('calendar_did_mount');
        document.dispatchEvent(event);
    }

    /**
     * Draws calendar using Kalendae
     */
    customDrawCalendar(kalendaeOptions) {
        if (this.calendarRef?.current.querySelector('.kalendae')) {
            this.calendarRef?.current.removeChild(this.calendarRef.current.querySelector('.kalendae'));
        }

        kalendaeOptions.format = 'DD/MM/YYYY';
        kalendaeOptions.closeOnSelection = true;

        this.calendar = new Kalendae(kalendaeOptions);

        $('.k-in-month:not(.past)').addClass('k-active');

        $(document).on('click', '.k-in-month:not(.past)', function () {
            $('.k-in-month:not(.past)').addClass('k-active');
        });

        this.calendar.subscribe(
            'change',
            function () {
                const selectedText = this.calendar.getSelectedAsText();
                const selectedDates = this.calendar.getSelectedAsDates();

                if (selectedText[0] && selectedText[1]) {
                    this.props.selectedHandler([moment(selectedDates[0]), moment(selectedDates[1])]);
                    $('.calendar-v2').scrollTop(0);
                }

                if (selectedText[0]) {
                    if (this.props.startOn && this.props.endOn) {
                        const selectedDate = moment(selectedDates[0]);
                        const daysToStart = Math.abs(selectedDate.diff(this.props.startOn, 'days'));
                        let daysToEnd = Math.abs(selectedDate.diff(this.props.endOn, 'days'));

                        if (selectedDate.isSame(this.props.endOn, 'day') === false && daysToEnd === 0) {
                            daysToEnd = 1;
                        }

                        if (daysToStart <= daysToEnd) {
                            this.props.selectedHandler([selectedDate, this.props.endOn]);
                        } else {
                            this.props.selectedHandler([
                                daysToEnd === 0 ? null : this.props.startOn,
                                daysToEnd === 0 ? null : selectedDate
                            ]);
                        }
                    }
                }
            }.bind(this, $)
        );
    }

    /**
     * When clicking outside the calendar, remove the current selection
     */
    removeSelectionHandler() {
        if (!this.calendar) {
            this.props.setShowModal(false);
            return;
        }

        $(document).click((e) => {
            // Remove selection when clicking outside
            if (!$(e.target).closest($(this.calendar.container).find('.k-days')).length) {
                if (!this.props.maintainSelectionOnClickOutside === true) {
                    this.calendar.setSelected(null);
                }
            }
        });
    }

    render() {
        const calendarClasses = classNames({
            'calendar-v2': true,
            'view-only': this.props.viewOnly,
            edit: !this.props.viewOnly
        });

        return <div ref={this.calendarRef} className={calendarClasses} style={this.props.style}></div>;
    }
}
