import React from 'react';
import classNames from 'classnames';
import CustomEvent from 'custom-event';
import Kalendae from 'kalendae';
import Calendar from './Calendar';

export default class BlockCalendar extends Calendar {
    customClass = 'block-calendar';

    componentDidMount() {
        this.drawCalendar();
        this.escapeKeyHandler();
        this.removeSelectionHandler();

        const event = new CustomEvent('calendar_did_mount');
        document.dispatchEvent(event);
    }

    /**
     * Draws calendar using Kalendae
     */
    customDrawCalendar(kalendaeOptions) {
        // If refresh, remove the previous instance
        if (this.calendarRef?.current.querySelector('.kalendae')) {
            this.calendarRef?.current.removeChild(this.calendarRef.current.querySelector('.kalendae'));
        }

        this.calendar = new Kalendae(kalendaeOptions);
    }

    /**
     * When clicking outside the calendar, remove the current selection
     */
    removeSelectionHandler() {
        // Remove current selection when clicking outside the calendar
        if (!this.calendar) {
            return;
        }

        $(document).click((e) => {
            // Remove selection when clicking outside
            if (!$(e.target).closest($(this.calendar.container).find('.k-days')).length) {
                this.calendar.setSelected(null);
            }
        });
    }

    render() {
        const calendarClasses = classNames({
            'calendar-v2': true,
            'view-only': this.props.viewOnly,
            edit: !this.props.viewOnly
        });

        return <div ref={this.calendarRef} className={calendarClasses} style={this.props.style}></div>;
    }
}
