import React from 'react';
import PropTypes from 'prop-types';

import i18n from '../../i18n';
import Utils from '../../utils/utils';

import Group from '../../models/Group';
import User from '../../models/User';

import Tooltip from '../widget/Tooltip';

export default class GroupList extends React.PureComponent {
    static propTypes = {
        groups: PropTypes.arrayOf(PropTypes.instanceOf(Group)),
        filter: PropTypes.func,
        context: PropTypes.oneOf(['me', 'user']),
        user: PropTypes.instanceOf(User)
    };

    static defaultProps = {
        groups: [],
        filter: () => true,
        context: 'me'
    };

    render() {
        const groups = this.props.groups.filter(this.props.filter);
        if (groups.length === 0) {
            return (
                <div>
                    {i18n.t('user:no_groups', {
                        context: this.props.context,
                        first_name: this.props.user && this.props.user.get('first_name')
                    })}
                </div>
            );
        }
        return (
            <div className="groups-list">
                {groups.map((group) => (
                    <Tooltip
                        key={`group_${group.id}`}
                        placement="top"
                        title={i18n.t(`search:${group.label()}`)}
                    >
                        <a
                            href={i18n.t('url:groups.consult', { id: group.id })}
                            className="groups-list-item"
                            style={{
                                backgroundImage: `url(${Utils.getCloudfrontDomain()}/images/groups/group_${
                                    group.id
                                }/logo.jpg)`
                            }}
                        >
                            {i18n.t(`search:${group.label()}`)}
                        </a>
                    </Tooltip>
                ))}
            </div>
        );
    }
}
