import Api from '../api/Api';

export const SEARCH_PROCESSING = 'SEARCH_PROCESSING';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const SEARCH_SET_DISPLAY_ALERTS = 'SEARCH_SET_DISPLAY_ALERTS';

export function searchProcessing(payload, isAdditional = false) {
    return {
        type: SEARCH_PROCESSING,
        isAdditional,
        ...payload
    };
}

export function searchResults(results, isAdditional = false) {
    return {
        type: SEARCH_RESULTS,
        bounds: results.bounds,
        results: results.homes,
        resultsCount: results.total,
        resultsStats: results.stats,
        lastSearchId: results.last_search_id,
        isAdditional
    };
}
const getUnchangedDataFromCalendar = (calendar) => {
    return {
        ...(calendar.exchange_types && { exchange_types: calendar.exchange_types })
    };
};
export function search(payload, isAdditional = false) {
    return (dispatch) => {
        dispatch(searchProcessing(payload, isAdditional));

        const { ...filters } = payload.filters;

        if (filters.preferred_destination && filters.preferred_destination.address) {
            filters.preferred_destination = {
                admin_level: filters.preferred_destination.admin_level,
                bounds: filters.preferred_destination.bounds
            };
        }

        if (filters.calendar?.last_minute && !isAdditional) {
            filters.calendar = {
                date_ranges: [
                    {
                        from: moment().format('YYYY-MM-DD'),
                        to: moment().add(15, 'days').format('YYYY-MM-DD')
                    }
                ],
                duration: {
                    number_of_nights: 1
                },
                ...getUnchangedDataFromCalendar(filters.calendar)
            };
        }

        return Api.Search.findHomes(
            filters,
            payload.offset,
            payload.limit,
            payload.queryString,
            payload.placeId,
            payload.lastSearchId,
            isAdditional
        ).then((results) => {
            dispatch(searchResults(results, isAdditional));
            return results;
        });
    };
}

export function setDisplayAlertsComponentStatus(payload) {
    return {
        type: SEARCH_SET_DISPLAY_ALERTS,
        payload
    };
}
