import { createStore, applyMiddleware, compose } from 'redux';
// import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import trackingMiddleware from './trackingMiddleware';
import reducers from '../reducers/index';

// const loggerMiddleware = createLogger();

export default function configureStore(initialState) {
    const finalCreateStore = compose(
        applyMiddleware(
            trackingMiddleware,
            thunkMiddleware,
            promiseMiddleware
            // loggerMiddleware
        ),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
    )(createStore);

    const store = finalCreateStore(reducers, initialState);

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers').default;
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
