import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import i18n from '../../i18n';
import UserModel from '../../models/User';
import BadgeVerified from '../common/BadgeVerified';
import useBadge from '../../hooks/useBadge';

export default function UserImage(props) {
    const {
        verified,
        forceVerifiedBadge = false,
        width = 200,
        height = 200,
        noLink,
        isConnected = true,
        fromUserView = false
    } = props;
    const [user, setUser] = useState(null);
    const [url, setUrl] = useState(null);
    const { level, badge } = useBadge(props.user.get('id'));
    const picture = useRef(null);

    const loadImage = (element) => {
        if (element.getAttribute('data-picture')) {
            const img = new Image();
            img.onload = function () {
                element.style.backgroundImage = `url(${this.src})`;
                $(element).closest('.lazyloading').removeClass('lazyloading');
            };
            img.onerror = function () {
                $(element).addClass('no-picture');
                $(element).closest('.lazyloading').removeClass('lazyloading');
            };
            img.src = element.getAttribute('data-picture');
        } else {
            $(element).addClass('no-picture');
            $(element).closest('.lazyloading').removeClass('lazyloading');
        }
    };

    const onUserImageClick = (e) => {
        if (!isConnected) {
            e.preventDefault();

            // On offline mode, clicking the user picture opens the Signup popup
            const event = new CustomEvent('open_signup_popup', {
                detail: {
                    version: 'contact'
                }
            });
            document.dispatchEvent(event);
        }
    };

    const scrollToPathCompletion = () => {
        const pathCompletionElement = document.getElementById('user-loyalty-completion');
        document.body.parentNode.scrollTo({
            top: pathCompletionElement.offsetTop - 300,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        if (picture.current) {
            loadImage(picture.current);
        }
    });

    useEffect(() => {
        setUser(props.user instanceof UserModel ? props.user : new UserModel(props.user));
    }, [props.user]);

    useEffect(() => {
        if (user) {
            setUrl(i18n.t('user:user.view.url', { id: user.id }));
        }
    }, [user]);

    return (
        user &&
        url && (
            <div className="user-picture lazyloading">
                <link itemProp="image" href={user.picture(null, null, true)} />
                {(() => {
                    if (noLink) {
                        return (
                            <span
                                className="user-image"
                                data-picture={user.picture(width, height, true)}
                                ref={picture}
                            ></span>
                        );
                    } else {
                        return (
                            <a
                                className="user-image"
                                onClick={onUserImageClick}
                                href={url}
                                title={user.get('first_name')}
                                data-picture={user.picture(width, height, true)}
                                ref={picture}
                            />
                        );
                    }
                })()}
                {fromUserView
                    ? ((verified && user.isVerified()) || forceVerifiedBadge) &&
                      parseInt(level, 10) === 0 && <BadgeVerified noLink withTooltip={false} />
                    : ((verified && user.isVerified()) || forceVerifiedBadge) && (
                          <BadgeVerified noLink withTooltip={false} />
                      )}
                {fromUserView && parseInt(level, 10) > 0 && (
                    <img
                        className="user-image-loyalty-badge"
                        src={badge?.src}
                        alt={badge?.alt}
                        onClick={scrollToPathCompletion}
                    />
                )}
            </div>
        )
    );
}

UserImage.propTypes = {
    user: PropTypes.object.isRequired,
    verified: PropTypes.bool,
    forceVerifiedBadge: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    noLink: PropTypes.bool,
    isConnected: PropTypes.bool,
    fromUserView: PropTypes.bool
};
