import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import i18n from '../../../i18n';
import Home from '../../../models/Home';
import Notification from '../../../models/Notification';
import Transfert from '../../../models/Transfert';
import User from '../../../models/User';

import { Icon, Text } from '@homeexchange/design';
import Availability from '../../../models/Availability';
import Utils from '../../../utils/utils';
import { trackHeaderMenu, HEADER_MENU_TRACKING_DATA_TEXT } from '../../../utils/utils-ts';
import CollectionLabel from '../../home/CollectionLabel';
import HomeStatus from './HomeStatus';

const MenuProfileHome = ({ home, single = false, notifications, user, availabilities }) => {
    const getTitle = () => {
        if (single) {
            return i18n.t('fillingpanel:my_home');
        }
        if (home.get('address')) {
            return home.get('address');
        }
        return home.title();
    };

    const calculateNights = (start_on, end_on) => {
        const startDate = moment(start_on);
        const endDate = moment(end_on);

        return endDate.diff(startDate, 'days');
    };

    const getAvailableNights = () => {
        const availablePeriods = availabilities?.filter(
            (a) => ![Availability.BOOKED.type, Availability.RESERVED.type].includes(a.get('type'))
        );

        const availableNights = availablePeriods?.reduce((acc, period) => {
            return acc + calculateNights(period.attributes.start_on, period.attributes.end_on);
        }, 0);

        return availableNights;
    };

    const getHomeCompletionRateMessage = () => {
        if (!notifications) {
            return;
        }
        const homeNotif = notifications.getHomeNotification(home.id);

        switch (homeNotif?.reason) {
            case Notification.HOME_REASON_OFFLINE:
                return i18n.t('fillingpanel:publish_home');
            case Notification.HOME_REASON_COMPLETE_YOUR_HOME:
                return i18n.t('fillingpanel:complete_home');
            case Notification.HOME_REASON_COMPLETE_YOUR_HOME_FIRST_TIME:
                return i18n.t('fillingpanel:complete_home_to_receive_gp2', {
                    gp: Transfert.MOTIVES_VALUE[Transfert.MOTIVE_FILLING_WRITE_HOME]
                });
            case Notification.HOME_REASON_COMPLETE_YOUR_HOME_EMPTY_CALENDAR:
                return i18n.t('home:add_availabilities');
            case Notification.HOME_REASON_UPDATE_CALENDAR_TOO_OLD:
                return i18n.t('home:top-menu-availabilities-up-to-date');
            default:
                return null;
        }
    };

    const handleHomeListingClick = () => {
        switch (notifications?.getHomeNotification(home.id)?.reason) {
            case Notification.HOME_REASON_OFFLINE:
                trackHeaderMenu(HEADER_MENU_TRACKING_DATA_TEXT.publishHome);
                break;
            case Notification.HOME_REASON_COMPLETE_YOUR_HOME:
                trackHeaderMenu(HEADER_MENU_TRACKING_DATA_TEXT.completeHome);
                break;
            case Notification.HOME_REASON_COMPLETE_YOUR_HOME_FIRST_TIME:
                trackHeaderMenu(HEADER_MENU_TRACKING_DATA_TEXT.completeHomeFirst);
                break;
            case Notification.HOME_REASON_COMPLETE_YOUR_HOME_EMPTY_CALENDAR:
                trackHeaderMenu(HEADER_MENU_TRACKING_DATA_TEXT.addAvailabilities);
                break;
            case Notification.HOME_REASON_UPDATE_CALENDAR_TOO_OLD:
                trackHeaderMenu(HEADER_MENU_TRACKING_DATA_TEXT.updateAvailabilities);
                break;
            default:
                if (availabilities && home.get('status') === Home.STATUS_ONLINE) {
                    trackHeaderMenu(HEADER_MENU_TRACKING_DATA_TEXT.homeCompleted);
                }
                break;
        }
    };

    useEffect(() => {
        const currentListing = document.querySelector(`#top-menu-home-${home.id}`);
        currentListing?.addEventListener('click', handleHomeListingClick);

        return () => {
            currentListing.removeEventListener('click', handleHomeListingClick);
        };
    }, [home]);

    if (!home) {
        return null;
    }

    const homeCompletionRateMessage = getHomeCompletionRateMessage();

    return (
        <li role="menuitem" id={`top-menu-home-${home.id}`} className="home-link">
            <a className="link-top-profile item-link" href={home.getEditUrl()}>
                <div className="item">
                    <div className="dropdown-title">
                        <Icon size="2xl" name="header-profile-home" />
                        <span>{getTitle()}</span>
                    </div>
                    {home.get('status') < Home.STATUS_ONLINE && home.get('completion_rate') >= 80 && (
                        <Text isSpan size="sm" color="tertiary">
                            {i18n.t('home:unlisted')}
                        </Text>
                    )}
                    {availabilities ? (
                        <>
                            {home.get('status') === Home.STATUS_ONLINE &&
                                availabilities &&
                                (getAvailableNights() > 0 ? (
                                    <Text isSpan size="sm" color="eco-green">
                                        {i18n.t('home:available-nights', { nights: getAvailableNights() })}
                                    </Text>
                                ) : (
                                    <Text isSpan size="sm" color="tertiary">
                                        {i18n.t('home:no-available-nights')}
                                    </Text>
                                ))}
                        </>
                    ) : (
                        <img
                            className="loader-availabilities"
                            src={`${Utils.getCloudfrontDomain()}/images/website/loader-${
                                window.gtg.heCollection ? 'prestige' : 'yellow'
                            }.svg`}
                            alt="Loading"
                        />
                    )}
                </div>
                {(homeCompletionRateMessage || home.get('completion_rate') < 100) && (
                    <div className="section-status-container">
                        {homeCompletionRateMessage && (
                            <Text color="secondary" size="sm" isBold className="top-menu-messages">
                                {homeCompletionRateMessage}
                            </Text>
                        )}
                        {home.get('completion_rate') < 100 && <HomeStatus home={home} />}
                    </div>
                )}
                <CollectionLabel home={home} user={user} />
            </a>
        </li>
    );
};

MenuProfileHome.propTypes = {
    home: PropTypes.instanceOf(Home),
    single: PropTypes.bool,
    notifications: PropTypes.instanceOf(Notification),
    user: PropTypes.instanceOf(User),
    availabilities: PropTypes.arrayOf(PropTypes.instanceOf(Availability))
};

MenuProfileHome.defaultProps = {
    single: false
};

export default MenuProfileHome;
