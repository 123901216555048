import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import i18n from '../../i18n';
import User from '../../models/User';

export default class TranslateButton extends React.PureComponent {
    static propTypes = {
        translations: PropTypes.object,
        user: PropTypes.instanceOf(User),
        toLanguage: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        loading: PropTypes.bool
    };

    static defaultProps = {
        translations: {}
    };

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.canBeTranslated = this.canBeTranslated.bind(this);
    }

    handleClick(userLocale) {
        return () => this.props.onChange(userLocale);
    }

    canBeTranslated() {
        const translationsLocales = Object.keys(this.props.translations);
        return translationsLocales.length > 0 && !translationsLocales.includes(i18n.language);
    }

    getOriginalLocale() {
        const { translations } = this.props;
        const translation = Object.keys(translations).find((locale) =>
            Object.keys(translations[locale]).find(
                (content) => typeof translations[locale][content] === 'string'
            )
        );
        if (translation) {
            return translation;
        }
        return this.props.user.get('locale');
    }

    render() {
        if (this.canBeTranslated()) {
            return (
                <div id="group-btn-translate">
                    {i18n.language === this.props.toLanguage && this.props.loading === false ? (
                        <button
                            className="btn btn-xs btn-ajax"
                            onClick={this.handleClick(this.getOriginalLocale())}
                        >
                            {i18n.t('common:translate_original_text')}
                        </button>
                    ) : (
                        <button
                            className={classNames('btn btn-xs btn-ajax')}
                            disabled={this.props.loading}
                            onClick={this.handleClick(i18n.language)}
                        >
                            {i18n.t('common:translate_text')}
                        </button>
                    )}
                </div>
            );
        }
        return false;
    }
}
