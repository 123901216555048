import Common from './Common';
import Utils from '../utils/utils';

export default class Product {
    static get(filters) {
        const params = Common.params(
            {
                url: `/v1/products`,
                method: 'get'
            },
            filters
        );
        return Common.request(params);
    }

    static me(currency, filters, fetchCollectionProduct) {
        const params = Common.params(
            {
                url: `/v1/products/${fetchCollectionProduct ? 'collection' : 'me'}`,
                method: 'get'
            },
            filters
        );

        if (currency) {
            params.data = { ...params.data, currency };
        }
        return Common.request(params);
    }

    static getForExchange(exchangeId, filters) {
        const params = Common.params(
            {
                url: `/v1/products/${exchangeId}`,
                method: 'get'
            },
            filters
        );
        return Common.request(params);
    }

    static getPriceCollection(locale) {
        const params = {
            url: `/v1/products/collection/price?locale=${locale}`,
            method: 'get'
        };
        return Common.request(params);
    }

    static getOldPrice() {
        const oldPrice = fetch(`${Utils.getCloudfrontDomain()}/data/prices/loyalty_price_2023.json`).catch(
            (error) => {
                console.log(`Unable to fetch data: ${error.message}`);
            }
        );

        return oldPrice;
    }

    static getNewPrice() {
        const newPrice = fetch(`${Utils.getCloudfrontDomain()}/data/prices/summer22.json`).catch((error) => {
            console.log(`Unable to fetch data: ${error.message}`);
        });

        return newPrice;
    }
}
